import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { H4, P } from '../../../../../components/Collection';
import { Statistic, StatisticContent, StatsContainer } from '../styles';
import { UserContext } from '../../..';
import { fetchAccountStats } from '../../../reducers';

const Stats = ({ isOnDashboard }) => {
  const dispatch = useDispatch();
  const userContext = useContext(UserContext);
  const { accountInfo } = useSelector(state => state.session);

  const userId = userContext?.userId || accountInfo?.userId;
  const profile = userContext?.profile || 'myProfile';

  const { stats: { data, status } } = useSelector(state => state.accounts.info.tabs[profile]);
  const { competition: { singlesPlayed, doublesPlayed } = {} } = data || {};

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchAccountStats({ userId, profile, type: 'stats' }));
    }
  }, [status, dispatch]);

  return (
    <StatsContainer extend isOnDashboard={isOnDashboard}>
      <P large bold>
        <Trans ns="events" i18nKey="competitions">
          Competition Stats
        </Trans>
      </P>

      <P small bold margin="20px 0 10px 0">
        <Trans ns="dashboard" i18nKey="accountLeaderboard.singles">
          Singles
        </Trans>
      </P>
      <Statistic>
        <StatisticContent>
          <P xSmall>{new Date().getFullYear()}</P>
          <H4>{singlesPlayed?.currentYear || 0}</H4>
        </StatisticContent>
        <StatisticContent noBorder>
          <P xSmall><Trans ns="dashboard" i18nKey="allTime">All Time</Trans></P>
          <H4>{singlesPlayed?.allTime || 0}</H4>
        </StatisticContent>
      </Statistic>

      <P small bold margin="20px 0 10px 0">
        <Trans ns="dashboard" i18nKey="accountLeaderboard.doubles">
          Doubles
        </Trans>
      </P>
      <Statistic>
        <StatisticContent>
          <P xSmall>{new Date().getFullYear()}</P>
          <H4>{doublesPlayed?.currentYear || 0}</H4>
        </StatisticContent>
        <StatisticContent noBorder>
          <P xSmall><Trans ns="dashboard" i18nKey="allTime">All Time</Trans></P>
          <H4>{doublesPlayed?.allTime || 0}</H4>
        </StatisticContent>
      </Statistic>
    </StatsContainer>
  );
};

export default Stats;
