import React from 'react';
import { Trans } from 'react-i18next';
import { SocialMediaButton, SocialMediaButtonsContainer } from '../../styles';
import LoadImage from '../../../../../components/common/LoadImage';

const SocialMediaButtonsComponent = ({ clubDetails }) => (
  <SocialMediaButtonsContainer>
    {clubDetails?.facebookUrl && (
      <SocialMediaButton to={clubDetails?.facebookUrl} external>
        <img src={LoadImage('facebook-icon-rounded-black.svg')} alt="" />
      </SocialMediaButton>
    )}

    {clubDetails?.instagramUrl && (
      <SocialMediaButton to={clubDetails?.instagramUrl} external>
        <img src={LoadImage('instagram-icon-black.svg')} alt="" />
      </SocialMediaButton>
    )}

    {clubDetails?.twitterUrl && (
      <SocialMediaButton to={clubDetails?.twitterUrl} external>
        <img src={LoadImage('twitter-icon-black.svg')} alt="" />
      </SocialMediaButton>
    )}

    {clubDetails?.location && (
      // eslint-disable-next-line max-len
      <SocialMediaButton external to={`https://maps.google.com/maps?q=${clubDetails?.location?.coords?.lat},${clubDetails?.location?.coords?.lng}&zoom=9`}>
        <img className="mr5" src={LoadImage('send-icon.svg')} alt="" />
        <Trans ns="clubs" i18nKey="seeOnMap">See on Map</Trans>
      </SocialMediaButton>
    )}
  </SocialMediaButtonsContainer>
);

export default SocialMediaButtonsComponent;
