import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Contain, P } from '../../../../../components/Collection';
import getAgeFromDate from '../../../../../components/common/getAgeFromDate';
import LoadImage from '../../../../../components/common/LoadImage';
import mixpanel from '../../../../../mixpanel';
import { FriendsIcon } from '../../../../Friends/components/FriendCard/styles';
import {
  Avatar, ContainAvatar, SearchOptionContainer, ID, Initials, PlayerLink,
} from '../../../JoinCompetition/components/Search/Option';
import { Button } from './styles';

const Index = ({
  player, isOnLFP, handler, isMe, contact,
}) => {
  const { accountInfo } = useSelector(state => state?.session);
  const { t } = useTranslation('events');
  const {
    id,
    userId,
    profilePicture,
    firstName,
    lastName,
    locationName,
    birthDate,
    gameLevelDouble: level,
  } = player;

  const nameInitials = () => {
    const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
    const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
    return `${first} ${last}`;
  };

  const avatarType = profilePicture ? 'picture' : 'initials';
  const user = {
    avatar: {
      picture: <Avatar src={LoadImage(`accounts/${userId}/${profilePicture}`, true)} alt={nameInitials()} />,
      initials: <Initials><P bold>{nameInitials()}</P></Initials>,
    },
    level: level ? ` - ${t('doubles')}: ${level}` : '',
    location: locationName ? ` - ${locationName}` : '',
  };

  return (
    <SearchOptionContainer isLFP noHover width="100%">
      <PlayerLink href={isMe ? '/my-account' : `/accounts/${id}`} target="_blank">
        <ContainAvatar>
          {user.avatar[avatarType]}
        </ContainAvatar>
        <Contain direction="column" padding="10px 0 10px 0">
          <P bold margin="unset">
            {accountInfo?.friendsIds?.includes(userId) && (
              <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
            )}
            {`${firstName} ${lastName}`}
            <ID>
              #
              {userId}
            </ID>
          </P>
          <Contain align="center">
            <P xSmall margin="unset">{`${birthDate && getAgeFromDate(birthDate)}${ user.location }${user.level}`}</P>
          </Contain>
        </Contain>
      </PlayerLink>
      <Button
        playerButton
        contact={!isMe}
        onClick={isMe ? () => {
          handler(player);
          if (isOnLFP) {
            mixpanel.track('Click on [Opt-Out] to remove myself from the list');
          } else {
            mixpanel.track('Click on [I\'m looking] button displayed when players are already on the list');
          }
        } : () => { contact(player); mixpanel.track('Click on [Chat] for Player on the list'); }}
      >
        <Trans ns="events" i18nKey={`lfp.${!isMe ? 'contact' : isOnLFP ? 'optOut' : 'imLooking'}`}>
          {!isMe ? 'Contact Player' : isOnLFP ? 'Opt out' : 'I\'m looking'}
        </Trans>
      </Button>
    </SearchOptionContainer>
  );
};

export default Index;
