import styled from '@emotion/styled';
import { Link, P } from '../../../../components/Collection';

export const Container = styled.div(({ hasLoader }) => ({
  position: 'relative',
  ...hasLoader && {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  minHeight: '350px',
  padding: '0 0 20px 0',
}));

export const GameTypeTab = styled.div(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  height: '50px',
  width: '95px',
  borderRadius: '4px',
  cursor: 'pointer',
  userSelect: 'none',

  '&:first-of-type': {
    margin: '0 10px 0 0',
  },

  ...active && {
    background: '#EAF6F6',
  },
}));

export const TabOptionsContainer = styled.div({
  display: 'flex',
  width: '100%',
  margin: '20px 0',
  '@media (max-width: 620px)': {
    flexDirection: 'column',
  },
});

export const FiltersContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 0 auto',

  '.form-group': {
    margin: 'unset',
    minWidth: '130px',
    '> div': {
      margin: 'unset',
    },
  },

  '@media (max-width: 620px)': {
    margin: '20px 0 0 0',
  },
});

export const EventsContainer = styled.div({});

export const Event = styled.div({
  border: '1px solid #EAF6F6',
  borderRadius: '10px',
  overflow: 'hidden',
  '&:not(last-of-type)': {
    margin: '0 0 22px 0',
  },
});

export const EventHeader = styled.div(({ doubles = false }) => ({
  background: doubles ? '#FFF0BD' : '#EAF6F6',
  padding: '20px 30px',
  '@media (max-width: 700px)': {
    padding: '20px 10px',
  },
}));

export const BadgeIcon = styled.img({
  margin: '0 5px 0 0',
});

export const BadgeCategory = styled.span({});

export const FriendlyMatchEventName = styled.h3(({ noUnderline }) => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '31px',

  ...noUnderline && {
    textDecoration: 'none !important',
  },

  '@media (max-width: 800px)': {
    fontSize: '20px',
  },
}));

export const EventName = styled(Link)(({ noUnderline }) => ({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '24px',
  lineHeight: '31px',
  cursor: 'pointer',

  ...noUnderline && {
    textDecoration: 'none !important',
  },

  '@media (max-width: 800px)': {
    fontSize: '20px',
  },
}));

export const EventMetaList = styled.ul({
  display: 'flex',
  alignItems: 'center',
  listStyle: 'none',
  '@media (max-width: 920px)': {
    flexWrap: 'wrap',
  },
});

export const LevelChanged = styled.div({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  justifyContent: 'center',
  height: '60px',
  borderRadius: '5px',
  padding: '10px',
  background: '#EAF6F6',
  margin: '20px 0 20px 0',
  '> div': {
    margin: 'unset',
  },
});

export const LevelChangedDate = styled.div({
  position: 'absolute',
  fontSize: '12px',
  opacity: '30%',
  left: '10px',
  top: '23px',
});

export const EventMetaListItem = styled.li({
  '&:first-of-type': {
    margin: '0 10px 0 0',
  },
  '&:not(:last-of-type):not(:first-of-type):after': {
    content: "'•'",
    margin: '0 10px',
  },

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '21px',

  '> a': {
    color: '#78ADAB',
  },
});

export const EventBody = styled.div({
  padding: '15px 30px',
});

export const TableHeader = styled.div({
  display: 'flex',
  '@media (max-width: 920px)': {
    display: 'none',
  },
});

export const Column = styled.div(({
  maxWidth, flex, justify, column, xMargin, center,
}) => ({
  ...flex && {
    display: 'flex',
    ...justify && {
      justifyContent: justify,
    },

    ...center && {
      alignItems: 'center',
    },

    ...column && {
      flexDirection: 'column',
    },
  },

  ...xMargin && {
    margin: xMargin,
  },

  width: '100%',
  maxWidth: `${maxWidth}px`,
}));

export const EventFooter = styled.div({
  display: 'flex',
  padding: '15px 30px',
  '.rankBefore': {
    margin: '0 10px 0 5px',
    padding: '2px 4px',
    background: '#EAF6F6',
    borderRadius: '4px',
    fontWeight: 700,
  },
});

export const MatchContainer = styled.div({
  display: 'flex',
  padding: '15px 0',
  borderBottom: '1px solid #EAF6F6',
  '&:last-of-type': {
    borderBottom: 'unset',
  },
  '@media (max-width: 920px)': {
    display: 'none',
  },
});

export const MatchContainerMobile = styled.div({
  '@media (min-width: 920px)': {
    display: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  borderBottom: '1px solid #EAF6F6',
  '&:first-of-type': {
    padding: '0 0 20px 0',
  },

  padding: '20px 0 20px 0',

  '&:last-of-type': {
    borderBottom: 'unset',
    padding: '20px 0 0 0',
  },
});

export const MatchMetaContainer = styled.div({
  display: 'flex',
});

export const MatchMeta = styled(P)({

});

export const MatchData = styled.div({
  margin: '0 0 0 auto',
});

export const WLBox = styled.span(({ win, lose }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '4px',
  background: win ? '#8FFFA7' : lose ? '#FF8298' : '#EAF6F6',

  minWidth: '28px',
  minHeight: '28px',

  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '12px',
  lineHeight: '16px',

  ...lose && {
    color: '#fff',
  },
}));

export const EventTotalWonPoints = styled(P)({
  margin: '5px 0 5px auto',
});
