import React, { useEffect, useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import { Container, CourtsContainer } from './styles';
import Filters from './components/Filters';
import CourtInfoCard from './components/CourtInfoCard';
import Pagination from '../../../components/Pagination';
import { H4, Paragraph } from '../../../components';
import LoadingSpinner from '../../../components/LoadingSpinner';
import BookCourtModal from './components/bookCourtModal';
import LoadImage from "../../../components/common/LoadImage";

const initialOptions = {
  page: 1,
  limit: 20,
};

const getLocationName = () => {
  const { location, coordinates } = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  if (location === 'currentLocation') {
    return coordinates;
  }
  if (location) {
    return `${location.replace('null, ', '')}`;
  }

  return null;
};

const GetCourts = () => {
  const history = useHistory();
  const { isLoggedIn } = useSelector(state => state.session);
  const { data: courts, status } = useSelector(state => state.clubs?.listing);
  const { results = [], totalResults = 0, totalPages } = courts;
  const [options, setOptions] = useState(initialOptions);
  const [bookingInfo, setBookingInfo] = useState({});
  const listRef = useRef(null);

  // redirect to login if not authenticated
  useEffect(() => {
    if (!isEmpty(bookingInfo) && !isLoggedIn) {
      const pathName = (history.location.pathname || '').split('/').filter(v => v).join('~');
      return history.push(`/login/${pathName}`);
    }
  }, [bookingInfo]);

  const handlePagination = (data) => {
    const page = data.selected + 1;

    if (options.page !== page) {
      setOptions({
        ...options,
        page,
      });
    }

    listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <Container fullwidth ref={listRef}>
      {/* Filters */}
      <Filters options={options} />

      {(['loading', 'idle'].includes(status)) && (
        <LoadingSpinner />
      )}

      {/* Heading Title */}
      <Paragraph larger className="heading-title">
        <Trans
          ns="clubs"
          i18nKey={`clubsFound${totalResults !== 1 ? '_plural' : ''}`}
          context={getLocationName() ? 'with_location' : ''}
        >
          <strong>{{ totalResults }} clubs</strong> found
          {{ location: getLocationName() }}
        </Trans>
      </Paragraph>

      {/* Courts Listing */}
      {(status === 'succeeded' && results && !!results.length) && (
        <CourtsContainer>
          {results.map((club) => (
            <CourtInfoCard
              key={club?._id}
              setBookingInfo={setBookingInfo}
              clubInfo={club}
            />
          ))}
        </CourtsContainer>
      )}

      {/* No results found */}
      {(status === 'succeeded' && results && !results.length) && (
        <div className="d-flex flex-column align-items-center mt50 mb50">
          <img src={LoadImage('clubs/court-type.svg')} alt="" width={82} height={50} />
          <H4 className="mt10">
            <Trans ns="clubs" i18nKey="noClubsFound">No results were found for this search.</Trans>
          </H4>
        </div>
      )}

      {/* Pagination */}
      <Pagination
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePagination}
        forcePage={options.page - 1}
        section="clubs"
      />

      {/*  Book a Court Modal */}
      {!isEmpty(bookingInfo) && isLoggedIn && (
        <BookCourtModal
          isOpen={!isEmpty(bookingInfo)}
          bookingInfo={bookingInfo}
          onRequestClose={() => setBookingInfo({})}
        />
      )}
    </Container>
  );
};

export default GetCourts;
