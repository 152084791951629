export default {
  title: 'Homepage',
  seoTitle: 'Play local. Dream global. Join a growing community of international amateur players | Sportya',
  stats: {
    failedToLoad: '<0>Failed to load statistics:</0> {{error}}',
    activePlayers: '<0>Players</0> <2>{{players}}</2>',
    activeEvents: '<0>Planned Events {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Clubs</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Courts</0> <2>{{courts}}</2> ',
    activeCities: '<0>Cities</0> <2>{{cities}}</2>',
    activeCountries:
    `<0>Countries</0> <2>Romania, Republic of
     Moldova, Bulgaria,
     Bahrain</2>`,
  },

  newEvents: {
    title: 'Featured Events',
  },

  clubArea: {
    title: 'Are you a Tennis Club Manager or Court Administrator?',

    first: {
      title: 'Manage local and global events',
      desc: 'Personalized for your club needs and court availability, for maximum impact.',
    },
    second: {
      title: 'Connect with new players',
      desc: 'Build and expand your player base with local and global players.',
    },
    third: {
      title: 'Become a hub for tennis enthusiasts',
      desc: 'Support level-based play and increase player engagement and loyalty.',
    },
    fourth: {
      title: 'Book courts',
      desc: 'Manage and book your courts. Keep players engaged, more frequently.',
    },
    button: 'Learn more',
  },

  centralPanel: {
    first: {
      title: 'Play more. Play your way',
      desc: 'Rate your game level and choose your play, from classic, knockout events, organized during the weekend, to group format events, night events, flexible Local Leagues, friendly matches and more.',
    },
    second: {
      title: 'Compete in amateur tennis tournaments',
      desc: 'Access a calendar of hundreds of Sportya events, start local and advance to international tennis events for amateur players, all organized on level-based play.',
    },
    third: {
      title: 'Join an international community of tennis players',
      desc: 'Showcase your profile, connect with other players, compete in local and global amateur rankings and make the most out of your tennis life.',
    },
    buttons: {
      first: 'Learn More',
      second: 'Join Now',
    },
  },

  slider: {
    findNewGamePartners: 'Play friendly matches or compete in local and global amateur tennis tournaments',
    joinCommunity: 'Find and Book tennis courts in partner clubs',
    improveYourGame: 'Discover level-based play, global amateur rankings, \nand player profiles',
    joinNow: 'Join Now',
    searchNow: 'Search Now',
    seeEvents: 'See Events',
  },
};
