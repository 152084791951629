import React, { useEffect } from 'react';
import Slider from 'react-slick';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import dateFormat from 'dateformat';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import LoadImage from '../../../../components/common/LoadImage';

import {
  SliderWrapper, Event, EventHeader, EventDetails, NewEvent, RedirectToEvent, InnerSlider, SeeAll,
} from './styles';
import { Contain, P } from '../../../../components/Collection';
import { H3 } from '../../../../components';

import { fetchFeaturedEvents } from './reducers';

const FeaturedEvents = ({ homepage = false, dashboard = false, amount }) => {
  const dispatch = useDispatch();
  const { data, status } = useSelector(state => state.featuredEvents);
  const results = data?.results;
  let events = [];

  useEffect(() => {
    if (status === 'idle') {
      dispatch(fetchFeaturedEvents());
    }
  }, [status, dispatch]);

  if (!isEmpty(results)) {
    // events = shuffle([...results]);
    events = results;
  }

  const slidesToShow = (value) => (amount && events?.length >= amount
    ? amount
    : events?.length > value
      ? value
      : events?.length
  );

  const settings = {
    dots: !homepage || dashboard,
    infinite: true,
    arrows: !dashboard,
    autoplay: !!homepage || dashboard,
    autoplaySpeed: 6000,
    speed: 1000,
    slidesToShow: slidesToShow(5),
    slidesToScroll: 1,
    centerPadding: '60px',

    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: slidesToShow(4),
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: slidesToShow(3),
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: events?.length > 3 ? 3 : events?.length,
          arrows: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          // eslint-disable-next-line no-unneeded-ternary
          arrows: homepage || dashboard ? false : false,
          // eslint-disable-next-line no-unneeded-ternary
          dots: homepage || dashboard ? true : true,
        },
      },
    ],
  };

  // event cover image
  const getFeaturedImage = (event) => {
    let featuredImage = null;
    const { featured = null } = event?.club?.defaultEventImages || {};
    if (featured) featuredImage = `clubs/${event?.club?.clubId}/${event?.category}/${featured}`;
    if (event?.image?.featured) featuredImage = `events/${event?.eventId}/${event?.image?.featured}`;

    return featuredImage;
  };

  return (
    <SliderWrapper homepage={!!homepage} dashboard={!!dashboard}>
      {(homepage || dashboard) && (
        <Contain align="center" margin="0 0 20px 0">
          <H3>
            <Trans ns="homepage" i18nKey="newEvents.title">
              Featured Events
            </Trans>
          </H3>
          <SeeAll to="/events">
            <Trans ns="events" i18nKey="seeAll">See All</Trans>
            {' '}
            {'>'}
          </SeeAll>
        </Contain>
      )}

      {events.length > 0 && (
        <Slider {...settings}>
          {events?.map((event, key) => (
            <InnerSlider key={key}>
              <Event to={`/events/${event?._id}`} homepage={(homepage || dashboard).toString()}>
                <EventHeader src={LoadImage(getFeaturedImage(event), true, 'featured', event?.category)} />
                <EventDetails>
                  <NewEvent>
                    <P xSmall bold>
                      <Trans ns="events" i18nKey="newEvent">
                        New event
                      </Trans>
                    </P>
                  </NewEvent>
                  <P xSmall>{`${dateFormat(event.startDate, 'dd mmm')} - ${dateFormat(event.endDate, 'dd mmm')}`}</P>
                  <P size={18} bold>{event.name}</P>
                  <P xSmall margin="unset">{`${event?.club?.clubName} • ${event?.club?.location?.city}`}</P>
                </EventDetails>
                <RedirectToEvent>
                  <P bold color="darkTurquoise" width="max-content">
                    <Trans ns="events" i18nKey="goToEvent2">Go to event</Trans>
                  </P>
                  <img src={LoadImage('akar-arrow-right.svg')} alt="" />
                </RedirectToEvent>
              </Event>
            </InnerSlider>
          ))}
        </Slider>
      )}
    </SliderWrapper>
  );
};

export default FeaturedEvents;
