import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { Range } from 'react-range';

import P from '../../../../components/Paragraph';
import Checkbox from '../../../../components/Form/VanillaCheckbox';
import CheckboxHandler from '../../../../components/CheckboxHandler';
import Radio from '../../../../components/Form/VanillaRadio';
import { Button } from '../../../../components/Collection';

const AdvancedFilters = ({
  handleModalFilters, toggle, filters, resetFilters,
}) => {
  const { t } = useTranslation('events');
  const { t: commonT } = useTranslation('common');
  const [ageRadioState, setAgeRadioState] = useState(filters.minAge ? 'custom' : 'all');
  const [rangeSliderValues, setRangeSliderValues] = useState([filters.minAge ?? 18, filters.maxAge ?? 65]);
  const [form, setForm] = useState({});

  useEffect(() => {
    if (ageRadioState === 'custom') {
      const [minAge, maxAge] = rangeSliderValues;
      Object.assign(form, {
        minAge,
        maxAge,
      });
    }
  }, [rangeSliderValues]);

  const genders = [
    { name: commonT('men'), key: 'male' },
    { name: commonT('women'), key: 'female' },
  ];

  const ageSelectors = [
    { value: 'all', label: commonT('allAges') },
    { value: 'custom', label: commonT('customAge') },
  ];

  const handleChangeAge = (event) => {
    setAgeRadioState(event.target.value);
    switch (event.target.value) {
    case 'all':
      delete form.minAge;
      delete form.maxAge;
      // eslint-disable-next-line no-param-reassign
      delete filters.minAge;
      // eslint-disable-next-line no-param-reassign
      delete filters.maxAge;
      setAgeRadioState(event.target.value);
      break;

    case 'custom':
      setAgeRadioState(event.target.value);
      break;
    default:
    }
  };

  return (
    <form className="centered-form">
      <div className="form-content-small">
        {!isEmpty(genders) && (
          <div className="form-group bd-bottom">
            <P large bold className="mb20">
              {t('gender')}
            </P>
            <div className="row">
              {genders.map((selector, key) => (
                <div key={key} className="col-sm-4">
                  <Checkbox
                    name="gender"
                    handler={() => setForm(CheckboxHandler('gender', selector.key, form, filters, true))}
                    checked={(form.gender?.split(',').includes(selector.key))}
                    {...(filters?.gender?.split(',').includes(selector.key)) && { defaultChecked: true }}
                    label={selector.name}
                  />
                </div>
              ))}
            </div>
          </div>
        )}

        <P large bold className="mb20">
          {t('age')}
        </P>
        <div className="row">
          {ageSelectors.map((selector, key) => (
            <div key={key} className="col-sm-4">
              <Radio
                name="ageSelector"
                label={selector.label}
                value={selector.value}
                checked={ageRadioState === selector.value}
                onChange={(event) => handleChangeAge(event)}
              />
            </div>
          ))}
        </div>

        {ageRadioState === 'custom' && (
          <div className="form-group">
            <Range
              step={1}
              min={10}
              max={100}
              values={rangeSliderValues}
              onChange={values => setRangeSliderValues(values)}
              renderTrack={({ props, children }) => (
                <div
                  onMouseDown={props.onMouseDown}
                  onTouchStart={props.onTouchStart}
                  style={{
                    ...props.style,
                    height: '36px',
                    display: 'flex',
                    width: '100%',
                  }}
                >
                  <div
                    ref={props.ref}
                    style={{
                      height: '4px',
                      width: '100%',
                      borderRadius: '4px',
                      background: '#ccc',
                      alignSelf: 'center',
                    }}
                  >
                    {children}
                  </div>
                </div>
              )}
              renderThumb={({ index, props }) => (
                <div
                  {...props}
                  style={{
                    ...props.style,
                    height: '21px',
                    width: '21px',
                    borderRadius: '50%',
                    backgroundColor: '#000',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    outline: 0,
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: '-28px',
                      color: '#000',
                      fontWeight: '500',
                      fontSize: '14px',
                      padding: '5px',
                      backgroundColor: 'transparent',
                    }}
                  >
                    {rangeSliderValues[index].toFixed(0)}
                  </div>
                </div>
              )}
            />
          </div>
        )}
      </div>
      <div className="form-footer">
        <span
          className="reset-to-default"
          onClick={() => {
            resetFilters();
            toggle();
          }}
        >
          {t('resetToDefault')}
        </span>
        <Button
          margin="5px 0 0 auto"
          padding="0 5px"
          minWidth="90px"
          onClick={() => {
            handleModalFilters(form);
            toggle();
          }}
          black
          maxwidth
          className="center-middle mb20"
        >
          {t('apply')}
        </Button>
      </div>
    </form>
  );
};

export default AdvancedFilters;
