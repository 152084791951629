import { post } from '../../components/common/http';

export const submit = async ({
  values, addToast, setSubmitting, resetForm, t, socketClientId,
}) => {
  try {
    const payload = {
      ...values,
      location: values.location,
      sportType: values.sportType.value,
      socketClientId,
    };

    payload.country = payload.country?.value;

    await post('/clubs-leads', payload);
  } catch (error) {
    // enable submit button
    setSubmitting(false);

    return (
      addToast
      && addToast(t('form.validation.error'), {
        appearance: 'error',
        autoDismiss: true,
        maxOpened: 1,
      })
    );
  }

  // enable submit button
  setSubmitting(false);

  // reset for if success
  resetForm();
  //
  // return (
  //  addToast &&
  //  addToast(t('form.validation.success'), {
  //    appearance: 'success',
  //    autoDismiss: true,
  //  })
  // );
};
