/** @jsxImportSource @emotion/react */
import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { debounce, isEmpty } from 'lodash';
import { useHistory } from 'react-router-dom';

import LoadImage from '../../../../components/common/LoadImage';
import CurrentPhaseStatus from '../../components/Event/currentPhaseStatus';

import { followEvent } from '../../actions';
import { updateFollowers } from '../../reducers';

import {
  Button, P, H1, Link,
} from '../../../../components/Collection';
import {
  Club,
  ClubAndEvent,
  ClubAvatar,
  ClubDetails,
  ClubName,
  Details,
  EventDetails,
  EventLogo,
  Follow,
  Info,
  Item,
  List,
  Text,
} from '../styles';
import BackButton from '../../../../components/BackButton';
import { clearMyEvents } from '../../../MyAccount/Events/reducers';
import { FollowIcon } from './styles';
import TextWithPopup from '../../../../components/Event/components/textWithPopup';

const images = require.context('../../../../assets/images', true);

const TopInfo = ({ eventInfo }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const { t: commonT } = useTranslation('common');
  const { accountInfo } = useSelector(state => state.session);
  const followedEvent = eventInfo.followers?.includes(accountInfo?.id) || false;

  const code = history.location.pathname.split('/')[1];
  const isEn = code === 'en';

  const handleFollowEvent = async () => {
    if (!accountInfo) {
      return history.push('/login');
    }

    followEvent({
      id: eventInfo?._id,
      addToast,
      t: commonT,
    }).then(() => {
      dispatch(updateFollowers(accountInfo?.id));
      dispatch(clearMyEvents({ type: 'followed' }));
      addToast(commonT(`SUCCESSFULLY_${followedEvent ? 'UNFOLLOW' : 'FOLLOW'}_EVENT`), {
        appearance: 'success',
        autoDismiss: true,
      });
    });
  };

  // event cover image
  let logoImage = null;
  const { logo = null } = eventInfo?.club?.defaultEventImages || {};
  if (logo) logoImage = `clubs/${eventInfo?.club?.clubId}/${eventInfo?.category}/${logo}`;
  if (eventInfo?.image?.logo) logoImage = `events/${eventInfo?.eventId}/${eventInfo?.image?.logo}`;

  const underMaintenance = eventInfo?.competitions?.filter(e => e.status === 'MAINTENANCE');
  const isUnderMaintenance = underMaintenance?.length === eventInfo?.competitions?.length && underMaintenance?.length;

  const getOrganizer = eventInfo?.organizer?.clientType === 'company'
    ? eventInfo?.organizer?.companyName
    : `${eventInfo?.organizer?.firstName} ${eventInfo?.organizer?.lastName}`;

  return (
    <Info>
      <CurrentPhaseStatus eventInfo={eventInfo} {...(isUnderMaintenance && { maintenance: true })} />
      <div className="title d-flex align-middle">
        <BackButton className="mr10" {...history} to="/events" bold />
        <H1 className="mb10 mt10">{eventInfo?.name}</H1>
      </div>
      <Details>
        <ClubAndEvent>
          {!isEmpty(eventInfo?.club) && (
            <Link
              to={eventInfo?.club?.id ? `/clubs/${eventInfo?.club?.id}` : `/events/${eventInfo?._id}`}
              textDecoration="none"
              width="fit-content"
            >
              <Club>
                <ClubAvatar
                  src={LoadImage(`clubs/${eventInfo?.club?.id}/${eventInfo?.club?.logoImage}`, true, 'logo')}
                  // eslint-disable-next-line no-param-reassign
                  onError={(e) => e.target.src = images(`./placeholders/placeholder-image-logo.svg`).default}
                />
                <ClubDetails>
                  {eventInfo?.club?.clubName === 'TBA' && (
                    <ClubName>
                      <TextWithPopup text="TBA" popUpText="To Be Announced" />
                      {' '}
                      <img src={LoadImage('arrow/right-link-arrow.svg')} alt="Go to club page" />
                    </ClubName>
                  )}

                  {eventInfo?.club?.clubName !== 'TBA' && eventInfo?.club?.clubName !== null && (
                    <ClubName medium>
                      {eventInfo?.club?.clubName}
                      {' '}
                      {eventInfo?.club?.partnerClub ? (
                        <img src={LoadImage('events/eventDetails/partner_club.svg')} alt="Partner Club" />
                      ) : (
                        <img src={LoadImage('arrow/right-link-arrow.svg')} alt="Go to club page" />)}
                    </ClubName>
                  )}
                  <P small>{eventInfo?.club?.location?.name}</P>
                </ClubDetails>
              </Club>
            </Link>
          )}
          <EventDetails>
            <List>
              <Item>
                <P bold><Trans ns="events" i18nKey="eventCategory">Category: </Trans></P>
                <P><Trans ns="common" i18nKey={eventInfo?.category}>N/A</Trans></P>
              </Item>
              <Item className="event-period">
                <P bold><Trans ns="events" i18nKey="eventPeriod">Event period: </Trans></P>
                <P>
                  {dateFormat(eventInfo?.startDate, 'd mmmm, yyyy')}
                  {' '}
                  -
                  {' '}
                  {dateFormat(eventInfo?.endDate, 'd mmmm, yyyy')}
                </P>
              </Item>
              {eventInfo?.courtType && (
                <>
                  <Item>
                    <P bold><Trans ns="events" i18nKey="surfaceType">Surface type: </Trans></P>
                    <P><Trans ns="common" i18nKey={eventInfo?.courtType}>{eventInfo?.courtType}</Trans></P>
                  </Item>
                  <Item>
                    <P bold><Trans ns="events" i18nKey="noOfCourts">No of courts: </Trans></P>
                    <P>{eventInfo?.numberOfCourts || 'N/A'}</P>
                  </Item>
                </>
              )}
            </List>
            <List>
              <Item>
                <P bold><Trans ns="events" i18nKey="organizerName">Organizer: </Trans></P>
                <P>
                  {getOrganizer}
                </P>
              </Item>
              <Item>
                <P bold><Trans ns="events" i18nKey="organizerPhone">Phone: </Trans></P>
                <P>{eventInfo?.organizer?.phone || 'N/A'}</P>
              </Item>
              <Item>
                <P bold><Trans ns="events" i18nKey="organizerEmail">Email: </Trans></P>
                <P>{eventInfo?.organizer?.email || 'N/A'}</P>
              </Item>
              <Item>
                <P bold><Trans ns="events" i18nKey="tournamentDirector">Tournament director: </Trans></P>
                <P>{eventInfo?.directorName || 'N/A'}</P>
              </Item>
              {eventInfo?.refereeName
                && (
                  <Item>
                    <P bold><Trans ns="events" i18nKey="refereeName">Referee name: </Trans></P>
                    <P className="text-capitalize">
                      {eventInfo?.refereeName}
                    </P>
                  </Item>
                )}
              {eventInfo?.supervisorName
                && (
                  <Item>
                    <P bold><Trans ns="events" i18nKey="supervisor">Supervisor: </Trans></P>
                    <P className="text-capitalize">
                      {eventInfo?.supervisorName}
                    </P>
                  </Item>
                )}
            </List>
          </EventDetails>
        </ClubAndEvent>
        <Follow>
          <EventLogo src={LoadImage(logoImage, true, 'logo', eventInfo?.category)} alt="Event Logo" />
          <Button
            noImgMargin
            outline={!followedEvent}
            width="100%"
            onClick={debounce(handleFollowEvent, 150)}
            alt="Follow Event"
          >
            <FollowIcon
              src={LoadImage(`events/${!followedEvent ? 'minus' : 'x'}-icon-followed-events.svg`)}
              alt={followedEvent ? 'Unfollow Event' : 'Follow Event'}
            />
            <Text smallFont={!isEn}>
              <Trans ns="events" i18nKey={!followedEvent ? 'followEvent' : 'unfollowEvent'}>Follow event</Trans>
            </Text>
          </Button>
        </Follow>
      </Details>
    </Info>
  );
};

export default TopInfo;
