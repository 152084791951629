import React from 'react';
import { Trans } from 'react-i18next';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

import { P, Link } from '../../../../components/Collection';
import {
  Avatar, ContainAvatar, ID, Initials, JoinedDate, JoinedDateText, Player,
} from './styles';

import getAgeFromDate from '../../../../components/common/getAgeFromDate';
import LoadImage from '../../../../components/common/LoadImage';
import LevelsBadges from '../../../../components/LevelsBadges';
import { FriendsIcon } from '../../../Friends/components/FriendCard/styles';
import RaiseHand from '../../../../components/Layout/components/Header/components/RaiseHand';

const PlayerCard = ({
  date,
  photo,
  level,
  levelDouble,
  firstName,
  lastName,
  userId,
  id,
  age,
  location,
  handRaised,
  playerInfo = null,
}) => {
  const { accountInfo } = useSelector(state => state?.session);

  return (
    <Player>
      {handRaised && (
        <RaiseHand playerInfo={playerInfo} />
      )}

      {!handRaised && (
        <JoinedDate>
          <JoinedDateText xSmall bold>
            <Trans ns="player" i18nKey="joined">Joined</Trans>
            :
            {' '}
            <time>{dateFormat(date, 'd mmm')}</time>
          </JoinedDateText>
        </JoinedDate>
      )}
      <ContainAvatar to={`/accounts/${id}`}>
        {photo
          ? (
            <Avatar
              src={LoadImage(`accounts/${userId}/${photo}`, true)}
              alt={`${firstName.charAt(0)} ${lastName.charAt(0)}`}
            />
          ) : (
            <Initials>
              <P large bold>
                {firstName && lastName ? firstName.charAt(0) + lastName.charAt(0) : ''}
              </P>
            </Initials>
          )}
        <LevelsBadges white small level={level ?? '?'} levelDouble={levelDouble ?? '?'} className="badge" />
      </ContainAvatar>

      <Link to={`/accounts/${id}`} className="Player Name">
        <P bold className="ellipsis">
          {accountInfo?.friendsIds?.includes(userId) && (
            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
          )}
          {`${firstName} ${lastName}`}
          <ID>
            #
            {userId}
          </ID>
        </P>
      </Link>
      {age && location ? (
        <P small>
          {age ? `${getAgeFromDate(age)} - ` : ''}
          {location || ''}
        </P>
      ) : ''}
    </Player>
  );
};

export default PlayerCard;
