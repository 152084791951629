import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { useTranslation, Trans } from 'react-i18next';

import mixpanel from '../../../mixpanel';

import { store as socketStore } from '../../../components/Socket/store';

import LoadImage from '../../../components/common/LoadImage';
import { P } from '../../../components/Collection';
import {
  fetchFriendlyMatches,
  addMatchToList,
  replaceMatchInList,
  changeSummaryTotals,
  fetchInactiveRequests,
  removePlaceholder,
} from '../reducers';
import {
  PageContainer,
  TabContent,
  ViewMore,
  ViewMoreArrow,
} from '../styles';

import Match from '../Matches/components/Match';
import Header from '../components/Header';
import MatchMovedPlaceholder from '../components/MatchMovedPlaceholder';
import ToggleVisible from '../../../components/common/ToggleVisible';
import AcceptDeclineCancelModal from '../components/Modals/AcceptDeclineMatch';
import { NoDataContainer } from '../StartMatch/components/WantToPlay/styles';
import { Button, H3 } from '../../../components';
import LinkWrapper from '../../../components/Link';
import { addConversationToList } from '../../Chat/reducers';
import AddScoreModal from '../components/Modals/AddScore';
import { formatMatchId, getLocalDate } from '../helpers';
import SetRatingModal from '../../../components/PlayerRating/components/SetRatingModal';
import handleNextPlayerRating from '../../../components/PlayerRating/helpers/handleNextPlayerRating';

const Requests = ({ name }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const globalState = useContext(socketStore);
  const { t: commonT } = useTranslation('common');
  const { t: chatT } = useTranslation('conversations');
  const [page, setPage] = useState(1);

  const [modalType, setModalType] = useState(false);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const { isComponentVisible: isModalVisible, setIsComponentVisible: setIsModalVisible } = ToggleVisible(false);
  const { data: matches, status } = useSelector(state => state?.friendlyMatches?.friendlyMatches?.requests);
  const { accountInfo } = useSelector(state => state?.session);

  const [ratePlayerData, setRatePlayerData] = useState({
    playerToRate: null,
    userPositionIndex: 0,
    thankYouModal: false,
    userId: accountInfo?.userId,
    matchInfo: null,
  });

  const isScoreModal = modalType?.type === 'confirmMatchScore';

  useEffect(() => {
    if (
      (status === 'idle')
      || (status === 'succeeded' && matches?.page && matches?.page !== page)
    ) {
      dispatch(fetchFriendlyMatches({ tab: 'requests', page }));
    }
  }, [dispatch, status]);

  useEffect(() => {
    if (page > 1) {
      dispatch(fetchInactiveRequests({ page }));
    }
  }, [status, page]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (success) {
        const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const matchStartDate = data?.match.ended
          ? new Date() : getLocalDate(data?.match?.startDate, data?.match?.timezone);
        switch (message) {
        case 'SUCCESSFULLY_ACCEPTED_FRIENDLY_MATCH':
          if (!data?.match?.pastMatch) {
            dispatch(addMatchToList({ match: data?.match, list: 'matches' }));
            dispatch(replaceMatchInList({
              match: {
                matchId: data?.match?.matchId,
                isPlaceholder: true,
                placeholderTranslation: 'invitationMovedToMatches',
                placeholderDestination: 'available',
              },
              list: 'requests',
            }));
            dispatch(changeSummaryTotals({ matches: 1, requests: -1 }));
          }
          break;
        case 'SUCCESSFULLY_DECLINED_FRIENDLY_MATCH':
          dispatch(replaceMatchInList({ match: data?.match, list: 'requests' }));
          mixpanel.track('DECLINE MATCH (INVITE)', {
            sp_asset_type: 'MATCH INVITE',
            sp_match_type: data?.match.ranked ? 'Ranked' : 'Unranked',
            sp_game_type: data?.match.gameType,
            sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
            sp_start_date: matchStartDate.toString(),
            sp_game_location_city: data?.match.location?.city,
            sp_game_location_country: data?.match.location?.country,
            sp_game_format: data?.match.format,
            sp_match_id: formatMatchId(data?.match.matchId),
          });
          break;
        case 'SUCCESSFULLY_REFUSED_FRIENDLY_MATCH':
          dispatch(replaceMatchInList({ match: data?.match, list: 'requests' }));
          mixpanel.track('Friendly matches NO MATCH', {
            sp_asset_type: 'PLAYED MATCH',
            sp_match_type: data?.match.ranked ? 'Ranked' : 'Unranked',
            sp_game_type: data?.match.gameType,
            sp_start_date_day_of_week: weekday[matchStartDate.getDay()],
            sp_start_date: matchStartDate.toString(),
            sp_game_location_city: data?.match.location?.city,
            sp_game_location_country: data?.match.location?.country,
            sp_game_format: data?.match.format,
            sp_match_id: formatMatchId(data?.match.matchId),
          });
          break;
        case 'SUCCESSFULLY_SAVED_SCORE':
          // rating user
          handleNextPlayerRating({
            ratePlayerData: {
              ...ratePlayerData,
              matchInfo: data?.match,
            },
            setRatePlayerData,
          });
          if (data?.match?.ended) {
            dispatch(replaceMatchInList({
              match: {
                matchId: data?.match?.matchId,
                isPlaceholder: true,
                placeholderTranslation: 'playedMatchMovedToScores',
                placeholderDestination: 'scores',
              },
              list: 'requests',
            }));
            dispatch(changeSummaryTotals({ matches: 0, requests: -1 }));
            dispatch(addMatchToList({ match: data?.match, list: 'scores' }));
          } else {
            dispatch(replaceMatchInList({
              match: {
                matchId: data?.match?.matchId,
                isPlaceholder: true,
                placeholderTranslation: 'playedMatchMovedToMatches',
                placeholderDestination: 'available',
              },
              list: 'requests',
            }));
            dispatch(addMatchToList({ match: data?.match, list: 'matches' }));
            dispatch(changeSummaryTotals({ matches: 1, requests: -1 }));
          }
          break;
        default:
        }
        setIsModalVisible(false);
        addToast(commonT(message), {
          appearance: 'success',
          autoDismiss: true,
        });
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'friendly-matches-response',
      );
      socket.on(
        'friendly-matches-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'friendly-matches-response',
        );
      };
    }
  }, [socketClientId]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      addToast(chatT(message), {
        appearance: success ? 'success' : 'error',
        autoDismiss: true,
        maxOpened: 1,
      });

      if (success) {
        dispatch(addConversationToList(data));
        return history.push(`/conversations/${data.id}`);
      }
    };

    if (socket) {
      socket.removeAllListeners('chat-response');
      socket.on('chat-response', listenForResponse);

      return () => socket.removeAllListeners('chat-response');
    }
  }, [socket]);

  useEffect(() => {
    mixpanel.track('Visited Requests tab');
    dispatch(removePlaceholder({ list: 'requests' }));
  }, []);

  const loadInactiveRequests = async () => {
    setPage((prev) => prev + 1);
  };

  return (
    <PageContainer>
      <Header page={name} />
      {matches?.results && matches?.results.length > 0 && (
        <TabContent>
          {matches.results.map((match, key) => (
            match.isPlaceholder
              ? <MatchMovedPlaceholder match={match} list="requests" />
              : (
                <Match
                  key={key}
                  match={match}
                  isARequest
                  toggleModal={{ isModalVisible, setIsModalVisible }}
                  setModalType={setModalType}
                  modalType={modalType}
                />
              )
          ))}
        </TabContent>
      )}

      {matches?.results && !matches?.results.length && status === 'succeeded' && (
        <NoDataContainer className="text-center">
          <img src={LoadImage('friendly-matches/black-palette.svg')} alt="" width={60} height={60} />
          <H3><Trans ns="friendlyMatches" i18nKey="noOpenRequests">No Open Requests</Trans></H3>
          <P className="mb30">
            <Trans ns="friendlyMatches" i18nKey="noRequestsSubtitle">
              You didn&apos;t receive any match requests yet.
              Invite your friends or other players and start a match now!
            </Trans>
          </P>
          <Button wide>
            <LinkWrapper
              to="/friendly-matches"
              onClick={() => mixpanel.track('Click START MATCH placeholder from Requests')}
            >
              <Trans ns="friendlyMatches" i18nKey="startMatch">Start Match</Trans>
            </LinkWrapper>
          </Button>
        </NoDataContainer>
      )}

      {matches?.loadMore && (
        <ViewMore onClick={loadInactiveRequests}>
          <P bold>
            <Trans ns="friendlyMatches" i18nKey="viewMore">View More</Trans>
          </P>
          <ViewMoreArrow src={LoadImage('friendly-matches/view-more-arrow.svg')} alt="View Inactive Requests" />
        </ViewMore>
      )}

      {isModalVisible && !isScoreModal && (
        <AcceptDeclineCancelModal modalData={modalType} />
      )}

      {isModalVisible && isScoreModal && (
        <AddScoreModal
          modalData={modalType}
          closeModalHandler={() => setIsModalVisible(false)}
        />
      )}

      {ratePlayerData?.playerToRate && (
        <SetRatingModal
          skippable
          ratingInfo={ratePlayerData?.playerToRate?.ratingInfo}
          user={ratePlayerData?.playerToRate}
          matchId={ratePlayerData?.matchInfo?.friendlyMatchId ?? ratePlayerData?.matchInfo?._id}
          gameType={ratePlayerData?.matchInfo?.gameType}
          userPositionIndex={ratePlayerData?.userPositionIndex}
          onRequestClose={() => setRatePlayerData({
            ...ratePlayerData,
            playerToRate: null,
          })}
          onSubmit={(v) => handleNextPlayerRating({
            ratePlayerData,
            setRatePlayerData,
            skipThankYouModal: !!v?.skipThankYouModal,
          })}
          thankYouModal={ratePlayerData?.thankYouModal}
          mixpanelData={{
            source: 'Post-Match',
            matchType: ratePlayerData?.matchInfo?.ranked ? 'Ranked' : 'Unranked',
            assetType: ratePlayerData?.matchInfo?.pastMatch ? 'PLAYED MATCH' : 'MATCH INVITE',
          }}
        />
      )}
    </PageContainer>
  );
};

export default Requests;
