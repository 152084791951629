import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';

import {
  InfoContainer,
  InfoIcon,
  Popup,
  ViewMore,
  ViewMoreArrow,
} from '../../../styles';

import { CategoryTitle } from '../../styles';

import { Contain, Link, P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import ContentLoader from '../../../../../components/ContentLoader';

import { fetchPartnerClubs } from '../../../reducers';
import { Container, PlayersContainer } from '../WantToPlay/styles';

import {
  ClubAvatar,
  ClubContainer,
  ClubDetails,
  DistanceContainer,
} from './styles';

import { CourtsNumber, CourtsTypeContainer } from '../../../../Clubs/Info/styles';
import { EventData, RadiusIcon } from '../../../../../components/Event/styles';

const PartnerClubs = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { accountInfo } = useSelector(state => state?.session);
  const { partnerClubs: { data, status } } = useSelector(state => state?.friendlyMatches);
  const didNotFetch = !data?.length && status === 'idle';
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (didNotFetch) {
      dispatch(fetchPartnerClubs({ coords: accountInfo?.location?.coords, page }));
    }
  }, [didNotFetch]);

  const loadMoreResults = async () => {
    dispatch(fetchPartnerClubs({ coords: accountInfo?.location?.coords, page: page + 1 }));
    setPage(page + 1);
  };

  const getCourtSurfaces = (club) => {
    const arr = [];
    (club?.courts || [])
      .filter((court) => court?.status === 'active')
      .map(({ surface }) => arr.push(...arr, t(surface)));

    return arr.join(", ");
  };

  return (
    <Container>
      {!!(data?.results?.length) && (
        <>
          <CategoryTitle large bold>
            <Contain>
              <img src={LoadImage('events/eventDetails/partner_club.svg')} className="mr5" alt="Partner Clubs" />
              <Trans ns="friendlyMatches" i18nKey="sportyaPartnerClubs">Sportya Partner Clubs</Trans>
            </Contain>
            <InfoContainer>
              <InfoIcon src={LoadImage('friendly-matches/info.svg')} alt="Find out more" />
              <Popup>
                <Trans ns="friendlyMatches" i18nKey="partnerClubsPopup">
                  Sportya Partner Clubs in your country (set in your Sportya profile).
                  These are selected sports venues in which you can participate in events or friendly matches.
                </Trans>
              </Popup>
            </InfoContainer>
          </CategoryTitle>

          <PlayersContainer>
            {data?.results?.map((club, key) => (
              <ClubContainer key={key}>
                <Link to={`/clubs/${club?.id}`}>
                  <ClubAvatar
                    src={LoadImage(club?.coverImage ? `clubs/${club?.id}/${club?.coverImage}` : null, true, 'cover')}
                  />
                </Link>
                <ClubDetails>
                  <Link to={`/clubs/${club?.id}`}>
                    <P bold>{club?.clubName}</P>
                  </Link>
                  <P small margin="unset">{club?.location?.name}</P>
                  {club?.courts && club?.courts.length && (
                    <CourtsTypeContainer>
                      <CourtsNumber>
                        <P display="flex" align="center" small>
                          <img src={LoadImage('clubs/court-type.svg')} alt="Courts" className="mr5" />
                          <Trans ns="clubs" i18nKey="totalActiveCourts">
                            {{ totalCourts: club.courts?.length }} Courts {{ courtSurfaces: getCourtSurfaces(club) }}
                          </Trans>
                        </P>
                      </CourtsNumber>
                    </CourtsTypeContainer>
                  )}
                </ClubDetails>

                {club?.distance > 0 && (
                  <DistanceContainer>
                    <EventData xSmall className="radius">
                      <RadiusIcon src={LoadImage('radius-marker-icon.svg')} />
                      {' '}
                      {(club?.distance / 1000).toFixed(2)}
                      {' '}
                      km
                    </EventData>
                  </DistanceContainer>
                )}
              </ClubContainer>
            ))}
          </PlayersContainer>

          {data.totalPages > page && (
            <ViewMore onClick={loadMoreResults}>
              <P xSmall bold>
                <Trans ns="friendlyMatches" i18nKey="viewMore">View More</Trans>
              </P>
              <ViewMoreArrow src={LoadImage('friendly-matches/view-more-arrow.svg')} alt="View more results" />
            </ViewMore>
          )}

          {status === 'loading' && data?.results?.page !== page && (
            <PlayersContainer className="mt30">
              <ContentLoader type="userCardList" items={6} />
            </PlayersContainer>
          )}
        </>
      )}

      {/* {raisedHands && !raisedHands?.results?.length && raisedHandsStatus === 'succeeded' && (
        <NoDataContainer className="text-center">
          <img src={LoadImage('equipment-icon.svg')} alt="" width={42} height={45} />
          <H3><Trans ns="friendlyMatches" i18nKey="playFriendlyMatch">Play a Friendly Match</Trans></H3>
          <P className="mb30">
            <Trans ns="friendlyMatches" i18nKey="playFriendlyMatchSubtitle">
              Be the 1st one to show that you want to play a friendly match as soon as possible!
            </Trans>
          </P>
          <RaiseHand handRaisedData={accountInfo?.handRaised} />
        </NoDataContainer>
      )} */}

      {status === 'loading' && !data?.length && (
        <PlayersContainer>
          <ContentLoader type="userCardList" items={6} />
        </PlayersContainer>
      )}
    </Container>
  );
};

export default PartnerClubs;
