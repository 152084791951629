import PubSub from 'pubsub-js';
import {
  post,
  patch,
  get,
  remove,
} from '../../components/common/http';

const catchError = ({
  error,
  addToast,
  t,
  fallbackMessage = 'API_ERROR',
}) => {
  const message = error?.response?.data?.message ?? error?.message ?? fallbackMessage;

  addToast(t(message), {
    appearance: 'error',
    autoDismiss: true,
  });

  PubSub.publish('api-error-handler', {
    message,
    hasNotification: true,
  });
};

export const followEvent = async ({ id, addToast, t }) => {
  try {
    await post(`/events/${id}/follow`);
  } catch (error) {
    catchError({
      error,
      addToast,
      t,
      fallbackMessage: 'FAILED_FOLLOW_EVENT',
    });
  }
};

export const registerToEvent = async ({
  eventId, competitionId, payload, socketClientId, addToast, t, partner,
}) => {
  try {
    await post(`/events/${eventId}/competitions/${competitionId}/entries`, {
      socketClientId,
      paymentInfo: payload,
      partner,
    });
  } catch (error) {
    catchError({
      error,
      addToast,
      t,
      fallbackMessage: 'EVENT_REGISTRATION_FAILED',
    });
  }
};

export const withdrawFromEvent = async ({
  eventId, competitionId, socketClientId, addToast, t,
}) => {
  try {
    await remove(`/events/${eventId}/competitions/${competitionId}/entries`, { data: { socketClientId } });
  } catch (error) {
    catchError({
      error,
      addToast,
      t,
      fallbackMessage: 'EVENT_WITHDRAWAL_FAILED',
    });
  }
};

export const getEventMatches = async (id) => {
  try {
    const { data: { data } } = await get(`/matches/${id}/event`);
    return data;
  } catch (error) {
    catchError({
      error,
      fallbackMessage: 'FAILED_TO_FETCH_MATCHES',
    });
  }
};

export const updateAccount = async ({
  id, payload, addToast, t,
}) => {
  try {
    await patch(`/accounts/${id}`, payload);
  } catch (error) {
    catchError({
      error,
      addToast,
      t,
      fallbackMessage: 'UPDATE_ACCOUNT_FAILED',
    });
  }
};
