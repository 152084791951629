/* eslint-disable no-duplicate-case */
/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Burger from './components/BurgerMenu';
import Nav from './Nav';
import LangSelect from '../LangSelect';

import { Button, Link } from '../../../Collection';

import {
  Header,
  Logo,
  AuthSection,
  HeaderContainer,
} from './styles';

import ProfileDropdown from './components/ProfileDropdown';
import NotificationDropdown from './components/NotificationsDropdown';
import LoadImage from '../../../common/LoadImage';
import Wallet from './components/Wallet';
import BannedBanner from './components/BannedBanner';
import mixpanel from '../../../../mixpanel';
import RaiseHand from './components/RaiseHand';
import RaiseHandModal from './components/RaiseHandModal';
import ToggleVisible from '../../../common/ToggleVisible';
import NewsBanner from './components/NewsBanner';

const HeaderComponent = ({
  children,
  isBanned,
  newsBanner,
  blur,
  handle,
  ...props
}) => {
  const { t } = useTranslation('header');
  const visitedProfileMembership = useSelector(state => state.accounts.info.general?.data?.membership?.plan);

  const {
    isComponentVisible: isRaiseHandVisible,
    setIsComponentVisible: setRaisedHandVisible,
    ref,
  } = ToggleVisible(false);

  const {
    name, accountInfo, isLoggedIn, CustomHeader, showRaiseHandModal,
  } = props;

  const [navBg, setNavBg] = useState(false);
  const [navbarColor, setNavbarColor] = useState('#fff');
  const [langSelectColor, setLangSelectColor] = useState('black');
  const { data: newMessages } = useSelector(state => state?.conversations?.newMessages);
  const page = name?.split(' ').join('');
  const isPlayer = accountInfo?.role === 'player';

  // Set an event listener to check how many pixels have been scrolled
  useEffect(() => {
    window.addEventListener('scroll', changeNavBackground);
    return () => {
      window.removeEventListener('scroll', changeNavBackground);
    };
  });

  // Defines the amount of pixels needed to scroll before the navbar changes color
  const changeNavBackground = () => {
    if (['Starting Guide'].includes(name)) {
      setNavBg(window.scrollY >= 150);
    } else if (['Events'].includes(name)) {
      if (window.innerWidth <= 650) {
        setNavBg(window.scrollY >= 40);
      } else if (window.innerWidth <= 990) {
        setNavBg(window.scrollY >= 50);
      } else if (window.innerWidth >= 1023) {
        setNavBg(window.scrollY >= 50);
      }
    } else if (['Find Players'].includes(name)) {
      setNavBg(window.scrollY >= 250);
    } else if (window.innerWidth <= 650) {
      setNavBg(window.scrollY >= 20);
    } else {
      setNavBg(window.scrollY >= 50);
    }
  };

  // Defines the color of the language selector in navbar on specific pages (Default is black)
  useEffect(() => {
    switch (name) {
    case 'Homepage':
      setNavbarColor('white');
      !navBg ? setLangSelectColor('white') : setLangSelectColor('black');
      break;

    case 'ComingSoon':
      setNavbarColor('white');
      !navBg ? setLangSelectColor('white') : setLangSelectColor('black');
      break;

    case 'Events':
      !navBg ? setLangSelectColor('white') : setLangSelectColor('black');
      break;

    case 'Starting Guide':
      setLangSelectColor('white');
      break;

    case 'Starting Guide':
      setLangSelectColor('white');
      break;

    case 'Find Players':
      setNavbarColor('white');
      break;

    case 'FriendlyMatches':
      setNavbarColor('#003640');
      setLangSelectColor('white');
      break;

    default:
      break;
    }
  }, [navBg]);

  const navbarProfile = {
    free: '#F8F8F8',
    summer: '#FFF8E1',
    premium: '#EAF6F6',
  };

  const pageProps = {
    Homepage: {
      changeColorOnScroll: true,
      transparentNav: !navBg,
      whiteNavText: !navBg,
      whiteBurgerBars: !navBg,
      whiteLogo: !navBg,
    },

    ForPlayers: {
      changeColorOnScroll: true,
      transparentNav: true,
    },

    ForPlayersLearnMore: {
      changeColorOnScroll: true,
      transparentNav: true,
    },

    ComingSoon: {
      changeColorOnScroll: true,
      whiteBurgerBars: !navBg,
      whiteNavText: !navBg,
      whiteAvatar: !navBg,
      whiteLogo: !navBg,
      whiteBell: !navBg,
      whiteChat: !navBg,
      darkCyan: !navBg,
      walletBg: true,
    },

    FindPlayers: {
      changeColorOnScroll: true,
      blueNav: !navBg,
    },

    StartingGuide: {
      whiteBurgerBars: true,
      transparentNav: true,
      whiteNavText: true,
      whiteLogo: true,
      whiteBell: true,
      whiteChat: true,
      blackNav: navBg,
      whiteAvatar: true,
    },

    FriendlyMatches: {
      changeColorOnScroll: true,
      whiteBurgerBars: true,
      whiteNavText: true,
      whiteAvatar: true,
      whiteLogo: true,
      whiteBell: true,
      whiteChat: true,
    },

    Events: {
      changeColorOnScroll: true,
      whiteBurgerBars: !navBg,
      transparentNav: !navBg,
      whiteNavText: !navBg,
      whiteAvatar: !navBg,
      whiteLogo: !navBg,
      whiteBell: !navBg,
      whiteChat: !navBg,
    },

    Myaccount: {
      navbarProfile: navbarProfile?.[visitedProfileMembership] ?? navbarProfile?.[accountInfo?.membership?.plan],
    },
    AccountProfile: {
      navbarProfile: navbarProfile?.[visitedProfileMembership] ?? navbarProfile?.[accountInfo?.membership?.plan],
    },
  };

  const LogoType = {
    true: 'logo-white.svg',
    false: 'logo.svg',
    undefined: 'logo.svg',
  };

  return CustomHeader ? (<CustomHeader />) : (
    <HeaderContainer blur={blur}>
      {isBanned && <BannedBanner />}
      {!isBanned && newsBanner && <NewsBanner />}
      <Header fixed {...pageProps?.[page]} navbarColor={navbarColor}>
        <Burger
          handle={handle}
          whiteBars={pageProps?.[page]?.whiteBurgerBars}
          loggedIn={isLoggedIn}
          accountRole={accountInfo?.role}
          t={t}
        />
        <Link to={isLoggedIn ? '/dashboard' : '/'}>
          <Logo
            src={LoadImage(LogoType?.[pageProps?.[page]?.whiteLogo])}
            alt="Sportya Logo"
            className="img-responsive"
          />
        </Link>

        <Nav
          whiteNavText={pageProps?.[page]?.whiteNavText}
          isLoggedIn={isLoggedIn}
          accountRole={accountInfo?.role}
          className="onlyDesktop"
        />

        <AuthSection>
          {!isLoggedIn && !blur && (
            <>
              <LangSelect color={langSelectColor} />
              <Button
                small
                outline
                minWidth="100px"
                padding="0 5px"
                className="onlyDesktop"
              >
                <Link to="/login">
                  {t('login')}
                </Link>
              </Button>
              <Button
                small
                minWidth="100px"
                margin="0 0 0 10px"
                padding="0 10px"
                className="onlyDesktop"
              >
                <Link to="/register">
                  {t('register')}
                </Link>
              </Button>
            </>
          )}

          {isLoggedIn && (
            <>
              {isPlayer && (
                <>
                  {!accountInfo?.isImpersonate && (
                    <>
                      <div className="mr10">
                        <RaiseHand header handRaisedData={accountInfo?.handRaised} />
                        {showRaiseHandModal && <RaiseHandModal account={accountInfo} />}
                      </div>

                      <Link
                        to="/conversations"
                        className="conversations-icon"
                        onClick={() => mixpanel.track('Access Chat section from Header')}
                      >
                        <img
                          src={LoadImage(`${pageProps?.[page]?.whiteChat ? 'white-' : ''}conversations-icon.svg`)}
                          alt="Conversations"
                          className="img-responsive"
                        />
                        {newMessages > 0
                          && <span className={`new-messages ${page}`}>{newMessages > 9 ? '9+' : newMessages}</span>}
                      </Link>
                      <NotificationDropdown whiteBell={pageProps?.[page]?.whiteBell} page={page} />
                    </>
                  )}
                  <Wallet balance={accountInfo?.walletBalance} currency={accountInfo?.currency} />
                </>
              )}
              <ProfileDropdown
                accountInfo={accountInfo}
                whiteAvatar={pageProps?.[page]?.whiteAvatar}
                name={name}
                state={{ isVisible: isRaiseHandVisible, setVisible: setRaisedHandVisible, ref }}
              />
            </>
          )}
        </AuthSection>
      </Header>
    </HeaderContainer>
  );
};

export default HeaderComponent;
