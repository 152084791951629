export default {
  seoTitle: 'Beállításaim | Sportya',
  cancelRequest: 'Kérés visszavonása',
  addFriend: 'Ismerős hozzáadása',
  confirmRequest: 'Fogadja el a kérést',
  friends: 'Barátok',
  unfriend: 'Ismerős törlése',

  profileSettings: {
    title: 'Beállításaim',

    playerDetails: {
      title: 'Fiók részletei',
      passionatePlayer: 'Szenvedélyes teniszjátékos',
      accountType: 'Fiók típusa',
      upgradeToPremium: 'Frisstés Prémiumra',
      subscriptionValidity: 'Előfizetés érvényessége',
      subscriptionState: 'Soha nem jár le',
      since: '{{createdAt}}-tól',
      free: 'Ingyenes',
      summer: 'Nyári fiók',
      premium: 'Prémium {{period}} fiók',
      accountMembership: {
        free: 'Ingyenes fiók',
        summer: 'Nyári fiók',
        premium: 'Prémium fiók',
      },
    },

    playerProfile: {
      accountType: {
        free: 'Ingyenes fiók',
        summer: 'Nyári fiók',
        premium: 'Prémium fiók',
      },
    },

    changePassword: {
      title: 'Jelszó módosítása',
      oldPassword: 'Régi jelszó',
      newPassword: 'Új jelszó',
      confirmNewPassword: 'Jelszó megerősítése',
      passwordRequirement: 'Az új jelszónak 8-16 alfanumerikus karakter hosszúságúnak kell lennie, és legalább egy speciális karaktert kell tartalmaznia.',
      pleaseEnterNewPassword: 'Kérjük, add meg az új jelszavadat.',
      passwordsDoNotMatch: 'A jelszavak nem egyeznek.',
      savePassword: 'Jelszó mentése',
    },

    personalInformation: {
      title: 'Személyes információk',
      firstName: 'Keresztnév',
      lastName: 'Vezetéknév',
      year: 'Év',
      month: 'Hónap',
      day: 'Nap',
      location: 'Tartózkodási hely',
      phoneNumber: 'Telefonszám',
      invalidPhoneNumber: 'Érvénytelen telefonszám',
      clubTitle: 'Cím',
    },

    profileDetails: {
      title: 'Profil részletei',
      singleGameLevel: 'Egyéni játékszint',
      doublesGameLevel: 'Páros játékszint',
      noGameLevel: 'Nem állítottad még be a játékszintedet',
      buttonSetLevel: 'Állítsd be a játékszintedet',
      needOfChange: 'Ha meg kell változtatnod a szintet, kérjük, lépj velünk kapcsolatba a <1>contact@sportya.net</1> címen.',
      dominantHand: 'Domináns kéz',
      left: 'Bal',
      right: 'Jobb',
      shirtSize: 'Pólóméret',
      shirtNone: 'Nincs',
    },

    languageCommunication: {
      title: 'Nyelv és kommunikáció',
      language: 'Nyelv',
      ro: 'Román',
      en: 'Angol',
      hu: 'Magyar',
      bg: 'Bolgár',
      email: 'Email',
      newsletter: 'Hírlevél',
      emailChatConversations: 'E-mail értesítések a csevegőbeszélgetésekről',
      emailFriendRequests: 'E-mailes értesítések az ismerőskérésekről',
      emailFriendlyMatches: 'E-mail értesítések barátságos meccsekhez',
      subscribe: 'Feliratkoztál a Sportya hírlevelére (játéklehetőségek a régiómban, újdonságok és egyebek)',
    },

    availabilitySchedule: {
      title: 'Rendelkezésre állási ütemterv',
      Mon: 'Hé',
      Tue: 'Ke',
      Wed: 'Sze',
      Thu: 'Csü',
      Fri: 'Pé',
      Sat: 'Szo',
      Sun: 'Va',
    },

    blockedPlayers: {
      title: 'Blokkolt játékosok',
      blockImplications: 'Ha letilt valakit, az a játékos többé nem tud meghívni téged meccsekre, nem cseveghet veled, és nem vehet fel ismerősként.',
      howToBlock: 'A játékosok blokkolásához kattintson a Játékos blokkolása elemre a profilbeállítások között.',
      unblock: 'Blokkolás feloldása',
      block: 'Blokkolja',
      unblockUser: '{{unblockName}} blokkolásának feloldása',
      blockUser: 'Blokkolja {{unblockName}}',
      areYouSureUnblock: 'Biztosan feloldja  <1>{{unblockName}}</1> blokkolását ?',
      areYouSureBlock: 'Biztosan letiltod <1>{{unblockName}}</1> ?',
      unblockInfo: 'A játékos meghívhat téged meccsekre, beszélgetést kezdeményezhet veled, vagy felvehet ismerősként.',
      blockInfo: 'A játékos többé nem hívhat meg meccsekre, nem kezdhet veled beszélgetést, és nem vehet fel barátként.',
      blockNote: 'Megjegyzés: Nem tartalmazza azokat a versenyeket, amelyeken mindketten részt vesztek.',
      blockPlayer: 'Játékos blokkolása',
      unblockPlayer: 'Blokkolás feloldása',
      reportPlayer: 'Jelentés játékos',
      reportUser: 'Jelentsd {{reportName}}',
      reportInfo: 'Kérjük, válassza ki a bejelentés okát, és adjon meg minden további információt, amely segít csapatunknak jobban megérteni a helyzetet. A bejelentett játékos nem kap értesítést.',
      reportButton: 'Jelentés',
      reportAndBlockButton: 'Jelentés és blokkolás',
      successReportAndBlockTitle: 'Bejelentette és letiltotta ezt a játékost',
      successReportText: 'Megvizsgáljuk bejelentését, és felvesszük Önnel a kapcsolatot, ha bármilyen információra van szükség.',
      gotIt: 'értettem',
      successReportTitle: 'Feljelentette ezt a játékost',
      youBlockedThisPlayer: 'Letiltottad ezt a játékost',
      manageBlockedPlayers: 'A kitiltott játékosok kezelése',
    },

    billingData: {
      title: 'Számlázási adatok',
      companyName: 'Cégnév',
      cui: 'Adóregisztrációs kód',
      regNumber: 'Regisztrációs szám',
      fullName: 'Teljes név',
      address: 'Utcanév, szám, emelet stb.',
      city: 'City',
      country: 'Ország',
    },

    reportReasons: {
      reason: 'A bejelentés oka',
      selectTheReason: 'Válassza ki az okot',
      harassment: 'Zaklatás vagy megfélemlítés',
      spam: 'Levélszemét',
      fakeProfile: 'Hamis profil',
      playerUnderFifteen: '15 év alatti játékos',
      other: 'Másik ok',
    },

    reportTextAreaInfo: 'Kérjük, adjon meg további információkat (nem kötelező)',
  },
};
