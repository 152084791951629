/* eslint-disable max-len */
import styled from '@emotion/styled';
import ComponentContainer from '../../../components/Container';
import virtualWalletAvailable from '../../../assets/images/bg/virtualWalletAvailable.svg';
import virtualWalletUnavailable from '../../../assets/images/bg/virtualWalletUnavailable.svg';
import virtualWalletNegative from '../../../assets/images/bg/virtualWalletNegative.svg';

export const VirtualWallet = styled.div`
  .walletWrapp {
    max-width: 918px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .walletHeader {
      height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background-image: url(${props => (props.availableFunds ? virtualWalletAvailable : (props.unavailableFunds ? virtualWalletUnavailable : virtualWalletNegative))});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      color: ${({ theme }) => theme?.colors?.white};
      padding-bottom: 10px;
      border-radius: 10px;
      margin-top: 40px;

      @media screen and (max-width: 480px) {
        h1 {
          font-size: ${({ theme }) => theme.functions?.toEm(30)} !important
        }
      }


      .walletTitle {
        font-size: 48px;
        color: ${({ theme }) => theme?.colors?.white};
      }

      p {
        margin: 22px 0 0;
        color: ${({ theme }) => theme?.colors?.white};
      }

      .walletSum {
        font-size: 38px;
        line-height: 50px;
        margin-top: 7px;
        color: ${({ theme }) => theme?.colors?.white};
      }

      .topUpBtn {
        background: white;
        margin-top: 15px;
        max-width: 250px;
        width: 100%;
        border: none;
        box-shadow: none;
        display: flex;
        justify-content: center;
        align-items: center;
        -webkit-appearance: none;

        img {
          margin-right: 12px;
        }

        > * {
          color: ${props => ({ theme }) => (props.availableFunds ? theme?.colors?.secondary : (props.unavailableFunds ? theme?.colors?.primary : theme?.colors?.error))};
        }
      }
    }

    .walletInfo {
      text-align: center;
      margin-top: 40px;
      margin-bottom: 0px;
    }

  }
`;

export const WalletTransactions = styled.div`
  margin-top: 40px;

  h3 {
    line-height: 36px;
    margin-bottom: 30px;
  }

  border: 1px solid ${({ theme }) => theme?.colors?.lightBlue};
  padding: 30px 40px 30px 40px;
  border-radius: 10px;

  .walletTransactions {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #EAF6F6;
    justify-content: space-between;
    padding-bottom: 10px;

    p {
      margin: 0;
    }

    .transactionId {
      max-width: 90px;
      width: 100%;
    }

    .transactionDate {
      max-width: 141px;
      width: 100%;
    }

    .transactionProduct {
      max-width: 487px;
      width: 100%;
    }

    .transactionAmount {
      max-width: 100px;
      width: 100%;
    }

    ${props => props.theme.functions?.mediaQueryMax('810px', `
      display: none;
    `)}

  }

  .noTransactionsWrapp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 345px;

    h4 {
      margin-top: 10px;
    }
  }
`;

export const ModalStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: ${({ theme }) => theme.functions?.toEm(28)}
  }

  line-height: 36px;
  margin-top: 45px;
}

.subTitle {
  margin-bottom: 30px;
}


.stripe-logo {
  display: flex;
  align-items: center;
  margin-top: 20px;
  padding-bottom: 55px;

  p {
    line-height: 16px;
  }

  img {
    margin-left: 4px;
  }
}

form {
  width: 100%;
  max-width: 350px;
}

.buttonWrapp {
  margin-top: 10px;
  max-width: 340px;
  width: 100%;

  .disableBTN {
    background-color: ${({ theme }) => theme?.colors?.disabledBlue};
    box-shadow: none;
    cursor: no-drop;

    p {
      color: ${({ theme }) => theme?.colors?.white};
      background-color: ${({ theme }) => theme?.colors?.disabledBlue};
    }
  }
}

`;

export const StripeModal = styled.div`
  max-width: 303px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 429px;

  img {
    margin-bottom: 10px;
  }

  h4 {
    font-size: ${({ theme }) => theme.functions?.toEm(24)}
  }

  line-height: 31px !important;
}

.loader {
  display: block !important;
  height: auto !important;
}
`;

export const Container = styled(ComponentContainer)`
  margin: 0 auto 20px auto;
`;

export const PriceWrap = styled.div`
  padding: 20px;
  border: ${props => ({ theme }) => (props.checked ? `2px solid ${theme?.colors?.primary}` : `1px solid ${theme?.colors?.disabledBlue}`)};
  border-radius: 4px;
  margin-bottom: 10px;
`;
