import React, { useState, useEffect, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, omit } from 'lodash';
import { Helmet } from 'react-helmet';
import dateFormat from 'dateformat';

import {
  Container,
  LeaderboardStyle,
  TabsBtn,
  TabsBtnContainer,
  TabsContainer,
} from './styles';

// import { MaintenanceBar } from '../Events/styles';
import LoadImage from '../../components/common/LoadImage';
import H1 from '../../components/H1';
import useModal from '../../components/Modal/useModal';
import Modal from '../../components/Modal/Modal';
import LevelSelector from './components/LevelSelector';
import Pagination from '../../components/Pagination';
import PlayerTableCard from './components/PlayersTableCard';
import Player from './components/Player';
// import FindPlayerHeader from './components/FindPlayerHeader';
import SearchBar from './components/Search/SearchBar';
import AdvancedFilters from './components/AccountsFilters/AdvancedFilters';
import { fetchLeaderboards, setActiveFilters, setLeaderboards } from './reducer';
import Paragraph from '../../components/Paragraph';

import calculationDate from '../../components/common/getCalculationDate';
import { getLeaderboard } from '../../components/Layout/reducers/session';
import { Button, Contain, P } from '../../components/Collection';
import FriendPlayer from './components/FriendPlayer';
import ContentLoaderHandler from '../../components/ContentLoader';

const initialFilters = {
  gameType: 'singles',
  gameLevel: 4,
  rankingsType: 'general',
};

const initialOptions = {
  page: 1,
  limit: 25,
};

const Leaderboards = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('events');
  const { t: leaderboards } = useTranslation('leaderboards');

  const { data: activeFilters } = useSelector(state => state.leaderboard?.activeFilters);
  const { data: generalRankings, status } = useSelector(state => state.leaderboard?.generalRankings);
  const lastRun = useSelector(state => state.session.leaderboard);

  const [filters, setFilters] = useState({
    ...activeFilters?.rankingsType === 'friends' && {
      ...omit(initialFilters, ['gameLevel']),
    },
    ...activeFilters?.rankingsType !== 'friends' && {
      ...initialFilters,
    },
    ...initialOptions,
    ...activeFilters,
  });

  const { isShowing, toggle } = useModal();
  const listRef = useRef(null);

  useEffect(() => {
    if (status === 'idle' || JSON.stringify(filters) !== JSON.stringify(activeFilters)) {
      dispatch(setActiveFilters(filters));
      dispatch(fetchLeaderboards(filters));
    }
  }, [filters, activeFilters, status]);

  useEffect(() => {
    if (isEmpty(lastRun)) {
      dispatch(getLeaderboard());
    }
  }, []);

  const clearResults = async () => {
    setFilters({
      ...omit(filters, ['string']),
      ...initialOptions,
    });
  };

  const handlePagination = (data) => {
    const page = data.selected + 1;

    if (filters.page !== page) {
      setFilters({
        ...filters,
        page,
      });
    }

    listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const handleChangeGameType = (data) => {
    setFilters({
      ...filters,
      ...initialOptions,
      gameType: data,
    });
  };

  const handleChangeRankingsType = (data) => {
    let newFilters = {
      ...initialFilters,
      ...initialOptions,
      rankingsType: data,
    };

    if (data === 'friends') {
      newFilters = omit(newFilters, ['gameLevel']);
    }

    setFilters(newFilters);
  };

  const handleChangeLevel = (value) => {
    setFilters({
      ...filters,
      ...initialOptions,
      gameLevel: value,
    });
  };

  function handleModalFilters(data) {
    const modalFilters = {};
    Object.keys(data).forEach((item) => {
      if (!isEmpty(data[item])) {
        modalFilters[item] = [data[item]].join(',');
      }
    });

    setFilters({
      ...filters,
      ...modalFilters,
      ...initialOptions,
    });
  }

  const resetAdvancedFilters = () => {
    const newFilters = {
      ...omit(filters, ['gender', 'country']),
      ...initialOptions,
    };

    setFilters(newFilters);
    toggle();
  };

  const from = dateFormat(calculationDate(lastRun).start, 'd mmm yyyy');
  const to = dateFormat(calculationDate(lastRun).end, 'd mmm yyyy');
  const levelSelectOptions = [
    { value: 4, label: leaderboards('level.4') },
    { value: 5, label: leaderboards('level.5') },
    { value: 6, label: leaderboards('level.6') },
    { value: 7, label: leaderboards('level.7') },
    { value: 8, label: leaderboards('level.8') },
    { value: 9, label: leaderboards('level.9') },
  ];
  const totalActiveFilters = Object.keys(filters)
    .filter(key => filters[key] && ['gender', 'country'].includes(key)).length;
  const hasFetched = status === 'succeeded' && !isEmpty(generalRankings);
  let friendsRank = ((initialOptions?.page - 1) * initialOptions?.limit) + 1;

  return (
    <Container>
      <Helmet>
        <title>{t('leaderboardSeoTitle')}</title>
      </Helmet>
      <LeaderboardStyle>

        <div className="leaderboardHeader mb30" ref={listRef}>
          <H1>
            <Trans ns="leaderboards" i18nKey="title">
              Leaderboards
            </Trans>
          </H1>
          {calculationDate(lastRun).last
            ? (
              <div className="leaderboardHeaderInfo">
                <Paragraph smaller>
                  <Trans ns="leaderboards" i18nKey="lastUpdate">
                    Last Update
                  </Trans>
                  :
                  {' '}
                  {dateFormat(calculationDate(lastRun).last, 'd mmm yyyy')}
                </Paragraph>
                <Paragraph smaller>
                  <Trans ns="leaderboards" i18nKey="timeframe">
                    Leaderboard calculation timeframe
                  </Trans>
                  {`: ${from} - ${to}`}
                </Paragraph>
              </div>
            ) : ''}
        </div>

        <SearchBar
          isLoading={!hasFetched}
          filters={filters}
          setFilters={setFilters}
          handleEnter={{ setter: setLeaderboards, reset: clearResults }}
          totalPlayers={generalRankings?.totalResults}
          mark={listRef}
        />

        <TabsContainer>
          <TabsBtnContainer>
            <TabsBtn
              onClick={() => handleChangeRankingsType('general')}
              selected={filters.rankingsType === 'general'}
            >
              <Trans ns="leaderboards" i18nKey="generalRankings">
                General Rankings
              </Trans>
            </TabsBtn>
            <TabsBtn
              onClick={() => handleChangeRankingsType('friends')}
              selected={filters.rankingsType === 'friends'}
            >
              <Trans ns="leaderboards" i18nKey="friendsRankings">
                Friends Rankings
              </Trans>
            </TabsBtn>
          </TabsBtnContainer>
        </TabsContainer>

        <div className={`filtersWrapp ${filters.rankingsType === 'general' ? '' : 'mb10'}`}>
          <div className="filtersButtons">
            <button
              type="button"
              onClick={() => handleChangeGameType('singles')}
              className={filters.gameType === 'singles' ? 'selectedFilter' : ''}
            >
              <Trans ns="leaderboards" i18nKey="filters.singles">
                Singles
              </Trans>
            </button>
            <button
              type="button"
              onClick={() => handleChangeGameType('doubles')}
              className={filters.gameType === 'doubles' ? 'selectedFilter' : ''}
            >
              <Trans ns="leaderboards" i18nKey="filters.doubles">
                Doubles
              </Trans>
            </button>
          </div>

          <div className="filterModalButton d-flex justify-content-end align-items-center">
            <Button
              small
              outline
              noBorder
              noImgMargin
              minWidth="116px"
              margin="0 0 0 30px"
              padding="15px 18px"
              className="filters"
              color="#fff"
              onClick={() => toggle()}
            >
              <img src={LoadImage('filter.svg')} alt="All" />
              <P bold margin="0 0 0 10px">{t('filter_all')}</P>
              {totalActiveFilters > 0 && (
                <P small bold color="#fff" className="filters-amount">{totalActiveFilters}</P>)}
            </Button>
          </div>
        </div>

        {filters.rankingsType === 'general' && (
          <div className="levelSelectorWrapp">
            {levelSelectOptions.map((level, idx) => {
              const active = filters.gameLevel === level.value;
              return (
                <LevelSelector
                  active={active}
                  clickHandler={() => {
                    handleChangeLevel(level.value);
                  }}
                  key={idx}
                >
                  {level.label}
                </LevelSelector>
              );
            })}
          </div>
        )}

        {!hasFetched ? (
          <Contain
            align="center"
            justify="center"
            width="100%"
            margin="150px 0 150px 0"
          >
            <ContentLoaderHandler />
          </Contain>
        ) : (
          <PlayerTableCard isFriendsTable={filters.rankingsType === 'friends'}>
            {generalRankings?.results?.length > 0
              ? filters.rankingsType !== 'friends'
                ? generalRankings?.results?.map((genericPlayer, idx) => (
                  <Player
                    key={idx}
                    idx={idx}
                    length={generalRankings?.results?.length}
                    player={genericPlayer}
                    gameType={filters.gameType}
                  />
                ))
                : generalRankings?.results?.map((friend, idx) => (
                  <FriendPlayer
                    key={idx}
                    idx={idx}
                    length={generalRankings?.results?.length}
                    player={friend}
                    gameType={filters.gameType}
                    friendsRank={friendsRank++}
                  />
                ))
              : leaderboards('noPlayersFound')}
          </PlayerTableCard>
        )}
        <Pagination
          pageCount={Math.ceil(generalRankings?.totalResults / generalRankings?.limit || 0)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePagination}
          forcePage={filters.page - 1}
        />
      </LeaderboardStyle>
      <Modal
        type2
        header
        title={t('filters')}
        noborder
        nobackground
        isShowing={isShowing}
        hide={toggle}
      >
        <AdvancedFilters
          {...{
            handleModalFilters, toggle, resetAdvancedFilters, filters,
          }}
        />
      </Modal>
    </Container>
  );
};

export default Leaderboards;
