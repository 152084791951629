import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import dateFormat from 'dateformat';

import { TabPlaceholder } from '../components/styles';
import { fetchTabData } from '../../reducers';
import { Contain, P } from '../../../../components/Collection';
import {
  Container, Date, Matches, Title, TitleContent, TitleHeader, TitleHeaderIcon, TitleMatches, TitlesContainer,
} from './styles';
import GameType from '../components/GameType';
import LoadImage from '../../../../components/common/LoadImage';
import Loader from '../../../../components/ContentLoader';

const categoryBg = {
  firstServe: '#E9E9E9',
  gold: '#FFCC18',
  silver: '#B0B0B0',
  platinum: '#5754FF',
  champions: '#CB5DFF',
  special: '#CB5DFF',
  league: '#39D377;',
};

const TitlesTab = ({ userId, profile }) => {
  const dispatch = useDispatch();
  const { t: commonT } = useTranslation('common');
  const [gameType, setGameType] = useState('singles');

  const { data: titles, status } = useSelector(state => state.accounts.info.tabs[profile].titles[gameType]);
  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const isLoading = generalStatus === 'loading';
  const didNotFetch = (isEmpty(titles) && status === 'idle');
  const hasNoResults = !titles?.length;

  useEffect(() => {
    if (didNotFetch) {
      dispatch(fetchTabData({
        userId, tab: 'titles', type: gameType, filters: {}, options: {}, profile,
      }));
    }
  }, [status, dispatch]);

  return isLoading
    ? (
      <Container hasLoader>
        <Loader />
      </Container>
    ) : (
      <Container>
        <Contain width="100%" margin="20px 0">
          <GameType gameType={gameType} handler={setGameType} />
        </Contain>
        {(titles || [])?.map((title, key) => {
          const hasAnyTitles = !!title?.events?.length;
          const championList = (title?.events)?.filter(event => event?.title === 'champion');
          const finalistList = (title?.events)?.filter(event => event?.title === 'finalist');
          const hasChampionTitles = !!championList?.length;
          const hasFinalistTitles = !!finalistList?.length;
          return hasAnyTitles && (
            <TitlesContainer key={key} border={categoryBg[title?.category]}>
              <TitleHeader bg={categoryBg[title?.category]}>
                <TitleHeaderIcon
                  src={LoadImage(`player-profile/titles-tab/${title?.category}.svg`)}
                  alt={commonT(title?.category)}
                />
                <P large bold color="white">
                  <Trans ns="common" i18nKey={title?.category}>
                    {title?.category}
                  </Trans>
                </P>
              </TitleHeader>
              <TitleContent>
                {hasChampionTitles && (
                  <TitleMatches>
                    <Title large bold>
                      <Trans ns="player" i18nKey="champion">
                        Champion
                      </Trans>
                    </Title>
                    {championList?.map((championTitle, championKey) => (
                      <Matches
                        key={championKey}
                        to={`/events/${championTitle?.eventId}/competitions/${championTitle?.competitionId}`}
                      >
                        {championTitle?.eventName}
                        <Date>{dateFormat(championTitle?.date, 'd mmm yyyy')}</Date>
                      </Matches>
                    ))}
                  </TitleMatches>
                )}
                {hasFinalistTitles && (
                  <TitleMatches addMarginTop={hasChampionTitles && hasFinalistTitles}>
                    <Title large bold>
                      <Trans ns="player" i18nKey="finalist">
                        Finalist
                      </Trans>
                    </Title>
                    {finalistList?.map((finalistTitle, finalistKey) => (
                      <Matches
                        key={finalistKey}
                        to={`/events/${finalistTitle?.eventId}/competitions/${finalistTitle?.competitionId}`}
                      >
                        {finalistTitle?.eventName}
                        <Date>{dateFormat(finalistTitle?.date, 'd mmm yyyy')}</Date>
                      </Matches>
                    ))}
                  </TitleMatches>
                )}
              </TitleContent>
            </TitlesContainer>
          );
        })}
        {hasNoResults && (
          <Contain
            width="fit-content"
            align="center"
            direction="column"
            margin="40px auto"
          >
            <TabPlaceholder src={LoadImage('tab-placeholder.png')} alt="No results found" />
            <P color="#78ADAB">No results found</P>
          </Contain>
        )}
      </Container>
    );
};

export default TitlesTab;
