const getCalculationDate = (leaderboard) => {
  const leadInfo = {
    lastUpdate: leaderboard?.date ? new Date(leaderboard?.date) : new Date(),
    get start() {
      return new Date(
        new Date(
          new Date(this.lastUpdate)
            .setDate(this.lastUpdate.getDate() - ((this.lastUpdate.getDay() + 6) % 7) - (53 * 7)),
        ).setHours(0, 0, 0),
      ).toString();
    },
    get end() {
      return new Date(
        new Date(
          new Date(this.lastUpdate).setDate(this.lastUpdate.getDate() - ((this.lastUpdate.getDay() + 6) % 7) - 8),
        ).setHours(23, 59, 59),
      ).toString();
    },
  };

  return {
    last: leadInfo.lastUpdate,
    start: leadInfo.start,
    end: leadInfo.end,
  };
};

export default getCalculationDate;
