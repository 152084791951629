import React, {
  useCallback, useEffect, useRef, useState, useContext,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import { useSelector, useDispatch } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';

import {
  Container, FriendsStyle, FriendsContainer, FriendsList,
} from './styles';
import H1 from '../../components/H1';
import { Contain } from '../../components/Collection';
import { H3, Button } from '../../components';
import LoadImage from '../../components/common/LoadImage';
import friendsHook from './hooks/friendsHook';
import FriendCard from './components/FriendCard';

import { store as socketStore } from '../../components/Socket/store';
import { addConversationToList } from '../Chat/reducers';
import { acceptFriendAction, removeFriend } from '../../components/Friends/reducers';
import { acceptFriend } from '../MyAccount/Settings/reducers';
import { getAccountInfo } from '../../components/Layout/reducers/session';
import mixpanel from '../../mixpanel';

const index = () => {
  const { t } = useTranslation('friends');
  const { t: commonT } = useTranslation('common');
  const listRef = useRef(null);

  const globalState = useContext(socketStore);
  const {
    state: {
      socket: { socketClientId, socket },
    },
  } = globalState;

  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const history = useHistory();

  const { accountInfo } = useSelector(state => state.session);
  const { data: friendsInfo, isLoading: reducerIsLoading } = useSelector(state => state.friends);

  if (accountInfo?.isImpersonate) {
    return <Redirect to="/dashboard" />;
  }

  const [tab, setTab] = useState('accepted');
  const [page, setPage] = useState(1);
  const [friendsCount, setFriendsCount] = useState({
    accepted: 0,
    received: 0,
    sent: 0,
  });

  const [filters, setFilters] = useState({
    status: 'accepted',
    sortBy: 'firstName',
  });

  const [initiatingChat, setInitiatingChat] = useState(false);
  const [acceptFriendSent, setAcceptFriendSent] = useState(false);
  const [tabChanged, setTabChanged] = useState(false);

  const { isLoading, error, hasMore } = friendsHook(filters, page, friendsInfo);

  const query = new URLSearchParams(history.location.search);
  const acceptFriendId = query.get('accept-friend');
  const openTab = query.get('tab');

  const observer = useRef();

  const lastElementRef = useCallback((node) => {
    if (isLoading) return;

    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting && hasMore) {
        setPage((prev) => prev + 1);
      }
    });

    if (node) observer.current.observe(node);
  }, [isLoading, hasMore]);

  useEffect(() => {
    if (!acceptFriendSent && acceptFriendId) {
      setAcceptFriendSent(true);
      dispatch(acceptFriend({ friendUserId: acceptFriendId, socketClientId }));
    }
  }, [acceptFriendSent]);

  useEffect(() => {
    if (friendsInfo.friends !== undefined) {
      const friendsStatistics = {
        accepted: friendsInfo.friends,
        received: friendsInfo.requestsReceived,
        sent: friendsInfo.requestsSent,
      };
      setFriendsCount(friendsStatistics);
    }
  }, [friendsInfo]);

  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (message === 'SUCCESSFULLY_CREATED_REPORT') {
        addToast(commonT(message), {
          appearance: 'success',
          autoDismiss: true,
        });
      }
      if (success && data) {
        if (message === 'SUCCESSFULLY_INITIATED_CHAT') {
          addToast(commonT(message), {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(addConversationToList(data));
          dispatch(getAccountInfo());
          return history.push(`/conversations/${data.id}`);
        }

        if ([
          'REMOVE_FRIEND_SUCCESS', 'DECLINE_FRIEND_SUCCESS', 'CANCEL_FRIEND_SUCCESS', 'BLOCK_PLAYER_SUCCESS',
        ].includes(message)) {
          addToast(commonT(message), {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(removeFriend(data));
          dispatch(getAccountInfo());
        }

        if (message === 'ACCEPT_FRIEND_SUCCESS') {
          addToast(commonT(message), {
            appearance: 'success',
            autoDismiss: true,
          });
          dispatch(acceptFriendAction(data));
          dispatch(getAccountInfo());
          history.push('/friends');
        }
      }

      if (!success && message !== 'CONVERSATION_NOT_FOUND') {
        addToast(commonT(message), {
          appearance: 'error',
          autoDismiss: true,
        });
      }

      setInitiatingChat(false);
    };

    if (socket) {
      socket.removeAllListeners('accounts-response');
      socket.on('accounts-response', listenForResponse);
      socket.removeAllListeners('friends-response');
      socket.on('friends-response', listenForResponse);
      socket.removeAllListeners('chat-response');
      socket.on('chat-response', listenForResponse);

      return () => {
        socket.removeAllListeners('accounts-response');
        socket.removeAllListeners('friends-response');
        socket.removeAllListeners('chat-response');
      };
    }
  }, [socketClientId]);

  const handleChangeFilterSingle = (data) => {
    const sortByTemp = data === 'accepted' ? 'firstName' : 'createdAt';
    setTab(data);

    const filtersTemp = {
      status: data,
      sortBy: sortByTemp,
    };

    setFilters(filtersTemp);
    setPage(1);
  };

  useEffect(() => {
    if (!tabChanged && (openTab === 'received')) {
      setTabChanged(true);
      handleChangeFilterSingle('received');
    }
  }, [tabChanged]);

  return (
    <Container>
      <Helmet>
        <title>{t('friendsSeoTitle')}</title>
      </Helmet>
      <FriendsStyle>
        <div className="friendsHeader mb30" ref={listRef}>
          <H1>
            <Trans ns="friends" i18nKey="title">
              Friends
            </Trans>
          </H1>
        </div>

        <div className="filtersWrapp">
          <div className="filtersButtons filtersStart">
            <button
              type="button"
              onClick={() => handleChangeFilterSingle('accepted')}
              className={tab === 'accepted' ? 'selectedFilter allFriends' : 'allFriends'}
            >
              <Trans ns="friends" i18nKey="tabs.allFriends">
                All Friends (
                {{ friendsCount: friendsCount.accepted }}
                )
              </Trans>
            </button>

            <button
              type="button"
              onClick={() => handleChangeFilterSingle('received')}
              className={tab === 'received' ? 'selectedFilter friendRequests' : 'friendRequests'}
            >
              <Trans ns="friends" i18nKey="tabs.friendRequests">
                Friend Requests (
                {{ friendsCount: friendsCount.received }}
                )
              </Trans>
            </button>

            <button
              type="button"
              onClick={() => handleChangeFilterSingle('sent')}
              className={tab === 'sent' ? 'selectedFilter sentRequests' : 'sentRequests'}
            >
              <Trans ns="friends" i18nKey="tabs.sentRequests">
                My Sent Requests (
                {{ friendsCount: friendsCount.sent }}
                )
              </Trans>
            </button>
          </div>

          <div className="filtersButtons filtersEnd">
            <Link
              to="/invite-friends"
              onClick={() => mixpanel.track('Access Invite your Friends from button in Friends section')}
            >
              <Button width="195px" padding="15px 20px" fontSize="16px" outline modalSmallButton noSetWidth>
                <span className="inviteFriends">
                  <img
                    className="inviteFriendsIcon"
                    src={LoadImage('invite-friends-icon.svg')}
                    alt="invite your friends"
                  />
                  <Trans ns="accounts" i18nKey="referral.invite.friends">
                    Invite Your Friends
                  </Trans>
                </span>
              </Button>
            </Link>
          </div>
        </div>

        {friendsInfo?.results && friendsInfo.results.length > 0 && (
          <Contain direction="column">
            <FriendsContainer>
              <FriendsList>
                {friendsInfo.results?.map((player, idx) => {
                  if (friendsInfo.results.length === idx + 1) {
                    return (
                      <div key={idx} ref={lastElementRef}>
                        <FriendCard
                          key={idx}
                          currentUser={accountInfo}
                          status={tab}
                          player={player.user}
                          socketClientId={socketClientId}
                          initiatingChat={initiatingChat}
                          setInitiatingChat={setInitiatingChat}
                          requestedAt={player.requestedAt}
                          friendsCount={friendsCount}
                          setFriendsCount={setFriendsCount}
                        />
                      </div>
                    );
                  }
                  return (
                    <FriendCard
                      key={idx}
                      currentUser={accountInfo}
                      status={tab}
                      player={player.user}
                      socketClientId={socketClientId}
                      initiatingChat={initiatingChat}
                      setInitiatingChat={setInitiatingChat}
                      requestedAt={player.requestedAt}
                      friendsCount={friendsCount}
                      setFriendsCount={setFriendsCount}
                    />
                  );
                })}
              </FriendsList>
              <div>{isLoading && 'Loading...'}</div>
              <div>{error && 'Error...'}</div>
            </FriendsContainer>
          </Contain>
        )}
        {(!friendsInfo.results || friendsInfo.results.length === 0) && !reducerIsLoading && (
          <Contain direction="column" textAlign="center" align="center">
            <img
              src={LoadImage(tab === 'accepted' ? 'search-friends-icon.svg' : 'no-friend-requests-icon.svg')}
              width="60px"
              height="60px"
              alt={tab === 'accepted' ? 'search friends' : 'no friend requests'}
            />
            <H3>
              {tab === 'received'
                ? t('noRequestsSent') : tab === 'sent'
                  ? t('noRequestsReceived') : t('wantToAddFirstFriend')}
            </H3>

            {tab === 'accepted' && (
              <Contain inputWidth align="center" justify="center">
                <Link to="/find-player">
                  <Button width="auto" padding="15px 40px" fontSize="16px">
                    <Trans ns="header" i18nKey="findAPlayer">Find a Player</Trans>
                  </Button>
                </Link>
              </Contain>
            )}
          </Contain>
        )}
      </FriendsStyle>
    </Container>
  );
};

export default index;
