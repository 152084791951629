import styled from '@emotion/styled';
import ComponentsContainer from '../../../components/Container';
import {
  H1, H3, Link, P,
} from '../../../components/Collection';
import PaginationComponent from '../../../components/Pagination';

export const Container = styled(ComponentsContainer)({
  margin: '30px auto 40px auto',

  '#map': {
    margin: '20px auto 60px auto',
    height: '500px !important',
    width: '100%',
    border: '0px',
    borderRadius: '10px',
  },
});

export const ContainSides = styled.div({
  display: 'flex',
  width: '100%',

  '@media (max-width: 1100px)': {
    flexDirection: "column",
  },

  '@media (max-width: 768px)': {
    flexDirection: 'column-reverse',
  },
});

export const LeftSide = styled.div({
  width: '100%',
  '@media (max-width: 768px)': {
    order: 2,
  },
});

export const RightSide = styled.div({
  margin: '0 0 0 37px',

  '@media (max-width: 1100px)': {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 0,
    gap: 20,
  },

  '@media (max-width: 991px)': {
    display: 'flex',
    flexDirection: 'column',
    order: 1,
    alignItems: 'center',
    margin: 'unset',
  },
});

export const ClubHeader = styled.div({
  position: 'relative',
});

export const ClubCover = styled.img({
  width: '100%',
  maxWidth: '1234px',
  maxHeight: '500px',
  borderRadius: '10px',
  objectFit: 'cover',
  objectPosition: 'center',
});

export const ClubAvatar = styled.img({
  display: 'block',
  width: '120px',
  height: '120px',
  borderRadius: '50%',
  position: 'absolute',
  bottom: '-60px',
  left: '50%',
  transform: 'translateX(-50%)',
  objectFit: 'cover',
  objectPosition: 'center',
});

export const ClubName = styled(H1)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: '80px 0 0 0',

  '> img': {
    margin: '0 0 0 10px',
  },

  '@media (max-width: 767px)': {
    textAlign: "center",
  },
});

export const ClubLocation = styled(P)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  '> img': {
    margin: '0 10px 0 0',
  },
});

export const SocialMediaButtonsContainer = styled.div({
  display: 'flex',
  justifyContent: 'center',
  margin: '20px 0 60px 0',

  '@media (max-width: 767px)': {
    margin: "20px 0",
  },
});

export const SocialMediaButton = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',

  width: 'max-content',
  height: '50px',

  border: '2px solid #10242C',
  borderRadius: '4px',
  padding: '0 15px',

  '&:hover': {
    textDecoration: 'none',
  },

  margin: '0 10px 0 0',
  '&:last-of-type': {
    margin: 'unset',
  },
});

export const SectionTitle = styled(H3)({
  margin: '0 0 20px 0',
});

export const SectionContainer = styled.div(({ noMargin }) => ({
  margin: noMargin ? 'unset' : '0 auto 75px auto',

  '@media (max-width: 767px)': {
    margin: "0 auto 20px",
  },
}));

export const MarginLeftAuto = styled.div({
  margin: '0 0 0 auto',
});

export const Pagination = styled(PaginationComponent)({
  margin: '20px auto 0 auto',
});

export const CourtType = styled(P)({
  display: 'inline-block',
  '&:after': {
    content: "','",
    margin: '0 5px 0 0',
  },

  '&:last-of-type': {
    '&:after': {
      content: "''",
      margin: 'unset',
    },
  },
});

export const CourtsTypeContainer = styled.div({
  display: 'inline',
});

export const CourtsNumber = styled.div({
  display: 'inline-block',
  verticalAlign: 'middle',
  width: 'fit-content',
  margin: '0 3px 3px 0',
  '> img': {
    margin: '0 5px 0 0',
  },
});
