export default {
  goodMorning: 'Добро утро',
  goodAfternoon: 'Добър ден',
  goodEvening: 'Добър вечер',
  neutralGreeting: 'Здравей',
  sendItAgain: 'Изпрати го отново',
  todayWeather: 'Днес е{{ weatherType }}, {{ degrees }}°C в {{ location }}',
  unavailableWeather: 'Прогнозата за времето не е налична.',
  verifyYourEmailAddress: '<0>Моля, проверете електронната си поща.</0> Имейл адресът трябва да бъде потвърден, преди да можете да използвате нашия сайт.',
  profileCompleted: '<0>Вашият профил е завършен.</0> Насладете се на цялото преживяване<2>{{ siteName }}</2>!',
  passwordResetSuccessfully: 'Моля, проверете входящата си кутия за инструкции за нулиране на паролата.',
  confirmEmailTitle: 'Моля, проверете електронната си поща. <1></1> Трябва да потвърдим вашия имейл адрес, преди да го използваме <3>{{ process.env.REACT_APP_SITE_NAME }}</3>.',
  confirmEmailSubtitle: 'Не получихте имейла? Изпратете отново имейла за потвърждение, за да завършите потвърждението на вашия имейл адрес.',
  ACTIVATION_EMAIL_SENT_SUCCESSFULLY: 'Имейлът за активиране е изпратен успешно.',
  ACCOUNT_DOES_NOT_EXIST: 'Акаунта не съществува.',
  ACTIVATION_EMAIL_WAS_NOT_SENT: 'Имейлът за активиране не беше изпратен.',
  completeProfile: {
    title: 'Завършете настройките на вашия профил и се насладете на цялото изживяване {{ siteName }}!',
    profileDetails: 'Данни за личния профил',
    setProfileDetails: 'Задайте подробности',
    profileVisibility: 'Видимост на профила',
    setProfileVisibility: 'Задай видимост',
    gameLevel: 'Ниво на игра',
    setGameLevel: 'Изберете вашето ниво на игра',
    profilePicture: 'Вашата снимка на профила',
    setProfilePicture: 'Качете вашата снимка',
    availability: 'Възможност за игра',
    setAvailability: 'Задайте кога можете да играете',
    skip: 'Пропусни',
  },
  welcome: {
    hello: 'Здравей {{username}}',
    welcomeToPlatform: 'Добре дошли в{{site}}!',
    welcomeText: 'Преди да започнете, помогнете ни да завършим вашия профил за най-добро изживяване. Отнема само минута!',
    startBtn: 'Нека да започнем',
    logout: 'Излезте',
  },
  choseLanguage: 'Изберете предпочитания от вас език, за да продължите',
  upcomingCompetitions: 'Бъдещи състезания',
  seeMyEvents: 'Моите събития',
  findAnEvent: 'Намерете събитие',
  searchForEventsOrCompetitions: 'Потърсете състезания във вашия регион',
  gettingStarted: 'Първи стъпки',
  gettingStartedDescription: 'Започнете и направете първите стъпки с <1>{{ process.env.REACT_APP_SITE_NAME }}</1>',
  letsStart: 'Нека да започнем',
  websiteGuides: 'Насоки <0>{{siteTitle}}</0>',
  learnMore: 'Разбери повече',
  pendingUpdate: 'Висяща актуализация',
  leaderboard: 'Класиране',
  updated: 'Актуализирано: <1>date</1>',
  stats: 'Статистики',
  singlesPlayed: 'Мачове на Сингъл',
  doublesPlayed: 'Мачове на Двойки',
  allTime: 'Тотално',
  registrationDate: 'Дата на Регистрация',
  friendlyMatches: 'Приятелски мачове',
  pvpEloCalculator: 'Калкулатор ELO Head2Head',
  pvpEloCalculatorDescription: 'Изберете 2 играча и вижте шансовете на всеки да спечели мача, в пряка среща.',
  calculate: 'Калкулирай',
  player: 'Играч',
  searchPlayer: 'Намерете играч',
  pageTitle: 'Dashboard',

  accountLeaderboard: {
    title: 'Класиране',
    updated: 'Актуализиране',
    singles: 'Сингъл',
    doubles: 'Двойки',
    position: 'Място',
  },

  guides: {
    1: {
      title: 'Sportya - Правила и Разпоредби',
      text: 'Официални правила, обхващащи всички състезателни и технически въпроси, уреждащи мачове, събития, класиране и други ',
    },
    2: {
      title: 'Формат Групи по 3 - Техническо ръководство ',
      text: 'Кратко ръководство, обхващащо игровата система, класирането, отегления и напредването в фазата на елиминациите за групи от трима, един от най-често срещаните игрови формати в събитията на Sportya',
    },
    3: {
      title: 'Локални Лиги - Техническо ръководство и често задавани въпроси ',
      text: 'Основна информация за Местните лиги, комуникация с играчи, класиране и често задавани въпроси, които могат да ви помогнат да се присъедините към система на игра възможно най-бързо ',
    },
  },

  minAgeRequired:
    'От 2022 г. вдигаме минималната възраст, необходима за участие в събитията на Sportya, на 15 години. Тази промяна е отразена и в нашия проект Tenis10 <1>(www.tenis10.ro)</1>, който вече ще позволи на играчи да се регистрират до 14-годишна възраст в събития, организирани за деца и юноши. Вашият акаунт в Sportya ще остане активен, но няма да можете да се регистрирате за събития, докато не достигнете минималната възраст.',
  rankedMatches: '<strong>{{ranked}}</strong> Конкурентен',
  unrankedMatches: '<strong>{{unranked}}</strong> Обучение',
};
