import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Container, Checkmark, Steps, Step, Progress, Stage,
} from './styles';

import {
  P, Button, Link, Contain,
} from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';

const CompleteProfile = () => {
  const { profileCompletion, currentStep } = useSelector(state => state.session);
  const capitalLetter = string => string.charAt(0).toUpperCase() + string.slice(1);
  const { i18n } = useTranslation();
  const isRo = i18n.language === 'ro';

  return (
    <Container isRo={isRo}>
      <P large bold>
        <Trans ns="dashboard" i18nKey="completeProfile.title">
          {{ siteName: process.env.REACT_APP_SITE_NAME }}
        </Trans>
      </P>
      <Progress>
        <Contain
          width="100%"
          direction="column"
          margin="15px 0 0 0"
        >
          <Steps>
            {profileCompletion?.map((step, key) => (
              <Step key={key} completed={step.completed}>
                <Stage isRo={isRo}>
                  {step.completed && (<Checkmark src={LoadImage('checkmark-outline.svg')} />)}
                  <P small bold>
                    <Trans ns="dashboard" i18nKey={`completeProfile.${step.step}`} />
                  </P>
                </Stage>
              </Step>
            ))}
          </Steps>
        </Contain>
        <Button
          NoBorder
          minWidth={isRo ? '240px' : '296px'}
        >
          <Link to={currentStep.path}>
            <Trans ns="dashboard" i18nKey={`completeProfile.set${capitalLetter(currentStep.step)}`} />
          </Link>
        </Button>
      </Progress>
    </Container>
  );
};

export default CompleteProfile;
