import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import dateFormat from 'dateformat';
import { isEmpty } from 'lodash';

import { getLeaderboard } from '../../../../components/Layout/reducers/session';
import calculationDate from '../../../../components/common/getCalculationDate';
import { fetchTabData, getEventsWithPenalties } from '../../reducers';
import { Contain, H3, P } from '../../../../components/Collection';
import GameType from '../components/GameType';
import Statistics from './components/Stats';
import {
  CalculationDate, CalculationDateContainer, Container, H3Title,
} from './styles';
import { UserContext } from '../..';
import {
  Column, Event, EventBody, EventFooter, EventHeader, EventName, EventsContainer, EventTotalWonPoints,
} from '../Activity/styles';
import EventMeta from '../Activity/components/eventMeta';
import Match from '../Activity/components/matches';
import rankBeforeEvent from '../../../../components/common/rankBeforeEvent';
import PenaltyEvent from './components/Penalties';
import Loader from '../../../../components/ContentLoader';

const PointsTab = () => {
  const user = useContext(UserContext);
  const dispatch = useDispatch();
  const { t: commonT } = useTranslation('common');
  const [gameType, setGameType] = useState('singles');
  const { userId, penalties, profile } = user || {};

  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const { leaderboard } = useSelector(state => state.session);
  const { data: points, penalties: eventPenalties, status } = useSelector(
    state => state.accounts.info.tabs[profile].points[gameType],
  );

  const didNotFetch = (isEmpty(points) && status === 'idle');
  const hasPointsResults = !!points?.length;
  const hasPenalties = !!eventPenalties?.length;
  const isLoading = generalStatus === 'loading';

  const userData = {
    gameLevel: user?.leaderboard?.[gameType]?.gameLevel,
  };

  useEffect(() => {
    if (didNotFetch) {
      dispatch(fetchTabData({
        userId,
        tab: 'points',
        type: gameType,
        filters: userData,
        options: {},
        profile,
      }));
    }
  }, [status, dispatch]);

  useEffect(() => {
    if (isEmpty(leaderboard)) {
      dispatch(getLeaderboard());
    }
  }, []);

  const startDate = dateFormat(calculationDate(leaderboard).start, 'd mmm yyyy');
  const endDate = dateFormat(calculationDate(leaderboard).end, 'd mmm yyyy');

  useEffect(() => {
    if (penalties?.length) {
      const isAlreadyFetched = [];
      penalties?.forEach(penalty => {
        if (!isAlreadyFetched.includes(penalty?.event?.eventId)) {
          dispatch(getEventsWithPenalties({ id: penalty?.event?.eventId, type: gameType, profile }));
          isAlreadyFetched.push(penalty?.event?.eventId);
        }
      });
    }
  }, [user]);

  return isLoading
    ? (
      <Container hasLoader>
        <Loader />
      </Container>
    ) : (
      <Container>
        <Contain width="100%" margin="20px 0">
          <GameType gameType={gameType} handler={setGameType} />
        </Contain>
        <CalculationDateContainer>
          <H3>Points Summary</H3>
          {calculationDate().last && (
            <CalculationDate xSmall>
              <Trans ns="leaderboards" i18nKey="timeframe">
                Leaderboard calculation timeframe
              </Trans>
              {`: ${startDate} - ${endDate}`}
            </CalculationDate>
          )}
        </CalculationDateContainer>

        <Statistics
          user={user}
          points={points}
          gameType={gameType}
        />

        {hasPointsResults && (
          <>
            <H3Title>
              <Trans ns="dashboard" i18nKey="bestCompetitionResults">Best Competition Results</Trans>
            </H3Title>

            <EventsContainer>
              {(points || [])?.map((event, key) => {
                const {
                  eventName, date, level, gameType: eventGameType, category, genderRestrictions,
                } = event || { eventName: commonT('notAvailable') };
                const withGroups = event?.matches?.filter(a => a.group);
                const withStages = event?.matches?.filter(a => a.stage >= 0)
                  .sort((a, b) => (a.stage > b.stage ? -1 : 1));
                const matches = [...withStages || [], ...withGroups || []];
                return (
                  <Event key={key}>
                    <EventHeader>
                      <EventName to={`/events/${event.eventId}/competitions/${event.competitionId}`}>
                        {eventName}
                      </EventName>
                      <EventMeta
                        {...{
                          category,
                          date,
                          level,
                          gameType: eventGameType,
                          clubId: event?.clubInfo?.id,
                          location: `${event?.clubInfo?.clubName}, ${event?.clubInfo?.location?.city}`,
                          restriction: genderRestrictions,
                        }}
                      />
                    </EventHeader>
                    <EventBody>
                      <Contain>
                        <Column maxWidth={140}>
                          <P xSmall>
                            <Trans ns="player" i18nKey="round">
                              Round
                            </Trans>
                          </P>
                        </Column>
                        <Column maxWidth={300}>
                          <P xSmall>
                            <Trans ns="player" i18nKey="opponent">
                              Opponent
                            </Trans>
                          </P>
                        </Column>
                        <Column maxWidth={250} flex justify="center">
                          <P xSmall>
                            <Trans ns="player" i18nKey="leaderboard">
                              Rankings
                            </Trans>
                          </P>
                        </Column>
                        <Column maxWidth={300} flex justify="center">
                          <P xSmall>
                            <Trans ns="player" i18nKey="score">
                              Score
                            </Trans>
                          </P>
                        </Column>
                        <Column maxWidth={20} flex justify="center" xMargin="0 0 0 auto">
                          <P xSmall>
                            <Trans ns="player" i18nKey="wL">
                              W/L
                            </Trans>
                          </P>
                        </Column>
                      </Contain>
                      {matches?.map((match, matchKey) => (
                        <Match key={matchKey} match={match} gameType={gameType} />
                      ))}
                    </EventBody>
                    <EventFooter>
                      {rankBeforeEvent(event, userId, user, commonT)}
                      <EventTotalWonPoints xSmall bold>
                        +
                        {' '}
                        {event.totalWonPoints}
                        &nbsp;pts.
                      </EventTotalWonPoints>
                    </EventFooter>
                  </Event>
                );
              })}
            </EventsContainer>
          </>
        )}

        {hasPenalties && (
          (eventPenalties || [])?.map((event, key) => <PenaltyEvent key={key} event={event} />)
        )}
      </Container>
    );
};

export default PointsTab;
