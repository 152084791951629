import styled from '@emotion/styled';
import ComponentContainer from '../../components/Container';
import LoadImage from '../../components/common/LoadImage';
import { H1, P } from '../../components/Collection';

export const Container = styled(ComponentContainer)({
  position: 'relative',
  minHeight: '350px',
});

export const GreetingContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  margin: '30px 0 0 0',
  '@media (max-width: 850px)': {
    flexDirection: 'column',
    marginBottom: 20,
    textAlign: 'center',
  },
});

export const Greeting = styled(H1)({});

export const Weather = styled(P)({
  margin: '0 0 0 auto',
  '@media (max-width: 850px)': {
    margin: 'unset',
  },
});

export const DashboardContent = styled.div({
  display: 'flex',
  gap: 30,

  '@media (max-width: 1200px)': {
    flexDirection: 'column',
    gap: 0,
  },
});

export const MainContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 2,
  maxWidth: 'calc(100% - 315px)',

  '@media (max-width: 1200px)': {
    flexDirection: 'column',
    margin: 'unset',
    maxWidth: '100%',
  },
});

export const SidebarContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  margin: '20px 0 0 0',
  minWidth: '285px',

  '@media only screen and (min-width: 1024px) and (max-width: 1200px)': {
    flexDirection: 'row',
    gap: 30,
  },
});

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  
  > div {
    width: calc(100% - 285px);
    margin-right: 15px;
    > a
    {
      text-decoration: none;
    }
  }

  
  aside {
    width: 285px;
  }

  ${props => props.theme.functions?.mediaQueryMax('1001px', `
    display: grid;
    justify-content: center;

    > div
    {
      display: grid;
      width: 100%;
      margin: 0 auto;
    }

    > aside
    {
      display: flex;
      justify-content: center;
      width: 100%;

      > div
      {
        margin: 15px;
        width: 30%;
        min-width: 300px;
      }
    }
  `)}

  ${props => props.theme.functions?.mediaQueryMax('768px', `
  > aside
  {
    display: grid;
    justify-content: center;
    width: 100%;
    
    > div
    {
      margin: 15px auto;
      width: 100%;
    }
  }
`)}

`;

export const FindEvents = styled.div`
  border-radius: 10px;
  height: 100px;
  ${props => props.theme.functions?.mediaQueryMax('650px', `
    height: unset;
  `)}
  background: ${({ theme }) => theme.colors?.primary} url('${LoadImage('find-an-event-bg.png')}') no-repeat 90% center;
  background-size: contain;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ margin }) => (margin ? `margin: ${margin};` : '')}
    
  > div {
    padding: 25px 30px;
  }

  h4, p {
    color: ${({ theme }) => theme.colors?.white};
    line-height: 1.2;
  }
  
  .go-to-events a {
    padding: 10px;
  }
`;

export const OnMobile = styled.div`
display: none;

${props => props.theme.functions?.mediaQueryMax('1001px', `
  display: block;
`)}

${props => props.width && `width: ${props.width};`}`;

export const OnDesktop = styled.div`
display: block;

${props => props.theme.functions?.mediaQueryMax('1001px', `
  display: none;
`)}`;
