import React, { useContext, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import mixpanel from '../../mixpanel';

import { FinalConfirmation } from './styles';

import LoadImage from '../../components/common/LoadImage';
import Paragraph from '../../components/Paragraph';
import H1 from '../../components/H1';
import H4 from '../../components/H4';
import ContentLoader from '../../components/ContentLoader';
import getCompetitionListTypeEntry from '../../components/common/getCompetitionListTypeEntry';
import { store as socketStore } from '../../components/Socket/store';

import { getAccountInfo } from '../../components/Layout/reducers/session';
import { fetchEventDetails } from '../Events/reducers';
import { fetchTransactions } from '../MyAccount/Wallet/reducers';
import { fetchOrders } from '../MyAccount/Orders/reducers';
import { fetchCompetitions } from '../Dashboard/components/UpcomingCompetitions/reducers';
import { fetchMyEvents } from '../MyAccount/Events/reducers';
import EventRegistrationInfo from "./components/EventRegistrationInfo";
import PaymentError from "./components/PaymentError";
import FooterButtons from "./components/FooterButtons";
import CourtBookingInfo from "./components/CourtBookingInfo";

const PaymentConfirmation = () => {
  const dispatch = useDispatch();
  const globalState = useContext(socketStore);
  const { state: { socket: { socketClientId, socket } } } = globalState;

  const { accountInfo } = useSelector(state => state.session);
  const { data: { memberships } } = useSelector(state => state.settings);
  const { data: eventInfo } = useSelector(state => state.events?.info);

  const { sessionId, paymentType, ...restParams } = useParams();

  const [paymentInfo, setPaymentInfo] = useState(null);
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const [session, setSession] = useState({});
  const [purchaseType, setPurchaseType] = useState(restParams.purchaseType);

  const [eventId, setEventId] = useState();
  const [competitionId, setCompetitionId] = useState();

  const { search } = window.location;
  const params = new URLSearchParams(search);
  const err = params.get('err');

  // set eventId & competitionId for event registration
  useEffect(() => {
    let tempEventId;
    let tempCompetitionId;
    if (paymentType === 'virtual-wallet') {
      [tempEventId, tempCompetitionId] = (sessionId || []).split('-');
      if (tempEventId && tempCompetitionId) {
        setEventId(tempEventId);
        setCompetitionId(tempCompetitionId);

        dispatch(fetchEventDetails(tempEventId));
      }
    }

    if (paymentType === 'credit-card' && session.success) {
      tempEventId = session.data.metadata.eventId;
      tempCompetitionId = session.data.metadata.competitionId;
      if (tempEventId && tempCompetitionId) {
        setEventId(tempEventId);
        setCompetitionId(tempCompetitionId);

        dispatch(fetchEventDetails(tempEventId));
      }
    }
  }, [paymentType, session]);

  // Event registration confirmation page
  useEffect(() => {
    const init = async () => {
      const [competitionInfo] = (eventInfo?.competitions || [])
        .filter((competition) => competition._id === competitionId);

      const listType = paymentInfo?.listType ?? getCompetitionListTypeEntry(accountInfo?.userId, competitionInfo);
      const registrationFee = (competitionInfo?.[listType] || [])
        .filter(({ userId }) => userId === accountInfo?.userId)
        .shift()?.amount;

      const amount = paymentInfo?.amount ?? registrationFee ?? null;

      setPaymentInfo({
        title: 'thankYouForRegistration',
        subTitle: listType ? `addedTo.${listType}` : 'paymentProcessing',
        event: {
          _id: eventInfo?._id,
          name: eventInfo?.name,
          level: eventInfo?.level,
          category: eventInfo?.category,
          startDate: eventInfo?.startDate,
          endDate: eventInfo?.endDate,
        },
        competition: competitionInfo,
        amount,
        currency: accountInfo?.currency,
      });
      setPurchaseType('eventRegistration');
    };

    if (!isEmpty(eventInfo) && eventId && competitionId) {
      init().then(() => setIsLoading(false));
    }
  }, [eventInfo, competitionId]);

  // Event registration confirmation page with virtual wallet payment
  useEffect(() => {
    const listenForResponse = async ({ success, message, data }) => {
      if (success && message === 'SUCCESSFULLY_CREATED_ENTRY') {
        const { listType, registrationFee } = data;

        // update account wallet & transactions
        dispatch(getAccountInfo());
        dispatch(fetchTransactions());
        dispatch(fetchOrders());

        // update account events(upcoming, registered events)
        dispatch(fetchCompetitions());
        dispatch(fetchMyEvents({
          id: accountInfo?.userId,
          activeTab: 'joined',
          options: { page: 1, limit: 10 },
        }));

        setPaymentInfo({
          ...paymentInfo,
          listType,
          amount: registrationFee,
          subTitle: listType ? `addedTo.${listType}` : 'paymentProcessing',
        });
      }

      if (!success) {
        setError(message);
      }
    };

    if (socket) {
      socket.removeAllListeners(
        'events-response',
      );
      socket.on(
        'events-response',
        listenForResponse,
      );

      return () => {
        socket.removeAllListeners(
          'events-response',
        );
      };
    }
  }, [socketClientId, paymentInfo]);

  // Membership confirmation page with virtual wallet payment
  useEffect(() => {
    const fetchData = async (membership) => {
      const [membershipType, period] = membership?.name?.split('-');
      // eslint-disable-next-line no-promise-executor-return
      await new Promise(r => setTimeout(r, 500));
      dispatch(getAccountInfo());
      dispatch(fetchTransactions());
      dispatch(fetchOrders());

      setPaymentInfo({
        title: 'congratulations',
        subTitle: `${membershipType}MembershipSuccessfully`,
        period,
        amount: membership?.discountedPrice?.[accountInfo.currency] ?? membership?.price?.[accountInfo.currency],
        currency: accountInfo.currency,
      });
      setPurchaseType('upgradeMembership');
    };

    if (memberships) {
      const [membership] = (memberships || []).filter(option => option.id === sessionId);
      if (membership) {
        fetchData(membership).then(() => setIsLoading(false));
      }
    }
  }, [sessionId, memberships]);

  // Fetch session data if payment was made with credit card
  useEffect(() => {
    async function fetchSession() {
      // eslint-disable-next-line max-len
      const url = `${process.env.REACT_APP_API_HOST}checkout-session/${sessionId}?currency=${accountInfo.currency}&purchaseType=${purchaseType}`;
      setSession(
        await fetch(url)
          .then((res) => res.json()),
      );
    }
    if (!isEmpty(accountInfo) && sessionId && paymentType === 'credit-card') {
      fetchSession();
    }
  }, [sessionId, paymentType, accountInfo]);

  // credit card payments
  useEffect(() => {
    if (session.success) {
      const amount = session.data.amount_total / 100;
      const currency = session.data.currency.toUpperCase();
      const { productId } = session.data.metadata;
      const sessionPurchaseType = session.data.metadata.purchaseType;
      setPurchaseType(sessionPurchaseType);

      switch (sessionPurchaseType) {
      case 'topUp': {
        setPaymentInfo({
          title: 'congratulations',
          subTitle: 'topUpSuccessfully',
          amount,
          currency,
        });
        setIsLoading(false);
        break;
      }
      case 'upgradeMembership': {
        const [membership] = (memberships || []).filter(option => option.id === productId);
        const [membershipType, period] = membership?.name?.split('-');
        setPaymentInfo({
          title: 'congratulations',
          subTitle: `${membershipType}MembershipSuccessfully`,
          period,
          amount,
          currency,
        });
        setIsLoading(false);
        break;
      }
      case 'eventRegistration': {
        const { listType } = session.data.metadata;
        setPaymentInfo({
          ...paymentInfo,
          subTitle: listType ? `addedTo.${listType}` : 'paymentProcessing',
          amount,
          currency,
          listType,
        });
        break;
      }
      case 'courtBooking': {
        setPaymentInfo({
          ...paymentInfo,
          title: 'yourCourtIsBooked',
          amount,
          currency,
          ...session.data.metadata,
        });
        setIsLoading(false);
        break;
      }
      default:
      }
    }
  }, [session]);

  useEffect(() => {
    if (err && !error) {
      setError(err);
    }
  }, [sessionId]);

  useEffect(() => {
    const mixpanelProfile = {
      sp_dominant_hand: accountInfo?.dominantHand,
      sp_game_level_single: accountInfo?.gameLevelSingle,
      sp_game_level_double: accountInfo?.gameLevelDouble,
      sp_gender: accountInfo?.gender,
      sp_language: accountInfo?.language,
      sp_membership: accountInfo?.membership?.plan,
      sp_profile_city: accountInfo?.location?.city,
      sp_profile_country: accountInfo?.location?.country,
      sp_registration_method: accountInfo?.registrationMethod,
      sp_account_id: accountInfo?.userId,
    };
    if (paymentInfo && paymentInfo?.competition) {
      mixpanel.track('Registration Complete', {
        sp_event_registration_complete_payment_method: paymentType,
        sp_event_registration_complete_competition_type: paymentInfo.competition.gameType,
      });
      mixpanel.people.set(mixpanelProfile);
      mixpanel.identify(accountInfo?.mixpanelId ?? accountInfo?.userId);
    } else if (paymentInfo && paymentInfo?.period) {
      mixpanel.track('Membership Purchase Complete', {
        sp_membership_purchase_complete_method: paymentType,
      });
      mixpanel.people.set({
        ...mixpanelProfile,
        sp_membership: `premium ${ paymentInfo?.period}`,
      });
      mixpanel.identify(accountInfo?.mixpanelId ?? accountInfo?.userId);
    }
  }, [paymentInfo]);

  if (isLoading) {
    return (<ContentLoader />);
  }

  if (!isEmpty(error)) {
    return (
      <FinalConfirmation>
        <div className="wrapp_confirmation">
          <div className="mainConfirmation">
            <PaymentError error={error} />
          </div>
        </div>
      </FinalConfirmation>
    );
  }

  return (
    <FinalConfirmation>
      <div className="wrapp_confirmation">
        <div className="mainConfirmation">
          <div className="logoplaceholder">
            <img src={LoadImage('checkmark.svg')} alt="checkMark" />
          </div>

          {/* Display heading title & subtitle */}
          <H1><Trans ns="paymentConfirmation" i18nKey={paymentInfo?.title}>Congratulations!</Trans></H1>
          {paymentInfo?.subTitle && (
            <H4>
              <Trans ns="paymentConfirmation" i18nKey={paymentInfo?.subTitle}>
                {{ period: paymentInfo?.period }}
                {{ amount: paymentInfo?.amount }}
                {{ currency: paymentInfo?.currency }}
              </Trans>
            </H4>
          )}

          {/* Show event registration payment info */}
          {paymentInfo?.event && paymentInfo?.competition && (<EventRegistrationInfo paymentInfo={paymentInfo} />)}

          {/* Show court booking payment info */}
          {purchaseType === 'courtBooking' && (<CourtBookingInfo paymentInfo={paymentInfo} />)}

          {/* Display virtual wallet paid amount & remaining wallet balance */}
          {paymentType === 'virtual-wallet' && !!paymentInfo?.amount && (
            <Paragraph small className="virtualWallet">
              <Trans ns="paymentConfirmation" i18nKey="walletStatus">
                You paid
                <strong>
                  {{ paid: Number(paymentInfo?.amount).toFixed(2) }}
                  {{ currency: paymentInfo?.currency }}
                </strong> from your Virtual Wallet.
                Remaining Balance:
                <strong>
                  {{ walletBalance: Number(accountInfo?.walletBalance).toFixed(2) ?? 0 }}
                  {{ currency: accountInfo?.currency }}
                </strong>.
              </Trans>
            </Paragraph>
          )}

          {/* Display footer buttons */}
          <FooterButtons paymentInfo={paymentInfo} purchaseType={purchaseType} />
        </div>
      </div>
    </FinalConfirmation>
  );
};

export default PaymentConfirmation;
