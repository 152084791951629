import React, { useState, useRef } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';

import { debounce, isEmpty } from 'lodash';

import Option from './Option';
import ToggleVisible from '../../../../../components/common/ToggleVisible';
import { get } from '../../../../../components/common/http';
import LoadImage from '../../../../../components/common/LoadImage';
import { Contain, P } from '../../../../../components/Collection';
import PartnerForDoubles from '../../../components/PartnerForDoubles';
import { Badge as ShieldBadge } from '../../../../../components/LevelsBadges/styles';

import {
  Avatar,
  AvatarContainer,
  Container,
  Initials,
  Player,
  ContainerPlayers,
  PlayerContainer,
  SearchBar,
  SearchIcon,
  SearchResults,
  ClearResults,
} from './styles';

import TeamAVG from '../Duo/components/teamAVG';
import getAge from '../../../../../components/common/getAgeFromDate';
import { FriendsIcon } from '../../../../Friends/components/FriendCard/styles';

const Index = ({ userHandler, partner }) => {
  const { t } = useTranslation('events');
  const inputRef = useRef();
  const [suggestions, setSuggestions] = useState(null);
  const hasOptions = !isEmpty(suggestions);
  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const { accountInfo } = useSelector(state => state.session);
  const [debounceInterval, setDebounceInterval] = useState(150);

  const fetch = debounce(async string => {
    const startTime = (new Date()).getTime();
    const { data: { data } } = await get(`/find-players/search?string=${string}&ignoreBlocked=true`);
    setSuggestions(data?.results.slice()?.filter(user => (
      user?.userId !== accountInfo?.userId
      && !isEmpty(user?.location)
      && user?.gameLevelDouble)));
    if (((new Date()).getTime() - startTime) > 100) {
      setDebounceInterval(300);
    } else {
      setDebounceInterval(150);
    }
  }, debounceInterval);

  const handleSearching = async string => {
    if (string.length <= 60) {
      if (string.length >= 3) {
        await fetch(string);
      } else if (suggestions?.length) {
        setSuggestions(null);
      }
      setIsComponentVisible(true);
    }
  };

  const clearResults = () => {
    setSuggestions(null);
    inputRef.current.value = '';
    return setIsComponentVisible(false);
  };

  const showResults = () => {
    if (hasOptions) {
      return setIsComponentVisible(true);
    }
  };

  return partner
    ? (
      <ContainerPlayers>
        <P large bold textAlign="center" margin="0 0 20px 0">
          <Trans ns="events" i18nKey="join.playerAdded">The player has been added to your team!</Trans>
        </P>
        <PlayerContainer>
          <Player>
            <AvatarContainer>
              {partner?.profilePicture
                ? (
                  <Avatar
                    src={LoadImage(`accounts/${partner?.userId}/${partner?.profilePicture}`, true)}
                    alt={`${partner?.firstName} ${partner?.lastName}`}
                  />
                )
                : (
                  <Initials>
                    <P bold large>{`${partner?.firstName.charAt(0)} ${partner?.lastName.charAt(0)}`}</P>
                  </Initials>
                )}
              <ShieldBadge small double className="badge">
                <P xSmall bold>{partner?.gameLevelDouble}</P>
              </ShieldBadge>
            </AvatarContainer>
            <Contain>
              {accountInfo?.friendsIds?.includes(partner.userId) && (
                <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
              )}
              <P bold>
                {`${partner?.firstName} ${partner?.lastName}`}
                {' '}
                <span className="id">
                  #
                  {partner?.userId}
                </span>
              </P>
            </Contain>
            <P>
              {getAge(partner.birthDate)}
              {partner?.location?.city && ` - ${partner?.location?.city}`}
            </P>
            <P small className="change" bold onClick={() => userHandler(null)}>
              <Trans ns="events" i18nKey="join.changePlayer">
                Change player
              </Trans>
            </P>
          </Player>
          <TeamAVG p1={accountInfo?.gameLevelDouble} p2={partner.gameLevelDouble} />
          <Player>
            <AvatarContainer>
              {accountInfo?.profilePicture
                ? (
                  <Avatar
                    src={LoadImage(`accounts/${accountInfo?.userId}/${accountInfo?.profilePicture}`, true)}
                    alt={`${accountInfo?.firstName} ${accountInfo?.lastName}`}
                  />
                )
                : (
                  <Initials>
                    <P bold large>{`${accountInfo?.firstName.charAt(0)} ${accountInfo?.lastName.charAt(0)}`}</P>
                  </Initials>
                )}
              <ShieldBadge small double className="badge">
                <P xSmall bold>{accountInfo?.gameLevelDouble}</P>
              </ShieldBadge>
            </AvatarContainer>
            <Contain>
              <P bold>
                {`${accountInfo?.firstName} ${accountInfo?.lastName}`}
                {' '}
                <span className="id">
                  #
                  {accountInfo?.userId}
                </span>
              </P>
            </Contain>
            <P>
              {getAge(accountInfo.birthDate)}
              {accountInfo?.location?.city && ` - ${accountInfo?.location?.city}`}
            </P>
            <P small className="you" bold>
              <Trans ns="common" i18nKey="you">
                You
              </Trans>
            </P>
          </Player>
        </PlayerContainer>
      </ContainerPlayers>
    ) : (
      <>
        <Container>
          <Contain direction="column" justify="center" height="100%" position="relative">
            <P bold large color="white">
              <Trans ns="events" i18nKey="join.searchPartner">
                First search for your partner to join this event
              </Trans>
            </P>

            <SearchBar
              maxLength={60}
              onClick={() => showResults()}
              onInput={e => handleSearching(e.target.value)}
              placeholder={t('join.duoJoin')}
              ref={inputRef}
            />

            <SearchIcon src={LoadImage('search-icon_black.svg')} />
            {inputRef.current?.value.length > 0 && (
              <ClearResults onClick={() => clearResults()}>
                <svg height="20" width="20" viewBox="0 0 20 20" aria-hidden="true" focusable="false">
                  {/* eslint-disable-next-line max-len */}
                  <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z" />
                </svg>
              </ClearResults>
            )}

            {isComponentVisible && (
              <SearchResults ref={ref}>
                {suggestions?.map((player, key) => (
                  <Option player={player} key={key} handler={userHandler} isDouble />
                ))}
                {inputRef.current?.value.length < 3 && (
                  <P textAlign="center">
                    <Trans ns="common" i18nKey="typeAtLeast3Chars">Type at least 3 characters...</Trans>
                  </P>
                )}
                {!suggestions?.length && inputRef.current?.value.length >= 3 && (
                  <P textAlign="center">
                    <Trans ns="common" i18nKey="noResultsFound">No results found</Trans>
                  </P>
                )}
              </SearchResults>
            )}
          </Contain>
        </Container>
        <PartnerForDoubles noPadding />
      </>
    );
};

export default Index;
