export default {
  title: 'Начална страница',
  seoTitle: 'Играйте на местно ниво, достъп и на международно ниво. Присъединете се към глобална общност от тенис любители| Sportya',
  stats: {
    failedToLoad: '<0>Статистиката не е налична:</0> {{error}}',
    activePlayers: '<0>Играчи</0> <2>{{players}}</2>',
    activeEvents: '<0>Планирани събития {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Клубове</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Кортове</0> <2>{{courts}}</2>',
    activeCities: '<0>Градове</0> <2>{{cities}}</2>',
    activeCountries: `<0>Държави</0> <2>Румъния, Република
     Молдова, България,
     Бахрейн</2>`,
  },

  newEvents: {
    title: 'Препоръчани Събития',
  },

  clubArea: {
    title: 'Вие сте управител на клуб или администратор на корт?',

    first: {
      title: 'Управлявайте местни и глобални събития',
      desc: 'Персонализирани за нуждите на вашия клуб и наличните кортове за максимална ефективност.',
    },
    second: {
      title: 'Свържете се с нови играчи',
      desc: 'Изградете и разширете своята база играчи.',
    },
    third: {
      title: 'Станете център на тенис ентусиасти',
      desc: 'Развива организираната игра, базирана на умения. Повишава ангажираността и лоялността на играчите на клуба.',
    },
    fourth: {
      title: 'Резервирай тенис корт',
      desc: 'Управлявай и предложи кортове под наем на цялата Sportya общност. Стимулира участието на играчите и увеличава честотата на игра.',
    },
    button: 'Разбери повече',
  },

  centralPanel: {
    first: {
      title: 'Играй повече. Играйте както искате',
      desc: 'Оценете нивото си на игра и изберете как искате да играете, от класически турнири, организирани през уикенда, до събития разделени в групи, вечерни турнири, гъвкави местни лиги, приятелски мачове и много други.',
    },
    second: {
      title: 'Състезавайте се в любителски тенис турнири',
      desc: 'Достъп до календар със много събития в Sportya, играйте на местно ниво и напредвайте към международните тенис събития за любители, всички организирани по нива на игра.',
    },
    third: {
      title: 'Присъединете се към международна общност от тенис любители',
      desc: 'Създайте свой собствен профил, свържете се с други играчи и се възползвайте максимално от страстта си към тениса.',
    },
    buttons: {
      first: 'Разбери повече',
      second: 'Участвай',
    },
  },

  slider: {
    findNewGamePartners: 'Играйте в приятелски мачове или аматьорски турнири, организирани на местно и международно ниво',
    joinCommunity: 'Намери и резервирай тенис кортове в партньорски клубове',
    improveYourGame: 'Преоткрий равностойните мачове, глобалният любителски ранкинг \nи персоналния тенис профил.',
    joinNow: 'Присъедини се сега',
    searchNow: 'Търсете сега',
    seeEvents: 'Вижте Събития',
  },
};
