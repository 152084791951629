export default {
  goodMorning: 'Good morning',
  goodAfternoon: 'Good afternoon',
  goodEvening: 'Good evening',
  neutralGreeting: 'Hello',
  todayWeather: 'Today is {{ weatherType }}, {{ degrees }}°C in {{ location }}',
  unavailableWeather: 'Unavailable weather',
  verifyYourEmailAddress: '<0>Please check your mail.</0> We need you to verify your email address before using our platform.',
  profileCompleted: '<0>Your profile is complete.</0> Enjoy the full <2>{{ siteName }}</2> experience!',
  passwordResetSuccessfully: 'Please check your inbox for password reset instructions.',
  confirmEmailTitle: 'Please check your email. <1></1> We need to verify your email address before using <3>{{ process.env.REACT_APP_SITE_NAME }}</3>.',
  confirmEmailSubtitle: "Didn't receive the mail? Resend the verification email to finish confirming your email address.",
  sendItAgain: 'Send it again',
  ACTIVATION_EMAIL_SENT_SUCCESSFULLY: 'Activation email was sent successfully.',
  ACCOUNT_DOES_NOT_EXIST: 'Account does not exist.',
  ACTIVATION_EMAIL_WAS_NOT_SENT: 'Activation email was not sent.',
  minAgeRequired:
    `Starting with 2022, we are raising the minimum age required to enter Sportya events to 15 years. This change is also reflected in our Tenis10 project <1>(www.tenis10.ro)</1>, 
  which will now allow players to register up to the age of 14 years, in events organized for children and junior players. Your Sportya account will remain active,
  yet you will not be able to register for events until you reach the minimum age.`,
  completeProfile: {
    title: 'Complete your profile and enjoy the full {{ siteName }} experience!',
    profileDetails: 'Profile details',
    setProfileDetails: 'Set details',
    profileVisibility: 'Profile visibility',
    setProfileVisibility: 'Set visibility',
    gameLevel: 'Game level',
    setGameLevel: 'Choose your level',
    profilePicture: 'Profile picture',
    setProfilePicture: 'Upload your picture',
    availability: 'Availability',
    setAvailability: 'Set your availability',
    skip: 'Skip',
  },
  welcome: {
    hello: 'Hello {{username}}',
    welcomeToPlatform: 'Welcome to {{site}}!',
    welcomeText: 'Before starting, help us complete your profile for the best experience. It only takes a minute!',
    startBtn: 'Let\'s start',
    logout: 'Logout',
  },
  choseLanguage: 'Select your preferred language to continue',
  upcomingCompetitions: 'Upcoming Competitions',
  seeMyEvents: 'See my Events',
  findAnEvent: 'Find an Event',
  searchForEventsOrCompetitions: 'Search for competitions in your area',
  gettingStarted: 'Getting started',
  gettingStartedDescription: 'Get started and make the first steps with <1>{{ process.env.REACT_APP_SITE_NAME }}</1>',
  letsStart: "Let's Start",
  websiteGuides: '<0>{{siteTitle}}</0> Guides',
  learnMore: 'Learn more',
  pendingUpdate: 'Pending update',
  leaderboard: 'Ranking',
  updated: 'Updated: <1>date</1>',
  stats: 'Stats',
  singlesPlayed: 'Singles Played',
  doublesPlayed: 'Doubles Played',
  allTime: 'All Time',
  friendlyMatches: 'Friendly Matches',
  registrationDate: 'Registration date',
  pvpEloCalculator: 'Head2Head ELO Calculator',
  pvpEloCalculatorDescription: 'Enter 2 players and see their odds of winning the match, in direct competition with each other.',
  calculate: 'Calculate',
  player: 'Player',
  searchPlayer: 'Search Player',
  pageTitle: 'Dashboard',

  accountLeaderboard: {
    title: 'Ranking',
    updated: 'Updated',
    singles: 'Singles',
    doubles: 'Doubles',
    position: 'Rank',
  },

  guides: {
    1: {
      title: 'Sportya - Rules & Regulations',
      text: 'The official Rules covering all competitive and technical aspects governing matches, events, rankings and more',
    },
    2: {
      title: 'Groups of 3 Playing Format - Technical Guide',
      text: 'A short guide covering the system of play, rankings, withdrawals and advancing to the eliminatory stage for Groups of 3 players, one of the most common playing formats encountered in Sportya events',
    },
    3: {
      title: 'Local Leagues - Technical Guide and FAQ',
      text: 'Basic information regarding the mechanics of Local Leagues, communication with players, rankings and frequently asked questions that can get you started fast',
    },
  },
  competitionStats: 'Competition Stats',
  rankedMatches: '<strong>{{ranked}}</strong> Ranked',
  unrankedMatches: '<strong>{{unranked}}</strong> Unranked',
  bestCompetitionResults: 'Best Competition Results',
};
