export default {
  seoTitle: '{{club}} - Club Tenis - {{location}} | Sportya',
  see_all: 'Vezi toate',
  find_court: 'Găsește un teren și joacă',
  view_details: 'Vezi Detalii',
  location: 'Locație',
  about: 'Despre',
  events: 'Evenimente',
  amenities: 'Facilități',
  openNow: 'Deschis',
  pickLocation: 'Alege o locație',
  date: 'Dată',
  today: 'Azi',
  hour: 'Oră',
  anytime: 'Oricând',
  search: 'Caută',
  filterEvents: 'Stadiu Ev.',
  seeOnMap: 'Vezi pe Hartă',

  title: 'Organizați evenimente și închiriați terenuri pentru jucătorii locali',
  modalTitle: 'Editează Profilul Clubului',
  seoTitle1: 'Pentru Cluburi | Sportya',
  info1: {
    title: 'Gestionează terenurile',
    desc: 'Deține controlul deplin al terenurilor tale!',
  },
  info2: {
    title: 'Găsește antrenori',
    desc: 'Rezervă lecții cu antrenori profesioniști pe un teren aproape de tine.',
  },
  info3: {
    title: 'Organizează evenimente competitive',
    desc: 'Administrează experiența jucătorilor și facilităților clubului tău.',
  },
  form: {
    title: 'Vrei să afli mai multe?',
    desc: 'Lasă-ne câteva informații și revenim în scurt timp.',
    firstName: 'Prenume',
    lastName: 'Nume',
    jobTitle: 'Ocupație',
    email: 'Email',
    phone: 'Telefon',
    city: 'Oraș',
    clubName: 'Numele Clubului / Locației',
    sport: 'Sport',
    numberOfCourts: 'Nr. terenuri',
    button: 'Vreau să fiu contactat',
    ToS: 'Prin contactarea noastră, confirmi că ești de acord cu procesarea datelor personale, așa cum este descris în <1>Declarația de Confidențialitate<1>.',
    validation: {
      required: 'Câmp necesar',
      invalidEmail: 'Email Invalid',
      success: 'Solicitarea a fost transmisă. Îți mulțumim!',
      error: 'Trimiterea a eșuat!',
    },
  },
  call: {
    title: 'Dă-ne un telefon',
    phoneNr: '+4 031 805 4042',
  },
  contact: {
    title: 'Contactează-ne',
    email: 'join@sportya.net',
  },
  CLUB_LEAD_CREATED: 'Cererea ta a fost trimisă cu succes!',
  CLUB_LEAD_ALREADY_EXIST: 'Adresa de email introdusă este deja atribuită unui club.',
  CLUB_LEAD_CREATE_ERROR: 'Nu am putut procesa cererea ta!',
  sportyaOSCTA: 'Ai cont de Club sau Organizator?',
  sportyaOSButton: 'Accesează SportyaOS',
  clubsFound: '<0>{{ totalResults }} club</0> găsit',
  clubsFound_plural: '<0>{{ totalResults }} cluburi</0> găsite',
  clubsFound_with_location: '<0>{{ totalResults }} club</0> găsit pentru <2>{{ location }}</2>',
  clubsFound_plural_with_location: '<0>{{ totalResults }} cluburi</0> găsite pentru <2>{{ location }}</2>',
  startingPriceFrom: "De la {{ startingPrice }} {{ currency }}",
  indoorCourts: "Terenuri Interioare",
  noClubsFound: "Nu au fost găsite rezultate pentru această căutare",
  filters: 'Filtre',
  onlyAvailableCourts: "Doar terenurile disponibile",
  onlyAvailableSlots: "Doar intervalele disponibile",
  showMap: "Arată Harta",
  selfService: "Self-Service",
  clearAll: "Resetează filtrele",
  apply: "Aplică",
  surface: "Suprafață",
  surfaceType: {
    grass: 'Iarbă',
    clay: 'Zgură',
    artificial: 'Sintetic',
    hard: 'Hard',
  },
  courtType: "Tip Teren",
  courtTypes: {
    outdoor: "Exterior",
    indoor: "Interior",
  },
  courtSize: "Dimensiune",
  courtSizes: {
    singles: "Simplu",
    doubles: "Dublu",
  },
  seeOtherAvailabilities: "Vezi alte disponibilități",
  currentlyUnavailable: "Momentan indisponibil",
  comingSoon: "În curând",
  court: "Teren",
  withLighting: "cu iluminat",
  checkout: "Confirmare",
  addPlayersToFriendlyMatches: "Adaugă jucători pentru Meci Amical",
  gameType: "Tip de joc",
  bookingId: "ID Rezervare: <1>{{ bookingId }}",
  start: "Dată",
  startTime: "Ora de începere",
  duration: "Durată",
  orderId: "ID Comandă",
  totalToBePaid: "Total de plată (incl. TVA)",
  totalWithVat: "Total (incl. TVA)",
  billingData: "Date Facturare",
  updateBillingData: "Actualizează datele de facturare",
  billingDataNotSet: "Datele de facturare nu sunt setate!",
  clickToUpdateBillingData: "Datele de facturare sunt incomplete. Click pentru a completa informațiile.",
  selectBillingInformation: "Selectați informațiile de facturare",
  individual: "Persoană fizică",
  company: "Companie",
  durationMin: '{{ duration }} min',
  payWithCard: 'Plătește cu Cardul',
  agreeBookingTos: 'Continuând rezervarea, confirm că sunt de acord cu \n <1>Termenii și Condițiile</1> și cu <4>Politica de Retur</4>.',
  friendlyMatch: 'Meci Amical',
  changePlayer: 'Schimbă jucătorul',
  additionalInfo: 'Informații suplimentare',
  bookACourt: 'Rezervă Teren',
  selfServiceCourts: 'Terenuri Self-Service',
  cancellationWithRefund: 'Anulare cu Rambursare - minimum <1>{{ hours }} ore în avans</1>',
  available: 'Disponibil',
  notAvailable: 'Indisponibil',
  yourBooking: 'Rezervarea mea',
  selfServiceCourtsPopupDescription: 'La sfârșitul fiecărui meci, jucătorii trebuie să lase terenul pregătit pentru meciul următor.',
  cancellationWithRefundPopupDescription: 'Rezervările la acest Club pot fi anulate cu minimum {{ hours }} ore înainte de ora de începere',
  failedToFetchData: 'Nu s-au putut prelua datele clubului.',
  myBookingTooltip: "Rezervarea mea <br /> {{ startHour }} - {{ endHour }} ({{ duration}} min)",
  totalActiveCourts: '{{ totalCourts }} Terenuri ({{ courtSurfaces }})',
  noAvailableCourts: 'Nu există terenuri disponibile pentru acest interval.',
  couldNotFetchCourtsBookings: 'Nu s-au putut prelua rezervările. Încearcă din nou!',
  upcomingNoBookings: 'Nu există rezervări viitoare',
  historyNoBookings: 'Nu există rezervări în istoric',
  myBookings: 'Rezervările mele',
  upcoming: 'Viitoare',
  history: 'Istoric',
  confirmedStatus: 'Finalizată',
  canceledStatus: 'Anulată',
  pendingStatus: 'În așteptare',
  cancelBooking: 'Anulează rezervarea',
  refunded: 'Rambursare',
  lateCancellation: 'Anulare cu întârziere',
  lateCancellationText: 'Sigur dorești să anulezi această rezervare? \n\n Conform Politicii de anulare a clubului, rezervarea va fi anulată <1>FĂRĂ RAMBURSARE</1>',
  earlyCancellation: 'Anulare Rezervare',
  earlyCancellationText: 'Sigur dorești să anulezi această rezervare? \n\n Conform Politicii de anulare a clubului, rezervarea va fi anulată <1>CU RAMBURSARE</1>',
  successPaymentStatus: 'Succes',
  pendingPaymentStatus: 'În așteptare',
  refundedPaymentStatus: 'Rambursare',
  minBookingTime: 'Minimum Booking Time',
};
