export default {
  seoTitle: '{{club}} - Club Tenis - {{location}} | Sportya',
  see_all: 'Виж всичко',
  find_court: 'Намерете корт и играйте',
  view_details: 'Разгледайте детайлите',
  location: 'Локация',
  about: 'Относно',
  events: 'Събития',
  amenities: 'Поздравления',
  openNow: 'Отворено',
  pickLocation: 'Изберете локация',
  date: 'Дата',
  today: 'Днес',
  hour: 'Час',
  anytime: 'по всяко време',
  search: 'Търси',
  seeOnMap: 'Виж на картата',
  filterEvents: 'Статус на турнира',

  title: 'Организирайте събития и наемайте кортове за местните играчи',
  modalTitle: 'Редактирай на Клубния Профил',
  seoTitle1: 'За Клубове | Sportya',
  info1: {
    title: 'Управлявайте кортовете Ви',
    desc: 'Имате пълен контрол над кортовете Ви!',
  },
  info2: {
    title: 'Намерете тенис професионалисти',
    desc: 'Резервирайте уроци с висококвалифицирани тенис професионалисти на корт близо до вас.',
  },
  info3: {
    title: 'Организирайте вашите събития',
    desc: 'Съсредоточете се върху грижата за вашите играчи и съоръжение.',
  },
  form: {
    title: 'Искате да знаете повече?',
    desc: 'Оставете ни малко информация и скоро ще се свържем с вас.',
    firstName: 'Презиме',
    lastName: 'Име',
    jobTitle: 'Професия',
    email: 'Email',
    phone: 'Телефон',
    city: 'Град',
    clubName: 'Име на Клуба / Град',
    sport: 'Спорт',
    numberOfCourts: 'Брой кортове',
    button: 'Желая да се свържете с мен',
    ToS: 'Свързвайки се с нас, вие потвърждавате, че сте съгласни с обработката на лични данни, както е описано в <1> Декларация за поверителност<1>.',
    validation: {
      required: 'Задължително поле',
      invalidEmail: 'Невалиден имайл',
      success: 'Заявката е изпратена. Благодарим Ви!',
      error: 'Изпращането не бе успешно!',
    },
  },
  call: {
    title: 'Обадете ни се',
    phoneNr: '+4 031 805 4042',
  },
  contact: {
    title: 'Свържете се с нас',
    email: 'join@sportya.net',
  },
  CLUB_LEAD_CREATED: 'Вашата заявка е изпратена успешно!',
  CLUB_LEAD_ALREADY_EXIST: 'Имейл адресът, който сте въвели, вече е вписан към съществуващ клуб.',
  CLUB_LEAD_CREATE_ERROR: 'Не можахме да обработим заявката Ви!',
  sportyaOSCTA: 'Имате Ли Акаунт В Клуб Или Организатор?',
  sportyaOSButton: 'Достъп до SportyaOS',
  clubsFound: '<0>Намерен е {{ totalResults }} клуб</0>',
  clubsFound_plural: '<0>Намерени са {{ totalResults }} клуба</0>',
  clubsFound_with_location: 'Намерен е <0>{{ totalResults }} клуб</0> за <2>{{ location }}</2>',
  clubsFound_plural_with_location: '<0>{{ totalResults }} клуба</0> намерени в <2>{{ location }}</2>',
  startingPriceFrom: "От {{ startingPrice }} {{ currency }}",
  indoorCourts: "Закрити кортове",
  noClubsFound: "Няма намерени резултати за това търсене",
  filters: 'Филтри',
  onlyAvailableCourts: "Само налични земи",
  onlyAvailableSlots: "Само налични земи",
  showMap: "Показване на картата",
  selfService: "Self-Service",
  clearAll: "Задаване на нови филтри",
  apply: "Приложи",
  surface: "Повърхност",
  surfaceType: {
    grass: 'Трева',
    clay: 'Клей',
    artificial: 'Изкуствена настилка',
    hard: 'Твърда настилка',
  },
  courtType: "Тип земя",
  courtTypes: {
    outdoor: "Външен",
    indoor: "Вътре",
  },
  courtSize: "Измерение",
  courtSizes: {
    singles: 'Сингъл',
    doubles: 'Двойки',
  },
  seeOtherAvailabilities: "Вижте други опции",
  currentlyUnavailable: "Моментално недостъпен",
  comingSoon: "Очаквайте скоро",
  court: "Терен",
  withLighting: "с осветление",
  checkout: "Потвърждение",
  addPlayersToFriendlyMatches: "Добавете играчи за приятелски мач",
  gameType: "Формат",
  bookingId: "Резервация: <1>{{ bookingId }}",
  start: "Дата",
  startTime: "Начален час",
  duration: "Продължителност",
  orderId: "ID Поръчка",
  totalToBePaid: "Тотално за плащане (с ДДС)",
  totalWithVat: "Тотално (с ДДС)",
  billingData: "Фактурни данни",
  updateBillingData: "Актуализиране на данните за плащане",
  billingDataNotSet: "Данните за плащане не са зададени!",
  clickToUpdateBillingData: "Данните за фактуриране са непълни. Кликнете, за да попълните цялата информация.",
  selectBillingInformation: "Изберете информация за плащане",
  individual: "Физическо лице",
  company: "Компания",
  durationMin: '{{ duration }} минути',
  payWithCard: 'Плащане с карта',
  agreeBookingTos: 'С продължаване на резервацията потвърждавам, че съм съгласен с \n <1>Условията</1> и <4>Политиката за връщане</4>.',
  friendlyMatch: 'Приятелски',
  changePlayer: 'Сменете партньора',
  additionalInfo: 'Допълнителна информация',
  bookACourt: 'Земя резерват',
  selfServiceCourts: 'Терени на Self-Service',
  cancellationWithRefund: 'Анулиране с възстановяване на сумата - поне <1>{{ hours }} часа предварително</1>',
  available: 'На разположение',
  notAvailable: 'Недостъпен',
  yourBooking: 'Моята резервация',
  selfServiceCourtsPopupDescription: 'В края на всеки мач играчите трябва да подготвим терена за следващия мач.',
  cancellationWithRefundPopupDescription: 'Резервациите в този клуб могат да бъдат анулирани най-малко {{ hours }} часа преди началния час.',
  failedToFetchData: 'Клубните данни не можаха да бъдат извлечени.',
  myBookingTooltip: "Моята резервация <br /> {{ startHour }} - {{ endHour }} ({{ duration}} минути)",
  totalActiveCourts: '{{ totalCourts }} кортове ({{ courtSurfaces }})',
  noAvailableCourts: 'Няма налични кортове за този интервал.',
  couldNotFetchCourtsBookings: 'Резервациите не можаха да бъдат извлечени. Опитай пак!’',
  upcomingNoBookings: 'Няма бъдещи резервации',
  historyNoBookings: 'Няма резервации в историята',
  myBookings: 'Моите резервации',
  upcoming: 'Предстоящо',
  history: 'Исторически',
  confirmedStatus: 'Завършена',
  canceledStatus: 'Отменено',
  pendingStatus: 'На изчакване',
  cancelBooking: 'Отменете резервацията',
  refunded: 'Пълно възстановяване',
  lateCancellation: 'Късно анулиране',
  lateCancellationText: 'Сигурни ли сте, че искате да отмените тази резервация? \n\n Съгласно Политиката за анулиране на клуба, резервацията ще бъде анулирана <1>БЕЗ ВЪЗСТАНОВЯВАНЕ</1>',
  earlyCancellation: 'Анулиране на резервация',
  earlyCancellationText: 'Сигурни ли сте, че искате да отмените тази резервация? \n\n Съгласно Политиката за анулиране на клуба, резервацията ще бъде анулирана <1>С ВЪЗСТАНОВЯВАНЕ</1>',
  successPaymentStatus: 'Успешно',
  pendingPaymentStatus: 'На изчакване',
  refundedPaymentStatus: 'Погасяване',
  minBookingTime: 'Minimum Booking Time',
};
