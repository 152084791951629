import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { isEmpty } from 'lodash';
import PubSub from 'pubsub-js';
import { get } from '../../components/common/http';

export const fetchTabData = createAsyncThunk('profileTabs/fetchTabData', async ({
  userId, tab, type, filters, options, profile,
}) => {
  try {
    const isTitlesTab = tab === 'titles';
    const endpoint = tab === 'rankings' ? 'leaderboards'
      : tab === 'points' ? 'best-results' : tab;

    // An array order of objects with keys, representing the order in which our titles should be.
    const order = [
      { key: 'firstServe' },
      { key: 'silver' },
      { key: 'gold' },
      { key: 'platinum' },
      { key: 'special' },
      { key: 'champions' },
      { key: 'league' },
    ];

    const filterParams = new URLSearchParams({ ...filters, ...options }).toString();
    const hasFilters = !isEmpty(filters) || !isEmpty(options);

    const {
      data: { data },
    } = await get(`/accounts/${userId}/${endpoint}?type=${type}${hasFilters ? `&${filterParams}` : ''}`);

    // If isTitlesTab is true, map through the order array, and filters the data array
    // to return only the elements that have a matching category key in the same order as they are listed in said array.
    const result = isTitlesTab ? order?.map((i) => data?.find(j => j?.category === i?.key))?.filter(a => a) : data;

    return {
      data: result, tab, type, profile,
    };
  } catch ({ response: { data: { message } } }) {
    PubSub.publish('api-error-handler', {
      message,
      hasNotification: false,
    });
  }
});

// Actions
export const fetchAccountDetails = createAsyncThunk('accounts/fetchAccountDetails', async (id) => {
  try {
    const { data: { data } } = await get(`/accounts/${id}`);
    return data;
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

export const fetchAccountStats = createAsyncThunk('profileTabs/fetchAccountStats', async ({
  userId,
  profile,
  type,
}) => {
  try {
    const { data: { data } } = await get(`/accounts/${userId}/${type}`);

    return { profile, data, type };
  } catch ({ response }) {
    PubSub.publish('api-error-handler', {
      message: response?.data?.message,
      hasNotification: false,
    });
  }
});

const profileTabs = createSlice({
  name: 'profileTabs',
  initialState: {
    myProfile: {
      leaderboard: {
        data: {},
        status: 'idle',
      },
      stats: {
        data: {},
        status: 'idle',
      },
      activity: {
        singles: {
          status: 'idle',
          levelChanges: [],
          hasFetchedLevelChanges: false,
          data: [],
        },
        doubles: {
          status: 'idle',
          levelChanges: [],
          hasFetchedLevelChanges: false,
          data: [],
        },
      },
      titles: {
        singles: {
          status: 'idle',
          data: [],
        },
        doubles: {
          status: 'idle',
          data: [],
        },
      },
      rankings: {
        singles: {
          status: 'idle',
          data: [],
        },
        doubles: {
          status: 'idle',
          data: [],
        },
      },
      points: {
        singles: {
          status: 'idle',
          penalties: [],
          data: [],
        },
        doubles: {
          status: 'idle',
          penalties: [],
          data: [],
        },
        fetched: [],
      },
      years: {
        singles: [],
        doubles: [],
      },
    },
    visitedProfile: {
      leaderboard: {
        data: {},
        status: 'idle',
      },
      stats: {
        data: {},
        status: 'idle',
      },
      activity: {
        singles: {
          status: 'idle',
          levelChanges: [],
          hasFetchedLevelChanges: false,
          data: [],
        },
        doubles: {
          status: 'idle',
          levelChanges: [],
          hasFetchedLevelChanges: false,
          data: [],
        },
      },
      titles: {
        singles: {
          status: 'idle',
          data: [],
        },
        doubles: {
          status: 'idle',
          data: [],
        },
      },
      rankings: {
        singles: {
          status: 'idle',
          data: [],
        },
        doubles: {
          status: 'idle',
          data: [],
        },
      },
      points: {
        singles: {
          status: 'idle',
          penalties: [],
          data: [],
        },
        doubles: {
          status: 'idle',
          penalties: [],
          data: [],
        },
        fetched: [],
      },
      years: {
        singles: [],
        doubles: [],
      },
    },
    error: null,
    generalStatus: 'idle',
  },
  reducers: {
    setEventsWithPenalty: (state, action) => {
      const { eventId, type, profile } = action.payload || {};
      if (!state[profile].points.fetched.includes(eventId)) {
        state[profile].points[type].penalties.push(action.payload);
        state[profile].points.fetched.push(eventId);
      }
    },
    clearAllTabs: (state, action) => {
      const { profile } = action.payload || {};
      const tabs = ['activity', 'titles', 'rankings', 'points'];
      const types = ['singles', 'doubles'];

      for (let i = 0; i < tabs.length; i++) {
        for (let index = 0; index < types.length; index++) {
          state[profile][tabs[i]][types[index]].status = 'idle';
          state[profile][tabs[i]][types[index]].data = [];
          if (tabs[i] === 'activity') {
            state[profile][tabs[i]][types[index]].levelChanges = [];
            state[profile][tabs[i]][types[index]].hasFetchedLevelChanges = false;
            state[profile].years = {
              singles: [],
              doubles: [],
            };
          }
        }
      }
    },
    clearMyTab: (state, action) => {
      const { type } = action?.payload || {};
      const isActivityTab = (type === 'activity' || type === 'rankings');
      state.myProfile[type] = {
        status: 'idle',
        data: [],
      };

      if (isActivityTab) {
        state.myProfile.years = {
          singles: [],
          doubles: [],
        };
      }

      state.myProfile.error = null;
    },
    setActiveYears: (state, action) => {
      const { type, data, profile } = action.payload || {};
      state[profile].years[type] = data;
    },
    setLevelChanges: (state, action) => {
      const { type, payload, profile } = action.payload || {};
      state[profile].activity[type].levelChanges = payload;
      state[profile].activity[type].hasFetchedLevelChanges = true;
    },
  },
  extraReducers: {
    [fetchTabData.pending]: (state) => {
      state.generalStatus = 'loading';
    },
    [fetchTabData.fulfilled]: (state, action) => {
      const {
        data, tab, type, profile,
      } = action.payload || {};
      if (tab && type) {
        state[profile][tab][type] = {
          ...state[profile][tab][type],
          data,
          status: 'succeeded',
        };
      }
      state.generalStatus = 'succeeded';
    },
    [fetchAccountStats.pending]: (state) => {
      state.generalStatus = 'loading';
    },
    [fetchAccountStats.fulfilled]: (state, action) => {
      const { profile, data, type } = action.payload || { profile: 'myProfile' };
      if (profile && type) {
        state[profile][type].data = data;
        state[profile][type].status = 'succeeded';
        state.generalStatus = 'succeeded';
      }
    },
    [fetchTabData.rejected]: (state, action) => {
      state.generalStatus = 'failed';
      state.error = action.error.message;
    },
  },
});

const accountDetails = createSlice({
  name: 'accountDetails',
  initialState: {
    data: {},
    status: 'idle',
    error: null,
  },
  reducers: {
    updateProfileInfo: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
  },
  extraReducers: {
    [fetchAccountDetails.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAccountDetails.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchAccountDetails.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

export const {
  clearMyTab, clearAllTabs, setActiveYears, setLevelChanges, setEventsWithPenalty,
} = profileTabs.actions;

export const getEventsWithPenalties = ({ id, type, profile }) => async (dispatch) => {
  try {
    const { data: { data } } = await get(`/events/${id}`);
    dispatch(setEventsWithPenalty({ ...data, type, profile }));
  } catch (error) { /* empty */ }
};

export const getActiveYears = ({ userId, type, profile }) => async (dispatch, useSelector) => {
  try {
    const years = useSelector(state => state.accounts.info.tabs.years[type]);
    const hasFetchedYears = years?.length;
    if (!hasFetchedYears) {
      const { data: { data } } = await get(`/accounts/${userId}/years-activity?type=${type}`);
      dispatch(setActiveYears({ type, data, profile }));
    }
  } catch (error) { /* empty */ }
};

export const getLevelChanges = ({
  userId, type, start, end, profile,
}) => async (dispatch) => {
  try {
    let url = `/leaderboards/${userId}/level-details?type=${type}`;
    url += `${start ? `&startDate=${start}` : ''}${end ? `&endDate=${end}` : ''}`;
    const {
      data: { data },
    } = await get(url);
    const result = data?.map(value => {
      const storedDate = value.createdAt;
      Object.assign(value, { createdAt: undefined });
      return {
        ...value,
        date: storedDate,
      };
    });
    return dispatch(setLevelChanges({ type, payload: result, profile }));
  } catch (error) { /* empty */ }
};

export const {
  updateProfileInfo,
} = accountDetails.actions;

const reducer = combineReducers({
  general: accountDetails.reducer,
  tabs: profileTabs.reducer,
});

export default reducer;
