import React, { useState, useEffect } from 'react';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { groupBy, isEmpty } from 'lodash';
import { Helmet } from 'react-helmet';
import { useTranslation, Trans } from 'react-i18next';
import mixpanel from '../../../mixpanel';

// import RJTBanner from '../../../components/Campaign/RomaniaJoacaTenis/smallBanner';

import ContentLoader from '../../../components/ContentLoader';
import LoadImage from '../../../components/common/LoadImage';
// import CompetitionAnnouncements from './components/CompetitionAnnouncements'
import Paragraph from '../../../components/Paragraph';
import H1 from '../../../components/H1';
import H3 from '../../../components/H3';
import MainDraw from './components/MainDraw';
import Winners from './components/Winners';
import Results from './components/Results';
// import Soon from "./components/Soon"
import Prizes from './components/Prizes';
import CurrentPhaseStatus from '../components/Event/currentPhaseStatus';
import Competition from './components/Competition';
import BackButton from '../../../components/BackButton';

import { CompetitionWrapp, CompetitionCard } from './styles';

import { fetchCompetitionMatches, fetchEventDetails } from '../reducers';
import { getEventMatches } from '../actions';

const downloadPDF = false; // TODO - activate when pdf is available
const drawOpeningSoonStatuses = [
  'readyToPlay',
  'openingSoon',
  'preRegistrationsOpen',
  'registrationsOpen',
  'drawPending',
];

const CompetitionDetails = () => {
  const { eventId, competitionId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation('events');

  const { data: eventInfo, status } = useSelector(state => state.events?.info);
  const [isRegistered, setIsRegistered] = useState();
  const [winners, setWinners] = useState([]);
  const [loadingWinners, setLoadingWinners] = useState(false);
  const [results, setResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);

  useEffect(() => {
    if (status === 'idle' || (status === 'succeeded' && eventInfo?._id !== eventId)) {
      dispatch(fetchEventDetails(eventId));
    }
  }, [competitionId, status, dispatch]);

  useEffect(() => {
    dispatch(fetchCompetitionMatches(competitionId));
  }, [competitionId, dispatch]);

  useEffect(() => {
    setIsRegistered(isRegistered);

    if (isRegistered === false) {
      dispatch(fetchEventDetails(eventId));
    }
  }, [isRegistered]);

  const [competitionInfo] = (eventInfo?.competitions || []).filter((v) => v._id === competitionId);

  useEffect(() => {
    if (competitionInfo?.level || competitionInfo?.gameType) {
      mixpanel.track('View Competition Details', {
        sp_competition_details_type: competitionInfo?.gameType,
        sp_competition_details_level: competitionInfo?.level,
        sp_competition_details_current_stage: eventInfo?.currentPhase?.status,
        sp_competition_details_ml_completion_status: (
          competitionInfo?.mlMaxNumber > competitionInfo?.mainList?.length ? 'available' : 'full'
        ),
      });
    }
  }, [competitionInfo]);

  if (status === 'loading') {
    return (<ContentLoader title={false} />);
  }

  if (status === 'failed' && isEmpty(eventInfo)) {
    return <Redirect to="/events" />;
  }

  const handleWinnersTab = async () => {
    if (isEmpty(winners) && eventInfo?._id) {
      setLoadingWinners(true);
      let result = await getEventMatches(eventInfo?._id);
      result = result.filter(match => !!match.winner && match.competition.stage === match.competition.finalStage);
      if (result) {
        const data = groupBy(result, 'competition.gameType');

        data.singles = (data.singles || []).map(match => ({
          ...match,
          players: [
            { accountType: 'player', ...match.player },
            { accountType: 'opponent', ...match.opponent },
          ],
        }));

        data.doubles = (data.doubles || []).map(match => ({
          ...match,
          players: [
            {
              accountType: 'player',
              winner: match.winner === 'player',
              team: [match.player, match.playerPartner],
            },
            {
              accountType: 'opponent',
              winner: match.winner === 'opponent',
              team: [match.opponent, match.opponentPartner],
            },
          ].sort((x, y) => y.winner - x.winner),
        }));

        setWinners(data);
        setLoadingWinners(false);
      }
    }
  };

  const handleResultsTab = async () => {
    if (isEmpty(results) && eventInfo?._id) {
      setLoadingResults(true);
      let result = await getEventMatches(eventInfo?._id);
      result = result.filter(match => !!match.winner && match.competition.stage === match.competition.finalStage);
      if (result) {
        const data = groupBy(result, 'competition.gameType');

        data.singles = (data.singles || []).map(match => ({
          ...match,
          players: [
            { accountType: 'player', ...match.player },
            { accountType: 'opponent', ...match.opponent },
          ],
        }));

        data.doubles = (data.doubles || []).map(match => ({
          ...match,
          players: [{
            accountType: 'player',
            winner: match.winner === 'player',
            team: [match.player, match.playerPartner],
          },
          {
            accountType: 'opponent',
            winner: match.winner === 'opponent',
            team: [match.opponent, match.opponentPartner],
          }].sort((x, y) => y.winner - x.winner),
        }));

        setResults(data);
        setLoadingResults(false);
      }
    }
  };

  const code = history.location.pathname.split('/')[1];
  const languageCode = code && code.length === 2 ? code : 'en';

  const prizes = {
    list: competitionInfo?.prizes?.[languageCode === 'en' || languageCode === 'ro' ? languageCode : 'en'],
    get exist() {
      // eslint-disable-next-line react/no-this-in-sfc
      const amount = this.list?.length;
      // eslint-disable-next-line react/no-this-in-sfc
      return amount > 0 && (this.list).filter(e => (e !== '' || e !== null) && e.prize !== '');
    },
  };

  // const display = {
  //   RJTCampaign: [
  //     '63592e580180780048326938',
  //     '6357cd6501807800483229ce',
  //     '6357c09701807800483224b0',
  //     '63540703018078004830aeb7',
  //     '63568b510180780048310a0a',
  //     '63533a560180780048309418',
  //     '63567a3701807800483103a0',
  //     '63540770018078004830aedb',
  //     '63533c8001807800483095f9',
  //     '635972fe01807800483279f4',
  //     '635108650180780048304824',
  //     '6357c06701807800483224aa',
  //     '6357bec301807800483223c8',
  //     '6354072c018078004830aec2',
  //     '6358d839018078004832502a',
  //     '6359734e0180780048327a05',
  //   ].includes(eventInfo?._id),
  // };

  return (
    <CompetitionWrapp>
      <Helmet>
        <title>
          {t(
            'competitionDetails.seoTitle',
            {
              eventName: eventInfo?.name,
              competitionLevel: competitionInfo?.level,
              gameType: t(competitionInfo?.gameType),
            },
          )}
        </title>
      </Helmet>
      <div className="CompWrapp">
        {/* Current event phase status */}
        <CurrentPhaseStatus
          eventInfo={eventInfo}
          center
          smallbadge
          {...(competitionInfo?.status === 'MAINTENANCE' && { maintenance: true })}
        />

        <div className="title d-flex align-middle">
          <BackButton className="mr10" {...history} to={`/events/${eventId}`} bold />
          <H1>{eventInfo?.name}</H1>
        </div>
        {/*
        {display.RJTCampaign && (
          <RJTBanner margin={'0 0 20px 0'} />)} */}

        <CompetitionCard>
          <div className="competitionCard">
            {/* Competition info */}
            <Competition
              displayType="competitionInfo"
              {...{
                competitionInfo,
                eventInfo,
                eventStatus:
                eventInfo?.currentPhase?.status,
                isRegistered,
                setIsRegistered,
              }}
              className="competitionInfo"
            />
            <Tabs>
              <div className="tabsWrapp">
                <TabList>
                  <Tab><H3><Trans ns="events" i18nKey="winners.title">Main Draw</Trans></H3></Tab>
                  {drawOpeningSoonStatuses.includes(eventInfo?.currentPhase?.status) ? (
                    <>
                      {/* <Tab><H3><Trans ns='events' i18nKey='winners.title'>Main Draw</Trans></H3></Tab> */}
                      {['resultsPending', 'ended'].includes(eventInfo?.currentPhase?.status) && (
                        <Tab onClick={handleWinnersTab}>
                          <H3><Trans ns="common" i18nKey="winners">Winners</Trans></H3>
                        </Tab>
                      )}
                      {['ended'].includes(eventInfo?.currentPhase?.status) && (
                        <Tab onClick={handleResultsTab}>
                          {eventInfo?.category === 'league' ? (
                            <H3><Trans ns="events" i18nKey="leaderboards">Leaderboards</Trans></H3>
                          ) : (
                            <H3><Trans ns="events" i18nKey="results">Results</Trans></H3>
                          )}
                        </Tab>
                      )}
                    </>
                  ) : ''}
                  {prizes.exist && (
                    <Tab>
                      <H3><Trans ns="events" i18nKey="prizes">Prizes</Trans></H3>
                    </Tab>
                  )}

                </TabList>
                {downloadPDF && (
                  <div className="pdfWrapp flexSB">
                    <img src={LoadImage('pdf-icon.svg')} alt="pdfIcon" />
                    <Paragraph small bold>
                      <Trans ns="common" i18nKey="winners.downloadPdf">Download PDF</Trans>
                    </Paragraph>
                  </div>
                )}
              </div>

              <TabPanel>
                <MainDraw
                  competitionInfo={competitionInfo}
                  currentPhase={eventInfo?.currentPhase?.status}
                  ended={eventInfo?.currentPhase?.status === 'ended'}
                  league={eventInfo?.category === 'league'}
                />
              </TabPanel>
              {drawOpeningSoonStatuses.includes(eventInfo?.currentPhase?.status) ? (
                ['resultsPending', 'ended'].includes(eventInfo?.currentPhase?.status) && (
                  <TabPanel>
                    {loadingWinners
                      ? <ContentLoader title={false} />
                      : <Winners />}
                  </TabPanel>)

                  .ended.includes(eventInfo?.currentPhase?.status) && (
                  <TabPanel>
                    {loadingResults && competitionInfo?.drawModel === 'groups'
                      ? <ContentLoader title={false} />
                      : <Results league={eventInfo?.category === 'league'} results={results} />}
                  </TabPanel>
                )
              ) : ''}
              {prizes.exist && (
                <TabPanel>
                  <Prizes prizes={prizes.list} />
                </TabPanel>
              )}
            </Tabs>
          </div>
        </CompetitionCard>

        {/* <div className="AnnouncementsWrapp">
          {announcement.map((announcement, idx) =>
            <CompetitionAnnouncements key={idx} announcement={announcement} />)
          }
        </div> */}

      </div>
    </CompetitionWrapp>
  );
};

export default CompetitionDetails;
