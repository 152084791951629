import React from 'react';

import { Badge } from './styles';
import { Contain, H3, P } from '../Collection';

const index = ({
  level, levelDouble, small, gold, silver, className,
}) => (
  <Contain {...className && { className }}>
    <Badge gold={gold} silver={silver} small={small}>
      {small
        ? <P xSmall bold>{level}</P>
        : <H3>{level}</H3>}
    </Badge>
    <Badge gold={gold} silver={silver} small={small} double>
      {small
        ? <P xSmall bold>{levelDouble}</P>
        : <H3>{levelDouble}</H3>}
    </Badge>
  </Contain>
);

export default index;
