export default {
  completeProfile: {
    profileDetails: 'Profiladatok',
    fillProfileDetails: 'Profiladatok kitöltése',
    sportyaAvailability: 'A Sportya elérhető romániai, moldovai, bulgáriai és magyarországi játékosok számára. Hamarosan, más országok játékosai számára is',
    year: 'Év',
    month: 'Hónap',
    day: 'Nap',
    gender: 'Nem',
    location: 'Tartózkodási hely',
    locationNotSet: '<0>Tartózkodási hely:</0> Állítsd be a tartózkodási helyedet',
    userLocationNotSet: '<0>Tartózkodási hely:</0> Nincs beállítva',
    age: 'Életkor',
    ageNotSet: '<0>Életkor:</0> Állítsd be az életkorodat',
    userAgeNotSet: '<0>Életkor:</0> Nincs beállítva',
    handNotSet: '<0>Játszó kéz:</0> Állítsd be a játszó kezedet',
    userHandNotSet: '<0>Játszó kéz:</0> Nincs beállítva',
    searchLocation: 'Tartózkodási hely keresése',
    chooseDominantHand: 'Válaszd ki a domináns kezedet',
    left: 'Bal',
    right: 'Jobb',
    continue: 'Tovább',
    saveAndContinue: 'Mentés & Tovább',
    uploadNewPhoto: 'Új fénykép feltöltése',
    removePhoto: 'Fénykép törlése',
    profileVisibility: 'Profil láthatósága',
    fillProfileVisibility: 'Válaszd ki, hogy a többi játékos hogyan lásson',
    allPlayers: 'Összes játékos',
    allPlayersDescription: 'A profilod látható lesz az összes játékos számára, és lehetőségük lesz üzenetet küldeni neked, bejelölni téged ismerősnek, és barátságos mérközésekre hívni ki téged.',
    allPlayersSubtitle: 'Javasoljuk ezt a lehetőséget, hogy teljes mértékben élvezhesd a {{ siteName }} élményt!',
    onlyFriendsAndTheirFriends: 'Csak az ismerőseid és azok ismerősei',
    onlyFriendsAndTheirFriendsDescription: 'Csak az ismerősnek jelölt játékosok és azok ismerősei számára lesz látható a profilod. \n\n Minden más játékos csak a profilod korlátozott verzióját láthatja (teljes név, játékosazonosító, tartózkodási hely, profilfotó), és csak üzeneteket küldhet, illetve felvehet téged ismerősként',
    onlyFriends: 'Csak az ismerőseid',
    onlyFriendsDescription: 'Csak azok a játékosok láthatják teljesen a profilodat, akik az ismerőseid között szerepelnek. \n\n Minden más játékos csak a profilod korlátozott verzióját láthatja (teljes név, játékosazonosító, tartózkodási hely, profilfotó), és csak üzeneteket küldhet, ilettve felvehet téged ismerősként.',
    gameLevel: 'Játékszint',
    gameLevelDescription: 'Amikor a teniszpályára lépünk, kiegyensúlyozott mérkőzéseket szeretnénk játszani. Emiatt a játékszint önértékelése és a szintalapú mérkőzések / események a teniszélmény alapjává váltak a Sportyában.',
    gameLevelList: {
      1: 'Kezdetben azt a játékszintet fogod beállítani, amely az Egyéni játékpotenciálodat jelzi. Ez automatikusan az induló játékszinted lesz a Páros mérkőzéseiden is, de az Egyéni és Páros játékszint a mérkőzések eredményei alapján módosulhat vagy módosítható. ',
      2: 'Ez nagyon fontos lépés, és nagy hatással lehet a Sportya eseményekkel és mérkőzésekkel kapcsolatos első benyomásaidra. Bölcsen válaszd ki a szintet, ne becsüld túl vagy alá a képességeidet. Próbálj minél objektívebb lenni.',
      3: 'A 4. szint kezdőnek felel meg, míg a 9. szint már közel áll a profi játékszinthez (általában a visszavonult profi játékosok választják).',
      4: 'A Sportya-ban a játékszint az eredmények alapján dinamikusan változik.',
    },
    chooseGameLevel: 'Válaszd ki a szintedet',
    seeVideoDemonstration: 'Nézd meg a videóbemutatót',
    selectThisLevel: 'Válaszd ki ezt a szintet',
    skipForNow: 'Halaszd későbbre',
    level: 'Szint',
    levelDescription: {
      4: 'A játékos lelkes, tanulási vággyal, illetve tenisztudással rendelkezik, és játékban tudja tartani a labdát. A játékosnál hiányzik a játéksebesség, megszakított / hiányos mozdulatai vannak, és a pálya lefedése nem az erőssége.',
      5: 'A játékos le tudja fedni a pályát, hogy lehetőségeket teremtsen, a tenyerese meglehetősen következetes, illetve hosszabb labdameneteket le tud folytatni. Játéksebessége továbbra is hiányzik, mozdulataiban általános bizonytalanság mutatkozik, ütései továbbra is megszakadnak/hiányosak. Gyakran megfigyelhető a döntő ütések hiánya.',
      6: 'A játékosnak jobb az ütéskontrollja, úgy a tenyeresnél, mint a fonáknál is, egységes játéktempót tud kialakítani, és akarata szerint építi a pontjait. A fonákja, bár irányítani tudja, továbbra is következetlen, a második adagolás általában lassú és nem hatékony / rosszul van helyezve. A megteremtett játéklehetőségek ellenére továbbra is hiányoznak a döntő ütések a játékából.',
      7: 'A játékos tudja használni az erejét, különösen a tenyeres ütésnél, váltogatja az ütéseit, irányítja a pörgetést, és pontokat tud gyűjteni ellenfele gyengeségeinek kihasználásával. A fölényhelyzetekben azonban hiányzik az átgondoltság, az erejét még mindig rosszul tudja használni, a kulcspontokban a hibák többnyire továbbra is jelen vannak a játékában, és a fonákjából még hiányzik az agresszió.',
      8: 'A játékos irányítani tudja a pörgetést, miközben növeli a sebességet, agresszív játékot folytat, vegyes ütéstartományt használ, és ritkán követ el ki nem kényszerített hibákat. A játékosnak azonban bizonyos esetekben nincs türelme, és amikor nyomás alatt van, általában nem hatékony a fonákja.',
      9: 'A játékos az adagolását félelmetes fegyverként használja, különféle ütések vannak az arzenáljában, váltogatja a pörgetést, és olyan ütéseket használ, amelyek gyakran kiszámíthatatlanok, hatékonyak, döntőek, úgy tenyeressel, mint fonákkal is.',
    },
    profilePicture: 'Profilkép',
    looksGreat: 'Nagyon jól néz ki',
    profilePictureSubtitle: 'A fényképek az ismerősőknek segítenek, hogy könnyebben felismerjenek \n a {{ siteName }} oldalon',
    uploadYourPhoto: 'Tölts fel egy fényképet',
    availability: 'Rendelkezésre állás',
    setAvailability: 'Állítsd be a rendelkezésre állásodat',
    availabilityDescription: 'A játékra való rendelkezésre állás beállításával könnyebben megtalálhatod a játékpartnereidet',
    completeProfile: 'A profiladatok sikeresen kitöltve',
    Mon: 'Hé',
    Tue: 'Ke',
    Wed: 'Sze',
    Thu: 'Csü',
    Fri: 'Pé',
    Sat: 'Szo',
    Sun: 'Va',
  },
  logout: {
    wantToLogout: 'Kijelentkezel?',
    logoutParagraph: 'Biztos, hogy ki akarsz jelentkezni?',
    btnCancel: 'Visszavonás',
    btnLogout: 'Kijelentkezés',
  },
  wallet: {
    seoTitle: 'Virtuális pénztárca | Sportya',
    walletInfo: 'A Sportya Virtuális pénztárca olyan fizetési rendszer, amely lehetővé teszi az eseményekre való gyors regisztrációt és a Sportya egyéb szolgáltatásainak kifizetését. Feltöltheted a pénztárcádat, és később fizetési lehetőségként használhatod a Sportya teljes felületén. A fizetéseket késedelem nélkül érvényesítjük. A fizetési / kártyaadatokat nem tároljuk.  ',
    chooseAmountToTopUp: 'Válaszd ki a feltölteni kívánt összeget',
    noTransactions: 'Nincs tranzakció',
    virtualWallet: 'Virtuális pénztárca',
    summerMembership: 'Nyári fiók',
    premiumMembership: 'Prémium fiók',
    availableFunds: 'Elérhető pénzeszközök ',
    transactionId: 'Tranzakció azonosítója',
    date: 'Dátum',
    product: 'Termék',
    amount: 'Összeg',
    topUp: 'Feltöltés',
    transactionTypes: {
      topUp: 'Virtuális pénztárca: Feltöltés',
      topUpByAdmin: 'Virtuális pénztárca: Feltöltés (rendszergazda által)',
      subtractByAdmin: 'Virtuális pénztárca: Levonás (rendszergazda által)',
      eventRegistration: 'Eseményre való regisztráció: <1>{{ eventName }}</1>',
      eventWithdrawal: 'Eseményről való visszalépés: <1>{{ eventName }}</1>',
      upgradeMembership: 'Tagság felminősítése: <1>{{ membershipName }}</1>',
      upgradeMembershipByAdmin: 'Tagság felminősítése (rendszergazda által): <1>{{ membershipName }}</1>',
      currencyNotSet: 'A Virtuális Pénztárca és a Feltöltés funkció használatának megkezdéséhez be kell állítania tartózkodási helyét.',
      balanceAdjustmentByAdmin: 'Egyenleg módosítása (rendszergazda által)',
      waitingListRefund: 'Várólista automatikus visszatérítése',
      mobile: {
        id: 'Azonosító',
        date: 'Dátum',
        type: 'Típus',
        amount: 'Összeg',
      },
    },
  },
  orders: {
    title: 'Rendeléseim',
    subtitle: 'Rendeléstörténet ',
    seoTitle: 'Rendeléseim | Sportya',
    orderList: {
      orderID: 'Rendelésazonosító',
      dateTime: 'Dátum és idő',
      orderType: 'Rendelés típusa',
      amount: 'Összeg',
      paymentMethod: 'Fizetési mód',
      orderDetails: 'Rendelés részletei',
      viewDetails: 'Részletek megtekintése',
      invoiceInfo: 'Számla információ',
      viewInvoice: 'Számla megtekintése',
      invoiceNotAvailable: "Not Available",
      orderSummary: {
        title: 'Rendelés részletei',
        seeEvents: 'Esemény megtekintése',
        about: 'A versenyre való nevezés érvényesítése megtörtént. Ezentúl ez a rendelés nem módosítható. További kérdéseivel forduljon hozzánk a',
        about2: 'További információ és visszaküldési szabályzatunk a címen érhető el',
        returnPolicy: 'Visszatérítési szabályzat',
        summary: 'Összegzés',
        bookingFee: 'Foglalási díj',
        sprintenDonation: 'Sprinten adomány',
        coupon: 'Utalvány',
        total: 'Összesen',
        inclTVA: 'ÁFA-val',
        clubEntryFees: 'Klub belépési díja',
        howToPayEntryFee: 'Érkezéskor fizeted a házigazda klubnak. Házigazda klub: ',
        data: {
          id: 'Rendelésazonosító',
          date: 'Dátum és idő',
          type: 'Rendelés típusa',
          amount: 'Összeg',
        },
      },
      mobile: {
        ID: 'Azonosító',
        Date: 'Dátum: ',
        Type: '<0>Típus:</0>',
        Price: '<0>Összeg:</0>',
        Payment: 'Fizetési mód',
      },
    },
    noOrders: 'Nincsenek korábbi rendelések',
  },
  notifications: {
    title: 'Értesítések',
    subtitle: 'Legújabb értesítések',
    seoTitle: 'Értesítések | Sportya',
    seeDetails: 'Részletek megtekintése',
    noNotifications: 'Nem található értesítés.',
    dropdown: {
      title: 'Értesítések',
      allNotifications: 'Összes értesítések megtekintése',
    },
    topUp: "Virtuális pénztárcájának egyenlege frissült. <0>Részletek megtekintése</0>.",
    gameLevelChangedByAdmin: "A rendszergazda módosította a játékszintedet. Az új szinted {{ gameLevelSingle }} az Egyéni, {{ gameLevelDouble }} a Páros esetében. <0>Részletek megtekintése</0>.",
    eventRegistrationBan: "A fiókodat érintő büntetések miatt a továbbiakban nem regisztrálhatsz eseményekre. <0>Részletek megtekintése</0>.",
    penaltyAdministrative: "A rendszergazda pontokat vont le a ranglistahelyezésedből. <0>Részletek megtekintése</0>",
    penaltyLateWithdrawal: "A(z) <0>{{ eventName }}</0> eseményről való késedelmes visszalépésedet feldolgoztuk. A regisztrációs díjadat visszatartottuk.",
    penaltyAnnouncedWithdrawal: "A(z) <0>{{ eventName }}</0> eseményről való visszalépésedet feldolgoztuk, és a regisztrációs díjadat hozzáadtuk a <0>Virtuális pénztárcádhoz</0>.",
    teamWithdrawalByAdmin: "A csapatodat visszavonták a(z) <0>{{ eventName }}</0> eseményről. <0>Részletek megtekintése</0>.",
    playerWithdrawalByAdmin: "Visszavontak a(z) <0>{{ eventName }}</0> eseményről. <0>Részletek megtekintése</0>.",
    teamWithdrawalFromDoubles: "<0>{{ partnerName }}</0> csapatpartnered visszavonta a csapatot a(z) <0>{{ eventName }}</0> eseményről.",
    registrationByAdminOnSLForDoubles: "A(z) <0>{{ eventName }}</0> rendezőlistájára történő csapatregisztrációd sikeres volt. <0>Részletek megtekintése</0>.",
    registrationByAdminOnSLForSingles: "A(z) <0>{{ eventName }}</0> rendezőlistájára történő regisztrációd sikeres volt. <0>Részletek megtekintése</0>.",
    registrationByAdminOnWLForDoubles: "A(z) <0>{{ eventName }}</0> várólistájára történő csapatregisztrációd sikeres volt. <0>Részletek megtekintése</0>.",
    registrationByAdminOnWLForSingles: "A(z) <0>{{ eventName }}</0> várólistájára történő regisztrációd sikeres volt. <0>Részletek megtekintése</0>.",
    registrationByAdminOnMLForDoubles: "A(z) <0>{{ eventName }}</0> főtáblájára történő csapatregisztrációd sikeres volt. <0>Részletek megtekintése</0>.",
    registrationByAdminOnMLForSingles: "A(z) <0>{{ eventName }}</0> főtáblájára történő regisztrációd sikeres volt. <0>Részletek megtekintése</0>.",
    promotionToMLForDoubles: "Visszalépések miatt a(z) {{eventName}} eseményen a párosok versenyében csapatod feljutott a főtáblára. <0>Részletek megtekintése</0>.",
    promotionToMLForSingles: "Visszalépések miatt a(z) {{eventName}} eseményen az egyéni versenyben feljutottál a főtáblára. <0>Részletek megtekintése</0>.",
    eventRegistrationSLOpen: "A(z) <0>{{ eventName }}</0> Rendezőlistájára való regisztráció megnyílt. <0>Csatlakozz most</0>.",
    eventRegistrationSLClosed: "A(z) <0>{{ eventName }}</0> Rendezőlistájára való regisztráció lezárult. A Főlista és a Várólista ranglistahelyezések alapján történő rendezése folyamatban van. <0>Részletek megtekintése</0>.",
    eventRegistrationMLOpen: "A(z) <0>{{ eventName }}</0> Főlistájára való regisztráció megnyílt. <0>Csatlakozz most</0>.",
    eventRegistrationMLClosed: "A(z) <0>{{ eventName }}</0> Főlistájára való regisztráció lezárult. A Várólistára való regisztráció a verseny kezdete előtti napig még tart. <0>Részletek megtekintése</0>.",
    eventMainDrawPublished: "A(z) <0>{{ eventName }}</0> Főlistájára való regisztráció lezárult. A Várólistára való regisztráció a verseny kezdete előtti napig még tart. <0>Részletek megtekintése</0>.",
    eventStarted: "A(z) <0>{{ eventName }}</0> mérkőzései ma kezdődnek. <0>Részletek megtekintése</0>.",
    eventEnded: "A(z) <0>{{ eventName }}</0> mérkőzései véget értek. Az eredmények és a nyertesek frissítése folyamatban van. <0>Részletek megtekintése</0>.",
    eventClosed: "A(z) <0>{{ eventName }}</0> eredményei frissítésre kerültek. <0>Részletek megtekintése</0>.",
    eventAnnouncementUpdates: "<0><strong>{{ eventName }}</strong></0> torna frissítése: \n\n {{ announcement }}",
    friendRequestReceived: "{{ partnerName }} baráti kérést küldött neked. <0>Részletek megtekintése</0>.",
    friendRequestAccepted: "Új barátod van a Sportya - {{ partnerName }}. <0>Profil megtekintése</0>.",
    friendJoined: "{{ partnerName }} az Ön meghívását követően csatlakozott a Sportya. <0>Profil megtekintése</0>.",
    friendRaisedHand: "{{ partnerName }} barátságos meccset keres a Sportya. <0>Meghívó a mérkőzésre</0>.",
    invitationReceived: "{{ partnerName }} meghívott benneteket egy {{ matchGameType }} meccsre - <strong>{{ matchFormatedId }}</strong>. <0>Lásd a meghívót</0>.",
    pastMatchInvite: "Erősítse meg részvételét, és a(z) {{ matchGameType }} játékot érje el {{ partnerName }} ellen - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    matchAccepted: "{{ partnerName }} elfogadta a meghívásodat egy {{ matchGameType }} játékra - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    matchDeclined: "{{ partnerName }} visszautasította a meghívást a {{ matchGameType }} mérkőzésre - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    matchDenied: "{{ partnerName }} visszautasította a részvételt a {{ matchGameType }} meccseden - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    playerWithdrew: "{{ partnerName }} visszalépett a/az {{ matchGameType }} mérkőzésedből - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    addScoreReminder: "Add hozzá a mérkőzésed eredményét {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Adja össze a pontszámot</0>.",
    upcomingMatchReminder: "A {{ matchGameType }} meccsed 30 perc múlva kezdődik - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    pastMatchConfirmed: "{{ partnerName }} megerősítette a {{ matchGameType }} meccset és az eredményt - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    scoreAdded: "{{ partnerName }} hozzáadta az eredményt a te {{ matchGameType }}  mérkőzésedhez - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    scoreChanged: "{{ partnerName }} megváltoztatta az eredményedet a {{ matchGameType }} mérkőzésen - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    scoreValidated: "A mérkőzés végeredménye {{ matchGameType }} érvényesítésre került - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    canceledMatch: "Az {{ matchGameType }} meccsed törölve lett - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    updatedMatch: "A {{ matchGameType }} meccsed frissítve - <strong>{{ matchFormatedId }}</strong>. <0>Mérkőzés megtekintése</0>.",
    playerRatingReceived: "Új értékelést kapott egy barátságos mérkőzésről. <0>Lásd értékelési átlag</0>.",
    newRewardReceived: "Gratulálunk! Új jutalmat kapott. <0>Ellenőrizze a Virtuális Pénztárcát</0>",
    bookingCancellation: "A <strong>{{ clubName }}</strong>-ben foglalt foglalását a klub törölte - <strong>{{ bookingId }}</strong>. <0>Foglalás Részleteinek Megtekintése</0>.",
    bookingRescheduled: "Foglalását a <strong>{{ clubName }}</strong>-nél a klub átütemezte - <strong>{{ bookingId }}</strong>. <0>Foglalás Részleteinek Megtekintése</0>.",
  },
  creditCard: 'Hitelkártya',
  virtualWallet: 'Virtuális pénztárca',
  summerMembership: 'Nyári fiók',
  premiumMembership: 'Prémium fiók',
  transactionTypes: {
    topUp: 'Virtuális pénztárca: Feltöltés',
    topUpByAdmin: 'Virtuális pénztárca: Feltöltés (rendszergazda által)',
    subtractByAdmin: 'Virtuális pénztárca: Levonás (rendszergazda által)',
    eventRegistration: 'Eseményre való regisztráció: <0>{{ eventName }}</0>',
    eventWithdrawal: 'Eseményről való visszalépés: <0>{{ eventName }}</0>',
    upgradeMembership: 'Tagság felminősítése:: <1>{{ membershipName }}</1>',
    upgradeMembershipByAdmin: 'Tagság felminősítése (rendszergazda által): <1>{{ membershipName }}</1>',
    balanceAdjustmentByAdmin: 'Egyenleg módosítása (rendszergazda által)',
    waitingListRefund: 'Várólista automatikus visszatérítése',
    currencyNotSet: 'A Feltöltés funkció használatához be kell állítanod a tartózkodási helyedet.',
    date: '<0>Dátum:</0> {{ createdAt }}',
    firstTaskCompletion: "Jutalom: Saját cselekvés",
    referralReward: "Jutalom: Vendégjátékos akció",
  },
  notificationButton: 'Értesítés gomb',
  pictureMaxSize: 'A profilképnek 1 MB-nál kisebbnek kell lennie.',
  premium: 'Prémium {{period}} fiók',
  undefinedMembership: 'Prémium',
  latestTransactions: 'Legújabb tranzakciók',
  partnerEventRegistration: 'Partner – Regisztráció az eseményre: <1>{{ eventName }}</1>',
  planUpdate: 'Tagság bővítése: <1>{{ membershipName }}</1>',
  referral: {
    title: "Hívd meg barátaidat & Szerezz jutalmakat",
    title2: "Hozzon létre fiókot, és szerezzen jutalmakat!",
    subtitle: "Hívd meg barátaidat a Sportya, és nyerj jutalmakat az első {{ maxRewards }} játékosok* után, akik meghívókódoddal csatlakoznak a Sportya! Egyenként <strong>{{ amount }} {{ currency }}</strong> összeget kapsz virtuális pénztárcákban**, amint <strong>az első alkalommal meghívott játékos sikeresen végrehajtja az alábbi műveletek valamelyikét</strong>:",
    subtitle2: "Szerezzen jutalmat, amikor először sikeresen végrehajtja az alábbi műveletek EGYIKÉT:",
    item1: "Az első érvényesített eredmény megszerzése egy Sportya versenyen",
    item11: "Az első érvényesített eredmény megszerzése egy Sportya versenyen",
    item2: "A Virtuális Pénztárcájukat Finanszírozzák",
    item22: "A Virtuális Pénztárcájukat Finanszírozzák",
    item3: "Prémium számla vásárlása Virtuális Pénztárcával vagy bankkártyával",
    item4: "Első részvétel egy barátságos mérkőzésen, megerősített pontszámmal",
    amountEarned: "{{ amount }} {{ currency }} jutalom",
    rewardsCompleted: "A jutalmak teljesítve {{ receivedRewards }}/{{ maxRewards }}",
    yourFriend: "Meghív",
    referVia: "Megosztani",
    shareTitle: "Megosztani",
    smallText1: "*A meghívó játékos csak a maximális {{ maxRewards }} erejéig kap jutalmat, aki az ajánlókóddal csatlakozik a Sportyához, és első alkalommal sikeresen végrehajtja a szükséges műveletek valamelyikét.",
    smallText2: "**A jutalmazott játékos Virtuális Pénztárcájába automatikusan jóváírunk 5 eurót vagy ennek megfelelő összeget a virtuális pénztárca pénznemében, amint valamelyik jogosult művelet sikeresen befejeződik. Minden felhasználó csak egyszer kapja meg a jutalmat, csak egy jogosult műveletért.",
    smallText3: "*A jutalom értékének megfelelő összeget a Sportya Virtuális Pénztárca pénznemében írjuk jóvá egyenlegén.",
    smallText4: "**A támogatható tevékenységek közül csak egy kap jutalmat az első alkalommal.",
    smallText5: "Kérjük, tekintse meg az <1>Felhasználási feltételeket</1>, ha többet szeretne megtudni a Sportya Rewards programról",
    inviteFriends: "Hívd meg barátaidat & Szerezz jutalmakat",
    inviteFriendsDescription: "Nyerj <strong>{{ amount }} {{ currency }}</strong> az első {{ maxRewards }} játékosok után, akik meghívókódoddal csatlakoznak a Sportya!",
    inviteYourFriends: "Hívd meg a barátaidat",
    invite: {
      playTogether: 'Hívd meg barátaidat és játssz együtt',
      friends: 'Hívd meg a barátaidat',
    },
    shareToMedia: {
      email: {
        title: 'Teniszezzünk a Sportya',
        body: `Szia! %0D%0A
A Sportyát, az amatőr teniszezők versenysport-platformját használom, és meghívlak, hogy csatlakozz! %0D%0A
A Sportyában találkozhatsz más játékosokkal, játszhatsz együtt barátságos mérkőzéseket, vagy versenyeken vehetsz részt és versenyezhetsz a hazai és nemzetközi ranglista első helyeiért! %0D%0A
Ez egy nagyszerű módja annak, hogy aktívak maradjanak és együtt szórakozzanak. %0D%0A
%0D%0A
Csatlakozz most a Sportya az egyedi meghívólink segítségével, és szerezz jutalmakat! %0D%0A
{{inviteLink}}`,
      },
      facebook: `Join me on Sportya, the competitive sports platform for amateur tennis players!\r\n
We can connect with other players, play friendly matches together, or join tournaments and compete for the top spots in national and international rankings!\r\n
Use my unique invitation link: {{inviteLink}}`,
      twitter: 'Csatlakozz hozzám a Sportyában, az amatőr teniszezők versenysport platformjában!%0aHozzon létre egy fiókot, hogy találkozzon más játékosokkal barátságos mérkőzéseken, nemzeti és nemzetközi versenyeken!%0a',
    },
    invitedBy: '<1>{{referee}}</1> meghívta Önt.',
    joinSportya: 'Hozzon létre fiókot, és szerezzen jutalmakat! <1>Tudj meg többet</1>.',
    share: 'Oszd meg szeretetedet a tenisz iránt! Hívd meg barátaidat a Sportya, és játssz együtt barátságos mérkőzéseket, vagy versenyezzenek a nemzeti és nemzetközi ranglista első helyezéseiért!',
    copyAndShare: 'Másolja ki és ossza meg a meghívó linkjét',
    willBeFriend: 'Azok a barátok, akiket meghívsz és regisztrálsz a Sportya szolgáltatásra, automatikusan felkerülnek a barátlistádra.',
    copy: 'Másolás',
    dashboardBannerTitle: "Egy lépéssel közelebb kerültél a jutalomhoz",
    dashboardBannerSubtitle: "<1>{{ amount }} {{ currency }}</1> ap, amikor először sikeresen végrehajtja az alábbi műveletek EGYIKÉT:",
    congrats: 'Gratulálunk! <1>{{ amount }} {{ currency }}</1> összeget kapott <3>Virtuális Pénztárcájába</3>, amiért sikeresen végrehajtotta első akcióját a Sportyán.',
    findOutMore: 'Tudjon meg többet a <1>Sportya Rewards</1> programról, vagy tekintse meg a <3>Felhasználási feltételeket</3>',
  },
  ratePlayer: 'Értékeld a játékost',
  changeRating: 'Módosítsa az értékelést',
  receivedRating: '{{ total }} jegyzet érkezett',
  receivedRatings: '{{ total }} jegyzet érkezett',
  ratePlayerInfo: 'Csak akkor értékelhet más játékosokat, ha legalább egy barátságos mérkőzést játszott velük.<br/>Egy játékos átlagos értékelése a többi játékostól kapott összes értékelés alapján kerül kiszámításra,<br/>és nyilvánosan megjelenik a játékos profiljában, ha legalább 3 értékeléssel rendelkezik.',
  noRatings: 'Nincs értékelés',
  rate: 'Értékelés mentése',
  removeRating: 'Értékelés visszavonása',
  ownRatingInfo: 'Átlagos értékelése csak akkor lesz látható minden játékos számára, ha legalább 3 értékelést kapott azoktól a játékosoktól, akikkel kapcsolatba került.',
  skipRating: 'Később értékelem',
  howWouldRatePlayer: 'Értékeld a játékost’',
  howWouldRateTitlePartner: 'Értékelje a Partnert',
  howWouldRateTitleOpponent: 'Értékeld az Ellenfelet 1',
  howWouldRateTitleOpponentPartner: 'Értékeld az Ellenfelet 2',
  thankYouForRating: 'Köszönjük értékelését!',
};
