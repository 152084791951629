import Login from './pages/Auth/Login';
import Homepage from './pages/Homepage';
import ForClubs from './pages/ForClubs';
// import ForPlayers from './pages/ForPlayers';
// import ForPlayersLearnMore from './pages/ForPlayers/about';
import Leaderboards from './pages/Leaderboards';
import Friends from './pages/Friends';
import Register from './pages/Auth/Register';
import ForgotPassword from './pages/Auth/ForgotPassword';
import SetPassword from './pages/Auth/SetPassword';
import ActivateAccount from './pages/Auth/ActivateAccount';
import Dashboard from './pages/Dashboard';
import Events from './pages/Events';
import EventDetails from './pages/Events/EventDetails';
import CompetitionDetails from './pages/Events/CompetitionDetails';
import JoinCompetition from './pages/Events/JoinCompetition';
import PaymentConfirmation from './pages/PaymentConfirmation';
import Layout from './components/Layout';
import Welcome from './pages/Dashboard/Welcome';
import Logout from './pages/Auth/Logout';
import Plans from './pages/Plans';
import RulesRegulations from './pages/RulesRegulations';
import LocalLeague from './pages/RulesRegulations/localLeague';
import TermsConditions from './pages/RulesRegulations/termsConditions';
import Confidentiality from './pages/RulesRegulations/confidentiality';
import CookiePolicy from './pages/RulesRegulations/cookiePolicy';
import ReturnPolicy from './pages/RulesRegulations/returnPolicy';
import GameFormatInfo from './pages/RulesRegulations/gameFormatInfo';
import ComingSoon from './pages/ComingSoon';
import FindAPlayer from './pages/FindAPlayer';
import { ClubInfo, ClubsListing } from './pages/Clubs';
import Profile from './pages/Profile';
import NotFound from './pages/NotFound';
import StartingGuide from './pages/Guide';
// import Campaign from './pages/Campaigns';
import CampaignJoinSportya from './pages/Campaigns/join-sportya';
import CampaignGetPremium from './pages/Campaigns/get-premium';
import CampaignJoinSportyaBulgaria from './pages/Campaigns/join-sportya-bulgaria';
import CampaignGetPremiumBulgaria from './pages/Campaigns/get-premium-bulgaria';

import {
  MyProfile,
  MyEvents,
  VirtualWallet,
  MySettings,
  Notifications,
  MyOrders,
} from './pages/MyAccount';

import {
  SetProfileDetails,
  SetProfileVisibility,
  SetGameLevel,
  SetProfilePicture,
  SetAvailability,
} from './pages/MyAccount/CompleteProfile';

import Chat from './pages/Chat';
import Referral from './pages/Referral';
import MatchForm from './pages/FriendlyMatches/MatchForm';
import Matches from './pages/FriendlyMatches/Matches';
import FriendlyMatchesRequests from './pages/FriendlyMatches/Requests';
import FriendlyMatchesScores from './pages/FriendlyMatches/Scores';
import FriendlyMatchesStart from './pages/FriendlyMatches/StartMatch';
import RaiseHand from './pages/FriendlyMatches/RaiseHand';
import FriendlyMatches from './pages/FriendlyMatches';
import Maintenance from './components/Maintenance';
import Bookings from "./pages/MyAccount/Bookings";
import BookingInfo from "./pages/MyAccount/Bookings/BookingInfo";

const routes = [
  {
    route: '/find-player',
    name: 'Find Players',
    Component: FindAPlayer,
    isPrivate: true,
    Layout,
  },
  {
    route: '/plans',
    name: 'Plans',
    Component: Plans,
    isPrivate: false,
    Layout,
  },
  {
    route: '/payment/confirmation/:sessionId/:purchaseType/:paymentType',
    name: 'Payment confirmation',
    Component: PaymentConfirmation,
    isPrivate: true,
    Layout,
  },
  {
    route: '/events',
    name: 'Events',
    Component: Events,
    isPrivate: false,
    Layout,
  },
  {
    route: '/events/:eventId',
    name: 'Event details',
    Component: EventDetails,
    isPrivate: false,
    Layout,
  },
  {
    route: '/events/:eventId/competitions/:competitionId',
    name: 'Competition details',
    Component: CompetitionDetails,
    isPrivate: true,
    Layout,
  },
  {
    route: '/events/:eventId/competitions/:competitionId/join',
    name: 'Competition join',
    Component: JoinCompetition,
    isPrivate: true,
    Layout,
  },
  {
    route: '/login',
    name: 'Login',
    Component: Login,
    isPrivate: false,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/login/:redirect',
    name: 'Login',
    Component: Login,
    isPrivate: false,
    Layout,
    Footer: false,
  },
  {
    route: '/logout',
    name: 'Logout',
    Component: Logout,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
    allowPendingAccount: true,
  },
  {
    route: '/register',
    name: 'Register',
    Component: Register,
    isPrivate: false,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/join/:referralCode',
    name: 'Register',
    Component: Register,
    isPrivate: false,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/invite-friends',
    name: 'Invite Friends',
    Component: Referral,
    isPrivate: true,
    Layout,
  },
  {
    route: '/forgot-password',
    name: 'Forgot password',
    Component: ForgotPassword,
    isPrivate: false,
    Layout,
    Footer: false,
  },
  {
    route: '/accounts/:id/reset-password/:resetCode',
    name: 'Reset password',
    Component: SetPassword,
    isPrivate: false,
    Layout,
    Footer: false,
    allowPendingAccount: true,
  },
  {
    route: '/accounts/:id/set-password/:resetCode',
    name: 'Set password',
    Component: SetPassword,
    isPrivate: false,
    Layout,
    Footer: false,
    allowPendingAccount: true,
  },
  {
    route: '/accounts/:id/activate/:activationCode',
    name: 'Activate account',
    Component: ActivateAccount,
    isPrivate: false,
    Layout,
    Footer: false,
    Header: false,
    allowPendingAccount: true,
  },
  {
    route: '/dashboard/welcome',
    name: 'Welcome',
    Component: Welcome,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/dashboard',
    name: 'Dashboard',
    Component: Dashboard,
    isPrivate: true,
    Layout,
    allowPendingAccount: true,
  },
  {
    route: '/my-account/profile-details',
    name: 'Set profile details',
    Component: SetProfileDetails,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/my-account/profile-visibility',
    name: 'Set profile visibility',
    Component: SetProfileVisibility,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/my-account/game-level',
    name: 'Set profile details',
    Component: SetGameLevel,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/my-account/profile-picture',
    name: 'Set profile picture',
    Component: SetProfilePicture,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/my-account/profile-picture/:editCurrentPhoto',
    name: 'Set profile picture',
    Component: SetProfilePicture,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/my-account/availability',
    name: 'Set profile details',
    Component: SetAvailability,
    isPrivate: true,
    Layout,
    Header: false,
    Footer: false,
  },
  {
    route: '/my-account/events',
    name: 'My events',
    Component: MyEvents,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account/wallet',
    name: 'My virtual wallet',
    Component: VirtualWallet,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account/orders',
    name: 'My orders',
    Component: MyOrders,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account/settings',
    name: 'My settings',
    Component: MySettings,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account/settings/:openTab',
    name: 'My settings',
    Component: MySettings,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account/notifications',
    name: 'Notifications',
    Component: Notifications,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account/bookings',
    name: 'Bookings',
    Component: Bookings,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account/bookings/:id',
    name: 'Bookings',
    Component: BookingInfo,
    isPrivate: true,
    Layout,
  },
  {
    route: '/my-account',
    name: 'My account',
    Component: MyProfile,
    isPrivate: true,
    Layout,
  },
  {
    route: '/accounts/:id',
    name: 'Account Profile',
    Component: Profile,
    isPrivate: true,
    Layout,
  },
  {
    route: '/clubs/:id',
    name: 'Club Info',
    Component: ClubInfo,
    isPrivate: false,
    Layout,
  },
  {
    route: '/clubs',
    name: 'Clubs',
    Component: ClubsListing,
    Layout,
  },
  {
    route: '/for-clubs',
    name: 'For Clubs',
    Component: ForClubs,
    isPrivate: false,
    Layout,
  },
  // {
  //   route: '/for-players',
  //   name: 'For players',
  //   Component: ForPlayers,
  //   isPrivate: false,
  //   Layout,
  //   allowPendingAccount: true,
  // },
  // {
  //   route: '/for-players/learn-more',
  //   name: 'ForPlayersLearnMore',
  //   Component: ForPlayersLearnMore,
  //   isPrivate: false,
  //   Layout,
  //   allowPendingAccount: true,
  // },
  {
    route: '/rules-regulations',
    name: 'RulesRegulations',
    Component: RulesRegulations,
    isPrivate: false,
    Layout,
  },
  {
    route: '/local-leagues-rules',
    name: 'LocalLeague',
    Component: LocalLeague,
    isPrivate: false,
    Layout,
  },
  {
    route: '/terms-conditions',
    name: 'TermsConditions',
    Component: TermsConditions,
    isPrivate: false,
    Layout,
  },
  {
    route: '/confidentiality',
    name: 'Confidentiality',
    Component: Confidentiality,
    isPrivate: false,
    Layout,
  },
  {
    route: '/cookie-policy',
    name: 'CookiePolicy',
    Component: CookiePolicy,
    isPrivate: false,
    Layout,
  },
  {
    route: '/return-policy',
    name: 'ReturnPolicy',
    Component: ReturnPolicy,
    isPrivate: false,
    Layout,
  },
  {
    route: '/groups-playing-formats',
    name: 'GameFormatInfo',
    Component: GameFormatInfo,
    isPrivate: false,
    Layout,
  },
  {
    route: '/coming-soon',
    name: 'ComingSoon',
    Component: ComingSoon,
    isPrivate: false,
    Layout,
  },
  {
    route: '/help',
    name: 'ComingSoon',
    Component: ComingSoon,
    isPrivate: false,
    Layout,
  },
  {
    route: '/leaderboards',
    name: 'Leaderboards',
    Component: Leaderboards,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friends',
    name: 'Friends',
    Component: Friends,
    isPrivate: true,
    Layout,
  },
  {
    route: '/',
    name: 'Homepage',
    Component: Homepage,
    isPrivate: false,
    Layout,
  },
  {
    route: '/for-players',
    name: 'Starting Guide',
    Component: StartingGuide,
    isPrivate: false,
    Layout,
  },
  {
    route: '/campaign/join-sportya',
    name: 'Campaign',
    Component: CampaignJoinSportya,
    Header: false,
    Footer: false,
    isPrivate: false,
    Layout,
  },
  {
    route: '/campaign/get-premium',
    name: 'Campaign',
    Component: CampaignGetPremium,
    Header: false,
    Footer: false,
    isPrivate: false,
    Layout,
  },
  {
    route: '/campaign/join-sportya-bulgaria',
    name: 'Campaign',
    Component: CampaignJoinSportyaBulgaria,
    Header: false,
    Footer: false,
    isPrivate: false,
    Layout,
  },
  {
    route: '/campaign/get-premium-bulgaria',
    name: 'Campaign',
    Component: CampaignGetPremiumBulgaria,
    Header: false,
    Footer: false,
    isPrivate: false,
    Layout,
  },
  {
    route: '/conversations/:conversationId?',
    name: 'Conversations',
    Component: Chat,
    isPrivate: true,
    Layout,
  },
  // Match Form invitations - START
  {
    route: '/friendly-matches/create',
    name: 'FriendlyMatchesCreate',
    customProps: { create: true },
    Component: MatchForm,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/create/played',
    name: 'FriendlyMatchesCreate',
    customProps: { create: true, played: true },
    Component: MatchForm,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/create/invite',
    name: 'FriendlyMatchesRematch',
    customProps: { invite: true },
    Component: MatchForm,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/edit',
    name: 'FriendlyMatchesEdit',
    customProps: { edit: true },
    Component: MatchForm,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/edit/played',
    name: 'FriendlyMatchesEdit',
    customProps: { edit: true, played: true },
    Component: MatchForm,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/rematch',
    name: 'FriendlyMatchesRematch',
    customProps: { rematch: true },
    Component: MatchForm,
    isPrivate: true,
    Layout,
  },
  // Match Form invitations - END
  {
    route: '/friendly-matches/available',
    name: 'FriendlyMatchesAvailable',
    Component: Matches,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/requests',
    name: 'FriendlyMatchesRequests',
    Component: FriendlyMatchesRequests,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/scores',
    name: 'FriendlyMatchesScores',
    Component: FriendlyMatchesScores,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/start',
    name: 'FriendlyMatchesStart',
    Component: FriendlyMatchesStart,
    isPrivate: true,
    Layout,
  },
  {
    route: '/friendly-matches/raise-hand',
    name: 'FriendlyMatchesRaiseHand',
    Component: RaiseHand,
    isPrivate: true,
    Header: false,
    Footer: false,
    Layout,
  },
  {
    route: '/friendly-matches',
    name: 'FriendlyMatches',
    Component: FriendlyMatches,
    Layout,
  },
  {
    route: '/under-maintenance',
    name: 'Maintenance',
    Component: Maintenance,
    Header: false,
    Footer: false,
    isPrivate: false,
    Layout,
  },
  {
    route: '*',
    name: 'NotFound',
    Header: false,
    Footer: false,
    Component: NotFound,
    isPrivate: false,
    Layout,
  },
];

export default routes;
