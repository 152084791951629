import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Contain, P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import { Avatar, ID, Initials } from '../../../../FindAPlayer/components/PlayerCard/styles';
import LinkWrapper from '../../../../../components/Link';
import { FriendIcon } from '../../../StartMatch/components/WantToPlay/styles';

const Player = ({ player, isDoubles, currentPlayer }) => {
  const { t: eventsT } = useTranslation('events');
  const { t: conversationsT } = useTranslation('conversations');

  if (!player) return '';
  const level = (isDoubles ? player?.gameLevelDouble : player?.gameLevelSingle) ?? player.level;

  return (
    <Contain direction="row" margin="0 0 19px 0" minWidth={300}>
      <Contain direction="column">
        <LinkWrapper to={`/accounts/${player.id}`} className="text-decoration-none">
          {player?.profilePicture
            ? (
              <Avatar
                small
                src={LoadImage(`accounts/${player.userId}/${player.profilePicture}`, true)}
                alt={`${player.firstName.charAt(0)} ${player.lastName.charAt(0)}`}
              />
            )
            : (
              <Initials small>
                <P bold>
                  {player?.firstName && player?.lastName ? player.firstName.charAt(0) + player.lastName.charAt(0) : ''}
                </P>
              </Initials>
            )}
        </LinkWrapper>
      </Contain>

      <Contain direction="column">
        <LinkWrapper to={`/accounts/${player.id}`} className="text-decoration-none">
          <P small bold margin="0 0 0 8px" className="d-flex">
            {player?.isFriend && (
              <FriendIcon src={LoadImage('friendly-matches/start-match/friends.svg')} alt="One of your friends" />
            )}
            {currentPlayer ? conversationsT('you') : `${player?.firstName} ${player?.lastName}`}
            <ID>
              #
              {player.userId}
            </ID>
          </P>
        </LinkWrapper>
        <P xSmall margin="0 0 0 8px">
          {isDoubles ? eventsT('doubles') : eventsT('singles')}: {level}
          <span className={`player-match-status player-match-status__${player?.invitation}`}>
            <img src={LoadImage(`friendly-matches/acceptance/${player?.invitation}-icon.svg`)} alt="" className="ml5" />
            <Trans ns="friendlyMatches" i18nKey={player?.invitation}>{player?.invitation}</Trans>
          </span>
        </P>
      </Contain>
    </Contain>
  );
};

export default Player;
