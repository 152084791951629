import React, { useState, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';

import Checkbox from '../../../components/Form/VanillaCheckbox';
import { Button } from '../../../components/Collection';
import Radio from '../../../components/Form/VanillaRadio';

import { put } from '../../../components/common/http';
import mixpanel from '../../../mixpanel';

import { isHandRaised } from '../../../components/Layout/components/Header/components/RaiseHandUtils';

import {
  DropdownHeader,
  InlineContainer,
  RaiseHandContainer,
  RaiseHandDescription,
  RaiseHandLabel,
  RaiseHandTitle,
  TableColumn,
  LowerHandButton,
} from '../../../components/Layout/components/Header/components/RaiseHandStyles';

const RaiseHandForm = ({ account, setVisible }) => {
  const { t: eventsT } = useTranslation('events');
  const { t } = useTranslation('friendlyMatches');

  const [handRaised, setHandRaised] = useState(isHandRaised(account?.handRaised?.timeframe?.expireAt));
  const [handRaisedInfo, setHandRaisedInfo] = useState({
    matchType: 'singles',
    timeframe: {
      name: 'next3Days',
      startAt: null,
      expireAt: null,
    },
    opponentLevel: 'aboveLevel',
  });
  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!isEmpty(account?.handRaised) && isHandRaised(account?.handRaised?.timeframe?.expireAt)) {
      setHandRaisedInfo(account?.handRaised);
    }
  }, [account?.handRaised]);

  const handleMatchType = (e) => {
    const matchType = (
      e?.target?.value !== handRaisedInfo?.matchType && handRaisedInfo?.matchType !== 'both') ? 'both' : (
        e?.target?.value === 'singles' && e?.target?.checked ? 'singles' : (
          e?.target?.value === 'singles' && !e?.target?.checked ? 'doubles' : 'singles'
        )
      );
    setHandRaisedInfo({
      ...handRaisedInfo,
      matchType,
    });
  };

  const raiseHandAction = async (raise = true) => {
    const reqPayload = {
      matchType: handRaisedInfo?.matchType,
      opponentLevel: handRaisedInfo.opponentLevel,
      timeframe: raise ? handRaisedInfo.timeframe.name : null,
    };

    await put('/accounts/raise-hand', reqPayload);

    mixpanel.track(raise ? (handRaised ? 'Update RAISE HAND' : 'Complete RAISE HAND') : 'LOWER HAND', {
      sp_game_type: handRaisedInfo?.matchType,
      sp_timeframe: handRaisedInfo.timeframe.name,
      sp_my_level_single: account?.gameLevelSingle,
      sp_my_level_double: account?.gameLevelDouble,
      sp_preferred_opponent: handRaisedInfo.opponentLevel,
    });

    setHandRaised(raise);
    setVisible();
  };

  return (
    <>
      <DropdownHeader>
        <RaiseHandTitle>
          <Trans ns="friendlyMatches" i18nKey="raiseHand.title">Raise Hand for a Friendly Match!</Trans>
        </RaiseHandTitle>

        <RaiseHandDescription>
          <Trans ns="friendlyMatches" i18nKey="raiseHand.description">
            Show other players that you’re eager to play immediately.
          </Trans>
        </RaiseHandDescription>
      </DropdownHeader>

      <RaiseHandContainer>
        <RaiseHandLabel>
          <Trans ns="friendlyMatches" i18nKey="raiseHand.gameTypeLabel">Play a friendly match for</Trans>
        </RaiseHandLabel>

        <InlineContainer addBottomBorder>
          <TableColumn>
            <Checkbox
              label={eventsT('singles')}
              name="matchType"
              value="singles"
              checked={['singles', 'both'].includes(handRaisedInfo.matchType)}
              onChange={handleMatchType}
            />
          </TableColumn>

          <TableColumn>
            <Checkbox
              label={eventsT('doubles')}
              name="matchType"
              value="doubles"
              checked={['doubles', 'both'].includes(handRaisedInfo.matchType)}
              onChange={handleMatchType}
            />
          </TableColumn>

        </InlineContainer>

        <RaiseHandLabel>
          <Trans ns="friendlyMatches" i18nKey="raiseHand.within">Within</Trans>
        </RaiseHandLabel>

        <InlineContainer addBottomBorder>
          <Radio
            label={t('raiseHand.next3Days')}
            value="next3Days"
            checked={['next3Days'].includes(handRaisedInfo.timeframe.name)}
            onChange={() => {
              setHandRaisedInfo({
                ...handRaisedInfo,
                timeframe: {
                  startAt: handRaisedInfo.startAt,
                  expireAt: handRaisedInfo.expireAt,
                  name: 'next3Days',
                },
              });
            }}
          />

          <Radio
            label={t('raiseHand.next7Days')}
            value="next7Days"
            checked={['next7Days'].includes(handRaisedInfo.timeframe.name)}
            onChange={() => {
              setHandRaisedInfo({
                ...handRaisedInfo,
                timeframe: {
                  startAt: handRaisedInfo.startAt,
                  expireAt: handRaisedInfo.expireAt,
                  name: 'next7Days',
                },
              });
            }}
          />

          <Radio
            label={t('raiseHand.thisWeekend')}
            value="thisWeekend"
            checked={['thisWeekend'].includes(handRaisedInfo.timeframe.name)}
            onChange={() => {
              setHandRaisedInfo({
                ...handRaisedInfo,
                timeframe: {
                  startAt: handRaisedInfo.startAt,
                  expireAt: handRaisedInfo.expireAt,
                  name: 'thisWeekend',
                },
              });
            }}
          />
        </InlineContainer>

        <RaiseHandLabel>
          <Trans ns="friendlyMatches" i18nKey="raiseHand.withPlayers">With Players</Trans>
        </RaiseHandLabel>

        <InlineContainer addBottomBorder>
          <TableColumn>
            <Radio
              label={t('raiseHand.aboveMyLevel')}
              value="aboveLevel"
              checked={['aboveLevel'].includes(handRaisedInfo.opponentLevel)}
              onChange={() => {
                setHandRaisedInfo({
                  ...handRaisedInfo,
                  opponentLevel: 'aboveLevel',
                });
              }}
            />
          </TableColumn>

          <TableColumn>
            <Radio
              label={t('raiseHand.myLevel')}
              value="sameLevel"
              checked={['sameLevel'].includes(handRaisedInfo.opponentLevel)}
              onChange={() => {
                setHandRaisedInfo({
                  ...handRaisedInfo,
                  opponentLevel: 'sameLevel',
                });
              }}
            />
          </TableColumn>
        </InlineContainer>
        <Button
          margin="30px 30px 20px 30px"
          onClick={() => { setDisabled(true); raiseHandAction(); }}
          minHeight="40px"
          disabled={disabled}
        >
          {handRaised && (
            <Trans ns="events" i18nKey="apply">Apply</Trans>
          )}
          {!handRaised && (
            <Trans ns="friendlyMatches" i18nKey="raiseHand.text">Raise Hand</Trans>
          )}
        </Button>

        {handRaised && (
          <LowerHandButton
            margin="0 30px 30px"
            onClick={() => { setDisabled(true); raiseHandAction(false); }}
            outline
            minHeight="40px"
            disabled={disabled}
          >
            <Trans ns="friendlyMatches" i18nKey="raiseHand.lower">Lower Hand</Trans>
          </LowerHandButton>
        )}
      </RaiseHandContainer>
    </>
  );
};

export default RaiseHandForm;
