import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { Formik, Form, Field } from 'formik';
import { Trans, useTranslation } from 'react-i18next';
import { Paragraph } from '../../../../components';
import Checkmark from '../../../../assets/images/checkmark-white.svg';

import Button from '../../../../components/Form/Button';
import Select from '../../../../components/Form/Select';
import { updateAccount } from './actions';

import { Container, Group, CustomRadio } from './styles';
import { Badge } from '../../../../components/LevelsBadges/styles';
import { H3 } from '../../../../components/Collection';

const ProfileDetails = ({ accountInfo }) => {
  const { t } = useTranslation('settings');
  const { t: commonT } = useTranslation('common');
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const tShirtSizes = [
    { value: 'NONE', label: t('profileSettings.profileDetails.shirtNone') },
    { value: 'XS', label: 'XS' },
    { value: 'S', label: 'S' },
    { value: 'M', label: 'M' },
    { value: 'L', label: 'L' },
    { value: 'XL', label: 'XL' },
    { value: 'XXL', label: 'XXL' },
  ];

  return (
    <Container>
      <H3 margin="0 0 20px 0">
        <Trans ns="settings" i18nKey="profileSettings.profileDetails.title">
          Profile Details
        </Trans>
      </H3>
      <Group>
        {accountInfo?.gameLevelSingle && accountInfo?.gameLevelDouble ? (
          <>
            <div className="wrap-content">
              <div>
                <Paragraph small bold>
                  {t('profileSettings.profileDetails.singleGameLevel')}
                </Paragraph>
                <Badge large>
                  <H3>{accountInfo?.gameLevelSingle}</H3>
                </Badge>
              </div>

              <div>
                <Paragraph small bold>
                  {t('profileSettings.profileDetails.doublesGameLevel')}
                </Paragraph>
                <Badge large double>
                  <H3>{accountInfo?.gameLevelDouble}</H3>
                </Badge>
              </div>
            </div>
            <Paragraph small>
              <Trans ns="settings" i18nKey="profileSettings.profileDetails.needOfChange">
                If you need to change the level please get in touch at
                <a href="mailto:contact@sportya.net">contact@sportya.net</a>
                .
              </Trans>
            </Paragraph>
          </>
        ) : (
          <>
            <Paragraph>You haven&apos;t set your game level yet</Paragraph>
            <Link to="/my-account/game-level">
              <Button outline>Set Your Game Level</Button>
            </Link>
          </>
        )}
      </Group>

      <Formik
        initialValues={{
          dominantHand: accountInfo?.dominantHand,
          tShirtSize: accountInfo?.tShirtSize?.toUpperCase() ?? 'NONE',
        }}
        enableReinitialize
        validationOnChange
        onSubmit={async (values, { setSubmitting }) => {
          const payload = {
            dominantHand: values.dominantHand,
            tShirtSize: values.tShirtSize.value,
          };

          await updateAccount({
            id: accountInfo.id,
            payload,
            addToast,
            setSubmitting,
            dispatch,
            t: commonT,
          });
        }}
      >
        {({
          errors,
          touched,
          isSubmitting,
          dirty,
          isValid,
          values,
          setFieldValue,
          setFieldTouched,
        }) => (
          <Form>
            <Group>
              <Paragraph>
                {t('profileSettings.profileDetails.dominantHand')}
              </Paragraph>
              <CustomRadio role="group" aria-labelledby="dominantHand">

                <Field
                  type="radio"
                  id="leftHand"
                  name="dominantHand"
                  value="left"
                  {...(values.dominantHand === 'left' && { checked: true })}
                />

                <label htmlFor="leftHand">
                  <img src={Checkmark} alt="" />
                  {t('profileSettings.profileDetails.left')}
                </label>

                <Field
                  type="radio"
                  id="rightHand"
                  name="dominantHand"
                  value="right"
                  {...(values.dominantHand === 'right' && { checked: true })}
                />

                <label htmlFor="rightHand">
                  <img src={Checkmark} alt="" />
                  {t('profileSettings.profileDetails.right')}
                </label>
              </CustomRadio>
            </Group>

            <Select
              name="tShirtSize"
              placeholder={t('profileSettings.profileDetails.shirtSize')}
              labelOn
              defaultValue={tShirtSizes.filter(obj => obj.value === values.tShirtSize)}
              lowInput
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              options={tShirtSizes}
              label={t('profileSettings.profileDetails.shirtSize')}
              {...{ errors, touched }}
            />

            <Button type="submit" disabled={isSubmitting || !(isValid && dirty)}>{commonT('saveChanges')}</Button>
          </Form>
        )}
      </Formik>
    </Container>
  );
};

export default ProfileDetails;
