export default {
  goodMorning: 'Jó reggelt!',
  goodAfternoon: 'Jó napot!',
  goodEvening: 'Jó estét!',
  neutralGreeting: 'Szia!',
  todayWeather: 'Ma {{ weatherType }} az idő, {{ degrees }}°C van {{ location }-ban/ben.',
  unavailableWeather: 'Az időjárás nem elérhető',
  verifyYourEmailAddress: '<0>Kérjük, ellenőrizd az e-mailjeidet.</0> Platformunk használata előtt meg kell erősítened e-mail címedet.',
  profileCompleted: '<0>Profilod elkészült.</0> Élvezd a teljeskörű <2>{{ siteName }}</2> élményt!',
  passwordResetSuccessfully: 'Kérjük, ellenőrizze a beérkezett üzeneteket a jelszó-visszaállítási utasításokért.',
  confirmEmailTitle: 'Kérjük, ellenőrizd az e-mailjeidet. <1></1> A(z) <3>{{ process.env.REACT_APP_SITE_NAME }}</3> használata előtt meg kell erősítenünk az e-mail címedet.',
  confirmEmailSubtitle: 'Nem kaptad meg az e-mailt? Az e-mail cím megerősítésének befejezéséhez küldd el újra az ellenőrző e-mailt.',
  sendItAgain: 'Küldd el újra',
  ACTIVATION_EMAIL_SENT_SUCCESSFULLY: 'Aktiváló e-mail sikeresen elküldve.',
  ACCOUNT_DOES_NOT_EXIST: 'A fiók nem létezik.',
  ACTIVATION_EMAIL_WAS_NOT_SENT: 'Aktiváló e-mail nem lett elküldve.',
  completeProfile: {
    title: 'Töltd ki a profilodat, és élvezd a teljeskörű {{ siteName }} élményt!',
    profileDetails: 'Profil részletei',
    setProfileDetails: 'Részletek beállítása',
    profileVisibility: 'Profil láthatósága',
    setProfileVisibility: 'Láthatóság beállítása',
    gameLevel: 'Játékszint',
    setGameLevel: 'Válaszd ki a játékszintedet',
    profilePicture: 'Profilkép',
    setProfilePicture: 'Profilkép feltöltése',
    availability: 'Rendelkezésre állás',
    setAvailability: 'Rendelkezésre állás beállítása',
    skip: 'Kihagyás',
  },
  welcome: {
    hello: 'Szia {{username}}',
    welcomeToPlatform: 'Üdvözlünk a {{site}} weboldalon!',
    welcomeText: 'Mielőtt elkezdenéd használni, segíts nekünk kitölteni profilodat a legjobb élmény elérése érdekében. Csak pár percig tart!',
    startBtn: 'Kezdjük',
    logout: 'Kijelentkezés',
  },
  choseLanguage: 'A folytatáshoz válaszd ki a kívánt nyelvet',
  upcomingCompetitions: 'Közelgő versenyek',
  seeMyEvents: 'Eseményeim megtekintése',
  findAnEvent: 'Esemény keresése',
  searchForEventsOrCompetitions: 'Keress versenyeket a környéken',
  gettingStarted: 'Kezdés',
  gettingStartedDescription: 'Kezdje el, és tegye meg az első lépéseket <1> {{process.env.REACT_APP_SITE_NAME}} </1>',
  letsStart: 'Kezdjük',
  websiteGuides: '<0>{{siteTitle}}</0> útmutatók',
  learnMore: 'Tudj meg többet',
  pendingUpdate: 'Függő frissítés',
  leaderboard: 'Ranglistahelyezés',
  updated: 'Frissítve: <1>dátum</1>',
  stats: 'Statisztikák',
  singlesPlayed: 'Lejátszott egyéni mérkőzések',
  doublesPlayed: 'Lejátszott páros mérkőzések',
  allTime: 'Bármikor',
  friendlyMatches: 'Barátságos mérkőzések',
  registrationDate: 'Regisztráció dátuma',
  pvpEloCalculator: 'Head2Head ELO kalkulátor',
  pvpEloCalculatorDescription: 'Adj meg 2 játékost és nézd meg, milyen esélyük van a mérkőzés megnyerésére, ha közvetlenül egymással játszanak.',
  calculate: 'Számítsd ki',
  player: 'Játékos',
  searchPlayer: 'Játékos keresése',
  pageTitle: 'Irányítópult',

  accountLeaderboard: {
    title: 'Ranglistahelyezés',
    updated: 'Frissítve',
    singles: 'Egyéni',
    doubles: 'Páros',
    position: 'Helyezés',
  },

  guides: {
    1: {
      title: 'Sportya - Szabályok és előírások',
      text: 'A hivatalos szabályok a mérkőzések, események, ranglistahelyezések és egyebek verseny- és technikai kérdéseire terjednek ki.',
    },
    2: {
      title: '3-fős csoportok lejátszási formátum - Technikai útmutató',
      text: 'Rövid útmutató a játékrendszerről, a ranglistahelyezésekről, a visszalépésekről és a kieséses szakaszba való továbbjutásról 3-fős csoportok számára, amely az egyik leggyakoribb játékformátum a Sportya eseményeken.',
    },
    3: {
      title: 'Helyi ligák – Technikai útmutató és GYIK',
      text: 'Alapvető információk a helyi ligák működéséről, a játékosokkal való kommunikációról, a ranglistahelyezésekről és a gyakran ismételt kérdésekről, amelyek alapján gyorsan elindulhatsz',
    },
  },

  minAgeRequired:
    '2022-től 15 évre emeljük a Sportya rendezvényeken való induláshoz szükséges alsó korhatárt. Ez a változás tükröződik Tenis10 projektünkben is <1>(www.tenis10.ro)</1>, amely mostantól lehetővé teszi a játékosok számára, hogy 14 éves korukig regisztráljanak gyermek- és utánpótlás-versenyeken. A Sportya fiókod aktív marad, de az alsó korhatár betöltéséig nem tudsz regisztrálni az eseményekre.',

  rankedMatches: '<strong>{{ranked}}</strong> Kompetitív',
  unrankedMatches: '<strong>{{unranked}}</strong> Barátságos',
};
