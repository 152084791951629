const checkPlayerEligibility = ({
  myLevel,
  playerPartner,
  opponent,
  opponentPartner,
  isDoubles,
  isRanked,
}) => ({
  singles: {
    min: myLevel - 1,
    max: myLevel + 1,
  },
  doubles: {
    average: (myLevel + playerPartner?.gameLevelDouble) / 2,
    opponentAverage: (opponent?.gameLevelDouble + opponentPartner?.gameLevelDouble) / 2,
    get min() { return this.average - 1; },
    get max() { return this.average + 1; },
  },
  get min() {
    return isDoubles ? this.doubles.min : this.singles.min;
  },
  get max() {
    return isDoubles ? this.doubles.max : this.singles.max;
  },
  get isEligible() {
    return isDoubles ? () => {
      const minRequired = this.doubles.min;
      const maxRequired = this.doubles.max;
      const opponentTeam = this.doubles.opponentAverage;
      return (playerPartner && opponent && opponentPartner && isRanked)
        ? (opponentTeam >= minRequired && opponentTeam <= maxRequired) : true;
    } : () => {
      const opponentLevel = opponent?.gameLevelSingle;
      const minRequired = this.singles.min;
      const maxRequired = this.singles.max;
      return (opponent && isRanked) ? (opponentLevel >= minRequired && opponentLevel <= maxRequired) : true;
    };
  },
});

export default checkPlayerEligibility;
