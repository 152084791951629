import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import mixpanel from '../../../mixpanel';
import LoadImage from '../../../components/common/LoadImage';

import RaiseHandForm from './RaiseHandForm';

import {
  CloseButton,
  RaiseHandDropDownList,
} from '../../../components/Layout/components/Header/components/RaiseHandStyles';

const RaiseHand = () => {
  const { accountInfo } = useSelector(state => state.session);
  const history = useHistory();

  useEffect(() => {
    if (!accountInfo.gameLevelSingle && !accountInfo.gameLevelDouble) {
      history.push('/my-account/game-level');
    }
  }, [accountInfo]);

  useEffect(() => {
    mixpanel.track('Visited Start Match tab');
  }, []);

  const setVisible = () => {
    history.goBack();
  };

  return (
    <RaiseHandDropDownList>
      <CloseButton
        src={LoadImage('close-icon.svg')}
        onClick={() => { setVisible(); }}
        alt="Close raise hand modal"
        notMobileOnly
      />
      <RaiseHandForm account={accountInfo} setVisible={setVisible} />
    </RaiseHandDropDownList>
  );
};

export default RaiseHand;
