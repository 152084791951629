import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Contain, Link, P } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';
import getAgeFromDate from '../../../../components/common/getAgeFromDate';
import { FriendsIcon } from '../../../Friends/components/FriendCard/styles';

export const Container = styled(Link)({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 20px 0',
  padding: '0 15px',
  cursor: 'pointer',
  background: '#fff',
  borderRadius: '4px',
  transition: 'all .2s ease-out',
  '&:hover': {
    background: '#EAF6F6',
  },
  '&:last-of-type': {
    margin: 'unset',
  },
});

export const ContainAvatar = styled(Link)({
  position: 'relative',
  margin: '0 10px 0 0',
  '.badge': {
    position: 'absolute',
    left: '50%',
    bottom: '-8px',
    transform: 'translateX(-50%)',
  },
});

export const Avatar = styled.img({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '38px',
  width: '38px',
  objectFit: 'cover',
  objectPosition: 'top',
  border: '1px solid rgb(234, 246, 246)',
  borderRadius: '50%',
});

export const Initials = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '38px',
  width: '38px',
  border: '1px solid rgb(234, 246, 246)',
  borderRadius: '50%',
});

const ID = styled.span({
  margin: '0 0 0 5px',
  fontWeight: 400,
  color: '#78ADAB',
});

const Index = ({ player }) => {
  const { accountInfo } = useSelector(state => state?.session);
  const { t } = useTranslation('common');

  const {
    id,
    userId,
    profilePicture,
    firstName,
    lastName,
    gameLevelSingle,
    birthDate,
    location,
  } = player;

  const nameInitials = () => {
    const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
    const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
    return `${first} ${last}`;
  };

  const avatarType = profilePicture ? 'picture' : 'initials';
  const user = {
    avatar: {
      picture: <Avatar src={LoadImage(`accounts/${userId}/${profilePicture}`, true)} alt={nameInitials()} />,
      initials: <Initials><P bold>{nameInitials()}</P></Initials>,
    },
    level: gameLevelSingle ? ` - ${t('gameLevel')}: ${gameLevelSingle}` : '',
    location: location?.city && location?.country ? ` - ${location.city}, ${location.country}` : '',
  };

  return (
    <Container to={`/accounts/${id}`} textDecoration="none">
      <ContainAvatar>
        {user.avatar[avatarType]}
      </ContainAvatar>
      <Contain direction="column">
        <P bold>
          {accountInfo?.friendsIds?.includes(userId) && (
            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
          )}
          {`${firstName} ${lastName}`}
          <ID>
            #
            {userId}
          </ID>
        </P>
        <Contain align="center">
          <P xSmall>{`${birthDate && getAgeFromDate(birthDate)}${ user.location }${user.level}`}</P>
        </Contain>
      </Contain>
    </Container>
  );
};

export default Index;
