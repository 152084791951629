import { createSlice } from '@reduxjs/toolkit';

// Slice
const slice = createSlice({
  name: 'friends',
  initialState: {
    data: {},
    friendsIds: [],
    isLoading: true,
    error: false,
    status: 'idle',
  },
  reducers: {
    startLoading: state => {
      state.isLoading = true;
    },
    setFriends: (state, action) => {
      state.data = action.payload;
      state.isLoading = false;
    },
    removeFriend: (state, action) => {
      const { data } = state;
      data.results = data.results.filter(friend => friend.user.id !== action.payload.id);
      data.friends = action.payload.friends;
      data.requestsReceived = action.payload.requestsReceived;
      data.requestsSent = action.payload.requestsSent;

      state.data = data;
    },
    acceptFriendAction: (state, action) => {
      const { data } = state;
      data.results = data.results.filter(friend => friend.user.id !== action.payload.id);
      data.friends = action.payload.friends;
      data.requestsReceived = action.payload.requestsReceived;
      data.requestsSent = action.payload.requestsSent;

      state.data = data;
    },
    hasError: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
  extraReducers: {
  },
});
export default slice.reducer;

export const {
  startLoading,
  setFriends,
  removeFriend,
  acceptFriendAction,
} = slice.actions;
