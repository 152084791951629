import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

import {
  Header,
  NewestPlayers,
  NewestContainer,
  List,
  ListOptions,
  RefPosition,
} from './styles';

import PlayerCard from './components/PlayerCard';
import TablePlayer from './components/TablePlayer';
import TableCard from './components/TableCard';
import Pagination from '../../components/Pagination';
import AccountsFilters from './components/AccountsFilters';
import SearchBar from './components/Search/SearchBar';

import {
  Contain, H1, H3, P,
} from '../../components/Collection';

import {
  fetchAccounts,
  resetAccounts,
  setAccounts,
  setActiveFilters,
} from './reducers';

import ContentLoaderHandler from '../../components/ContentLoader';

const initialOptions = {
  page: 1,
  limit: 25,
};

const FindAPlayer = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('player');
  const { t: commonT } = useTranslation('common');
  const listRef = useRef(null);
  const {
    data: accounts,
    newest,
    status,
  } = useSelector(state => state.accounts?.list);
  const { data: activeFilters } = useSelector(state => state.accounts?.activeFilters);
  const [readableFilters, setReadableFilters] = useState();
  const [filters, setFilters] = useState({
    ...initialOptions,
    ...activeFilters,
  });

  useEffect(() => {
    if (filters.location) {
      setReadableFilters(`${filters.location.replace('null, ', '')}`);
    } else {
      setReadableFilters(null);
    }
  }, []);

  useEffect(() => {
    if (status === 'idle' || JSON.stringify(filters) !== JSON.stringify(activeFilters)) {
      dispatch(setActiveFilters(filters));
      dispatch(fetchAccounts(filters));
    }
  }, [filters, activeFilters, status]);

  const handlePagination = (data) => {
    const page = data.selected + 1;

    if (filters.page !== page) {
      setFilters({
        ...filters,
        page,
      });
    }

    listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const playersFound = readableFilters
    ? (accounts?.totalResults === 1 ? 'playerFound' : 'playersFound')
    : (accounts?.totalResults === 1 ? 'playerFoundNc' : 'playersFoundNc');
  const hasSetFilters = filters?.gender || filters?.location || filters?.location || filters?.gameLevelSingle;

  return (
    <>
      <Helmet>
        <title>{t('seoTitle')}</title>
      </Helmet>

      {/* search bar */}
      <Header>
        <Contain maxWidth="1234px" width="100%" direction="column">
          <H1><Trans ns="player" i18nKey="findAPlayer">Find a Player</Trans></H1>
          <SearchBar
            isLoading={status === 'loading'}
            filters={filters}
            setFilters={setFilters}
            handleEnter={{ setter: setAccounts, reset: resetAccounts }}
            totalPlayers={accounts?.totalResults}
            mark={listRef}
          />
        </Contain>
      </Header>

      {/* newest players */}
      {!filters.string && (
        <NewestContainer id="#top">
          <H3 margin="0 0 20px 0">
            <Trans ns="player" i18nKey="newestPlayers">Recently Joined Sportya</Trans>
          </H3>

          {status === 'succeeded' && (
            <NewestPlayers>
              {newest?.map((user, idx) => (
                <PlayerCard
                  key={idx}
                  date={user.createdAt}
                  photo={user.profilePicture ? user.profilePicture : null}
                  level={user.gameLevelSingle}
                  levelDouble={user.gameLevelDouble}
                  firstName={user.firstName}
                  lastName={user.lastName}
                  userId={user.userId}
                  id={user._id}
                  age={user.birthDate}
                  location={
                    user.location ? user.location.name : null
                  }
                  handRaised={user.handRaised ?? null}
                  playerInfo={user}
                />
              ))}
            </NewestPlayers>
          )}

        </NewestContainer>
      )}

      {/* list players */}
      <List>
        <RefPosition ref={listRef} />
        <ListOptions>
          <H3>
            {filters.string || hasSetFilters ? (
              <Trans ns="player" i18nKey={playersFound} count={accounts?.totalResults || 0}>
                <strong>{{ totalResults: accounts?.totalResults || 0 }} players</strong> found
                {{ location: readableFilters }}
              </Trans>
            ) : (
              <Trans ns="player" i18nKey="allPlayers">All Players</Trans>
            )}
          </H3>
          <AccountsFilters
            filters={filters}
            setFilters={setFilters}
            setReadableFilters={setReadableFilters}
          />
        </ListOptions>

        {status === 'loading' && (
          <Contain
            align="center"
            justify="center"
            width="100%"
            margin="150px 0 150px 0"
          >
            <ContentLoaderHandler />
          </Contain>
        )}

        {status === 'succeeded' && (
          <>
            {accounts?.results?.length > 0 && (
              <TableCard>
                {accounts?.results.map((user, idx) => (
                  <TablePlayer
                    player={user}
                    key={idx}
                    idx={idx}
                    length={accounts?.results?.length}
                  />
                ))}

                <Pagination
                  pageCount={Math.ceil(accounts?.totalResults / accounts?.limit) || 0}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePagination}
                  forcePage={filters.page - 1}
                />
              </TableCard>
            )}
            {accounts?.results?.length === 0 && (
              <P margin="30px auto !important" width="fit-content">{commonT('noResultsFound')}</P>
            )}
          </>
        )}
      </List>
    </>
  );
};

export default FindAPlayer;
