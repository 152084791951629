export default {
  hero: {
    title: 'Добре дошли в \nSportya',
    desc: 'Има много какво да научите за Sportya, така че ще започнем с най-важното. Разгледайте ръководството по-долу, за да разберете как работят мачовете, събитията и класирането.',
  },
  firstPhase: {
    title: 'Какво е Sportya?',
    desc: `Sportya е конкурентна спортна екосистема, в която десетки
                хиляди любители играчи участват в приятелски мачове,
                лиги и събития, за да се насладите на балансирана игра или да се състезавате
                за най-добрите места в национални и международни класации.`,
    articles: {
      1: {
        title: 'Открий',
        desc: 'След като се присъедините към Sportya, вие ставате част от голяма общност от любители-тенисисти и получавате достъп до събития, организирани в стотици тенис клубове. Ние се разширяваме в нови региони и държави, като Ви предлагаме по-голямо разнообразие от избор за всеки път, който изберете – или играете на местно ниво в партньорски клубове, или изследвате и търсите нови преживявания по целия свят.',
      },
      2: {
        title: 'Предизвикай',
        desc: 'Започнете с няколко приятелски мача, присъединете се към местна лига, ако гъвкавостта на графика е важна за вас, или се впуснете направо в състезателни събития и се борете за върха на националните и международните класации за любители.',
      },
      3: {
        title: 'Свържи се',
        desc: 'Дръжте приятелите си близо, запознайте се с нови и споделете своя опит. Изградете и покажете своя профил на играча и извлечете максимума от играта тенис.',
      },
      4: {
        title: 'Ниво на игра',
        subtitle: 'Играй равностойни мачове',
        desc: 'Всеки член на Sportya играе на определено ниво на игра. Нивото определя събитията, към които можете да се присъедините - както в състезателна турнирна игра, така и в социална приятелска игра.',
      },
    },
  },
  thirdPhase: {
    1: {
      title: '6 нива на игра',
      desc: 'Приветстваме всички тенисисти, от начинаещи до опитни играчи. Оценете своето ниво на игра по време на създаване на акаунт, излезте и играйте и се насладете на балансирани мачове. Ние предлагаме 6 нива на игра, определени като комбинация от способности и опит.',
    },
    2: {
      title: 'Нива за Сингъл и Двойки',
      desc: 'Те са отделни в Sportya. Първоначално ще зададете ниво на игра, което е показателно за вашия потенциал за игра на сингъл. Това автоматично ще бъде вашето начално ниво на игра за вашите мачове на двойки. Докато се развивате, и двете ви нива автоматично се развиват с вас.',
    },
  },
  fourthPhase: {
    1: {
      title: 'Местна лига',
      subtitle: 'Гъвкав график',
      desc: 'Местните лиги ви позволяват да играете един мач на седмица, като следвате гъвкав график, като същевременно поддържате духа на състезанието жив. Регистрацията за местни лиги в момента е БЕЗПЛАТНА в Sportya.',
    },
    2: {
      title: 'Приятелски мачове',
      subtitle: 'Намерете нови приятели',
      desc: 'Приятелските мачове са най-гъвкавият начин да изживеете тениса в Sportya. Приятелските мачове могат да се играят по всяко време и навсякъде с други членове на Sportya. Нашата система за предизвикателства ще ви помогне да намерите подходящ партньор в играта, да намерите подходящо място и да докладвате резултата онлайн, като допринесете за вашето класиране и профил на играча. Някои функции са в процес на разработка в момента, но си струва чакането.',
    },
    comingSoon: 'Очаква се в 2023',
    comingSoonFM: 'Очаква се в Q1 2023',
    inDevelopment: 'В развитие',
  },
  fifthPhase: {
    title: 'Ранкирани събития',
    subtitle: 'Най-добрият мач е следващият',
    desc: 'Хлябът и маслото на Sportya, класираните събития се организират в съгласуван годишен календар, който ви позволява да се състезавате в местни и глобални аматьорски класации и да се стремите към върха. Sportya е за играчите аматьори това, което ATP/WTA е за професионалните играчи.',
    articles: {
      1: {
        title: 'Категории събития',
        desc: '1st Serve, Silver, Gold, Platinum, специални събития и Турнир на шампионите. Всеки турнир има специфична роля в календара и изискванията за влизане се различават от една категория до друга. За всяко събитие ние организираме едно или повече състезания въз основа на нивата на играта, за сингъл или двойки.',
      },
      2: {
        title: 'Схеми на игра',
        desc: 'Насладете се на множество варианти, от класически схеми с пряка елиминация, организирани през уикенда, до събития в групов формат, нощни събития и други.',
      },
      3: {
        title: 'Формати',
        desc: 'Повечето мачове се играят като 2 тай брейк сета с решаващ шампионски тай брейк (до 10 точки) при 1:1 сета.',
      },
      4: {
        title: 'Пакет за участие',
        desc: 'Освен достъпа до събитието и съпътстващите дейности (координация, резултати, класиране, актуализации на дейностите, обслужване на клиенти и т.н.), входните такси в Sportya обикновено включват 2 нови първокласни тенис топки, разпределени на мач и бутилка вода на мач.',
      },
    },
  },
  sixthPhase: {
    title: 'Играйте сега',
    desc: 'В крайна сметка всичко се свежда до това - насърчаваме забавното и ангажиращо състезание, основата на спорта, което в крайна сметка води до напредък във вашата игра.',
  },
  playVideo: 'Видео',
};
