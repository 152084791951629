export default {
  SOMETHING_WENT_WRONG: "И ... OUT! Нещо се обърка. Помогнете ни да подобрим услугата – моля, кажете ни какво се случи на hq@sportya.net.",
  CHANGE_PASSWORD_FAILED: "Паролата не можа да бъде актуализирана.",
  CHANGE_PASSWORD_SUCCESS: "Паролата бе актуализирана успешно.",
  ACCOUNT_ACTIVATED: "Вашият акаунт е потвърден успешно.",
  ACCOUNT_ALREADY_ACTIVATED: "Вашият акаунт вече е валидиран.",
  COULD_NOT_ACTIVATE_ACCOUNT: "Открихме грешка и не можахме да потвърдим вашия акаунт. Помогнете ни да подобрим услугата - моля, кажете ни какво се е случило на hq@sportya.net.",
  ACCOUNT_ALREADY_EXIST: "Акаунтът вече съществува. Можете да влезете или да изберете нова парола.",
  AUTH_FAILED: "Възникна грешка и не можем да ви идентифицираме акаунта. Помогнете ни да подобрим услугата - моля, кажете ни какво се е случилоa hq@sportya.net.",
  INVALID_CREDENTIALS: "Данните за удостоверяване са невалидни. Моля, опитайте отново.",
  INTERNAL_ERROR: "Системата идентифицира вътрешна грешка. Помогнете ни да подобрим услугата - моля, кажете ни какво се е случило на hq@sportya.net.",
  SERVICE_UNAVAILABLE: 'Опа! Нещо не работи. Моля, съобщете за грешката на contact@sportya.net, за да можем да я поправим възможно най-скоро.',
  UPDATE_ACCOUNT_SUCCESS: "Акаунтът е актуализиран успешно.",
  UPDATE_ACCOUNT_FAILED: "Профилът не можа да бъде актуализиран. Помогнете ни да подобрим услугата - моля, кажете ни какво се е случило на hq@sportya.net.",
  UPDATE_CLUB_SUCCESS: 'Клубният акаунт е актуализиран успешно.',
  UPDATE_CLUB_FAILED: 'Клубният акаунт не можа да бъде актуализиран.',
  SESSION_EXPIRED: "Сесията е изтекла. Моля, влезте отново.",
  SUCCESSFULLY_FOLLOW_EVENT: "Събитието е добавено към вашия списък с желания и ще бъде следено.",
  SUCCESSFULLY_UNFOLLOW_EVENT: "Събитието е премахнато от списъка с желания и вече не се следи.",
  FAILED_FOLLOW_EVENT: "Събитието не може да бъде добавено/премахнато от списъка с предпочитания.",
  ADD_FRIEND_SUCCESS: "Успешно изпратихте поканата за приятелство",
  ACCEPT_FRIEND_SUCCESS: "Честито! Имате нов приятел в Sportya",
  REMOVE_FRIEND_SUCCESS: "Играчът е премахнат успешно от вашия списък с приятели",
  DECLINE_FRIEND_SUCCESS: "Успешно отказахте поканата за приятелство",
  CANCEL_FRIEND_SUCCESS: "Вие успешно отменихте изпратената покана за приятелство",
  UNBLOCK_PLAYER_SUCCESS: 'Играчът беше успешно деблокиран.',
  BLOCK_PLAYER_SUCCESS: 'Играчът беше успешно блокиран.',
  SUCCESSFULLY_CREATED_REPORT: 'Играчът беше докладван успешно.',
  SUBSCRIPTION_ALREADY_ACTIVATED: "Вашият абонамент вече е активиран.",
  COULD_NOT_AUTHORIZE_PAYMENT: "Плащането не може да бъде разрешено. Моля, опитайте отново.",
  MEMBERSHIP_NOT_FOUND: "Избраният абонамент не беше намерен. Моля, опитайте отново.",
  INSUFFICIENT_BALANCE: "Ъпгрейда на абонамента не бе успешно. Недостатъчен баланс.",
  AMOUNT_NOT_VALID: "Ъпгрейда на абонамента не бе успешно. Невалидна сума.",
  SUMMER_SUBSCRIPTION_ACTIVATED: "Хайде! Вашият летен акаунт е активиран успешно.",
  PREMIUM_SUBSCRIPTION_ACTIVATED: "Хайде! Вашият Премиум акаунт е активиран успешно.",
  ALREADY_REGISTERED_TO_COMPETITION: "Вече имате регистрация за максимален брой формати от този тип за това събитие.",
  MAX_ALLOWED_SAME_COMPETITION_ERROR: "Вече сте регистрирани на макс.бр. състезания от същия вид, част от това събитие.",
  EVENT_REGISTRATION_SUCCESSFULLY: "Успешно се включихте в състезанието.",
  EVENT_WITHDRAWAL_SUCCESSFULLY: "Успешно се оттеглихте от състезанието.",
  FEE_TAX_NOT_AVAILABLE: "Таксите за това състезание все още не са определени. Моля, опитайте отново по-късно.",
  NOT_REGISTERED_TO_COMPETITION: "Вие не участвате в това състезание.",
  PAYMENT_NOT_FOUND: "Не можахме да изчислим таксата за това състезание. Помогнете ни да подобрим услугата - моля, кажете ни какво се е случило на hq@sportya.net.",
  INVALID_FEE_TAX: "Не можахме да изчислим таксата за това състезание. Помогнете ни да подобрим услугата - моля, кажете ни какво се е случило на hq@sportya.net.",
  USER_INVALID_FEE_TAX: "Не можахме да изчислим таксата за състезание за вашия акаунт. Моля, опитайте отново по-късно.",
  PARTNER_USER_INVALID_FEE_TAX: "Не можахме да изчислим таксата за състезание за вашият партньор. Моля, опитайте отново по-късно.",
  CURRENT_PASSWORD_NOT_MATCH: "Старата ви парола е неправилна. Опитай пак.",
  USER_NOT_ALLOWED_CASE_LEVEL: "Регистрацията не може да бъде обработена. Вашето ниво на игра не отговаря на критериите за участие в това състезание.",
  PARTNER_USER_NOT_ALLOWED_CASE_LEVEL: "Регистрацията не може да бъде обработена. Нивото на вашия партньор в играта не отговаря на критериите за участие в това състезание.",
  ACCESS_FORBIDDEN: 'Операцията не е разрешена.',
  ACCOUNT_BLOCKED: 'Вашият акаунт е блокиран. Моля, свържете се с нас.',
  ERROR_TIMEFRAME: "Записванията за този турнир все още не са отворени.",
  USER_NOT_ALLOWED_CASE_GENDER: "Регистрацията не може да бъде обработена. Вашият пол не отговаря на критериите за участие в това състезание.",
  PARTNER_USER_NOT_ALLOWED_CASE_GENDER: "Регистрацията не може да бъде обработена. Полът на вашия партньор не отговаря на критериите за участие в това състезание.",
  WITHDRAW_NOT_ALLOWED: "Оттеглянето от това съзтезание вече не е разрешено. Моля, свържете се с нас на hq@sportya.net.",
  ERROR_BANNED_USER: "В момента не можете да се регистрирате за това състезание, защото имате забрана. Изчакайте да изтече периода на забрана, преди да се регистрирате за ново състезание.",
  ERROR_BANNED_PARTNER: "Участникът {{ partnerName }} - {{ partnerId }} в момента няма право да се регистрира за събития. Моля, изберете друг съотборник",
  ERROR_USER_REGISTERED_SAME_WEEK: 'Вече сте регистрирани в състезание, което се провежда едновременно с това събитие. Проверете събитията, за които вече сте регистрирани в секцията Моите събития, част от управлението на вашия акаунт.',
  USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Регистрацията не може да бъде обработена. Типът на Вашия акаунт (БЕЗПЛАТЕН) не съвпада с критериите за регистрация на състезанието (само ПРЕМИУМ акаунти).",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTTYPE: "Регистрацията не може да бъде обработена. Типът акаунт на вашия партньор в екипа (БЕЗПЛАТЕН) не съвпада с критериите за регистрация на състезанието (само ПРЕМИУМ акаунти).",
  USER_NOT_ALLOWED_CASE_MAXAGE: "Регистрацията не може да бъде обработена. Вашата възраст не отговаря на критериите за участие в това състезание.",
  PARTNER_USER_NOT_ALLOWED_CASE_MAXAGE: "Регистрацията не може да бъде обработена. Възрастта на партньора в играта не отговаря на критериите за участие в това състезание.",
  USER_NOT_ALLOWED_CASE_MINAGE: "Регистрацията не може да бъде обработена. Вашата възраст не отговаря на критериите за участие в това състезание.",
  PARTNER_USER_NOT_ALLOWED_CASE_MINAGE: "Регистрацията не може да бъде обработена. Възрастта на партньора в играта не отговаря на критериите за участие в това състезание.",
  USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "Не сте стигнали до мин. брой изиграни турнири, необходими за това състезание.",
  PARTNER_USER_NOT_ALLOWED_CASE_ACCOUNTACTIVITY: "Партньорът ви не достигнал до мин. брой от изиграни турнири, необходими за регистрация за това състезание.",
  USER_NOT_ALLOWED_CASE_RANK: "Не отговаряте на критериите за регистрация. Вашето текущо класиране не попада в границите, определени за това състезание.",
  PARTNER_USER_NOT_ALLOWED_CASE_RANK: "Вашият партньор в играта не отговаря на критериите за регистрация. Неговото текущо класиране не попада в рамките на това състезание.",
  TEAM_NOT_ALLOWED_AVG_LEVEL: "Регистрацията не може да бъде обработена. Нивото на игра на отбора не съответства на ограничението за ниво, прилагано за това състезание.",
  TEAM_LEVEL_FORBIDDEN_TO_TEAMS: "Регистрацията не може да бъде обработена. Нивото на игра на членовете на отбора не съответства на ограничението за ниво, прилагано за това състезание.",
  TEAM_MUST_BE_MIXT: "Регистрацията не може да бъде обработена. Вашият отбор трябва да бъде смесен, за да отговаря на критериите за участие в това състезание.",
  COULD_NOT_CREATE_FRIENDLY_MATCH: 'Приятелски мач не може да бъде създаден в момента. Моля, опитайте отново по-късно!',
  SUCCESSFULLY_UPDATED_FRIENDLY_MATCH: 'Вашият приятелски мач беше успешно актуализиран.',
  COULD_NOT_UPDATE_FRIENDLY_MATCH: 'Вашият приятелски мач не може да бъде актуализиран в момента. Моля, опитайте отново по-късно!',
  SUCCESSFULLY_ACCEPTED_FRIENDLY_MATCH: 'Успешно приехте поканата за мач.',
  COULD_NOT_ACCEPT_FRIENLDY_MATCH: 'Поканата за мач не може да бъде приета в момента. Моля, опитайте отново по-късно!',
  SUCCESSFULLY_DECLINED_FRIENDLY_MATCH: 'Успешно отказахте поканата за мач.',
  COULD_NOT_DECLINE_FRIENLDY_MATCH: 'Поканата за мач не може да бъде отказана в момента. Моля, опитайте отново по-късно!',
  SUCCESSFULLY_FRIENDLY_MATCH_WITHDRAWN: '‘Успешно се оттеглихте от мача.',
  COULD_NOT_WITHDRAW_FRIENLDY_MATCH: 'Вашето оттегляне от мача не може да бъде обработено в момента. Моля, опитайте отново по-късно!',
  SUCCESSFULLY_CANCELED_FRIENDLY_MATCH: 'Успешно отменихме мача ви.',
  COULD_NOT_CANCEL_FRIENLDY_MATCH: 'Вашият мач не може да бъде отменен в момента. Моля, опитайте отново по-късно!',
  SUCCESSFULLY_REFUSED_FRIENDLY_MATCH: 'Успешно докладвахте мача като неигран.',
  COULD_NOT_REFUSE_FRIENLDY_MATCH: 'В момента мачът не може да бъде маркиран като неигран. Моля, опитайте отново по-късно!',
  SUCCESSFULLY_SAVED_SCORE: 'Резултатът е запазен успешно.',
  COULD_NOT_SAVE_SCORE: 'Вашият резултат не може да бъде запазен в момента. Моля, опитайте отново по-късно!',
  passwordMustMatch: 'Паролите не съвпадат. Моля, опитайте отново.',
  missingLocation: 'Мястото за това събитие все още не е установено.',
  rank: 'Класиране',
  age: 'Възраст',
  minage: 'Минимална възраст.',
  maxage: 'Максимална възраст. ',
  page_not_found: 'Страницата не е намерена',
  go_back: 'Назад',
  isLoading: 'Зареждане...',
  january: `Януари`,
  february: "Фежруари",
  march: "Март",
  april: "Април",
  june: "Юни",
  july: "Юли",
  august: "Август",
  september: "Септември",
  october: "Октомври",
  november: "Ноември",
  december: "Декември",
  January: "Януари",
  February: "Февруари",
  March: "Март",
  April: "Април",
  June: "Юни",
  July: "Юли",
  August: "Август",
  September: "Септември",
  October: "Октомври",
  November: "Ноември",
  December: "Декември",
  Jan: "Ян",
  Feb: "Феб",
  Mar: "Мар",
  Apr: "Апр",
  May: "Май",
  may: 'Май',
  Jun: "Юн",
  Jul: "Юл",
  Aug: "Авг",
  Sep: "Сеп",
  Oct: "Окт",
  Nov: "Нов",
  Dec: "Дек",
  Mon: "Пон",
  Tue: "Вто",
  Wed: "Сря",
  Thu: "Чет",
  Fri: "Пет",
  Sat: "Съб",
  Sun: "Нед",
  Monday: "Понеделник",
  Tuesday: "Вторник",
  Wednesday: "Сряда",
  Thursday: "Четвъртък",
  Friday: "Петък",
  Saturday: "Събота",
  Sunday: "Неделя",
  male: "Мъжки",
  female: "Женски",
  men: "Мъж",
  women: "Жена",
  mixt: "Среден",
  select: "Изберете",
  level: "Ниво",
  continue: "Продължи",
  requiredField: "Задължително поле",
  invalidEmail: "Инвалиден Email",
  minChars: "Мин.бр. {{ chars }} букви",
  maxChars: "Мак.бр. {{ chars }} букви",
  mustContainerSpecialChars: "Включва поне един специален знак (@,!,#, etc)",
  logout: "Излизане",
  login: "Влизане",
  signup: "Регистрирай се",
  password: "Парола",
  email: "Имейл",
  country: "Държава",
  close: 'Затвори',
  clubOwner: 'Собственик на клуба',
  firstName: "Първо име",
  lastName: "Фамилия",
  phoneNumber: "Телефон",
  openHours: 'Програма',
  phone: 'Телефон',
  nrNotValid: 'Невалиден телефонен номер',
  imClubManager: "Аз съм Мениджър на клуб",
  moreDetails: 'Повече информация',
  birthdate: 'Рождена дата',
  maintenance: 'Поддръжка',
  inMaintenance: 'В поддръжка',
  regBackSoon: 'Регистрациите ще бъдат възобновени скоро',
  days: 'Дни',
  day: 'Ден',
  from: 'От',
  to: 'Към',
  back: 'Назад',
  dismiss: 'Затвори',
  metaDescription: 'Sportya е един феномен посветен на общност управлявана от страстта си към спорта. Вдъхновявани от духа на състезанието. Без ограничения от възраст, пол, цвят или други вярвания. Светът на SportyaTM няма географски ограничения, не са необходими паспорти,  ние се ръководим само от духа на fair-play. Всички говорим на един и същ език и това е езикът на СПОРТА.',
  metaKeywords: 'тенис, тенис събития, тенис състезания, тенис турнири, тенис мачове, тенис общност, тенис лиги, местни лиги, партньорски тенис, тенисисти, аматьори, тенис клубове, тенис клуб, приятелски мачове, партньорски мачове, игра на тенис, търсене играчи, търси събития, тенис кортове, спорт',
  ogTitle: 'Sportya - Играй най-добрия мач',
  ogDescription: 'Присъединете се към мен в Sportya, за да играем приятелски мачове и да участваме в национални и международни турнири! \n\r Създайте своя акаунт, като използвате моята уникална връзка за покана и спечелете награди: \n\r {{ link }}',
  saveChanges: 'Запазване на актуализациите',
  languages: {
    en: "English",
    bg: "Български",
    ro: "Română",
    hu: "Magyar",
    cz: 'Čeština',
  },
  grass: 'Трева',
  clay: 'Клей',
  artificial: 'Изкуствена настилка',
  hard: 'Твърда настилка',
  gold: "Gold",
  silver: "Silver",
  platinum: "Platinum",
  champions: "Шампиони",
  winners: 'Шампиони',
  courtNr: 'Корт {{courtNr}}',
  premium: "Премиум",
  special: "Специални",
  firstServe: 'Първи сервис',
  league: 'Език',
  allAges: "Всички възрасти",
  type: 'Вид',
  customAge: "Избери интервал",
  location: "Локация",
  LocationGmaps: 'Местоположение',
  category: "Категория",
  month: "Месец",
  year: "Година",
  all: 'Всички',
  romania: 'Румъния',
  bulgaria: 'България',
  moldova: 'Република Молдова',

  migration: {
    1: `Миграцията на данни от платформата Tennis Partner към Sportya се извършва през този период и засяга видимостта на определени части от Профилите на играчите. 
    Те ще бъдат достъпни скоро. 
    Благодаря ви за търпението.`,
  },

  players: "Играчи",
  teams: 'Отбори',
  areYouSure: 'Сигурни ли сте?',
  setLevel: 'Изберете нивото',

  allLocations: 'Всички Локации',
  allCategories: 'Всички Категории',
  allMonths: 'Всички Месеци',
  allYears: 'Всички Години',
  allLevels: 'Всички Нива',

  onlyfemale: 'Само Жени',
  onlymale: 'Само Мъже',
  onlymixt: 'Само Смесени',

  right: 'Дясна',
  left: 'Лява',
  searchPlayer: 'Намерете играч',
  'Romania-All': 'Румъния (Всички)',
  'Bulgaria-All': 'България (Всички)',
  'Moldova-All': 'Молдова (Всички)',
  officialBall: 'Официална топка',
  eventPrizes: 'Награди на събитието',
  eventPrizesDescription: 'Стандартните награди за класически турнир (Silver, Gold, Platinum, Специални Турнири), всяко състезание се състои от медал и грамота. Всички допълнителни награди ще бъдат актуализирани в този раздел. \ n \ n В случай на местни лиги, моля, изпратете ни съобщение на contact@sportya.net, за да генерираме вашата дигитална грамота за 1-во място.',
  gameLevel: 'Ниво на игра',
  show: 'Покажи',
  uploadPlaceholder: 'Поставете файловете тук или щракнете за качване.',
  download: "Свали",
  viewPDF: "Вижте Схемата на игра в PDF",
  clearResults: 'Изтриване на резултатите',
  onlyLatin: `Моля, използвайте само букви от латинската азбука. 
  Това ограничение е необходимо, за да позволи на други играчи да ви намерят по-лесно в системата
  (ex: за записване на отбор на двойки).`,
  PREMIUM_MEMBERSHIP_UPGRADE: "Чудесно! Вашият Premium {{period}} акаунт беше успешно активиран.",
  PARTNER_USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Регистрацията не може да бъде извършена. Играчите трябва да са навършили 15 години за да участват в турнирите на Sportya.',
  USER_NOT_ALLOWED_CASE_FORBIDDEN_AGE: 'Регистрацията не може да бъде извършена. Играчите трябва да са навършили 15 години за да участват в турнирите на Sportya.',
  banned: `Вашият профил е временно блокиран за <0>{{time}}</0>. Няма да можете да се регистрирате за нови турнири до <1>{{until}}.</1>
Моля, свържете се с екипа на Sportya за подробности на <2>contact@sportya.net</2>`,
  notAvailable: 'Не е наличен',
  weeks: '{{time}} седмици',
  singleWeek: '1 седмица',
  free: 'Безплатно',
  custom: 'Персонален',
  bahrain: 'Бахрейн',
  armenia: 'Армения',
  hungary: 'Унгария',
  czechRepublic: 'Чехия',
  city: 'град',
  countriesByKey: {
    ro: 'Румъния',
    md: 'Република Молдова',
    bg: 'България',
    cz: 'Чехия',
    am: 'Армения',
    hu: 'Унгария',
    bh: 'Бахрейн',
  },
  soonToBeAnnounced: 'Скоро ще бъде обявено',

  'Hungary-All': 'Унгария (всичко)',
  'Bahrain-All': 'Бахрейн (всичко)',
  'Armenia-All': 'Армения (всичко)',
  'Czechia-All': 'Чехия (всичко)',
  noResultsFound: 'Няма намерени резултати',
  typeAtLeast3Chars: 'Въведете поне 3 знака...',
  allActivities: 'Всички дейности',
  eventSponsors: 'Спонсори на събитието',
  sponsors: 'Спонсорите',
  coordinators: 'Координатори',
  officialStore: 'Официален магазин',
  officialSponsors: 'Официални спонсори',
  proximityRadius: 'Близост',
  currentLocation: 'Моето местоположение',
  calendar: 'Календар',
  radius: 'Радиус',
  SUCCESSFULLY_INITIATED_CHAT: 'Conversation was successfully initiated.',
  COULD_NOT_INITIATE_CHAT: 'Conversation could not be initiated. Try again later.',
  cancelPopup: {
    title: 'Сигурни ли сте, че искате да напуснете тази страница?',
    stay: 'Оставам на страницата',
    quit: 'Напускам страницата',
  },
  COULD_NOT_RATE_PLAYER: 'Оценката не може да бъде запазена в момента. Моля, опитайте отново по-късно.',
  SUCCESSFULLY_RATED_PLAYER: 'Успешно запазихте своята оценка.',
  SUCCESSFULLY_DELETED_RATING: 'Оценката е оттеглена успешно.',
  PaidParking: "Платена паркинг",
  FreeParking: "Безплатен паркинг",
  EquipmentRental: "Наем на оборудване",
  Store: "Магазин",
  Lockers: "Съблекални",
  Showers: "Душове",
  Restaurant: "Ресторант",
  Cafeteria: "КАФЕ",
  Bar: "Бар",
  VendingMachine: "Автомат",
  SpecialAccess: "Специален достъп",
  KidsPlayground: "Детска площадка",
  referralCode: "Код за покана (по избор)",
  INVALID_REFERRAL_CODE: 'Невалиден код на поканата',
  REFERRAL_CODE_APPLIED_SUCCESSFULLY: 'Кодът на поканата е приложен успешно!',
  date: "Дата",
  time: "Час",
  no: "Не",
  yes: "Да",
  FAILED_TO_BOOK_COURT: "Полето не можа да бъде планирано! Опитай пак!",
  BOOKING_COULD_NOT_BE_CREATED: "Полето не можа да бъде планирано! Опитай пак!",
  COULD_NOT_CALCULATE_BOOKING_PRICE: "Полето не можа да бъде планирано! Опитай пак!",
  COURT_NO_LONGER_AVAILABLE: "Тази земя вече не е достъпна! Опитайте да резервирате друг.",
  START_DATE_OUTSIDE_MAX_BOOKING_INTERVAL: "Полето не можа да бъде планирано! Опитай пак!",
  BOOKING_CONFIRMED_SUCCESSFULLY: "Честито! Избраната земя е успешно резервирана!",
  COURT_IS_NOT_AVAILABLE: "Тази земя вече не е достъпна! Опитайте да резервирате друг.",
  CLUB_BOOKING_DISABLED: "Този терен току-що беше деактивиран от клуба. Опитайте да резервирате друг.",
  FAILED_TO_CONFIRM_BOOKING: "Резервацията не можа да бъде потвърдена. Моля, опитайте отново или се свържете с нас.",
  ALREADY_WITHDRAWN: "Вие вече се оттеглиха от това състезание.",
  RESOURCE_NOT_FOUND: "Route could not be found.",
  BOOKING_CANCELED_SUCCESSFULLY: "Резервацията е успешно анулирана.",
  FAILED_TO_CANCEL_BOOKING: "Резервацията не можа да бъде анулирана.",
  BOOKING_ALREADY_CANCELED: "Резервацията вече е анулирана.",
  INVALID_FRIENDLY_MATCH_SCORE_FORMAT: 'Невалиден резултат въз основа на избрания от вас формат на съвпадение!',
  INVOICE_UNAVAILABLE: 'Фактурата не е налична.',
  bookNow: "Резервирайте сега",
  searchAndBookCourts: "<0>ГОЛЕМИ НОВИНИ!</0> Вече можете да ТЪРСИТЕ и РЕЗЕРВИРАТЕ корт в Партньорските клубове на Sportya."
};
