import React from 'react';

import { PlayerName } from '../../styles';
import { Initials, PlayerAvatar, PlayerAvatarContainer } from '../../../StartMatch/components/WantToPlay/styles';
import { Badge } from '../../../../../components/LevelsBadges/styles';

import LinkWrapper from '../../../../../components/Link';
import LoadImage from '../../../../../components/common/LoadImage';
import { Paragraph } from '../../../../../components';
import nameInitials from '../../helpers/nameInitials';

const DisplayPlayerInfo = ({
  match,
  position: role,
  isDoubles = false,
  myPosition,
}) => (
  <PlayerAvatarContainer isDoubles={isDoubles}>
    <LinkWrapper to={`/accounts/${match[role]?.id}`}>
      <PlayerAvatar>
        {match[role].profilePicture
          ? (
            <img
              src={LoadImage(`accounts/${match[role].userId}/${match[role].profilePicture}`, true)}
              alt={`${match[role].firstName} ${match[role].lastName}`}
            />
          )
          : <Initials isDoubles={isDoubles}><Paragraph bold>{nameInitials(match[role])}</Paragraph></Initials>}
      </PlayerAvatar>
    </LinkWrapper>
    <div className="game-level game-level__one">
      <Badge small double={isDoubles}>
        <Paragraph smaller bold>{match[role]?.[isDoubles ? 'gameLevelDouble' : 'gameLevelSingle'] ?? '-'}</Paragraph>
      </Badge>
    </div>
    {isDoubles && (
      <PlayerName small>
        <span>{role === myPosition ? 'You' : match[role].firstName}</span>
        {isDoubles ? (
          <img
            src={LoadImage(`friendly-matches/acceptance/${match[role].invitation}-icon.svg`)}
            alt=""
            className="ml5"
          />
        ) : ''}
      </PlayerName>
    )}
  </PlayerAvatarContainer>
);

export default DisplayPlayerInfo;
