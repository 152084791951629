/* eslint-disable import/no-named-as-default */
/* eslint-disable no-shadow */
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import LoadImage from '../../../../common/LoadImage';
import ToggleVisible from '../../../../common/ToggleVisible';
import Membership from './component/Membership';

import {
  Contain, P, H3, Link, Button,
} from '../../../../Collection';

import NotificationBubble from '../../../../NotificationBubble';
import { Badge } from '../../../../LevelsBadges/styles';
import mixpanel from '../../../../../mixpanel';
import RaiseHand from './RaiseHand';
import { getFriendsInfo } from '../../../reducers/session';
import { CloseButton, ModalBackground } from './RaiseHandStyles';
import {
  Avatar, DropdownMenu, Initials, Item, List, ProfileDropdownContainer, ProfileDropdownLogout,
} from './styles';

const ProfileDropdown = ({
  accountInfo, whiteAvatar, name, state,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation('header');
  const { friends } = useSelector(state => state.session);
  const [friendsCount, setFriendsCount] = useState(0);
  const { isVisible, setVisible, ref: secondRef } = state;

  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);

  useEffect(() => {
    if (friends === null && isComponentVisible) {
      dispatch(getFriendsInfo());
    }
  }, [friends, isComponentVisible]);

  useEffect(() => {
    setFriendsCount(friends ? friends.requestsReceived : 0);
  }, [friends]);

  const nameInitials = `${(accountInfo?.firstName?.charAt(0) + accountInfo?.lastName?.charAt(0)).toUpperCase()}`;
  const avatarType = accountInfo?.profilePicture ? 'picture' : 'initials';

  const isFriendsPage = ['Friends'].includes(name);

  const user = {
    avatar: {
      picture: <Avatar
        borderColor={whiteAvatar}
        src={LoadImage(`accounts/${accountInfo?.userId}/${accountInfo?.profilePicture}`, true)}
        alt={`${accountInfo?.firstName} ${accountInfo?.lastName}`}
        onClick={() => setIsComponentVisible(!isComponentVisible)}
      />,

      initials:
  <Initials
    borderColor={whiteAvatar}
    onClick={() => setIsComponentVisible(!isComponentVisible)}
  >
    <P bold {...whiteAvatar && { color: 'white' }}>
      {nameInitials}
    </P>
  </Initials>,
    },
    hasLevel: accountInfo?.gameLevelSingle ?? false,
    isPlayer: accountInfo?.role === 'player',
  };

  return (
    <ProfileDropdownContainer ref={ref}>
      {user.avatar?.[avatarType]}
      {isComponentVisible && (
        <>
          <ModalBackground zIndex={1} mobileOnly />
          <DropdownMenu>
            <CloseButton
              src={LoadImage('close-icon.svg')}
              onClick={() => setIsComponentVisible(false)}
              alt="Close profile dropdown"
            />
            <Contain
              className="header"
              direction="column"
              align="center"
              width="100%"
            >

              {user.isPlayer ? (
                <>
                  <Membership membership={accountInfo?.membership?.plan?.split('-')[0]} />
                  <H3 textAlign="center" fontSize={24}>
                    {accountInfo?.firstName}
                    {' '}
                    {accountInfo?.lastName}
                  </H3>
                  <P small color="darkTurquoise">
                    #
                    {accountInfo?.userId}
                  </P>
                </>
              ) : (
                <>
                  <P small><Trans ns="common" i18nKey="clubOwner">Club owner</Trans></P>
                  <H3>
                    {accountInfo?.firstName}
                    {' '}
                    {accountInfo?.lastName}
                  </H3>
                  <P>{accountInfo?.clubInfo?.businessName}</P>
                </>
              )}

              {user.hasLevel && (
                <Contain justify="space-between">
                  <Contain direction="column" align="center" margin="0 10px 0 0">
                    <P>
                      <Trans ns="dashboard" i18nKey="accountLeaderboard.singles">Singles</Trans>
                    </P>
                    <Badge small><P xSmall bold>{accountInfo?.gameLevelSingle}</P></Badge>
                  </Contain>

                  <Contain direction="column" align="center" margin="0 0 0 10px">
                    <P>
                      <Trans ns="dashboard" i18nKey="accountLeaderboard.doubles">Doubles</Trans>
                    </P>
                    <Badge small double><P xSmall bold>{accountInfo?.gameLevelDouble}</P></Badge>
                  </Contain>
                </Contain>
              )}

              <Button
                outline
                small
                height="40px"
                width="100%"
                margin="20px 0 0 0"
              >
                <Link to="/my-account">
                  {t('myProfile')}
                </Link>
              </Button>
              <RaiseHand
                closeProfileDropdown={() => setIsComponentVisible(false)}
                state={{ isVisible, setVisible, ref: secondRef }}
                playerInfo={accountInfo}
                handRaisedData={accountInfo?.handRaised}
                mobileOnly
                inMenu
              />
              <List>
                {user.isPlayer ? (
                  <>
                    {!accountInfo?.isImpersonate && (
                      <>
                        <Item>
                          <P bold small>
                            <Link
                              to="/invite-friends"
                              onClick={() => mixpanel.track(
                                'Access Invite your Friends from My-Account drop-down menu',
                              )}
                            >
                              <Trans ns="accounts" i18nKey="referral.invite.friends">Invite Your Friends</Trans>
                            </Link>
                          </P>
                        </Item>
                        <Item>
                          <P bold small>
                            <Link
                              to="/friends"
                              onClick={() => mixpanel.track('Access Friends section from My-Account drop-down menu')}
                            >
                              <Trans ns="header" i18nKey="menu.friends">Friends</Trans>
                              {friendsCount > 0
                                && !isFriendsPage
                                && (
                                  <NotificationBubble addLeftMargin>
                                    {friendsCount}
                                  </NotificationBubble>
                                )}
                            </Link>
                          </P>
                        </Item>
                      </>
                    )}
                    <Item>
                      <P bold small>
                        <Link to="/my-account/events">
                          <Trans ns="header" i18nKey="menu.myEvents">My Events</Trans>
                        </Link>
                      </P>
                    </Item>
                    <Item>
                      <P bold small>
                        <Link to="/my-account/bookings">
                          <Trans ns="header" i18nKey="menu.bookings">Bookings</Trans>
                        </Link>
                      </P>
                    </Item>

                    <Item>
                      <P bold small>
                        <Link to="/my-account/settings">
                          <Trans ns="header" i18nKey="menu.mySettings">My Settings</Trans>
                        </Link>
                      </P>
                    </Item>

                    <Item>
                      <P bold small>
                        <Link to="/my-account/orders">
                          <Trans ns="header" i18nKey="menu.myOrders">My Orders</Trans>
                        </Link>
                      </P>
                    </Item>

                    <Item>
                      <P bold small>
                        <Link to="/my-account/wallet">
                          <Trans ns="header" i18nKey="menu.virtualWallet">
                            Virtual Wallet
                          </Trans>
                        </Link>
                      </P>
                    </Item>
                    <Item>
                      <P bold small>
                        <Link to="/plans">
                          <Trans ns="header" i18nKey="menu.plans">Get a Premium Account</Trans>
                          &nbsp;&nbsp;
                          <img src={LoadImage('premium-account-icon.svg')} alt="Upgrade to Premium" />
                        </Link>
                      </P>
                    </Item>
                  </>
                ) : (
                  <Item>
                    <P bold small>
                      <Link to="/my-account/settings">
                        <Trans ns="accounts" i18nKey="menu.mySettings">My Settings</Trans>
                      </Link>
                    </P>
                  </Item>
                )}
              </List>
              <ProfileDropdownLogout>
                <Link to="/logout" className="logout">
                  <P bold small color="darkRed"><Trans ns="common" i18nKey="logout">Log Out</Trans></P>
                  <img className="ml10" src={LoadImage('red-logout-icon.svg')} alt="Logout" />
                </Link>
              </ProfileDropdownLogout>
            </Contain>
          </DropdownMenu>
        </>
      )}
    </ProfileDropdownContainer>
  );
};

export default ProfileDropdown;
