import styled from '@emotion/styled';
import { Button, P } from '../../../../../../components/Collection';

export const Container = styled.div({
  display: 'flex',
  justifyContent: 'center',
  padding: '0 0 40px 0',
  '@media (max-width: 1125px)': {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'unset',
  },
});

export const BadgeContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: '0 50px 0 0',
  '&:last-of-type': {
    margin: 'unset',
  },
});

export const AvailabilityContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 0 0 60px',
  '@media (max-width: 900px)': {
    padding: '20px 0 0 0',
  },
});

export const InfoContainer = styled.div(({
  column, separator, noLeftPadding, noRightPadding, noPadding, hideOnMobile,
}) => ({
  display: 'flex',
  maxWidth: '430px',

  ...column && {
    flexDirection: 'column',
  },
  ...separator && {
    borderRight: '1px solid #C1E6E5',
  },
  ...!noPadding && {
    padding: `0 ${noRightPadding ? '0' : '60px'} 0 ${noLeftPadding ? '0' : '60px'}`,
  },

  '@media (max-width: 1125px)': {
    padding: !hideOnMobile ? '20px 20px 20px 20px' : '20px',
    borderRight: 'unset',
    borderBottom: '1px solid #C1E6E5',
    '&:last-of-type': {
      borderBottom: 'unset',
    },
  },

  ...hideOnMobile && {
    '@media (max-width: 480px)': {
      display: 'none',
    },
  },
}));

export const ScheduleContainer = styled.div({
  display: 'flex',
  '@media (max-width: 1125px)': {
    justifyContent: 'center',
  },
  '@media (max-width: 600px)': {
    padding: '20px 10px 0 10px',
  },
});

export const AvailabilityDay = styled(P)({
  margin: '20px 10px 5px 0',
  '@media (max-width: 1125px)': {
    margin: '10px 5px 0 10px',
  },
});

export const AvailabilityIcon = styled.img({
  width: '10px',
});

export const ChangeAvailability = styled.span({
  fontFamily: 'DM Sans',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '16px',

  padding: '9px 0 0 0',
  margin: '0 0 0 10px',

  cursor: 'pointer',
  textDecoration: 'underline',
});

export const OptionButton = styled(Button)(({ noMargin }) => ({
  ...!noMargin && {
    margin: '20px 0 0 0',
  },
  padding: '0 20px',
  height: '40px',

  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '18px',
  color: '#10242C',
}));
