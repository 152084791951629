import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import mixpanel from '../../../mixpanel';
import { TabContent, PageContainer } from '../styles';
import StartMatchBanner from './components/Banner';
import InvitePlayersInYourArea from './components/InvitePlayers';
import SearchForAPlayer from './components/SearchForPlayer';
import TopPicks from './components/TopPicks';
import WantToPlay from './components/WantToPlay';
import Header from '../components/Header';
import RaiseHand from '../../../components/Layout/components/Header/components/RaiseHand';
import PartnerClubs from './components/PartnerClubs';

const StartMatch = ({ name }) => {
  const { accountInfo } = useSelector(state => state.session);
  const history = useHistory();

  useEffect(() => {
    if (!accountInfo.gameLevelSingle && !accountInfo.gameLevelDouble) {
      history.push('/my-account/game-level');
    }
  }, [accountInfo]);

  useEffect(() => {
    mixpanel.track('Visited Start Match tab');
  }, []);

  return (
    <PageContainer>
      <Header page={name} />
      <TabContent>
        <RaiseHand mobileOnly isFriendlyMatches handRaisedData={accountInfo?.handRaised} />
        <StartMatchBanner />
        <WantToPlay />
        <InvitePlayersInYourArea />
        <PartnerClubs />
        <TopPicks />
        <SearchForAPlayer />
      </TabContent>
    </PageContainer>
  );
};

export default StartMatch;
