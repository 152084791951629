import React, { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../..';
import { Contain, Link, P } from '../../../../../../../components/Collection';
import LoadImage from '../../../../../../../components/common/LoadImage';
import {
  AvailabilityContainer,
  OptionButton,
  AvailabilityDay,
  AvailabilityIcon,
  ChangeAvailability,
  InfoContainer,
  ScheduleContainer,
} from '../styles';

import RaiseHand from '../../../../../../../components/Layout/components/Header/components/RaiseHand';
import { isHandRaised } from '../../../../../../../components/Layout/components/Header/components/RaiseHandUtils';

const AvailabilityInfo = () => {
  const { t: commonT } = useTranslation('common');
  const history = useHistory();
  const { isProfileCompleted } = useSelector(state => state.session);
  const { isMyProfile, user } = useContext(UserContext);
  const { availability } = user || {};

  return (
    <AvailabilityContainer>
      {((!isMyProfile && isHandRaised(user?.handRaised?.timeframe?.expireAt)) || isMyProfile) && (
        <RaiseHand
          handRaisedData={user?.handRaised}
          noMargins
          maddMarginInDetails
          details="right"
          maxContent
          interactive={isMyProfile}
          isMyProfile={isMyProfile}
        />
      )}
      <InfoContainer noPadding>
        {availability ? (
          <Contain direction="column">
            <Contain>
              <P textAlign="center">
                <strong>
                  <Trans ns="player" i18nKey="availabilityFriendly">
                    Availability for Friendly Matches
                  </Trans>
                </strong>
                {isMyProfile && (
                  <ChangeAvailability onClick={() => history.push('/my-account/settings?openTab=Schedule')}>
                    <Trans ns="events" i18nKey="change">
                      Change
                    </Trans>
                  </ChangeAvailability>
                )}
              </P>
            </Contain>
            <ScheduleContainer>
              {Object.keys(availability).map((day, key) => (
                <AvailabilityDay small bold key={key}>
                  <AvailabilityIcon
                    src={LoadImage(availability[day]?.enabled ? 'checkmark.svg' : 'close-icon.svg')}
                    alt={`Available on ${day}.`}
                  />
                  {` ${commonT(day)}`}
                </AvailabilityDay>
              ))}
            </ScheduleContainer>
          </Contain>
        ) : isMyProfile ? (
          <Contain direction="column">
            <P margin="15px 0 5px 0">
              <Trans ns="player" i18nKey="availabilityFriendly">
                Availability for friendly matches
              </Trans>
            </P>
            <OptionButton outline noMargin>
              <Link to={isProfileCompleted ? '/my-account/settings?openTab=Schedule' : '/my-account/availability'}>
                <Trans ns="player" i18nKey="setYourAvailability">
                  Set Your Availability
                </Trans>
              </Link>
            </OptionButton>
          </Contain>
        ) : (
          <P textAlign="center">
            <Trans ns="player" i18nKey="availabilityFriendlyNotSet">
              Availability for friendly matches is not set yet
            </Trans>
          </P>
        )}
      </InfoContainer>
    </AvailabilityContainer>
  );
};

export default AvailabilityInfo;
