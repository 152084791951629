import React, { useContext } from 'react';
import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';
import dateFormat from 'dateformat';

import LoadImage from '../../../../../components/common/LoadImage';
import { P } from '../../../../../components/Collection';
import {
  InnerStats,
  Statistic,
  StatisticContent,
  StatsContainer,
  StatsH4,
} from '../styles';
import { UserContext } from '../../..';

const Rankings = ({ isOnDashboard }) => {
  const userContext = useContext(UserContext);
  const { accountInfo } = useSelector(state => state.session);
  const userInfo = userContext ?? accountInfo;

  if (userInfo === null) {
    return (<></>);
  }

  const {
    leaderboard,
    rank,
    rankDouble,
    elo,
    eloDouble,
    createdAt,
  } = userInfo;

  let singlesTrend;
  let doublesTrend;

  if (leaderboard?.singles?.previousRank > rank) {
    singlesTrend = 'up';
  } else if (leaderboard?.singles?.previousRank < rank) {
    singlesTrend = 'down';
  } else {
    singlesTrend = 'no';
  }

  if (leaderboard?.doubles?.previousRank > rankDouble) {
    doublesTrend = 'up';
  } else if (leaderboard?.doubles?.previousRank < rankDouble) {
    doublesTrend = 'down';
  } else {
    doublesTrend = 'no';
  }

  const data = {
    updatedAt: leaderboard?.lastUpdate,
    singles: {
      position: rank ?? 'N/A',
      elo: elo ?? 'N/A',
      trend: singlesTrend,
    },
    doubles: {
      position: rankDouble ?? 'N/A',
      elo: eloDouble ?? 'N/A',
      trend: doublesTrend,
    },
  };

  return (
    <StatsContainer extend isOnDashboard={isOnDashboard}>
      <P large bold>
        <Trans ns="dashboard" i18nKey="accountLeaderboard.title">
          Ranking
        </Trans>
      </P>
      {data.updatedAt && (
        <P xSmall customColor="#78ADAB">
          <Trans ns="dashboard" i18nKey="accountLeaderboard.updated">
            Updated
          </Trans>
          {`: ${dateFormat(data.updatedAt, 'dd mmm yyyy')}`}
        </P>
      )}

      <InnerStats isOnDashboard={isOnDashboard}>
        <div>
          <P small bold margin="20px 0 10px 0">
            <Trans ns="dashboard" i18nKey="accountLeaderboard.singles">
              Singles
            </Trans>
          </P>
          <Statistic>
            <StatisticContent noBorder>
              <P xSmall>
                <Trans ns="dashboard" i18nKey="accountLeaderboard.position">
                  Position
                </Trans>
              </P>
              <StatsH4>
                {data.singles?.position}
                <img
                  src={LoadImage(`leaderboard-${data.singles?.trend}-arrow.svg`)}
                  alt="Trend for singles"
                />
              </StatsH4>
            </StatisticContent>
          </Statistic>
        </div>

        <div>
          <P small bold margin="20px 0 10px 0">
            <Trans ns="dashboard" i18nKey="accountLeaderboard.doubles">
              Doubles
            </Trans>
          </P>
          <Statistic>
            <StatisticContent noBorder>
              <P xSmall>
                <Trans ns="dashboard" i18nKey="accountLeaderboard.position">
                  Rank
                </Trans>
              </P>
              <StatsH4>
                {data.doubles?.position}
                <img
                  src={LoadImage(`leaderboard-${data.doubles?.trend}-arrow.svg`)}
                  alt="Trend for doubles"
                />
              </StatsH4>
            </StatisticContent>
          </Statistic>
        </div>
      </InnerStats>

      <P xSmall margin="30px 0 0 0">
        <Trans ns="dashboard" i18nKey="registrationDate">Registration date</Trans>:
      </P>
      <P large bold>{dateFormat(createdAt, 'd mmm yyyy')}</P>
    </StatsContainer>
  );
};

export default Rankings;
