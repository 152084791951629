export default {
  title: 'Caută un Jucător',
  singleLevel: 'Nivel Simplu',
  seoTitle: 'Jucători | Sportya',
  playerProfileSeoTitle: '{{firstName}} {{lastName}} - Jucători | Sportya',
  doubleLevel: 'Nivel Dublu',
  playerHand: 'Mână Dominantă',
  availabilityFriendly: 'Disponibilitatea pentru meciuri amicale',
  availabilityFriendlyNotSet: 'Disponibilitatea pentru meciuri amicale nu este încă setată',
  activity: 'Activitate',
  titles: 'Titluri',
  leaderboards: 'Clasamente',
  levelNotSet: 'Nivelul nu a fost setat',
  singles: 'Simplu',
  doubles: 'Dublu',
  points: 'Puncte',
  stats: 'Statistici',
  pickYear: 'An',
  allActivities: 'Activități',
  level: 'Nivel',
  round: 'Tur',
  oponent: 'Adversar',
  eloRating: 'Evaluare ELO',
  leaderboard: 'Clasare',
  score: 'Scor',
  wL: 'W/L',
  winStatusShorthand: 'W',
  loseStatusShorthand: 'L',
  woStatusShorthand: 'W.O.',
  eloDescription: 'Clasare înainte de turneu: ',
  playerFound: '<0>{{ totalResults }} jucător</0> găsit în <2>{{ location }}</2>',
  playersFound: '<0>{{ totalResults }} jucători</0> găsiți în <2>{{ location }}</2>',
  playerFoundNc: '<0>{{ totalResults }} jucător</0> găsit',
  playersFoundNc: '<0>{{ totalResults }} jucători</0> găsiți',
  champion: 'Campion',
  finalist: 'Finalist',
  week: 'Săptămână',
  calculationDate: 'Calculation Date',
  rank: 'Rank',
  move: 'Move',
  bestResults: 'Cele mai Bune Rezultate',
  pointsSummary: 'Sumar Punctaj',
  playerRank: 'Clasare',
  totalPoints: 'Puncte Totale',
  competitionPoints: 'Puncte Evenimente',
  friendlyMatchPoints: 'Puncte Meciuri Amicale',
  sendMessage: 'Trimite Mesaj',
  myEvents: 'Evenimentele Mele',
  setYourGameLevel: 'Setează-ți Nivelul de Joc',
  setYourAvailability: 'Setează-ți Disponibilitatea',
  mySettings: 'Setările Mele',
  gameLevel: 'Nivel de Joc',
  basePoints: 'Puncte de Bază',
  penaltyPoints: 'Puncte de Penalizare',
  newestPlayers: 'Jucători Noi',
  player: 'Jucător',
  friendlyMatches: 'Meciuri Amicale',
  registrationDate: 'Data Înregistrării',
  findAPlayer: 'Găsește un Jucător',
  joined: 'Membru din',
  allPlayers: 'Toți Jucătorii',
  upgradedAt: 'Nivelul a fost schimbat la',
  best7results: 'Top 7 rezultate',
  changePhoto: 'Schimbă poza',
  availabilityForFriendlyMatches: 'Disponibilitate pentru meciuri amicale',
};
