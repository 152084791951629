/** @jsxImportSource @emotion/react */
import React from 'react';
import { css, useTheme } from '@emotion/react';

import {
  Avatar,
  Container,
  GameLevel,
  Initials,
} from './styles';

import LoadImage from '../common/LoadImage';
import Paragraph from '../Paragraph';
import { getAge } from '../../pages/FriendlyMatches/helpers';
import { Badge } from '../LevelsBadges/styles';
import Link from '../Link';

const nameInitials = ({ firstName, lastName }) => {
  const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
  const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
  return `${first} ${last}`;
};

const ProfileCard = ({
  user = {},
  gameType = null,
  layout = "default",
}) => {
  const {
    id,
    userId,
    firstName,
    lastName,
    location,
    birthDate,
    profilePicture,
    gameLevelSingle,
    gameLevelDouble,
    level,
  } = user;
  const fullName = `${firstName} ${lastName}`;
  const { colors } = useTheme();
  const birthDateAndLocation = [getAge(birthDate), location?.name].filter(v => !!v).join(' - ');
  const gameLevel = gameType === 'doubles' ? gameLevelDouble : gameLevelSingle;

  if (layout === 'inviteFriends') {
    return (
      <Container className="mb0">
        <Link to={`/accounts/${id}`}>
          <Avatar
            css={css`
              border-color: ${colors.lightTurquoise};
            `}
          >
            <img
              src={LoadImage('mgm/check-circle.png')}
              alt=""
              css={css`
              position: absolute;
              right: -20px;
              top: -10px;
            `}
            />
            {profilePicture && (
              <img
                src={LoadImage(`accounts/${userId}/${profilePicture}`, true)}
                alt={fullName}
                className="profilePicture"
              />
            )}
            {!profilePicture && (
              <Initials>
                {nameInitials(user)}
              </Initials>
            )}
          </Avatar>
          <Paragraph small className="mt0 mb0">{fullName}</Paragraph>
        </Link>
      </Container>
    );
  }

  return (
    <Container>
      <Avatar>
        {profilePicture && (
          <img
            src={LoadImage(`/accounts/${userId}/${profilePicture}`, true)}
            alt={fullName}
            className="profilePicture"
          />
        )}
        {!profilePicture && (
          <Initials>
            {nameInitials(user)}
          </Initials>
        )}
        {gameType && (
          <GameLevel>
            <Badge small double={gameType === 'doubles'}>
              <Paragraph smaller bold>{gameLevel ?? level ?? '-'}</Paragraph>
            </Badge>
          </GameLevel>
        )}
      </Avatar>
      <Paragraph bold className="mb0">
        {fullName}
        <span
          css={css`
            color: ${colors.mediumTurquoise};
            font-weight: normal;
            margin-left: 5px;
          `}
        >
          #{userId}
        </span>
      </Paragraph>
      <Paragraph small>{birthDateAndLocation}</Paragraph>
    </Container>
  );
};

export default ProfileCard;
