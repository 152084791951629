export default {
  goodMorning: 'Bună dimineața',
  goodAfternoon: 'Bună ziua',
  goodEvening: 'Bună seara',
  neutralGreeting: 'Salut',
  sendItAgain: 'Trimite din nou',
  todayWeather: 'Today is {{ weatherType }}, {{ degrees }}°C in {{ location }}',
  unavailableWeather: 'Prognoza meteo indisponibilă.',
  verifyYourEmailAddress: '<0>Te rugăm să-ți verifici email-ul.</0> Adresa de email trebuie confirmată înainte de a putea utiliza site-ul nostru.',
  profileCompleted: '<0>Profilul tău este finalizat.</0> Bucură-te de întreaga experiență <2>{{ siteName }}</2>!',
  passwordResetSuccessfully: 'Te rugăm să verifici email-ul pentru instrucțiunile de resetare a parolei.',
  confirmEmailTitle: 'Te rugăm să îți verifici email-ul. <1></1> Trebuie să vă verificăm adresa de e-mail înainte de a utiliza <3>{{ process.env.REACT_APP_SITE_NAME }}</3>.',
  confirmEmailSubtitle: 'Nu ai primit e-mail-ul de validare? Solicită retrimiterea lui pentru a finaliza confirmarea adresei tale de e-mail.',
  ACTIVATION_EMAIL_SENT_SUCCESSFULLY: 'E-mail-ul de verificare a fost trimis cu succes.',
  ACCOUNT_DOES_NOT_EXIST: 'Contul nu există.',
  ACTIVATION_EMAIL_WAS_NOT_SENT: 'E-mail-ul de verificare nu a fost trimis.',
  minAgeRequired:
    `Începând cu 2022, creștem vârsta minimă necesară pentru a participa la evenimentele Sportya la 15 ani. 
  Această schimbare se reflectă și în proiectul nostru <1>Tenis10 (www.tenis10.ro)</1>, care va permite acum jucătorilor să se înscrie până la vârsta de 14 ani,
  la evenimentele organizate pentru copii și juniori. Contul tău Sportya va rămâne activ, dar nu te vei putea înregistra la evenimente până când nu vei atinge vârsta minimă.`,
  completeProfile: {
    title: 'Finalizează setarea profilului tău și bucură-te de întreaga experiență {{ siteName }}!',
    profileDetails: 'Detaliile profilului personal',
    setProfileDetails: 'Setează detaliile',
    profileVisibility: 'Vizibilitatea profilului',
    setProfileVisibility: 'Setează vizibilitatea',
    gameLevel: 'Nivel de joc',
    setGameLevel: 'Alege nivelul tău de joc',
    profilePicture: 'Poza profilului tău',
    setProfilePicture: 'Încarcă poza ta',
    availability: 'Disponibilitatea pentru joc',
    setAvailability: 'Setează disponibilitatea',
    skip: 'Treci peste',
  },
  welcome: {
    hello: 'Salut {{username}}',
    welcomeToPlatform: 'Bun venit în {{site}}!',
    welcomeText: 'Înainte de a începe, ajută-ne să îți completăm profilul pentru cea mai bună experiență. Durează doar un minut!',
    startBtn: 'Să începem',
    logout: 'Deconectați-vă',
  },
  choseLanguage: 'Alege limba ta preferată pentru a continua',
  upcomingCompetitions: 'Competiții Viitoare',
  seeMyEvents: 'Evenimentele mele',
  findAnEvent: 'Găsește un Eveniment',
  searchForEventsOrCompetitions: 'Caută competiții în zona ta',
  gettingStarted: 'Primii pași',
  gettingStartedDescription: 'Bucură-te de toate funcționalitățile și joacă meciuri în <1>{{ process.env.REACT_APP_SITE_NAME }}</1>',
  letsStart: 'Să Începem',
  websiteGuides: 'Ghiduri <0>{{siteTitle}}</0>',
  learnMore: 'Află mai multe',
  pendingUpdate: 'În curs de actualizare',
  leaderboard: 'Clasament',
  updated: 'Actualizare: <1>date</1>',
  stats: 'Statistici',
  singlesPlayed: 'Meciuri de Simplu',
  doublesPlayed: 'Meciuri de Dublu',
  allTime: 'Total',
  registrationDate: 'Data Înregistrării',
  friendlyMatches: 'Meciuri Amicale',
  pvpEloCalculator: 'Calculator ELO Head2Head',
  pvpEloCalculatorDescription: 'Alege 2 jucători și vezi șansele fiecăruia de a câștiga meciul, în competiție directă.',
  calculate: 'Calculează',
  player: 'Jucător',
  searchPlayer: 'Caută un jucător',
  pageTitle: 'Dashboard',

  accountLeaderboard: {
    title: 'Clasament',
    updated: 'Actualizare',
    singles: 'Simplu',
    doubles: 'Dublu',
    position: 'Loc',
  },

  guides: {
    1: {
      title: 'Sportya - Reguli și Reglementari ',
      text: 'Regulile oficiale care acoperă toate aspectele competiționale și tehnice care guvernează meciurile, evenimentele, clasamentele și multe altele ',
    },
    2: {
      title: 'Format Grupe de 3 - Ghid Tehnic',
      text: 'Un scurt ghid care acoperă sistemul de joc, clasamente, retrageri și avansarea în etapa eliminatorie pentru Grupuri de 3 jucători, unul dintre cele mai frecvent întâlnite formate de joc în evenimentele Sportya',
    },
    3: {
      title: 'Ligi Locale - Ghid Tehnic și Întrebări Frecvente ',
      text: 'Informații de bază cu privire la mecanica Ligilor Locale, comunicarea cu jucătorii, clasamente și întrebări frecvente care te pot ajuta să te alături sistemului de joc, cât mai rapid ',
    },
  },

  rankedMatches: '<strong>{{ranked}}</strong> Competitiv',
  unrankedMatches: '<strong>{{unranked}}</strong> Antrenament',
};
