import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';

import { Container } from '../WantToPlay/styles';
import {
  InfoContainer, InfoIcon, Popup, ViewMore, ViewMoreArrow,
} from '../../../styles';
import { CategoryTitle } from '../../styles';
import { PlayersContainer } from './styles';

import PlayerInYourArea from './components/Player';
import { P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import ContentLoader from '../../../../../components/ContentLoader';

import { fetchPlayersNearby } from '../../../reducers';

const InvitePlayersInYourArea = () => {
  const dispatch = useDispatch();
  const { accountInfo } = useSelector(state => state?.session);
  const {
    playersNearby: { data: playersNearby, status: playersNearbyStatus },
  } = useSelector(state => state?.friendlyMatches);
  const [page, setPage] = useState(1);

  useEffect(() => {
    if (
      (!isEmpty(accountInfo) && playersNearbyStatus === 'idle')
      || (playersNearbyStatus === 'succeeded' && playersNearby?.page && playersNearby?.page !== page)
    ) {
      dispatch(fetchPlayersNearby({
        page,
        limit: 8,
      }));
    }
  }, [playersNearbyStatus, accountInfo, page]);

  const loadMoreResults = async () => {
    setPage((prev) => prev + 1);
  };

  return (
    <Container>
      {playersNearby && playersNearby?.results.length > 0 && (
        <>
          <CategoryTitle large bold>
            <Trans ns="friendlyMatches" i18nKey="invitePlayerInArea">Invite a Player in your Area</Trans>
            <InfoContainer>
              <InfoIcon src={LoadImage('friendly-matches/info.svg')} alt="Find out more" />
              <Popup>
                <Trans ns="friendlyMatches" i18nKey="invitePlayerInAreaPopUp">
                  Players within a 30km proximity radius around the location set in your Sportya profile
                </Trans>
              </Popup>
            </InfoContainer>
          </CategoryTitle>

          <PlayersContainer>
            {playersNearby?.results?.map((result, key) => <PlayerInYourArea key={key} data={result} />)}
          </PlayersContainer>

          {playersNearby.totalPages > page && playersNearbyStatus !== 'loading' && (
            <ViewMore onClick={loadMoreResults}>
              <P bold>
                <Trans ns="friendlyMatches" i18nKey="viewMore">View More</Trans>
              </P>
              <ViewMoreArrow src={LoadImage('friendly-matches/view-more-arrow.svg')} alt="View more results" />
            </ViewMore>
          )}

          {playersNearbyStatus === 'loading' && playersNearby?.page !== page && (
            <PlayersContainer className="mt30">
              <ContentLoader type="userCardGrid" items={8} />
            </PlayersContainer>
          )}
        </>
      )}

      {playersNearbyStatus === 'loading' && !playersNearby && (
        <PlayersContainer>
          <ContentLoader type="userCardGrid" items={8} />
        </PlayersContainer>
      )}
    </Container>
  );
};

export default InvitePlayersInYourArea;
