export default {
  title: 'Domovská stránka',
  seoTitle: 'Hrajte v místì. Uvažujte globálnì. Pøipojte se k rostoucí mezinárodní komunitì amatérských hráèù | Sportya',
  stats: {
    failedToLoad: '<0>Naètení statistik se nezdaøilo:</0> {{error}}',
    activePlayers: '<0>Hráèi</0> <2>{{players}}</2>',
    activeEvents: '<0>Plánované události {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Kluby</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Kurty</0> <2>{{courts}}</2> ',
    activeCities: '<0>Mìsta</0> <2>{{cities}}</2>',
    activeCountries:
    `<0>Zemì</0> <2> Rumunsko, Republika
     Moldávie, Bulharsko,
     Bahrajn</2>`,
  },

  newEvents: {
    title: 'Vybrané události',
  },

  clubArea: {
    title: 'Jste manažerem tenisového klubu nebo správcem kurtù?',

    first: {
      title: 'Spravovat místní a globální události',
      desc: 'Pøizpùsobeno požadavkùm vašeho klubu a dostupnosti kurtu, kvùli maximálnímu úèinku.',
    },
    second: {
      title: 'Spojit se s novými hráèi',
      desc: 'Budujte a rozšiøujte svou hráèskou základnu o místní a globální hráèe.',
    },
    third: {
      title: 'Stát se centrem pro tenisové nadšence',
      desc: 'Podpoøte hraní podle dosažených úrovní a zvyšte zapojení a loajalitu hráèù.',
    },
    fourth: {
      title: 'Rezervovat kurty',
      desc: 'Správa a rezervace kurtù. Udržujte hráèe v aktivitì a èastìji.',
    },
    button: 'Podrobné informace',
  },

  centralPanel: {
    first: {
      title: 'Hrát víc. Hrát po svém',
      desc: 'Ohodnote svou herní úroveò a vyberte si hru ve svém stylu, poèínaje klasickými vyøazovacími turnaji poøádanými bìhem víkendu, až po akce skupinového formátu, noèní akce flexibilní místní ligy, pøátelská utkání a další.',
    },
    second: {
      title: 'Úèast na amatérských tenisových turnajích',
      desc: 'Získejte pøístup ke kalendáøi se stovkami událostí platformy Sportya, zaènìte na místní úrovni a posléze pøejdìte na mezinárodní tenisové události pro amatérské hráèe, které jsou organizovány podle dosažené herní úrovnì.',
    },
    third: {
      title: 'Pøipojit se k mezinárodní komunitì tenistù',
      desc: 'Pøedveïte svùj hráèský profil, spojte se s ostatními hráèi, soupeøte v místních a globálních amatérských žebøíècích a prožívejte svùj tenisový život naplno.',
    },
    buttons: {
      first: 'Podrobné informace',
      second: 'Pøipojit se',
    },
  },

  slider: {
    findNewGamePartners: 'Hrajte v přátelských zápasech nebo amatérských turnajích pořádaných lokálně i mezinárodně',
    joinCommunity: 'Hrajte v místì. Uvažujte globálnì.\nPøipojte se k rostoucí mezinárodní komunitì amatérských hráèù',
    improveYourGame: 'Objevte hru podle dosažené herní úrovnì, globální amatérské žebøíèky, a profily hráèù',
    joinNow: 'Pøipojit se',
    searchNow: 'Search Now',
    seeEvents: 'Zobrazit události',
  },
};
