import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { isEmpty } from 'lodash';
import { get } from '../../components/common/http';

export const fetchAccounts = createAsyncThunk('accounts/fetchAccounts', async (params) => {
  const activeParams = Object.fromEntries(Object.entries(params).filter(([, v]) => !!v));
  const filterParams = new URLSearchParams(activeParams).toString();
  const { data: { data } } = await get(`/find-players?${filterParams}`);
  return data;
});

export const fetchAccountsLocations = createAsyncThunk('events/fetchAccountsLocation', async ({ filters }) => {
  const filterParams = new URLSearchParams({ ...filters }).toString();
  const { data: { data } } = await get(`/accounts/locations?${filterParams}`);
  return data;
});

// Slice
const accounts = createSlice({
  name: 'accounts',
  initialState: {
    data: [],
    initial: [],
    newest: [],
    status: 'idle',
    error: null,
  },
  reducers: {
    setAccounts: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    resetAccounts: (state) => {
      state.data = state.initial;
    },
  },
  extraReducers: {
    [fetchAccounts.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAccounts.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
      state.initial = action.payload;
      if (isEmpty(state.newest)) {
        state.newest = [...state.data?.results?.slice(0, 8)];
      }
    },
    [fetchAccounts.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const accountsLocations = createSlice({
  name: 'locations',
  initialState: {
    data: [],
    status: 'idle',
    error: null,
  },
  extraReducers: {
    [fetchAccountsLocations.pending]: (state) => {
      state.status = 'loading';
    },
    [fetchAccountsLocations.fulfilled]: (state, action) => {
      state.status = 'succeeded';
      state.data = action.payload;
    },
    [fetchAccountsLocations.rejected]: (state, action) => {
      state.status = 'failed';
      state.error = action.error.message;
    },
  },
});

const activeFilters = createSlice({
  name: 'activeFiltersAccounts',
  initialState: {
    data: {},
  },
  reducers: {
    setActiveFilters: (state, action) => {
      state.data = { ...action.payload };
    },
  },
});

export const {
  setAccounts,
  resetAccounts,
} = accounts.actions;
export const { setActiveFilters } = activeFilters.actions;

export default {
  list: accounts.reducer,
  locations: accountsLocations.reducer,
  activeFilters: activeFilters.reducer,
};
