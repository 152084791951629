export default {
  title: 'Ранглисти',
  seoTitle: 'Ранглисти | Sportya',
  lastUpdate: 'Последна Актуализация',
  timeframe: 'Период за изчисляване на класирането',
  searchBar: 'Търсете сред {{results}} играчи',
  filters: {
    title: 'Филтри (Всички)',
    modalTitle: 'Филтри',
    singles: 'Сингъл',
    doubles: 'Двойки',
    gender: 'Пол',
    age: 'Възраст',
    reset: 'Задай нови филтри',
    apply: 'Приложи',
  },
  level: {
    4: 'Ниво 4',
    5: 'Ниво 5',
    6: 'Ниво 6',
    7: 'Ниво 7',
    8: 'Ниво 8',
    9: 'Ниво 9',
  },
  playerTableCard: {
    rank: 'Място',
    player: 'Играч',
    points: 'Точки',
    participations: 'Събития',
    elo: 'Рейтинг ELO',
    friendlyMatches: 'Приятелски мачове',
    friend: 'Friend',
    rankedFriendlyMatches: 'Приятелски Състезателни Мачове',
    generalRank: 'Общо Класиране',
    rankedMatches: 'Състезателни Мачове',
    unrankedMatches: 'Тренирайте Мачове',
    totalMatches: 'Всички Мачове',
  },
  noPlayersFound: 'Няма намерени играчи.',
  generalRankings: 'Общо Класиране',
  friendsRankings: 'Класиране На Приятели',
};
