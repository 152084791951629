import React from 'react';

import Paragraph from '../../../../components/Paragraph';

import { PlayerStyle } from './styles';
import LoadImage from '../../../../components/common/LoadImage';
import Link from '../../../../components/Link';
import getAgeFromDate from '../../../../components/common/getAgeFromDate';
import { Badge } from '../../../../components/LevelsBadges/styles';
import { P } from '../../../../components/Collection';

const FriendPlayer = ({
  player,
  length,
  idx,
  gameType,
  friendsRank,
}) => {
  const {
    profilePicture,
    firstName,
    lastName,
    _id,
    userId,
    birthDate,
    leaderboard,
  } = player;

  const locationCity = player?.location?.city;
  const locationCountry = player?.location?.country;

  const border = idx + 1 !== length;
  const rank = leaderboard?.[gameType]?.rank;
  const points = leaderboard?.[gameType]?.points;
  // let elo = player?.elo;
  const gameLevel = leaderboard?.[gameType]?.gameLevel;

  const eventsParticipation = leaderboard?.[gameType]?.eventsParticipation;
  const friendlyMatches = leaderboard?.[gameType]?.friendlyMatches;
  const previousRank = leaderboard?.[gameType]?.previousRank ?? rank;

  if (gameType === 'doubles') {
    // elo = player?.eloDouble;
  }

  const rankTrend = rank < previousRank ? 'up' : (rank > previousRank ? 'down' : 'no');
  const rankDiff = rank - previousRank;

  return (
    <PlayerStyle border={border}>
      <div className="rank">
        <Paragraph large bold>{friendsRank ?? '-'}</Paragraph>
        &nbsp;
        <div>
          <img src={LoadImage(`rank-${rankTrend}-arrow-icon.svg`)} alt="greenArrow" />
          {rankDiff > 0 && (
            <Paragraph smaller bold color="red">{rankDiff}</Paragraph>
          )}
          {rankDiff < 0 && (
            <Paragraph smaller bold color="league">{rankDiff * -1}</Paragraph>
          )}
        </div>
      </div>
      <div className="mainPlayerInfo">
        <div className="imageWrapp">
          <Link to={`/accounts/${_id}`}>
            {profilePicture ? (
              <img
                src={LoadImage(`accounts/${userId}/${profilePicture}`, true)}
                alt={firstName
                  && lastName
                  && firstName.charAt(0) + lastName.charAt(0)}
              />
            ) : (
              <div className="player__avatar d-flex">
                <div className="player__avatar--wrapp position-relative">
                  <div className="player__avatar--default d-flex">
                    <Paragraph medium bold className="text-uppercase">
                      {firstName
                        && lastName
                        && firstName.charAt(0) + lastName.charAt(0)}
                    </Paragraph>
                  </div>
                </div>
              </div>
            )}

            {gameLevel && (
              <div className="levelWrapp">
                <Badge small double={gameType === 'doubles'}><P xSmall bold>{gameLevel}</P></Badge>
              </div>
            )}
          </Link>
        </div>
        <div className="info">
          <div>
            <Link to={`/accounts/${_id}`}>
              <Paragraph bold>{firstName} {lastName} <span>#{userId}</span></Paragraph>
            </Link>
          </div>
          <div>
            <Paragraph small>
              {birthDate !== null ? `${getAgeFromDate(birthDate)} - ` : ''}
              {locationCity ? `${locationCity}, ` : ''}
              {locationCountry ? `${locationCountry}` : ''}
            </Paragraph>
          </div>
        </div>
      </div>

      <div className="points">
        <Paragraph bold>{rank ?? '-'}</Paragraph>
      </div>

      <div className="points">
        <Paragraph bold>{points ?? 0}</Paragraph>
      </div>

      <div className="participations">
        <Paragraph bold>{eventsParticipation ?? 0}</Paragraph>
      </div>

      {/* <div className="elo">
        <Paragraph bold>{ elo ?? 'N/A'}</Paragraph>
      </div> */}

      <div className="friendlyMatches">
        <Paragraph bold>{friendlyMatches?.ranked ?? 0}</Paragraph>
      </div>

      <div className="friendlyMatches">
        <Paragraph bold>{friendlyMatches?.unranked ?? 0}</Paragraph>
      </div>

      <div className="friendlyMatches">
        <Paragraph bold>{friendlyMatches?.ranked + friendlyMatches?.unranked}</Paragraph>
      </div>
    </PlayerStyle>
  );
};

export default FriendPlayer;
