import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';

import ClubPage from '../Clubs/Info';
import PlayerProfile from '../PlayerProfile';
import { clearAllTabs, fetchAccountDetails } from '../PlayerProfile/reducers';
import LoadingSpinner from '../../components/LoadingSpinner';

const Profile = ({ name }) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { accountInfo } = useSelector(state => state.session);
  const { data: visitedProfile } = useSelector(state => state.accounts.info.general);
  const isMyProfile = (name === 'my-account') || (accountInfo?.id === id);
  const didNotFetch = isEmpty(visitedProfile) || id !== visitedProfile?.id;
  const [fetchedOnce, setFetchedOnce] = useState(false);
  const isClub = [isMyProfile ? accountInfo : visitedProfile]?.role === 'club';
  const [isDataFetched, setIsDataFetched] = useState(isMyProfile);

  if (isClub) {
    return (<ClubPage />);
  }

  useEffect(() => {
    if (didNotFetch && !fetchedOnce && !isMyProfile) {
      if (visitedProfile?.id !== id) {
        dispatch(clearAllTabs({ profile: 'visitedProfile' }));
      }

      dispatch(fetchAccountDetails(id)).then(() => {
        setIsDataFetched(true);
      });

      return setFetchedOnce(true);
    }

    return setIsDataFetched(true);
  }, [id]);

  return ((isMyProfile || (!isMyProfile && !isEmpty(visitedProfile))) && isDataFetched)
    ? <PlayerProfile isMyProfile={isMyProfile} profileInfo={isMyProfile ? accountInfo : visitedProfile} />
    : <LoadingSpinner />;
};

export default Profile;
