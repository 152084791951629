/** @jsxImportSource @emotion/react */
import React, { useEffect, useState, useRef } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ModalVideo from 'react-modal-video';

import {
  H1, H2, H3, H4, Paragraph,
} from '../../components';
import LoadImage from '../../components/common/LoadImage';
import Container from '../../components/Container';
import Button from '../../components/Button';
import Link from '../../components/Link';

import {
  Hero,
  Guide,
  Video,
  Content,
  SliderContainer,
  BalancedMatches,
  HiddenPanel,
  ArticleBox,
  FirstPhase,
  SecondPhase,
  ThirdPhase,
  FourthPhase,
  FifthPhase,
  SixthPhase,
} from './styles';

const StartingGuide = () => {
  const [level, setLevel] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [arrowHidden, setArrowHidden] = useState();
  const { t: commonT } = useTranslation('common');

  // Set an event listener to check how many pixels have been scrolled
  useEffect(() => {
    window.addEventListener('scroll', hideArrow);
    return () => {
      window.removeEventListener('scroll', hideArrow);
    };
  });

  // Defines the amount of pixels needed to scroll before the arrow is hidden
  const hideArrow = () => {
    setArrowHidden(window.scrollY >= 150);
  };

  const listRef = useRef(null);

  const scrollDown = () => listRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });

  const levels = [4, 5, 6, 7, 8, 9];

  const videos = {
    4: 'kp6EmPM8T6o',
    5: '9iSEzTTtvoY',
    6: 'OTjLF_jij-8',
    7: '4OsShZOTxdw',
    8: 'RIvu-6NY0y4',
    9: 'Ukvmhrv-isw',
  };

  const settings = {
    arrows: true,
    dots: true,
    autoplay: false,
    slidesToScroll: 1,
    centerMode: true,
    infinite: true,
    className: 'center',
    centerPadding: '30%',
    slidesToShow: 1,
    speed: 500,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          centerPadding: '20%',
        },
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          centerPadding: '5%',
        },
      },
    ],
  };

  return (
    <Guide>
      <Hero arrowHidden={arrowHidden}>
        <Content>
          <H1>
            <Trans ns="guide" i18nKey="hero.title">
              Welcome to<br /> Sportya
            </Trans>
          </H1>

          <Paragraph large>
            <Trans ns="guide" i18nKey="hero.desc">
              There is a lot to learn about Sportya, so we&#39;ll start<br />
              with the essentials. Explore the guide below to<br />
              get the rundown of how matches, events and<br />
              rankings work.
            </Trans>
          </Paragraph>
        </Content>
        <div className="arrow" onClick={() => scrollDown()}>&#8595;</div>
      </Hero>
      <Container fullwidth>
        <div className="scrollTo" ref={listRef} />
        <FirstPhase>
          <img src={LoadImage('getting-started-guide/logo-flag.svg')} alt="logo" />
          <H1>
            <Trans ns="guide" i18nKey="firstPhase.title">
              What Is Sportya?
            </Trans>
          </H1>
          <Paragraph className="mb50">
            <Trans ns="guide" i18nKey="firstPhase.desc">
              Sportya is a competitive sport ecosystem where tens of <br />
              thousands of amateur players engage in friendly matches, <br />
              leagues and events to enjoy balanced play or compete <br />
              for the best spots in national and international rankings.
            </Trans>
          </Paragraph>
          <div className="contain d-flex flex-column">
            <ArticleBox>
              <img src={LoadImage('getting-started-guide/icon/compass.svg')} alt="discover" />
              <H2>
                <Trans ns="guide" i18nKey="firstPhase.articles.1.title">
                  Discover
                </Trans>
              </H2>
              <Paragraph>
                <Trans ns="guide" i18nKey="firstPhase.articles.1.desc">
                  Once you join Sportya, you become part of a large and passionate community of amateur
                  players, and you gain access to events organized in hundreds of tennis clubs. We are
                  expanding to new regions and countries, bringing you greater diversity of choice for
                  whatever path you choose - either playing locally at partner clubs or exploring and
                  seeking new experiences around the world.
                </Trans>
              </Paragraph>
            </ArticleBox>
            <div className="d-flex two">
              <ArticleBox smaller>
                <img src={LoadImage('getting-started-guide/icon/trophy.svg')} alt="challenge" />
                <H2>
                  <Trans ns="guide" i18nKey="firstPhase.articles.2.title">
                    Challenge
                  </Trans>
                </H2>
                <Paragraph>
                  <Trans ns="guide" i18nKey="firstPhase.articles.2.desc">
                    Get started with a few friendly matches, join a local league if flexibility
                    of schedule is important to you or jump right into competitive events, and
                    fight your way to the top of the national and international rankings for amateur players.
                  </Trans>
                </Paragraph>
              </ArticleBox>
              <ArticleBox smaller>
                <img src={LoadImage('getting-started-guide/icon/chat.svg')} alt="connect" />
                <H2>
                  <Trans ns="guide" i18nKey="firstPhase.articles.3.title">
                    Connect
                  </Trans>
                </H2>
                <Paragraph>
                  <Trans ns="guide" i18nKey="firstPhase.articles.3.desc">
                    Keep your friends close, meet new ones, share your experiences. Build and
                    showcase your player profile and make the most out of your tennis life.
                  </Trans>
                </Paragraph>
              </ArticleBox>
            </div>
          </div>
        </FirstPhase>

        <BalancedMatches>
          <H3>
            <Trans ns="guide" i18nKey="firstPhase.articles.4.title">Game Level</Trans>
            {' '}
            <div className="dash" />
          </H3>
          <H1><Trans ns="guide" i18nKey="firstPhase.articles.4.subtitle">Play Balanced Matches</Trans></H1>
          <Paragraph smaller>
            <Trans ns="guide" i18nKey="firstPhase.articles.4.desc">
              Every Sportya member plays at a specific Game Level. The Level determines the events
              that you can join and together with your ELO Rating, also facilitates proper matching
              both in competitive tournament play and social friendly play.
            </Trans>
          </Paragraph>
        </BalancedMatches>

        {levels && levels.length && (
          <SecondPhase>
            <HiddenPanel left />
            <HiddenPanel right />
            <SliderContainer level={commonT('level')}>
              <Slider {...settings}>
                {levels?.map((id, key) => (
                  <Video key={key}>
                    <img
                      src={LoadImage(`getting-started-guide/thumbnails/${id}.png`)}
                      alt={`Video Level ${id}`}
                      onClick={() => { setLevel(id); setOpen(!isOpen); }}
                    />
                    <div className="video_button" onClick={() => { setLevel(id); setOpen(!isOpen); }}>
                      <Paragraph bold>
                        <Trans ns="guide" i18nKey="playVideo">Play Video</Trans>
                      </Paragraph>
                      <img src={LoadImage('play-icon-black.svg')} alt="Play Video" />
                    </div>
                  </Video>
                ))}
              </Slider>
            </SliderContainer>
          </SecondPhase>
        )}

        <ThirdPhase>
          <div className="two d-flex">
            <ArticleBox bg1>
              <H2><Trans ns="guide" i18nKey="thirdPhase.1.title">6 Game Levels</Trans></H2>
              <Paragraph>
                <Trans ns="guide" i18nKey="thirdPhase.1.desc">
                  We welcome all players, from starters to seasoned players. Evaluate your Game Level
                  during account creation, get out and play and enjoy balanced matches. We offer 6
                  levels of play, each determined by a mix of abilities and experience.
                </Trans>
              </Paragraph>
            </ArticleBox>
            <ArticleBox bg2>
              <H2><Trans ns="guide" i18nKey="thirdPhase.2.title">Singles and Doubles Levels</Trans></H2>
              <Paragraph>
                <Trans ns="guide" i18nKey="thirdPhase.2.desc">
                  We treat them separately in Sportya. You will initially set a level of play indicative
                  of your Singles game potential. This will automatically be your starting level of play
                  for your Doubles matches. As you evolve, both of your levels automatically evolve with you.
                </Trans>
              </Paragraph>
            </ArticleBox>
          </div>
          {/* <ArticleBox bg3>
                        <H2>ELO Rating</H2>
                        <Paragraph>Apart from the Game Level, we calculate an ELO Rating for each player,
                        which is adjusted dynamically after each match, either part of a competitive event
                         or social friendly play. The ELO Rating is based on the algorithm created by
                         physicist Arpad Elo and is personalized for the Sportya system.
                        In Sportya, the Game Level indicates the allocation of a player in a category of players with
                        similar skills / abilities of play. The ELO rating indicates with much greater finesse the power
                          / competitiveness of the player within the Game Level he is a part of.
                        Both the Game Level and ELO work together and form the basis of our matchmaking system, both for
                            competitive events and friendly matches.</Paragraph>
                    </ArticleBox> */}
        </ThirdPhase>

        <FourthPhase>
          <div className="d-flex flex-column">
            <img src={LoadImage('getting-started-guide/icon/trophy-award.svg')} alt="comming" />
            <Paragraph className="coming-soon">
              <Trans ns="guide" i18nKey="fourthPhase.inDevelopment">
                In Development
              </Trans>
            </Paragraph>
            <H4>
              <Trans ns="guide" i18nKey="fourthPhase.1.title">
                Local League
              </Trans>
            </H4>
            <H3>
              <Trans ns="guide" i18nKey="fourthPhase.1.subtitle">
                Keep Your Play Flexible
              </Trans>
            </H3>
            <Paragraph>
              <Trans ns="guide" i18nKey="fourthPhase.1.desc">
                Local Leagues allow you to play one match per week, following a flexible
                time schedule, while keeping the spirit of the competition alive.
                Registration for Local leagues is currently FREE in Sportya.
              </Trans>
            </Paragraph>
          </div>
          <div className="d-flex flex-column">
            <img src={LoadImage('getting-started-guide/icon/rockets.svg')} alt="comming soon" />
            {/* <Paragraph className='coming-soon'>
              <Trans ns='guide' i18nKey='fourthPhase.comingSoonFM'>
                Coming in 2023
                </Trans>
                </Paragraph> */}
            <H4>
              <Trans ns="guide" i18nKey="fourthPhase.2.title">
                Friendly Matches
              </Trans>
            </H4>
            <H3>
              <Trans ns="guide" i18nKey="fourthPhase.2.subtitle">
                Find New Game Partners
              </Trans>
            </H3>
            <Paragraph>
              <Trans ns="guide" i18nKey="fourthPhase.2.desc">
                Friendly matches are the most flexible way to experience tennis in Sportya.
                Friendly matches can be played anytime, anywhere, with other Sportya members.
                Our matchmaking system will help you find a suitable game partner, find a suitable
                location, and report the result online, contributing to your ranking and player profile.
                Some features are in development at the moment but worth the wait.
              </Trans>
            </Paragraph>
          </div>
        </FourthPhase>

        <FifthPhase>
          <H3>
            <Trans ns="guide" i18nKey="fifthPhase.title">Ranked Events</Trans>
            {' '}
            <div className="dash" />
          </H3>
          <H1><Trans ns="guide" i18nKey="fifthPhase.subtitle">Your Best Match Awaits</Trans></H1>
          <Paragraph smaller>
            <Trans ns="guide" i18nKey="fifthPhase.desc">
              The bread and butter of Sportya, Ranked Events are being organized in a coherent yearly
              calendar, allowing you to compete in local and global amateur rankings and aim for the top.
              Sportya is to amateur players what the ATP / WTA is to professional players.
            </Trans>
          </Paragraph>
          <div className="mt50">
            <div className="contain d-flex">
              <ArticleBox bg4 alignLeft>
                <img src={LoadImage('getting-started-guide/icon/ranked.svg')} alt="ranked events" />
                <H2>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.1.title">
                    Ranked Event Categories
                  </Trans>
                </H2>
                <Paragraph>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.1.desc">
                    1st Serve, Silver, Gold, Platinum, Special and Tournament of Champions.
                    Each has a specific role in the calendar and entry requirements differ from
                    one category to the other. For each event, we organize one or more competitions
                    based on Game Levels, either for Singles or Doubles.
                  </Trans>
                </Paragraph>
              </ArticleBox>
              <ArticleBox bg4 alignLeft>
                <img src={LoadImage('getting-started-guide/icon/formats.svg')} alt="event formats" />
                <H2>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.2.title">
                    Event Formats
                  </Trans>
                </H2>
                <Paragraph>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.2.desc">
                    Enjoy a multitude of formats, from classic, knockout events,
                    organized during the weekend, to group format events, night events and more.
                  </Trans>
                </Paragraph>
              </ArticleBox>
            </div>
            <div className="contain d-flex">
              <ArticleBox bg4 alignLeft>
                <img src={LoadImage('getting-started-guide/icon/match-formats.svg')} alt="match formats" />
                <H2>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.3.title">
                    Match Formats
                  </Trans>
                </H2>
                <Paragraph>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.3.desc">
                    Most matches in Sportya Ranked Events are played as 2 Tie Break Sets
                    with match tie break (10 points) at 1 set all.
                  </Trans>
                </Paragraph>
              </ArticleBox>
              <ArticleBox bg4 alignLeft>
                <img src={LoadImage('getting-started-guide/icon/packs.svg')} alt="participation pack" />
                <H2>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.4.title">
                    Participation Pack
                  </Trans>
                </H2>
                <Paragraph>
                  <Trans ns="guide" i18nKey="fifthPhase.articles.4.desc">
                    Besides access to the event and all corresponding implications of the event
                    taking place in Sportya (event coordination, results, rankings, activity updates,
                    customer care etc.), entry fees in Sportya generally include 2 new premium
                    tennis balls allocated per match and a bottle of water per match.
                  </Trans>
                </Paragraph>
              </ArticleBox>
            </div>
          </div>
        </FifthPhase>

        <SixthPhase>
          <H1>
            <Trans ns="guide" i18nKey="sixthPhase.title">
              Start Playing
            </Trans>
          </H1>
          <Paragraph>
            <Trans ns="guide" i18nKey="sixthPhase.desc">
              At the end of the day, it all comes down to this - we encourage fun and engaging competition,
              the foundation of sport, that ultimately leads to progress in your game and in everyday life.
            </Trans>
          </Paragraph>
          <Button wide outline>
            <Link to="/events">
              <Trans ns="forPlayers" i18nKey="findAnEvent">
                Find an Event ⟶
              </Trans>
            </Link>
          </Button>
        </SixthPhase>
      </Container>
      <ModalVideo
        channel="youtube"
        autoplay
        isOpen={isOpen}
        videoId={videos[level]}
        onClose={() => setOpen(false)}
      />
    </Guide>
  );
};

export default StartingGuide;
