export default {
  title: 'Find a Player',
  seoTitle: 'Players | Sportya',
  playerProfileSeoTitle: '{{firstName}} {{lastName}} - Players | Sportya',
  allPlayers: 'All Players',
  singleLevel: 'Singles Level',
  doubleLevel: 'Doubles Level',
  playerHand: 'Playing Hand',
  availabilityFriendly: 'Availability for Friendly Matches',
  availabilityFriendlyNotSet: 'Availability for Friendly Matches is not set yet',
  activity: 'Activity',
  titles: 'Titles',
  leaderboards: 'Rankings',
  levelNotSet: 'Level not set',
  singles: 'Singles',
  doubles: 'Doubles',
  points: 'Points',
  stats: 'Stats',
  pickYear: 'Year',
  allActivities: 'All Activities',
  level: 'Level',
  round: 'Round',
  opponent: 'Opponent',
  eloRating: 'ELO Rating',
  leaderboard: 'Ranking',
  score: 'Score',
  wL: 'W/L',
  winStatusShorthand: 'W',
  loseStatusShorthand: 'L',
  woStatusShorthand: 'W.O.',
  eloDescription: 'Rank before tournament: ',
  champion: 'Champion',
  finalist: 'Finalist',
  week: 'Week',
  calculationDate: 'Calculation Date',
  rank: 'Rank',
  move: 'Move',
  bestResults: 'Best Competition Results',
  pointsSummary: 'Points Summary',
  playerRank: 'Player Rank',
  totalPoints: 'Total Points',
  competitionPoints: 'Competition Points',
  friendlyMatchPoints: 'Ranked Friendly Match Points',
  basePoints: 'Base Points',
  penaltyPoints: 'Penalty Points',
  playerFound: '<0>{{ totalResults }} player</0> found in <2>{{ location }}</2>',
  playersFound: '<0>{{ totalResults }} players</0> found in <2>{{ location }}</2>',
  playerFoundNc: '<0>{{ totalResults }} player</0> found',
  playersFoundNc: '<0>{{ totalResults }} players</0> found',
  changePhoto: 'Change photo',
  setYourGameLevel: 'Set Your Game Level',
  gameLevel: 'Game level',
  setYourAvailability: 'Set Your Availability',
  availabilityForFriendlyMatches: 'Availability for friendly matches',
  sendMessage: 'Send Message',
  myEvents: 'My Events',
  mySettings: 'My Settings',
  newestPlayers: 'Newest Players',
  player: 'Player',
  friendlyMatches: 'Friendly Matches',
  registrationDate: 'Registration Date',
  findAPlayer: 'Find a Player',
  joined: 'Joined',
  upgradedAt: 'Level changed to',
  best7results: 'Best 7 results',
  competitionStats: 'Competition Ssstats',
  friendlyMatchStats: 'Friendly Match Stats',
};
