import React from 'react';
import { isEmpty } from 'lodash';
import { Trans } from 'react-i18next';

import { Container, CourtDescription, ImageWrapper } from './styles';

import { Button, Paragraph } from '../../../../../components';
import LoadImage from '../../../../../components/common/LoadImage';
import Link from '../../../../../components/Link';

const CourtInfoCard = ({
  clubInfo,
  setBookingInfo,
  startDate,
  startHour,
}) => {
  const {
    _id,
    clubName,
    location,
    coverImage,
    startingPrice,
    currency,
    courts,
  } = clubInfo;

  const { date, time } = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );
  const bookingStartDate = startDate ?? date;
  const bookingStartHour = time ?? startHour;
  const availableHours = [...new Set(clubInfo?.availableHours)]
    .sort()
    .filter((value) => (bookingStartHour && value >= bookingStartHour) || (!bookingStartHour && value))
    .slice(0, 3);
  const hasIndoorCourts = (courts || []).filter((court) => court.type === "indoor")?.length > 0;

  return (
    <Container>
      <Link to={`/clubs/${_id}?date=${bookingStartDate}`}>
        <ImageWrapper>
          <img
            src={LoadImage(coverImage ? `clubs/${_id}/${coverImage}` : null, true)}
            alt={clubName}
            className="img-responsive"
          />
          {hasIndoorCourts && (
            <div className="badge">
              <Trans ns="clubs" i18nKey="indoorCourts">Indoor Courts</Trans>
            </div>
          )}
        </ImageWrapper>
      </Link>
      <CourtDescription>
        <Paragraph medium bold className="ellipsis">
          <Link to={`/clubs/${_id}?date=${bookingStartDate}`} className="clubName">{clubName}</Link>
        </Paragraph>
        <Paragraph small className="ellipsis">{location?.name}</Paragraph>
        {!isEmpty(availableHours) && (
          <>
            <Paragraph smaller className="startingPrice">
              <Trans ns="clubs" i18nKey="startingPriceFrom">
                From {{ startingPrice }} {{ currency }}
              </Trans>
            </Paragraph>
            <ul>
              {availableHours.map((hour, key) => (
                <li
                  key={key}
                  onClick={() => setBookingInfo({
                    clubInfo,
                    startDate: bookingStartDate,
                    startHour: hour,
                  })}
                >
                  {hour}
                </li>
              ))}
            </ul>
          </>
        )}

        {isEmpty(availableHours) && !isEmpty(clubInfo?.courts) && (
          <Button outline small>
            <Link to={`/clubs/${_id}?date=${bookingStartDate}`}>
              <Trans ns="clubs" i18nKey="seeOtherAvailabilities">
                See Other Availabilities
              </Trans>
            </Link>
          </Button>
        )}

        {isEmpty(clubInfo?.courts) && (
          <Button outline small disabled>
            <Trans ns="clubs" i18nKey="comingSoon">
              Coming Soon
            </Trans>
          </Button>
        )}
      </CourtDescription>
    </Container>
  );
};

export default CourtInfoCard;
