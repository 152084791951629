/* eslint-disable jsx-a11y/label-has-associated-control */
/** @jsxImportSource @emotion/react */
import React from 'react';
import { Field } from 'formik';
import { css, useTheme } from '@emotion/react';

const Radio = ({
  label, name, value, ...props
}) => {
  const theme = useTheme();
  const { functions } = theme;
  return (
    <div
      css={css`

        label {
          display:flex;
          position: relative;
          padding-left: ${functions?.toEm(35)};
          cursor: pointer;
          font-size: ${functions?.toEm(16)};
          line-height: ${functions?.toEm(22)};
          user-select: none;
          opacity: 1;
          color: ${theme.colors?.black};
          top: 0;
          left: 0;
          pointer-events: all;
          vertical-align: middle;
          pointer-events: all;
          align-items: center;
          justify-content: flex-end;
          flex-flow: row-reverse;

          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;

            &:checked {
              & ~ .checkmark {
                background: ${theme.colors?.black};
              }
            }
          }

          &:hover {
            input {
              & ~ .checkmark {
                background: ${theme.colors?.primary};
                &:after {
                  display: block;
                }
              }
            }
          }
        }

        .checkmark {
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          top: 0;
          left: 0;
          height: 25px;
          width: 25px;
          background-color: ${theme.colors?.white};
          border-radius: 50%;
          border: 2px solid ${theme.colors?.black};

          > img
          {
            z-index: 1;
          }
        }
      `}
      {...props}
    >
      <label>
        <Field
          css={css`
            font-size: ${functions?.toEm(14)};
          `}
          type="radio"
          name={name}
          checked={props.checked}
          value={value}
          {...props}
        />
        <span className="textRadio">{label}</span>
        <span className="checkmark">{props.checkmark && (<img src={props.icon} alt="Checkmark Icon" />)}</span>
      </label>
    </div>
  );
};

export default Radio;
