import React from 'react';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Contain, P } from '../../../../../components/Collection';
import LoadImage from '../../../../../components/common/LoadImage';
import getAgeFromDate from '../../../../../components/common/getAgeFromDate';
import { FriendsIcon } from '../../../../Friends/components/FriendCard/styles';

export const PlayerLink = styled.a({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    textDecoration: 'none',
  },
});

export const SearchOptionContainer = styled.div(({ noHover, width, isLFP }) => ({
  display: 'flex',
  alignItems: 'center',
  margin: '0 0 20px 0',
  padding: isLFP ? 'unset' : '0 15px',
  minHeight: '50px',
  background: '#fff',
  borderRadius: '4px',
  '&:hover': {
    textDecoration: 'none',
  },
  '@media (max-width: 700px)': {
    flexDirection: 'column',
    alignItems: 'unset',
  },
  ...width && {
    width,
  },
  ...!noHover && {
    userSelect: 'none',
    transition: 'all .2s ease-out',
    '&:hover': {
      background: '#EAF6F6',
    },
  },
  ...isLFP ? {
    margin: '8.5px 0',
    '&:first-of-type': {
      margin: '0 0 8.5px 0',
    },
    '&:last-of-type': {
      margin: '8.5px 0 0 0',
    },
  } : {
    cursor: 'pointer',
    '&:last-of-type': {
      margin: 'unset',
    },
  },
}));

export const ContainAvatar = styled.div({
  position: 'relative',
  margin: '0 10px 0 0',
  '.badge': {
    position: 'absolute',
    left: '50%',
    bottom: '-8px',
    transform: 'translateX(-50%)',
  },
});

export const Avatar = styled.img({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '38px',
  width: '38px',
  objectFit: 'cover',
  objectPosition: 'top',
  border: '1px solid rgb(234, 246, 246)',
  borderRadius: '50%',
});

export const Initials = styled.div({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '38px',
  width: '38px',
  border: '1px solid rgb(234, 246, 246)',
  borderRadius: '50%',
});

export const ID = styled.span({
  margin: '0 0 0 5px',
  fontWeight: 400,
  color: '#78ADAB',
});

const SearchOption = ({ player, handler, isDouble = false }) => {
  const { accountInfo } = useSelector(state => state.session);
  const { t } = useTranslation('common');

  const {
    userId,
    profilePicture,
    firstName,
    lastName,
    birthDate,
    location,
  } = player;

  let level = player.gameLevelSingle;

  if (isDouble) level = player.gameLevelDouble;

  const nameInitials = () => {
    const first = firstName?.charAt(0) !== undefined ? firstName?.charAt(0).toUpperCase() : '';
    const last = lastName?.charAt(0) !== undefined ? lastName?.charAt(0).toUpperCase() : '';
    return `${first} ${last}`;
  };

  const avatarType = profilePicture ? 'picture' : 'initials';
  const user = {
    avatar: {
      picture: <Avatar src={LoadImage(`accounts/${userId}/${profilePicture}`, true)} alt={nameInitials()} />,
      initials: <Initials><P bold>{nameInitials()}</P></Initials>,
    },
    level: level ? ` - ${t('gameLevel')}: ${level}` : '',
    location: location?.city && location?.country ? ` - ${location.city}, ${location.country}` : '',
  };

  return (
    <SearchOptionContainer onClick={() => handler(player)}>
      <ContainAvatar>
        {user.avatar[avatarType]}
      </ContainAvatar>
      <Contain direction="column">
        <P bold>
          {accountInfo?.friendsIds?.includes(userId) && (
            <FriendsIcon src={LoadImage('friend-confirmed-icon.svg')} />
          )}
          {`${firstName} ${lastName}`}
          <ID>
            #
            {userId}
          </ID>
        </P>
        <Contain align="center">
          <P xSmall>{`${birthDate && getAgeFromDate(birthDate)}${ user.location }${user.level}`}</P>
        </Contain>
      </Contain>
    </SearchOptionContainer>
  );
};

export default SearchOption;
