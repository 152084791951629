export default {
  title: 'Homepage',
  seoTitle: 'Joacă la nivel local. Accede la nivel internațional. Alătură-te unei comunități globale de jucători amatori | Sportya',
  stats: {
    failedToLoad: '<0>Statistici indisponibile:</0> {{error}}',
    activePlayers: '<0>Jucători</0> <2>{{players}}</2>',
    activeEvents: '<0>Evenimente planificate {{currentYear}}</0> <2>{{events}}</2>',
    activeClubs: '<0>Cluburi</0> <2>{{clubs}}</2>',
    activeCourts: '<0>Terenuri</0> <2>{{courts}}</2>',
    activeCities: '<0>Orașe</0> <2>{{cities}}</2>',
    activeCountries:
    `<0>Țări</0> <2>Romania, Republica
     Moldova, Bulgaria,
     Bahrain</2>`,
  },

  newEvents: {
    title: 'Evenimente Recomandate',
  },

  clubArea: {
    title: 'Ești Manager de Club sau Administrator de Terenuri?',

    first: {
      title: 'Gestionează evenimente locale și globale',
      desc: 'Personalizate pentru nevoile clubului dvs. și disponibilitățile terenurilor, pentru un impact maxim.',
    },
    second: {
      title: 'Conectează-te cu noi jucători',
      desc: 'Construiește și extinde-ți baza de jucători.',
    },
    third: {
      title: 'Devino un hub pentru pasionații de tenis',
      desc: 'Sprijină jocul organizat pe niveluri de abilități. Sporește implicarea și loialitatea jucătorilor din club.',
    },
    fourth: {
      title: 'Închiriează terenuri de tenis',
      desc: 'Gestionează și oferă terenurile pentru închiriere către întreaga comunitate Sportya. Stimulează implicarea jucătorilor și crește frecvența de joc.',
    },
    button: 'Află mai multe',
  },

  centralPanel: {
    first: {
      title: 'Joacă mai mult. Joacă așa cum vrei tu',
      desc: 'Evaluează-ți nivelul și alege cum vrei să joci, de la evenimente clasice, knockout, organizate în weekend, la evenimente cu format de grupe, evenimente organizate in timpul serii, Ligi Locale flexibile, meciuri amicale și multe altele.',
    },
    second: {
      title: 'Concurează în turnee de tenis pentru amatori',
      desc: 'Accesează un calendar de sute de evenimente Sportya, joacă la nivel local și avansează la evenimente internaționale de tenis pentru jucători amatori, toate organizate pe bază de niveluri de joc.',
    },
    third: {
      title: 'Alătură-te unei comunități internaționale de jucători de tenis',
      desc: 'Crează-ți propriul profil de jucător, conectează-te cu alți jucători și profită la maxim de experiența jocului de tenis.',
    },
    buttons: {
      first: 'Află mai multe',
      second: 'Participă',
    },
  },

  slider: {
    findNewGamePartners: 'Joacă în meciuri amicale sau în turnee pentru amatori, organizate la nivel local și internațional',
    joinCommunity: 'Găsește și rezervă terenuri de tenis în cluburi partenere',
    improveYourGame: 'Descoperă meciuri pe bază de nivel de joc, clasamente pentru amatori și profile personale',
    joinNow: 'Înscrie-te Acum',
    searchNow: 'Caută Acum',
    seeEvents: 'Alfă mai multe',
  },
};
