import React, { useState, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { debounce, isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';

import { get } from '../../../../components/common/http';
import { MatchFormSearchBar, MatchFormSearchContainer, MatchFormSearchResults } from './styles';
import ToggleVisible from '../../../../components/common/ToggleVisible';
import MatchFormSearchOption from './components/PlayerOption';

const FriendlyMatchesFormSearchBar = ({
  handler,
  isOpponent,
  isPartner,
  isDoubles,
  selectedOption: selectedPlayer,
  hasError,
  bottomMargin,
  match,
  teams,
}) => {
  const inputRef = useRef(null);
  const { playerPartner, opponent, opponentPartner } = teams || {};

  const { ref, isComponentVisible, setIsComponentVisible } = ToggleVisible(false);
  const { accountInfo } = useSelector(state => state.session);
  const [suggestions, setSuggestions] = useState(null);
  const [, setIsLoading] = useState(false);
  const { t } = useTranslation('friendlyMatches');
  const [selectedOption, selectOption] = useState(selectedPlayer ?? false);
  const inputPlaceholder = isPartner
    ? t('partner') : `${t('scoresData.opponent')}${isOpponent > 0
      ? ` ${isOpponent}` : ''}`;

  const options = useMemo(() => suggestions, [suggestions]);
  const hasOptions = !isEmpty(options);

  const fetch = async player => get(`/find-players/search?string=${player}&ignoreBlocked=true`).then(payload => {
    const { data: { data: { results: playersList } = {} } } = payload;

    if (playersList?.length) {
      return (playersList?.slice()).filter(user => (user?.userId !== accountInfo?.userId)
        && (user?.location?.city || user?.location?.city)
        && (user?.gameLevelDouble || user?.gameLevelSingle)
        && (user?.userId !== playerPartner?.userId)
        && (user?.userId !== opponent?.userId)
        && (user?.userId !== opponentPartner?.userId));
    }

    return [];
  }).then(list => {
    setSuggestions(list);
    setIsComponentVisible(true);
    return setIsLoading(false);
  });

  const debouncedFetch = debounce(fetch, 150);
  const debouncedFetch2 = debounce(fetch, 50);

  const handleSearching = player => {
    if (player.length) {
      if (player.length <= 60) {
        if (player.length >= 20) {
          debouncedFetch(player);
          return setIsLoading(true);
        }
        debouncedFetch2(player);
        return setIsLoading(true);
      }
    }
  };

  const showResults = () => {
    if (hasOptions) {
      return setIsComponentVisible(true);
    }
  };

  const handleOptionSelection = option => {
    handler(option);
    return selectOption(option);
  };

  const invitations = {
    [match?.player?.userId]: match?.player?.invitation,
    [match?.playerPartner?.userId]: match?.playerPartner?.invitation,
    [match?.opponent?.userId]: match?.opponent?.invitation,
    [match?.opponentPartner?.userId]: match?.opponentPartner?.invitation,
  };

  return selectedOption ? (
    <MatchFormSearchOption
      player={selectedOption}
      invitationStatus={invitations[selectedOption.userId]}
      handler={handleOptionSelection}
      playerInputPlaceholder
      isDoubles={isDoubles}
      hasError={hasError}
      bottomMargin={bottomMargin}
    />
  ) : (
    <MatchFormSearchContainer>
      <MatchFormSearchBar
        maxLength={60}
        onClick={() => showResults()}
        onInput={e => handleSearching(e.target.value)}
        placeholder={inputPlaceholder}
        bottomMargin={bottomMargin}
        ref={inputRef}
      />

      {hasOptions && isComponentVisible && (
        <MatchFormSearchResults ref={ref}>
          {options?.map((player, key) => (
            <MatchFormSearchOption
              key={key}
              player={player}
              invitationStatus={invitations[player.userId]}
              isDoubles={isDoubles}
              handler={handleOptionSelection}
            />
          ))}
        </MatchFormSearchResults>
      )}
    </MatchFormSearchContainer>
  );
};

export default FriendlyMatchesFormSearchBar;
