import React, { useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { components } from 'react-select';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import Paragraph from './Paragraph';
import LinkWrapper from './Link';
import Select from './Form/Select';

const BillingDataSelector = ({ billingAs, setBillingAs }) => {
  const history = useHistory();
  const { t } = useTranslation('clubs');
  const { accountInfo } = useSelector((state) => state?.session);
  let billingData;

  useEffect(() => {
    if (!isEmpty(accountInfo?.billingData) && !billingAs) {
      const entityType = Object.keys(accountInfo.billingData)
        .filter((v) => !!accountInfo?.billingData[v].default)
        .shift();
      setBillingAs(entityType || 'individual');
    }
  }, [accountInfo, billingAs]);

  if (!isEmpty(accountInfo?.billingData)) {
    billingData = Object.keys(accountInfo.billingData)
      .map((entityType) => ({
        label: entityType,
        value: entityType,
        billingInfo: accountInfo?.billingData[entityType],
      }));
  }

  if (billingData && billingData.length === 1) {
    billingData.push({
      label: 'company',
      value: 'company',
      billingInfo: null,
    });
  }

  if (!billingAs || isEmpty(billingData)) return (<></>);

  return (
    <div className="billing-data-selector-container">
      <Paragraph className="d-flex space-between">
        <Trans ns="clubs" i18nKey="billingData">
          Billing data
        </Trans>
        <LinkWrapper to="/my-account/settings?openTab=billing">
          <Trans ns="clubs" i18nKey="updateBillingData">
            Update Billing Data
          </Trans>
        </LinkWrapper>
      </Paragraph>
      <Select
        name="billingData"
        placeholder={t('selectBillingInformation')}
        onChange={({ value, billingInfo }) => {
          if ([null, undefined].includes(billingInfo?.address)) {
            return history.push(`/my-account/settings?openTab=billing`);
          }
          setBillingAs(value);
        }}
        components={{ Option }}
        options={billingData}
        formatOptionLabel={formatOptionLabel}
        width="100%"
        height="70px"
        defaultValue={billingData.filter((v) => v.value === billingAs).shift()}
        heightOptions={60}
        isSearchable={false}
        class="mb0"
      />
    </div>
  );
};

const Option = (props) => {
  const { data } = props;
  return (
    <div className="p5">
      <components.Option {...props}>
        {formatBillingData(data)}
      </components.Option>
    </div>
  );
};

const formatBillingData = ({ value, billingInfo }) => (
  <div className="d-flex flex-column">
    <Paragraph bold className="mt0">
      <Trans ns="clubs" i18nKey={value}>{value}</Trans>
      {!billingInfo?.city && (<> - <Trans ns="clubs" i18nKey="billingDataNotSet">Billing Data not set!</Trans></>)}
    </Paragraph>
    {value === 'individual' && (
      <Paragraph smaller className="mb0">
        {billingInfo.fullName} - {billingInfo.phoneNumber}
      </Paragraph>
    )}
    {value === 'company' && billingInfo && (
      <Paragraph smaller className="mb0">
        {billingInfo.companyName} - {billingInfo.cui}
      </Paragraph>
    )}
    {![null, undefined].includes(billingInfo?.address) && (
      <Paragraph smaller className="mb0">
        {billingInfo.address ?? `${billingInfo.address},`} {billingInfo.city}, {billingInfo.country}
      </Paragraph>
    )}
    {[null, undefined].includes(billingInfo?.address) && (
      <Paragraph smaller className="mb0">
        <Trans ns="clubs" i18nKey="clickToUpdateBillingData">Click to update your billing data.</Trans>
      </Paragraph>
    )}
  </div>
);

const formatOptionLabel = (data) => formatBillingData(data);

export default BillingDataSelector;
