import styled from '@emotion/styled';
import { Button } from '../../../../Collection';

export const RaiseHandBtn = styled.div((props) => ({
  display: 'flex',
  position: 'relative',
  cursor: props.interactive ? 'pointer' : 'auto',
  minWidth: props.interactive ? 'auto' : '100px',
  width: props.mobileOnly ? '100%' : 'auto',
  backgroundColor: props.outline ? '#ffffff' : '#10242C',
  border: props.outline ? props.interactive ? '2px solid #10242C' : '2px solid #EAF6F6' : '1px solid #ffffff',
  height: props.interactive ? '40px' : '36px',
  padding: '18px 20px 18px 15px',
  borderRadius: '84px',
  ...!props.noMargins && {
    margin: props.mobileOnly ? '10px 0 0 0' : props.down ? '0' : 'auto',
  },
}));

export const RaiseHandDiv = styled.div((props) => ({
  display: props.mobileOnly ? 'none' : 'flex',
  position: 'relative',
  flexDirection: props.down ? 'column' : 'row',
  alignItems: props.down ? 'end' : 'auto',
  userSelect: 'none',
  cursor: props.interactive ? 'pointer' : 'auto',

  '@media (max-width: 1160px)': {
    display: props.mobileOnly ? 'flex' : props.header ? 'none' : 'auto',
    width: props.maxContent ? 'max-content' : props.mobileOnly ? '100%' : 'auto',
    ...props.isFriendlyMatches && {
      margin: '0 0 10px 0',
    },
  },

  ...(props.down && {
    '@media (max-width: 767px)': {
      flexDirection: 'row',
      alignItems: 'center',
      gap: 20,
      marginTop: 40,
    },
  }),

  ...props.maxContent && {
    width: 'max-content',
    '@media (max-width: 1125px)': {
      ...!props.noPaddingOnMobile && {
        padding: '0 20px',
      },
    },
  },

  ...!props.header && !props.isMyProfile && {
    '&:hover > .popup': {
      visibility: 'visible',
    },
  },
}));

export const TableColumn = styled.div(() => ({
  width: '33%',
}));

export const RaiseHandImg = styled.img((props) => ({
  position: 'relative',
  top: props.arrow ? '1px' : '0',
  margin: props.arrow ? '0 0 0 10px' : '0 0 0 0',
  height: props.interactive ? 'auto' : '28px',
}));

export const RaiseHandBtnContain = styled.div(() => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const RaiseHandText = styled.span((props) => ({
  color: props.outline ? '#10242C' : 'white',
  fontWeight: '700',
  size: '14px',

  ...(props.header && {
    '@media (min-width: 1551px)': {
      display: props.fullText ? 'block' : 'none',
    },

    '@media (max-width: 1550px)': {
      display: props.fullText ? 'none' : 'block',
    },
  }),
}));

export const RaiseHandDetails = styled.span(({ position, maddMarginInDetails }) => ({
  margin: maddMarginInDetails ? '0 0 0 10px' : position === 'right' ? '0 0 0 10px' : '6px 0 0',
  display: 'flex',
  alignItems: 'center',
  fontSize: '12px',
}));

export const RaiseHandTitle = styled.h2(() => ({
  fontWeight: '700',
  fontSize: '20px',
  margin: '30px 0 0 0',
}));

export const RaiseHandDescription = styled.p(() => ({
  fontWeight: '400',
  fontSize: '16px',
  margin: '10px 0 20px 0',
  '@media (max-width: 1023px)': {
    fontSize: '14px',
  },
}));

export const RaiseHandLabel = styled.p(() => ({
  fontWeight: '700',
  fontSize: '14px',
  margin: '20px 0 21px 0',
}));

export const DropdownHeader = styled.div(() => ({
  backgroundColor: '#EAF6F6',
  height: '114px',
  padding: '0 30px',

  '@media (max-width: 1023px)': {
    height: '144px',
  },
}));

export const ModalBackground = styled.div(({ zIndex, mobileOnly }) => ({
  ...mobileOnly && {
    '@media (min-width: 1023px)': {
      display: 'none',
    },
  },
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: zIndex || 20,
  width: '100vw',
  height: '100vh',
  maxWidth: 'unset !important',
  touchAction: 'none',
  userSelect: 'none',
  background: 'rgba(0,0,0, 0.7)',
}));

export const RaiseHandDropDownList = styled.div(({ theme }) => ({
  position: 'fixed',
  left: '50%',
  transform: 'translateX(-50%)',
  maxHeight: 'calc(100vh - 30px)',
  minWidth: 'unset',
  display: 'flex',
  flexDirection: 'column',
  border: '1px solid #C1E6E5',
  borderRadius: '10px',
  boxShadow: '0px 2px 4px rgba(16, 36, 44, 0.1)',
  background: '#fff',
  overflow: 'hidden',
  width: '600px',
  cursor: 'initial',
  textAlign: 'left',
  zIndex: 99,

  '.see-all': {
    a: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      height: 56,
      color: theme.colors.primary,
    },
  },

  '@media (max-width: 1023px)': {
    width: '100%',
    maxHeight: '100vh',
    minWidth: 'unset',
    top: '0',
    borderRadius: '0',
  },
}));

export const RaiseHandContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  overflow: 'auto',
  height: '100%',
  maxHeight: '70vh',
  padding: '0 30px',
});

export const LowerHandButton = styled(Button)(({ theme: { colors } }) => ({
  minWidth: '100px',
  transition: 'all 0.3s ease-out',
  border: `2px solid ${colors.lightBlue}`,
  boxShadow: `inset 0 0 0 1px ${colors.lightBlue}`,

  '&:hover': {
    boxShadow: `inset 0 0 0 3px ${colors.lightBlue}`,
  },
}));

export const CloseButton = styled.img(({ notMobileOnly }) => ({
  display: 'none',
  position: 'absolute',
  cursor: 'pointer',
  top: '20px',
  right: '20px',

  ...notMobileOnly && {
    display: 'unset',
  },

  '@media (max-width: 1024px)': {
    display: 'unset',
  },
}));

export const InlineContainer = styled.div(({ addBottomBorder }) => ({
  display: 'inline',

  '> div': {
    minWidth: '150px',
    display: 'inline-block',
    width: 'fit-content',
    margin: '0 20px 0 0',
    height: '60px',
  },

  ...addBottomBorder && {
    borderBottom: '1px solid #EAF6F6',
  },
}));

export const Popup = styled.div(() => ({
  visibility: 'hidden',
  position: 'absolute',
  padding: '5px 10px',
  borderRadius: '4px',
  top: '-40px',
  left: '50%',
  transform: 'translateX(-50%)',
  background: 'rgb(15, 36, 44)',
  width: 'max-content',
  zIndex: 2,

  p: {
    whiteSpace: 'unset',
  },

  '@media (max-width: 670px)': {
    left: '0 !important',
    top: '-40px !important',
    transform: 'unset',
    width: 'unset',
  },
}));
