export default {
  RAISE_HAND_SUCCESS: 'Zvedl jste ruku pro přátelský zápas.',
  LOWER_HAND_SUCCESS: 'Sklonil jsi ruku.',
  createMatch: 'Nový zápas',
  createNewMatchInvite: 'Pozvánka na zápas',
  addPlayedMatch: 'Zápas odehrá',
  raiseHand: {
    text: 'Zvedni ruku',
    title: 'Zvedněte ruku pro přátelský zápas!',
    description: 'Ukaž ostatním hráčům, že máš velkou chuť hrát co nejdříve.',
    gameTypeLabel: 'Zahrajte si přátelský zápas',
    within: 'během',
    next3Days: 'Následující 3 dny',
    next7Days: 'Následující 7 dny',
    thisWeekend: 'Tento víkend',
    withPlayers: 'S Hráči',
    aboveMyLevel: 'Moje úroveň +/-1',
    myLevel: 'Pouze můj úroveň',
    lower: 'Snížte ruku',
    daysLeft: '{{time}} dny',
    dayLeft: '{{time}} den',
    singlesAndDoubles: 'Dvouhry & Čtyřhry',
    sameLevelDescription: 'Stejná úroveň',
    aboveLevelDescription: 'Úroveň +/-1',
    tooltip: 'Chce sehrát přátelský zápas co nejdříve',
  },
  startMatch: 'Start',
  startMatchButton: 'Zápas začíná',
  activeMatches: 'Zápasy <1>({{ matches }})</1>',
  requests: 'Pozvánky <1>({{ requests }})</1>',
  scores: 'Skóre',
  friendlyMatches: 'Přátelské Zápasy',
  inviteFriends: 'Pozvěte své přátele nebo jiné hráče na nový zápas nebo přidejte již odehraný zápas do Sportya',
  sportyaPartnerClubs: 'Partnerské Kluby Sportya',
  partnerClubsPopup: 'Partnerské Kluby Sportya jsou partnerská místa, kde se můžete zúčastnit akcí Sportya nebo přátelských zápasů.',
  wantToPlay: 'Chtějí hrát zápas',
  wantToPlayPopUp: 'Hráči, kteří zdvihli ruku pro okamžitý přátelský zápas.',
  viewMore: 'Ukázat více',
  nearby: 'V oblasti',
  distanceAway: '{{ kmDistance }} km',
  invite: 'Pozvěte',
  rematch: 'Hrát znovu',
  invitePlayerInArea: 'Pozvěte blízkého hráče',
  invitePlayerInAreaPopUp: 'Hráči v okruhu 30 km od místa nastaveného ve vašem profilu Sportya.',
  otherTopPicks: 'Další top doporučení',
  otherTopPicksPopUp: 'Jucétoři, které jste předtím potkali v přátelských nebo soutěžních zápasech a/nebo vaši oprávnění přátelé na Sportya.',
  searchSpecificPlayer: 'Chcete najít konkrétního hráče?',
  findAPlayer: 'Najít hráèe',
  singlesMatch: 'Dvouhra',
  doublesMatch: 'Čtyřhra',
  singlesFriendlyMatch: 'Přátelský Zápas Ve Dvouhře',
  doublesFriendlyMatch: 'Přátelský Zápas Ve Čtxřhře',
  scoresData: {
    opponent: 'Oponent',
    opponents: 'Protivníci',
  },
  ranked: 'Konkurenční',
  unranked: 'Přátelský',
  matchType: 'Typ zápasu',
  matchFormat: 'Formát zápasu',
  createdBy: 'Vytvořil',
  players: 'Hráèi',
  yourTeam: 'Vaše tým',
  opponentTeam: 'Soupeřský tým',
  viewInActivity: 'Èinnost',
  reportMatch: 'Nahlásit zápas',
  matchId: 'Zápas ID: {{ matchId }}',
  singleSet: 'Jeden Set',
  '2setsWithSuperTieBreak': 'Dva Sety A Supertiebreak',
  '2setsOutOf3': 'Dva Vítězné Sety',
  later: 'Žádná volba',
  playFriendlyMatch: 'Hraj přátelský zápas',
  playFriendlyMatchSubtitle: 'Ukaž ostatním hráčům, že chceš hrát přátelský zápas co nejdříve možná!',
  noMatch: 'Chybějící zápas',
  editMatch: 'Upravit zápas',
  cancelMatch: 'Zrušení zápasu',
  confirmMatch: 'Potvrď zápas',
  withdraw: 'Odstoupit',
  goToFriendlyMatches: 'Jít Na Přátelské Zápasy',
  requestsArrow: 'Pozvánky',
  matches: 'Zápasy',
  latestRequest: 'Nejnovější pozvání',
  nextMatch: 'Příští zápas',
  yourMatch: 'Tvůj zápas',
  noRequestsOrMatches: 'Nemáte žádné nové pozvánky ani přátelské tenisové zápasy?',
  noRequestsOrMatchesDescription: 'Pozvěte své přátele nebo jiné hráče na nový zápas nebo přidejte již odehraný zápas do Sportya',
  modalAcceptMatchTitle: 'Přijměte pozvánku na zápas',
  modalDeclineMatchTitle: 'Odmítnout pozvání na zápas',
  modalCancelMatchTitle: 'Zrušit zápas',
  modalNoMatchFromRequestsTitle: 'Zápas nebyl odehrán',
  modalNoMatchFromMatchesTitle: 'Zápas nebyl odehrán',
  modalAcceptMatchSubtitle: 'Přijímáte pozvání na tento zápas?',
  modalDeclineMatchSubtitle: 'Jste si jistý, že chcete odmítnout tuto pozvánku na zápas?',
  modalCancelMatchSubtitle: 'Jste si jistý, že chcete zrušit tento zápas?',
  modalNoMatchFromRequestsSubtitle: 'Opravdu chcete odepřít tuto účast a označit zápas jako neodehraný?',
  modalNoMatchFromMatchesSubtitle: 'Opravdu chcete odepřít tuto účast a označit zápas jako neodehraný?',
  modalWithdrawSubtitle: 'Jste si jistý, že chcete se stáhnout z tohoto zápasu?',
  accept: 'Akceptovat',
  acceptMatch: 'Přijměte zápas',
  decline: 'Odmítnout',
  cancel: 'Storno',
  goBack: 'Menjen vissza',
  mark: 'Chybějící zápas',
  addScore: 'Přidejte skóre',
  confirmScore: 'Potvrďte skóre',
  changeScore: 'Změnit skóre',
  abandoned: 'Opustil',
  addScoreInfo: 'Vaše skóre bude čekat na potvrzení ostatními účastníky po dobu až 48 hodin, poté bude automaticky ověřeno a budou generovány body v případě soutěžního zápasu.',
  changeScoreInfo: 'Pokud je aktuální skóre nesprávné, prosím vyplňte a potvrďte zápas s správným skóre. Nové skóre bude čekat na potvrzení od ostatních účastníků po dobu až 48 hodin, než budou validovány.',
  confirmScoreInfo: 'Pokud je aktuální skóre nesprávné, prosím vyplňte a potvrďte zápas s správným skóre. Nové skóre bude čekat na potvrzení od ostatních účastníků po dobu až 48 hodin, než budou validovány.',
  confirmMatchScoreInfo: 'Pokud je aktuální skóre nesprávné, prosím vyplňte a potvrďte zápas s správným skóre. Nové skóre bude čekat na potvrzení od ostatních účastníků po dobu až 48 hodin, než budou validovány.',
  played: 'Odehrán',
  seeLocation: 'Zobrazit na mapě',
  noActiveMatches: 'Nemáte žádné aktivní přátelské tenisové zápasy',
  noMatchesSubtitle: 'Nemáte žádné naplánované přátelské zápasy v této chvíli. Pozvěte své přátele nebo jiné hráče a začněte nový zápas!',
  noOpenRequests: 'Nedostali jste pozvánky',
  noRequestsSubtitle: 'Dosud jste neobdrželi pozvání na přátelské zápasy. Pozvěte své přátele nebo další hráče a hrajte spolu zápas!',
  noScores: 'Nemáte žádná ověřená skóre',
  noScoresSubtitle: 'Zatím jsi neodehrál žádný přátelský zápas se ověřenými výsledky. Ujisti se, že jsou zaznamenány a ověřeny výsledky ze všech dosavadních zápasů, nebo pozvi své přátele nebo další hráče a společně si zahrajte nový zápas!',
  reportReasons: {
    reason: 'Důvod',
    selectTheReason: 'Vyberte důvod',
    incorrectScore: 'Nesprávné skóre',
    undisputedMatch: 'Nehraný zápas',
    other: 'Jiný důvod',
    reportTextAreaInfo: 'Uveďte další informace (volitelné)',
  },
  reportedMatch: 'Nahlásili jste tento zápas',
  reportedMatchDescription: 'Provedeme analýzu tvého hlášení a pokud budou potřeba další informace, budeme se ti ozývat.',
  dateAndTime: 'Datum & Čas',
  clubOrAddress: 'Klub nebo Adresa',
  minute: 'Minut',
  hour: 'Hodina',
  format: 'Formát',
  decideLater: 'Rozhodnout později',
  sendInvite: 'Pošlete pozvánku',
  captain: 'Kapitán',
  partner: 'Partner',
  pending: 'Čekání',
  accepted: 'Potvrzeno',
  declined: 'Odmítl',
  score: 'Skóre',
  tie: 'Tie',
  matchDetails: 'Detaily zápasu',
  selectMatchType: 'Vyberte typ tenisového zápasu',
  proposedMatchFormat: 'Navrhovaný formát zápasu',
  countrySelect: 'Jsou zobrazena místa z {{country}} <0>Změňte zemi</0>',
  playerMinMaxLevelError: 'Úroveň soupeře není kompatibilní s požadavkem na úroveň pro soutěžní zápas. Prosím, vyberte hráče s úrovní hry v rozmezí {{min}}-{{max}} nebo změňte typ zápasu na Tréninkový zápas, abyste mohli pokračovat v hře s již vybraným hráčem.',
  teamMinMaxLevelError: 'Průměrná úroveň týmu není kompatibilní s požadavky na úroveň pro soutěžní zápas. Prosím, vyberte tým s průměrnou úrovní hry v rozmezí {{min}}-{{max}} nebo změňte typ zápasu na Tréninkový zápas, abyste mohli nadále hrát s již vybraným týmem.',
  updateMatch: 'Aktualizujte zápas',
  pendingScorePopup: 'Skóre čeká na potvrzení - Potvrďte prosím svůj výsledek nebo jej opravte do {{ dueTime }}.',
  invitationDeclined: 'Odmítnutá pozvánka - Prosím, zkontrolujte detaily zápasu a podnikněte nezbytná opatření',
  playerWithdrawn: 'Hráč se stáhl - Prosím, zkontrolujte detaily zápasu a podnikněte nezbytná opatření',
  noMatchReported: 'Chybějící zápas - Prosím, zkontrolujte detaily zápasu a podnikněte nezbytná opatření',
  inactiveScoreUpdate: 'Skóre v tuto chvíli nelze přidat/potvrdit/změnit kvůli nepotvrzeným hráčům/hráčů',
  decideGameFormatLater: 'Hráči mohou rozhodnout o formátu zápasu později nebo jej vyplnit po zápase.',
  hasTimeLimitError: 'Omlouváme se, ale nemůžete přidat zápas hraný v soutěžním režimu, který je starší než 5 dní. Systém umožňuje přidávat soutěžní zápasy odehrané pouze do 5 dnů od data utkání. Pokud však přesto chcete tento zápas přidat do své historie zápasů, můžete jej přidat jako cvičný zápas bez bodů.',
  confirmMatchScore: 'Potvrďte zápas a výsledek',
  rankedFriendlyMatches: 'Přátelské Soutěžní Zápasy',
  generalRank: 'Obecný Řád',
  totalMatches: 'Všechny Zápasy',
  allActivity: 'Veškerá Činnost',
  minutesUntil: 'uzávěrka {{time}} min',
  hoursUntil: 'uzávěrka {{time}} h',
  daysUntil: 'uzávěrka {{time}} dny',
  monthsUntil: 'uzávěrka {{time}} měsíce',
  yearsUntil: 'uzávěrka {{time}} roky',
  saveMatch: 'Uložit Zápas',
  gameType: 'Typ hry',
  rankedMatch: 'Soutěžní zápas',
  unrankedMatch: 'Tréninkový zápas',
  generatesPoints: 'Generuje body v žebříčku. Formát zápasu: Dva Vítězné Sety.',
  doesntGeneratePoints: 'Nevytváří body v žebříčku. Jsou k dispozici různé formáty zápasů.',
  matchInvitation: 'Pozvánka na zápas',
  playedMatch: 'Odohraný zápas',
  editParticipantWarning: 'Vezměte prosím na vědomí, že změna hráče v zápase vynuluje předchozí skóre a skóre musí být znovu zadáno a potvrzeno pro konečné ověření.',
  playedMatchForm: {
    title: 'Úspěšně jste přidali již odehraný zápas s:',
    desc: 'Váš zápas čeká na potvrzení od ostatních účastníků pro potvrzení konečného výsledku. Abychom urychlili proces validace konečného skóre, navrhujeme ti kontaktovat osobně ostatní účastníky a naléhavě je vyzvat k potvrzení tvého zápasu a skóre.',
    button: 'Pochopil jsem',
  },
  matchInviteForm: {
    title: 'Úspěšně jste odeslali pozvánku na přátelský zápas na adresu:',
    desc: 'Pentru a vă asigura că jucătorii invitați răspund în timp util, vă recomandăm să-i contactați personal și să-i încurajați să accepte invitația.',
    button: 'Pochopil jsem',
  },
  invitationPendingConfirmation: 'Pozvání na zápasy musí pozvaní hráči přijmout.',
  timeRequiredForPlayedMatch: 'Odehrané soutěžní zápasy lze přidat do 5 dnů po datu utkání.',
  modalWithdrawTitle: 'Odstoupení ze zápasu',
  modalWithdraw: 'Jste si jistý, že chcete se stáhnout z tohoto zápasu?',
  guide: {
    quickGuide: 'Rychlý Průvodce',
    title: 'Rychlý průvodce přátelskými zápasy',
    intro: 'Zažijte radost z hraní tenisu v přátelských zápasech Sportya.<br> Hrajte s přáteli nebo jinými hráči v neformálních cvičných zápasech nebo soutěžních zápasech, ve dvouhře nebo čtyřhře, a získejte body, abyste se dostali do žebříčku.',
    ranked: '<strong>Soutěžní zápasy generují body pro vítězné hráče</strong> v žebříčku, když je potvrzeno konečné skóre:<br><strong>+4 body</strong> za vítězství v zápase proti stejné úrovni<br><strong>+2 body</strong> body za vítězství v zápase proti nižší úrovni<br><strong>+6 bodů</strong> za vítězství v zápase proti vyšší úrovni',
    unranked: '<strong>Tréninkové zápasy</strong> jsou neformální zápasy pro zábavu nebo procvičování.',
    scores: '<strong>Skóre</strong> musí být potvrzeny soupeři (nebo alespoň jedním soupeřem ve čtyřhře) do 48 hodin, aby mohly být ověřeny. Jakákoli změna skóre restartuje časovač.',
    playedMatches: '<strong>Odehrané soutěžní zápasy</strong> lze přidat do 5 dnů po datu utkání.',
    withdrawals: 'Jakékoli <strong>odstoupení</strong> nebo nahlášení <strong>Chybějící zápas</strong> účastníkem zápasu vyžaduje pozornost organizátora zápasu, aby zkontroloval podrobnosti zápasu a podnikl příslušná opatření.<br><strong>1 x Chybějící zápas</strong> v utkání dvouhry ruší zápas pro oba hráče<br><strong>2 x Chybějící zápas</strong> od týmu soupeře ve čtyřhře ruší zápas pro oba týmy',
    contact: '<strong>Osobně kontaktujte ostatní účastníky</strong> zápasu a <strong>vyzvěte je, aby zápas přijali</strong>, nebo je předem upozorněte na svůj záměr provést změny nebo zápas zrušit. To pomůže vyhnout se jakémukoli zmatku nebo nepříjemnostem pro ostatní hráče.',
  },
  report: 'Nahlásit',
  gotIt: 'Pochopil jsem',
  withdrawn: 'Vybrání',
  canceled: 'Zrušeno',
  scoresChangeInfo: 'Skóre tohoto zápasu můžete změnit pouze pomocí možnosti Změnit skóre po uložení aktualizací a zavření tohoto formuláře.',
  rankedTooltip: {
    title: 'Vítězové obdrží:',
    1: '+4 body za vítězství v zápase proti stejné úrovni',
    2: '+2 body za vítězství v zápase proti nižší úrovni',
    3: '+6 bodů za vítězství v zápase proti vyšší úrovni',
  },
  rankBeforeMatch: 'Pořadí před zápasem:',
  inviteToMatch: 'Pozvánka na zápas',
  cancelPopup: {
    title: 'Opravdu chcete opustit tuto stránku?',
    stay: 'Zůstávám na stránce',
    quit: 'Opouštím stránku',
  },
  invitationMovedToMatches: 'Pozvánka byla úspěšně přijata! Zápas {{matchId}} byl přesunut do ',
  matchMovedToScores: 'Skóre bylo úspěšně potvrzeno! Zápas {{matchId}} byl přesunut do ',
  playedMatchMovedToScores: 'Skóre bylo úspěšně potvrzeno! Zápas {{matchId}} byl přesunut do ',
  playedMatchMovedToMatches: 'Zápas byl úspěšně potvrzen! Zápas {{matchId}} byl přesunut do ',
  scoreValidationInvalidWinner: 'Prosím, pečlivě zkontrolujte své skóre a vyplňte správné a úplné skóre, aby mohl být na jeho základě určen vítěz/vítězný tým.',
  landingPage: {
    header: {
      title: 'Zahrajte si perfektní zápas',
      description: `Zažijte radost z hraní tenisu s přáteli nebo jinými hráči v cvičných zápasech nebo soutěžních zápasech, dvouhře nebo čtyřhře, když budete sbírat body, abyste se dostali do žebříčku.`,
    },
    section1: {
      title: ' Znovu aktivujte svého soutěživého ducha',
      description: `Aktivujte svou vášeň pro tenis spojením s ostatními amatérskými hráči ve vašem okolí prostřednictvím Sportya. Ať už vyzýváte staré přátele nebo hledáte nové soupeře, zažijte vzrušení ze soutěžních a cvičných zápasů. Sledujte svůj pokrok a zjistěte, kdo na konci každé sezóny vyjde jako vítěz.`,
    },
    section2: {
      title: 'Hrajte flexibilně – Upravte si hru ve svém vlastním stylu',
      description: `Užijte si maximální flexibilitu s přátelskými zápasy Sportya, které jsou dostupné kdykoli a kdekoli. Převezměte iniciativu a pomocí chatu se spojte s hráči, nastavte čas a místo zápasu a připravte se na hru. Potřebujete návrhy na umístění her? Další informace o partnerských klubech najdete na klubových profilových stránkách ve Sportya, takže si můžete vybrat tu nejinspirativnější.`,
    },
    section3: {
      title: 'Zlepšete svou hru, přijměte nové výzvy',
      description: `Každý hráč představuje jedinečnou výzvu, která vám dává šanci vyvinout se a stát se lepším tenistou. Přijměte různé herní styly a využijte šanci zlepšit svou hru s každým zápasem.`,
    },
    section4: {
      title: 'Zvedněte ruku pro přátelský zápas',
      description: `Ukažte ostatním hráčům ve vašem regionu, že jste připraveni hned hrát. Použijte funkci Zvedni ruku k vyjádření své dostupnosti pro zápas v nadcházejících dnech, čímž vytvoříte vzrušující očekávání vašeho příštího zápasu Sportya.`,
    },
    section5: {
      title: 'Našli jste zápas? Zarezervujte si kurt přes Sportya',
      subtitle: 'Vydání ve 4. čtvrtletí 2023',
      description: `Objevte dostupné kurty ve vašem okolí a shromážděte své přátele na nezapomenutelný zápas. Sportya zrychlí vaši cestu od hledání zápasu k rezervaci kurtu během několika kliknutí. Rozlučte se s únavnými telefonáty a ztraceným časem.`,
    },
    actions: {
      title: 'Vybojujte své nejlepší utkání!',
      button: 'Zaregistrujte se nyní',
    }
  },
};
