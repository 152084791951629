import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import {
  Column, Container, RanksContainer, RanksHeader,
} from './styles';
import LoadImage from '../../../../components/common/LoadImage';
import { Contain, P } from '../../../../components/Collection';
import { fetchTabData, getActiveYears } from '../../reducers';
import Pagination from '../../../../components/Pagination';
import Loader from '../../../../components/ContentLoader';
import Select from '../../../../components/Form/Select';
import { FiltersContainer, TabOptionsContainer } from '../Activity/styles';
import { TabPlaceholder } from '../components/styles';
import GameType from '../components/GameType';
import Rank from './components/Rank';

const RankingsTab = ({ userId, profile }) => {
  const dispatch = useDispatch();
  const { t: commonT } = useTranslation('common');
  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const [gameType, setGameType] = useState('singles');
  const [currentPage, setCurrentPage] = useState(0);
  const [filters, setFilters] = useState({});
  const [options, setOptions] = useState({
    limit: 10,
    page: 1,
  });

  const { data: ranks, status } = useSelector(state => state.accounts.info.tabs[profile].rankings[gameType]);
  const years = useSelector(state => state.accounts.info.tabs[profile].years[gameType]);
  const didNotFetch = !ranks?.length && status === 'idle';
  const hasYearsOfActivity = !!years?.length;
  const hasMultiplePages = ranks?.totalPages > 1;
  const hasNoResults = !!ranks?.length;
  const isLoading = generalStatus === 'loading';
  const isDoubles = gameType === 'doubles';

  useEffect(() => {
    if (didNotFetch) {
      dispatch(fetchTabData({
        userId, tab: 'rankings', type: gameType, filters, options, profile,
      }));
      if (!hasYearsOfActivity) {
        dispatch(getActiveYears({ userId, type: gameType, profile }));
      }
    }
  }, [status, dispatch]);

  const handlePagination = (data) => {
    const { selected } = data || {};
    const selectedPage = selected + 1;

    if (currentPage !== selected) {
      const newOptions = {
        ...options,
        page: selectedPage,
      };

      setCurrentPage(selected);
      setOptions(newOptions);
      return dispatch(fetchTabData({
        userId, tab: 'rankings', type: gameType, filters, options: newOptions, profile,
      }));
    }
    return null;
  };

  const handleFilters = (data) => {
    const newFilters = { ...filters };
    if (!data) {
      delete newFilters.year;
    } else {
      newFilters.year = data?.value;
    }

    const newOptions = {
      ...options,
      page: 1,
    };

    setFilters(newFilters);
    setOptions(newOptions);
    return dispatch(fetchTabData({
      userId, tab: 'rankings', type: gameType, filters: newFilters, options: newOptions, profile,
    }));
  };

  const mapYears = () => {
    if (!isEmpty(years)) {
      const sortedYears = years?.slice().sort((a, b) => (a > b ? -1 : 1));

      return sortedYears?.map(year => ({
        label: `${year}`,
        value: `${year}`,
      }));
    }
  };

  return isLoading
    ? (
      <Container hasLoader>
        <Loader />
      </Container>
    ) : (
      <Container>
        <TabOptionsContainer>
          <GameType gameType={gameType} handler={setGameType} />
          <FiltersContainer>
            <Select
              name="year"
              placeholder={commonT('allYears')}
              onChange={(year) => handleFilters(year)}
              options={mapYears()}
              label={commonT('year')}
              isClearable
              isSearchable={false}
              lowInput
              {...filters.year && {
                labelOn: true,
                defaultValue: { label: filters.year, value: filters.year },
              }}
            />
          </FiltersContainer>
        </TabOptionsContainer>
        {!hasNoResults && !didNotFetch && (
          <RanksContainer>
            <RanksHeader>
              <Column maxWidth={35} flex justify="center">
                <P xSmall>
                  <Trans ns="player" i18nKey="week">
                    Week
                  </Trans>
                </P>
              </Column>
              <Column maxWidth={520} flex justify="center">
                <P xSmall>
                  <Trans ns="player" i18nKey="calculationDate">
                    Calculation Date
                  </Trans>
                </P>
              </Column>
              <Column maxWidth={520} flex justify="center">
                <P xSmall>
                  <Trans ns="player" i18nKey="leaderboard">
                    Rank
                  </Trans>
                </P>
              </Column>
              <Column maxWidth={35} flex justify="center">
                <P xSmall>
                  <Trans ns="player" i18nKey="Move">
                    Move
                  </Trans>
                </P>
              </Column>
            </RanksHeader>
            {(ranks?.results || [])?.map((rank, key) => <Rank key={key} rank={rank} isDoubles={isDoubles} />)}
          </RanksContainer>
        )}
        {hasNoResults && !didNotFetch && (
          <Contain
            width="fit-content"
            align="center"
            direction="column"
            margin="40px auto"
          >
            <TabPlaceholder src={LoadImage('tab-placeholder.png')} alt="No results found" />
            <P color="#78ADAB">
              <Trans ns="common" i18nKey="noResultsFound">
                No results found
              </Trans>
            </P>
          </Contain>
        )}
        {hasMultiplePages && (
          <Pagination
            pageCount={ranks?.totalPages || 0}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePagination}
            forcePage={currentPage}
          />
        )}
      </Container>
    );
};

export default RankingsTab;
