export default {
  title: 'Ranglisták',
  seoTitle: 'Játékos ranglisták | Sportya',
  lastUpdate: 'Utolsó frissítés',
  timeframe: 'Ranglista-számítási időkeret',
  searchBar: 'Keresés {{results}} játékosok között',
  filters: {
    title: 'Szűrő (Mind)',
    modalTitle: 'Szűrők',
    singles: 'Egyéni',
    doubles: 'Páros',
    gender: 'Nem',
    age: 'Életkor',
    reset: 'Alapértelmezett visszaállítása',
    apply: 'Alkalmazás',
  },
  level: {
    4: '4. szint',
    5: '5. szint',
    6: '6. szint',
    7: '7. szint',
    8: '8. szint',
    9: '9. szint',
  },
  playerTableCard: {
    rank: 'Rang',
    player: 'Játékosok',
    points: 'Pontok',
    participations: 'Események',
    elo: 'ELO rangsorolás',
    friendlyMatches: 'Barátságos mérkőzések',
    friend: 'Friend',
    rankedFriendlyMatches: 'Barátságos Versenymérkőzések',
    generalRank: 'Általános Rangsor',
    rankedMatches: 'Versenyképes Mérkőzések',
    unrankedMatches: 'Gyakorló Mérkőzések',
    totalMatches: 'Összes Mérkőzés',
  },
  noPlayersFound: 'Nem találtunk játékosokat.',
  generalRankings: 'Általános Rangsor',
  friendsRankings: 'Barátok Rangsor',
};
