import React, { useContext, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Contain, P, Button, H3,
} from '../../../../../../../../../../components/Collection';
import SelectComponent from '../../../../../../../../../../components/Form/Select';
import TextArea from '../../../../../../../../../../components/TextArea';
import { reportPlayer } from '../../../../../../../../../MyAccount/Settings/reducers';
import {
  ModalBackground, ReportModal as Modal, ReportModalContent, ReportModalHeader,
} from '../styles';
import { Close } from '../../../../../../../../../../components/Modal/styles';
import LoadImage from '../../../../../../../../../../components/common/LoadImage';
import { UserContext } from '../../../../../..';
import mixpanel from '../../../../../../../../../../mixpanel';
import { FriendContext } from '../../../../../../../../../Friends/components/FriendCard';
import { blockPlayer } from '../../reducers';

const ReportModal = ({ modalHandler }) => {
  let uContext = null;

  if (useContext(UserContext)) {
    uContext = useContext(UserContext);
  } else {
    uContext = useContext(FriendContext);
  }

  const { user, socketClientId } = uContext;

  const { t } = useTranslation('settings');
  const dispatch = useDispatch();
  const editorRef = useRef(null);

  const {
    firstName, lastName, userId, id, email,
  } = user || {};

  const [reportReason, setReportReason] = useState(null);
  const [editorText, setEditorText] = useState('');

  const handleDropdownChange = (e) => {
    const description = editorRef?.current.value;
    setEditorText(description);
    return setReportReason(e);
  };

  const reportReasons = [
    { value: 'harassment', label: t('profileSettings.reportReasons.harassment') },
    { value: 'spam', label: t('profileSettings.reportReasons.spam') },
    { value: 'fake-profile', label: t('profileSettings.reportReasons.fakeProfile') },
    { value: 'player-under-15-years', label: t('profileSettings.reportReasons.playerUnderFifteen') },
    { value: 'other', label: t('profileSettings.reportReasons.other') },
  ];

  const report = (blockPlayerAction = false) => {
    const description = editorRef?.current.value;

    const payload = {
      reported: {
        firstName,
        lastName,
        userId,
        id,
        email,
      },
      reason: reportReason.value,
      reasonDescription: description,
      blockReported: blockPlayerAction,
      socketClientId,
    };

    if (blockPlayerAction) {
      mixpanel.track('Confirm [Report&Block] in confirmation dialog');
      dispatch(blockPlayer({ blockingPlayerId: id, socketClientId }));
    } else {
      mixpanel.track('Confirm [Report] in confirmation dialog');
    }

    dispatch(reportPlayer(payload));
    return modalHandler(true);
  };

  return (
    <>
      <ModalBackground />
      <Modal>
        <Close src={LoadImage('close-icon.svg')} onClick={() => modalHandler()} />
        <ReportModalHeader>
          <H3>
            {t('profileSettings.blockedPlayers.reportUser', { reportName: `${firstName} ${lastName}` })}
          </H3>

          <P margin="10px 0 28px 0">
            {t('profileSettings.blockedPlayers.reportInfo')}
          </P>
        </ReportModalHeader>

        <ReportModalContent>
          <SelectComponent
            placeholder={t('profileSettings.reportReasons.selectTheReason')}
            labelOn
            lowPlaceholder
            options={reportReasons}
            label={t('profileSettings.reportReasons.reason')}
            onChange={(e) => handleDropdownChange(e)}
            value={reportReason}
            height="50px"
            isSearchable={false}
          />

          <TextArea
            placeholder={t('profileSettings.reportTextAreaInfo')}
            innerRef={editorRef}
            defaultValue={editorText}
            height="100px"
          />

          <Contain justify="center" direction="column" margin="10px 0 0 0">
            <Button onClick={() => report(true)} disabled={!reportReason}>
              {t('profileSettings.blockedPlayers.reportAndBlockButton')}
            </Button>

            <Button margin="10px 0" onClick={() => report()} disabled={!reportReason}>
              {t('profileSettings.blockedPlayers.reportButton')}
            </Button>

            <Button onClick={() => modalHandler()} outline>
              <Trans ns="events" i18nKey="cancel">Cancel</Trans>
            </Button>
          </Contain>
        </ReportModalContent>
      </Modal>
    </>
  );
};

export default ReportModal;
