import React from 'react';
import { useSelector } from 'react-redux';

import { Container } from './styles';

import Loader from '../../../../components/ContentLoader';
// import ELO from './components/Elo';
import Rankings from './components/Ranking';
import Stats from './components/Stats';
import FriendlyMatchStats from './components/FriendlyMatchStats';

const StatsTab = () => {
  const { generalStatus } = useSelector(state => state.accounts.info.tabs);
  const isLoading = generalStatus === 'loading';

  return isLoading
    ? (
      <Container hasLoader>
        <Loader />
      </Container>
    ) : (
      <Container>
        <Rankings />
        <Stats />
        <FriendlyMatchStats />
        {/* <ELO /> */}
      </Container>
    );
};

export default StatsTab;
