import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import {
  FullWidth,
  Tab,
  TabContainer,
  TabIcon,
  TabsHeader,
  TitleAndRaiseHandContainer,
  QuickGuideButton,
  ButtonIcon,
  HeaderButtonsContainer,
} from '../styles';

import { H1 } from '../../../components/Collection';
import { fetchSummary, seenRequests } from '../reducers';
import LoadImage from '../../../components/common/LoadImage';
import { Paragraph } from '../../../components';
import mixpanel from '../../../mixpanel';
import QuickGuide from './Modals/QuickGuide';
import { NewMatchButton } from '../StartMatch/styles';

const Header = ({ page }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    data: summary,
    status: summaryStatus,
    unseenRequests,
  } = useSelector(state => state?.friendlyMatches?.summary);

  const isTabSelected = (tab) => page === tab;
  const [isGuideVisible, setIsGuideVisible] = useState(false);

  useEffect(() => {
    if (summaryStatus === 'idle') {
      dispatch(fetchSummary());
    }
  }, [summaryStatus]);

  useEffect(() => {
    if (page === 'friendlymatchesrequests') {
      dispatch(seenRequests());
    }
  }, [page]);

  useEffect(() => {
    const guideSeen = localStorage.getItem('friendlyMatchesQuickGuideSeen');
    if (!guideSeen) {
      setIsGuideVisible(true);
      localStorage.setItem('friendlyMatchesQuickGuideSeen', true);
    }
  }, []);

  const matches = summary?.totalMatches ?? 0;
  const requests = summary?.totalRequests ?? 0;

  const handleCreateMatch = () => {
    mixpanel.track('Initiate NEW MATCH INVITE', { sp_action_source: 'blank' });
    const url = '/friendly-matches/create';
    history.push(url);
  };

  const handleAddPlayedMatch = () => {
    mixpanel.track('Initiate ADD PLAYED MATCH', { sp_action_source: 'blank' });
    const url = '/friendly-matches/create/played';
    history.push(url);
  };

  return (
    <>
      <TitleAndRaiseHandContainer>
        <H1 width="fit-content">
          <Trans ns="friendlyMatches" i18nKey="friendlyMatches">Friendly Matches</Trans>
          <QuickGuideButton onClick={() => setIsGuideVisible(true)}>
            <Trans ns="friendlyMatches" i18nKey="guide.quickGuide">Friendly Matches</Trans>
            <img src={LoadImage('friendly-matches/quick-guide-icon.svg')} alt="" className="ml10" />
          </QuickGuideButton>
        </H1>

        {!isTabSelected('friendlymatchesstart') && (
          <HeaderButtonsContainer>
            <NewMatchButton onClick={() => handleCreateMatch()} isHeaderButton>
              <ButtonIcon
                src={LoadImage('friendly-matches/start-match/button-trophy.svg')}
                alt="Create Match!"
                isHeaderButton
              />
              <Paragraph bold>
                <Trans ns="friendlyMatches" i18nKey="createNewMatchInvite">Create Match</Trans>
              </Paragraph>
            </NewMatchButton>

            <NewMatchButton onClick={() => handleAddPlayedMatch()} isHeaderButton>
              <ButtonIcon
                src={LoadImage('friendly-matches/start-match/button-circle-plus.svg')}
                alt="Add Played Match!"
                isHeaderButton
              />
              <Paragraph bold>
                <Trans ns="friendlyMatches" i18nKey="addPlayedMatch">Add Played Match</Trans>
              </Paragraph>
            </NewMatchButton>
          </HeaderButtonsContainer>
        )}
      </TitleAndRaiseHandContainer>
      <TabsHeader>
        <TabContainer>
          <Tab active={isTabSelected('friendlymatchesstart')} to="/friendly-matches">
            <TabIcon src={LoadImage('friendly-matches/tabs/tennis-ball.svg')} />
            <Trans ns="friendlyMatches" i18nKey="startMatch">Start Match</Trans>
          </Tab>
          <Tab active={isTabSelected('friendlymatchesavailable')} to="/friendly-matches/available">
            <TabIcon src={LoadImage('friendly-matches/tabs/tennis-racket-checkmark.svg')} />
            <FullWidth>
              <Trans ns="friendlyMatches" i18nKey="activeMatches">
                Matches
                {' '}
                <span>
                  (
                  {{ matches }}
                  )
                </span>
              </Trans>
            </FullWidth>
          </Tab>
          <Tab active={isTabSelected('friendlymatchesrequests')} to="/friendly-matches/requests">
            <TabIcon src={LoadImage('friendly-matches/tabs/tennis-racket-question-mark.svg')} />
            <FullWidth>
              <Trans ns="friendlyMatches" i18nKey="requests">
                Requests
                {' '}
                <span>
                  (
                  {{ requests }}
                  )
                </span>
              </Trans>
            </FullWidth>
            {unseenRequests && <span className="new-requests" />}
          </Tab>
          <Tab active={isTabSelected('friendlymatchesscores')} to="/friendly-matches/scores" noMargin>
            <TabIcon src={LoadImage('friendly-matches/tabs/scores.svg')} />
            <Trans ns="friendlyMatches" i18nKey="scores">Scores</Trans>
          </Tab>
        </TabContainer>
      </TabsHeader>
      <QuickGuide isGuideVisible={isGuideVisible} setIsGuideVisible={setIsGuideVisible} />
    </>
  );
};

export default Header;
