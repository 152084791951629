import React from 'react';
import styled from '@emotion/styled';
import { Link, P } from '../../../../Collection';
import LoadImage from '../../../../common/LoadImage';

const WalletContainer = styled.div(props => ({
  height: '40px',
  padding: '9px 16px',
  borderRadius: '75px',
  margin: '0 18px 0 15px',
  width: 'max-content',
  background: props.negative
    ? props.theme.colors.darkRed
    : props.positive
      ? props.theme.colors.secondary
      : props.walletBg
        ? props.theme.colors.primary
        : props.theme.colors.secondary,

  a: {
    display: 'flex',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    textDecoration: 'none',
    img: {
      marginRight: '6px',
    },
  },

  '@media (max-width: 1550px)': {
    padding: 'unset',

    a: {
      width: '40px',
      height: '40px',
      justifyContent: 'center',
      p: {
        display: 'none',
      },
      img: {
        marginRight: 'unset',
      },
    },
  },

  '@media (max-width: 767px)': {
    margin: '0 18px 0 0',
    //   display: 'none',
  },

  '@media (max-width: 479px)': {
    margin: '0 16px 0 8px',
  },
}));

const Wallet = ({ balance, currency }) => (
  <WalletContainer {...balance < 0 ? { negative: true } : balance > 0 ? { positive: true } : { neutral: true }}>
    <Link to="/my-account/wallet">
      <img src={LoadImage('wallet-icon.svg')} alt="Account Wallet" />
      <P bold color="white">
        {balance !== 0 ? balance.toFixed(2) : 0}
        {' '}
        {currency || 'RON'}
      </P>
    </Link>
  </WalletContainer>
);

export default Wallet;
