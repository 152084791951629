import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import mixpanel from '../../../../../mixpanel';
import LoadImage from '../../../../common/LoadImage';

import { P } from '../../../../Collection';

import {
  Popup,
  RaiseHandBtn,
  RaiseHandBtnContain,
  RaiseHandDetails,
  RaiseHandDiv,
  RaiseHandImg,
  RaiseHandText,
} from './RaiseHandStyles';

import { calculateDaysLeft, getDetailsText, isHandRaised } from './RaiseHandUtils';
import ToggleVisible from '../../../../common/ToggleVisible';
import getWindowSize from '../../../../common/getWindowSize';

import { showRaiseHandModal } from '../../../reducers/session';

const RaiseHand = (props) => {
  const {
    header,
    handRaisedData,
    mobileOnly,
    details,
    small,
    maddMarginInDetails,
    interactive = true,
    isFriendlyMatches,
    maxContent,
    isMyProfile,
    noPaddingOnMobile,
  } = props;

  const { ref } = ToggleVisible(false);
  const { accountInfo } = useSelector(state => state.session);
  const history = useHistory();

  const { t } = useTranslation('friendlyMatches');
  const { t: eventsT } = useTranslation('events');
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [isLevelSet, setIsLevelSet] = useState(true);
  const [handRaised, setHandRaised] = useState(isHandRaised(handRaisedData?.timeframe?.expireAt));

  const dispatch = useDispatch();

  useEffect(() => {
    if (!accountInfo.gameLevelSingle && !accountInfo.gameLevelDouble) {
      setIsLevelSet(false);
    }
  }, [accountInfo]);

  useEffect(() => {
    setHandRaised(isHandRaised(handRaisedData?.timeframe?.expireAt));

    if (handRaisedData && Object.keys(handRaisedData).length > 0) {
      const remainingDays = calculateDaysLeft(handRaisedData?.timeframe?.expireAt, handRaisedData?.timeframe?.startAt);

      if (remainingDays > 0) {
        setDaysRemaining(remainingDays);
      } else {
        setDaysRemaining(null);
      }
    }
  }, [handRaisedData]);

  const { width } = getWindowSize();
  const isMobile = width <= 1023;

  const raiseHandClicked = (e) => {
    if (!handRaised) {
      mixpanel.track('Click RAISE HAND');
    }

    if (!isLevelSet) {
      history.push('/my-account/game-level');
      return;
    }

    if (!interactive) {
      e.stopPropagation();
    }

    if (isMobile) {
      history.push('/friendly-matches/raise-hand');
    } else {
      dispatch(showRaiseHandModal());
    }
  };

  return (
    <RaiseHandDiv
      ref={ref}
      header={header}
      mobileOnly={mobileOnly}
      interactive={interactive}
      down={details === 'down'}
      isFriendlyMatches={isFriendlyMatches}
      maxContent={maxContent}
      noPaddingOnMobile={noPaddingOnMobile}
      isMyProfile={isMyProfile}
    >
      <Popup className="popup" details={details}>
        <P xSmall color="white">{t('raiseHand.tooltip')}</P>
      </Popup>

      <RaiseHandBtn
        onClick={(e) => raiseHandClicked(e)}
        outline={handRaised}
        interactive={interactive}
        mobileOnly={mobileOnly}
        isFriendlyMatches={isFriendlyMatches}
        small={small}
        down={details === 'down'}
        {...props}
      >
        <RaiseHandBtnContain mobileOnly={mobileOnly}>
          <RaiseHandImg
            src={LoadImage(!handRaised ? 'hand-not-raised.svg' : 'hand-raised.svg')}
            interactive={interactive}
          />
          {handRaised && (
            <>
              <RaiseHandText outline header={header} className="ml5" fullText>
                {daysRemaining < 1 ? ''
                  : handRaisedData?.timeframe?.name === 'thisWeekend'
                    ? t('raiseHand.thisWeekend')
                    : daysRemaining === 1
                      ? t('raiseHand.dayLeft', { time: daysRemaining })
                      : t('raiseHand.daysLeft', { time: daysRemaining })}
              </RaiseHandText>
              {header && (
                <RaiseHandText outline header={header} className="ml5">
                  {daysRemaining < 1 ? ''
                    : handRaisedData?.timeframe?.name === 'thisWeekend' ? 'W'
                      : t('raiseHand.dayLeft', { time: daysRemaining }).substring(0, 3).toLowerCase().replace(' ', '')}
                </RaiseHandText>
              )}
            </>
          )}

          {!handRaised && (
            <RaiseHandText className="ml5" header={header} fullText>
              <Trans ns="friendlyMatches" i18nKey="raiseHand.text">Raise Hand</Trans>
            </RaiseHandText>
          )}
        </RaiseHandBtnContain>
      </RaiseHandBtn>

      {(details && !header && handRaised) && (
        <RaiseHandDetails position={details} maddMarginInDetails={maddMarginInDetails}>
          {getDetailsText(handRaisedData?.matchType, handRaisedData?.opponentLevel, t, eventsT)}
        </RaiseHandDetails>
      )}

    </RaiseHandDiv>
  );
};

export default RaiseHand;
