import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useSelector } from 'react-redux';
import { Trans } from 'react-i18next';

import { Contain, H2, P } from '../../../../components/Collection';
import LoadImage from '../../../../components/common/LoadImage';
import Options from './components/Options';
import Avatar from './components/Avatar';
import Info from './components/Info';
import GameLevelInfo from './components/Info/components/gameLevel';
import PlayerRating from '../../../../components/PlayerRating';

import { store as socketStore } from '../../../../components/Socket/store';
import {
  BlockedIcon,
  Container,
  MobileGameLevelContainer,
  UserID,
} from './styles';

const bgColors = {
  premium: '#EAF6F6',
  summer: '#FFF8E1',
  free: '#f8f8f8',
};

export const UserContext = createContext();
const TopSection = ({ user, isMyProfile }) => {
  const {
    firstName,
    lastName,
    id,
    userId,
    ratingInfo,
  } = user || {};

  const globalState = useContext(socketStore);

  const { state: { socket: { socketClientId, socket } } } = globalState;
  const {
    accountInfo: {
      isImpersonate, userId: accountInfoUserId, blockedPlayers, friendsIds,
    },
  } = useSelector(state => state.session);
  const [iAmBlocked, setIAmBlocked] = useState(false);
  const [isBlocked, setIsBlocked] = useState(false);
  const [friendStatus, setFriendStatus] = useState(false);

  useEffect(() => {
    if (userId !== accountInfoUserId) {
      setIAmBlocked(!!(blockedPlayers || []).find((e) => e.user.id === id));
      setIsBlocked(!!(blockedPlayers || []).find(({ blockedUser }) => blockedUser.id === id));
    }
  }, [blockedPlayers]);

  useEffect(() => {
    if (userId && friendsIds) {
      setFriendStatus(friendsIds?.includes(userId));
    }
  }, [userId, friendsIds]);

  const accountType = user?.membership ? user?.membership.plan.split('-')[0] : null;

  const contextValue = useMemo(() => ({
    user,
    iAmBlocked,
    isBlocked,
    socketClientId,
    socket,
    setFriendStatus,
    friendStatus,
    setIsBlocked,
    setIAmBlocked,
    isMyProfile,
  }), [user, isMyProfile, friendStatus, iAmBlocked, isBlocked]);

  return (
    <Container bg={bgColors[accountType]}>
      <Avatar user={user} isMyProfile={isMyProfile} />
      <div className="text-center">
        <PlayerRating
          ratingInfo={ratingInfo}
          section="profile"
          myProfile={userId === accountInfoUserId}
          user={user}
        />
      </div>
      <Contain justify="center" align="center">
        <H2 textAlign="center">
          {`${firstName} ${lastName} `}
          <UserID>
            #{userId}
          </UserID>
        </H2>
      </Contain>

      {isBlocked && (
        <Contain align="center" justify="center" textAlign="center" margin="6px 0 0 0">
          <BlockedIcon src={LoadImage('blocked-red-icon.svg')} />
          <P color="darkRed">
            <Trans ns="settings" i18nKey="profileSettings.blockedPlayers.youBlockedThisPlayer">
              {'You\'ve blocked this player'}
            </Trans>
          </P>
        </Contain>
      )}

      <UserContext.Provider value={contextValue}>
        <MobileGameLevelContainer>
          <GameLevelInfo />
        </MobileGameLevelContainer>
        <Options
          isMyProfile={isMyProfile}
          isImpersonate={isImpersonate}
        />
        <Info />
      </UserContext.Provider>
    </Container>
  );
};

export default TopSection;
