export default {
  completeProfile: {
    profileDetails: 'Podrobnosti o profilu',
    fillProfileDetails: 'Vyplòte své podrobnosti o profilu',
    sportyaAvailability: 'Platforma Sportya je k dispozici hráèùm v Rumunsku, Moldavsku a Bulharsku. Již brzy budou dostupné další zemì',
    year: 'Rok',
    month: 'Mìsíc',
    day: 'Den',
    gender: 'Pohlaví',
    location: 'Místo',
    locationNotSet: '<0>Místo:</0> Zadejte svou polohu',
    userLocationNotSet: '<0>Místo:</0> Dosud nenastaveno',
    age: 'Vìk',
    ageNotSet: '<0>Vìk:</0> Zadejte svùj vìk',
    userAgeNotSet: '<0>Vìk:</0> Dosud nenastaveno',
    handNotSet: '<0>Hrající ruka:</0>Zadejte svou hrající ruku',
    userHandNotSet: '<0>Hrající ruka:</0> Dosud nenastaveno',
    searchLocation: 'Hledat místo...',
    chooseDominantHand: 'Vybrat dominantní ruku',
    left: 'Levá',
    right: 'Pravá',
    continue: 'Pokraèovat',
    saveAndContinue: 'Uložit a pokraèovat',
    uploadNewPhoto: 'Nahrát novou fotografii',
    removePhoto: 'Odebrat fotografii',
    profileVisibility: 'Viditelnost profilu',
    fillProfileVisibility: 'Zvolte zpùsob, jak chcete, aby vás hráèi vidìli',
    pictureMaxSize: 'Profilový obrázek musí být menší než 1 MB.',
    allPlayers: 'Všichni hráèi',
    allPlayersDescription: 'Váš profil bude viditelný pro všechny hráèe, kteøí budou mít možnost posílat vám zprávy, pøidat si vás mezi pøátele a zvát vás na pøátelská utkání.',
    allPlayersSubtitle: 'Doporuèujeme tuto možnost, abyste si mohli prostøedí {{ siteName }} plnì užívat!',
    onlyFriendsAndTheirFriends: 'Pouze vaši pøátelé a jejich pøátelé',
    onlyFriendsAndTheirFriendsDescription: 'Váš profil bude viditelný pouze hráèùm, kteøí byli pøidáni jako vaši pøátelé a jejich pøátelùm. \n\n Všichni ostatní hráèi uvidí omezenou verzi vašeho profilu (Celé jméno, Èíslo hráèe, Místo, Profilová fotografie) a budou moci Posílat vám zprávy a Pøidat si vás mezi pøátele.',
    onlyFriends: 'Pouze vaši pøátelé',
    onlyFriendsDescription: 'Váš profil bude plnì viditelný pouze hráèùm, kteøí byli pøidáni jako vaši pøátelé. \n\n Všichni ostatní hráèi uvidí omezenou verzi vašeho profilu (Celé jméno, Èíslo hráèe, Místo, Profilová fotografie) a budou moci Posílat vám zprávy a Pøidat si vás mezi pøátele.',
    gameLevel: 'Úroveò hry',
    gameLevelDescription: 'Když vstoupíme na tenisový kurt, chceme hrát vyrovnané zápasy. Standardem pro uspokojivé tenisové zážitky na platformì Sportya se z tohoto dùvodu stalo sebehodnocení úrovnì hry a na ní založené zápasy a události.',
    gameLevelList: {
      1: 'Na zaèátku si nastavíte úroveò hry, která odpovídá vašemu hernímu potenciálu ve dvouhøe. Tato úroveò bude automaticky vaší poèáteèní i pro zápasy ve ètyøhøe, ale úrovnì hry pro dvouhru a ètyøhru budou / mohou být upraveny na základì výsledkù vašich zápasù. ',
      2: 'Jedná se o velmi dùležitý krok, který mùže mít velký vliv na vaše první zkušenosti s událostmi a zápasy na platformì Sportya. Moudøe posuïte svou úroveò, své schopnosti nepøeceòujte ani nepodceòujte. Zkuste být co nejobjektivnìjší.',
      3: 'Úroveò 4 odpovídá zaèáteèníkùm a úroveò 9 je nejblíže úrovni profesionální hry (obvykle ji volí vysloužilí profesionální hráèi).',
      4: 'Na platformì Sportya se úroveò hry dynamicky pøizpùsobuje a mìní na základì výsledkù.',
    },
    chooseGameLevel: 'Zvolit úroveò hry',
    seeVideoDemonstration: 'Zobrazit videoukázku',
    selectThisLevel: 'Zvolit tuto úroveò',
    skipForNow: 'Zatím pøeskoèit',
    level: 'Úroveò',
    levelDescription: {
      4: 'Hráè je nadšeny, touží se uèit, má znalosti tenisové hry a umí udržet míè ve høe. Hráè nemá dostateènou herní rychlost, má pøerušované / neúplné pohyby a jeho slabou stránkou je pokrytí kurtu.',
      5: 'Hráè umí využívat pokrytí kurtu k vytváøení pøíležitostí, je pomìrnì konzistentní pøi úderech z forhendu a dokáže udržet konzistentní rychlou výmìna míèù. Stále má nedostatky v rychlosti hry, jeho pohyby jsou celkovì nejisté a støely jsou stále pøerušované / nedokonèené. Èasto dochází k absenci rozhodujících zásahù.',
      6: 'Hráè má lepší kontrolu nad míèem pøi úderech z forhendu i bekhendu, dokáže vyvinout konzistentní tempo hry a plánovitì navyšuje své bodové skóre. I když je bekhend provádìn pod kontrolou, je stále nekonzistentní a druhý servis je obvykle pomalý a neefektivní / špatnì umístìný. I pøes vytvoøené herní pøíležitosti v jeho høe stále chybí rozhodující støely.',
      7: 'Hráè umí využít sílu zejména pøi forhendu, mìní své údery, ovládá rotaci a dokáže využívat slabiny svého soupeøe a tak navyšovat své skóre. V okamžicích pøevahy však chybí spekulace, síla mùže využívána nesprávnì, v klíèových momentech hry se stále vyskytují chyby a bekhend stále postrádá agresivitu.',
      8: 'Hráè kontroluje rotaci a zároveò zvyšuje rychlost, hraje agresivnì, používá smíšené údery a jen málokdy dìlá nevynucené chyby. V nìkterých pøípadech však postrádá trpìlivost a v momentech vysokého tlaku má obecnì neúèinný bekhend.',
      9: 'Hráè používá svùj servis jako zbraò, ze které jde strach, má ve svém arzenálu rùzné údery, støídá rotace a používá èasto nepøedvídatelné údery, které jsou úèinné jak z forhendu, tak z bekhendu.',
    },
    profilePicture: 'Profilový obrázek',
    looksGreat: 'Vypadá skvìle',
    profilePictureSubtitle: 'Díky fotografiím vás \n vaši pøátelé v {{ siteName }} poznají',
    uploadYourPhoto: 'Nahrajte svou fotografii',
    availability: 'Dostupnost',
    setAvailability: 'Nastavit dostupnost',
    availabilityDescription: 'Nastavením dostupnosti hry snadnìji naleznete své herní partnery',
    completeProfile: 'Dokonèit profil',
    Mon: 'Po',
    Tue: 'Út',
    Wed: 'Stø',
    Thu: 'Èt',
    Fri: 'Pá',
    Sat: 'So',
    Sun: 'Ne',
  },
  logout: {
    wantToLogout: 'Odhlásit uživatele?',
    logoutParagraph: 'Opravdu se chcete odhlásit?',
    btnCancel: 'Storno',
    btnLogout: 'Odhlásit se',
  },
  wallet: {
    seoTitle: 'Virtuální penìženka | Sportya',
    walletInfo: 'Virtuální penìženka Sportya je platební systém, který umožòuje rychlou registraci na akce a platby za další služby Sportya. Penìženku si mùžete nabít a pozdìji ji na celé platformì Sportya použít jako platební prostøedek. Platby se ovìøují okamžitì. Vaše platební údaje / údaje o kartì neukládáme.  ',
    chooseAmountToTopUp: 'Zvolte èástku, kterou chcete nabít',
    noTransactions: 'Žádné transakce',
    virtualWallet: 'Virtuální penìženka',
    summerMembership: 'Letní sezónní úèet',
    premiumMembership: 'Prémiový úèet',
    availableFunds: 'Dostupné finanèní prostøedky',
    transactionId: 'ID transakce',
    date: 'Datum',
    product: 'Produkt',
    amount: 'Èástka',
    topUp: 'Nabít',
    transactionTypes: {
      topUp: 'Virtuální penìženka: Nabít',
      topUpByAdmin: 'Virtuální penìženka: Nabít (provedeno správcem)',
      subtractByAdmin: 'Virtuální penìženka: Odeèíst (provedeno správcem)',
      eventRegistration: 'Registrace události: <1>{{ eventName }}</1>',
      eventWithdrawal: 'Odstoupení z události: <1>{{ eventName }}</1>',
      upgradeMembership: 'Upgrade èlenství: <1>{{ membershipName }}</1>',
      upgradeMembershipByAdmin: 'Upgrade èlenství (provedeno správcem): <1>{{ membershipName }}</1>',
      currencyNotSet: 'Abyste mohli zaèít používat funkci Virtuální penìženka a dobíjení, musíte zadat svou polohu.',
      balanceAdjustmentByAdmin: 'Úprava zùstatku (provedeno správcem)',
      waitingListRefund: 'Automatická náhrada v poøadníku',
      mobile: {
        id: 'Èíslo',
        date: 'Datum',
        type: 'Typ',
        amount: 'Èástka',
      },
    },
  },
  orders: {
    title: 'Mé objednávky',
    subtitle: 'Historie objednávek',
    seoTitle: 'Mé objednávky | Sportya',
    orderList: {
      orderID: 'Èíslo objednávky',
      dateTime: 'Datum a èas',
      orderType: 'Typ objednávky',
      amount: 'Èástka',
      paymentMethod: 'Zpùsob platby',
      orderDetails: 'Podrobnosti objednávky',
      viewDetails: 'Zobrazit podrobnosti',
      invoiceInfo: 'Informace o faktuře',
      viewInvoice: 'Zobrazit fakturu',
      invoiceNotAvailable: "Not Available",
      orderSummary: {
        title: 'Podrobnosti objednávky',
        seeEvents: 'Zobrazit událost',
        about: 'Zápis do turnaje byl potvrzen. Od této chvíle nelze tuto objednávku mìnit. Máte-li další dotazy, kontaktujte nás na',
        about2: 'Další informace o našich Zásadách vracení jsou dostupné na webové adrese',
        returnPolicy: 'Zásady vracení',
        summary: 'Shrnutí',
        bookingFee: 'Rezervaèní poplatek',
        sprintenDonation: 'Finanèní pøíspìvek Sprinten',
        coupon: 'Kupón',
        total: 'Celkem',
        inclTVA: 'Vèetnì DPH',
        clubEntryFees: 'Vstupní poplatek klubu',
        howToPayEntryFee: 'Platí se pøi pøíjezdu do hostitelského klubu. Hostitelský klub: ',
        data: {
          id: 'Èíslo objednávky',
          type: 'Typ objednávky',
          date: 'Datum a èas',
          method: 'Zpùsob platby',
        },
      },
      mobile: {
        ID: 'Èíslo',
        Date: 'Datum: ',
        Type: '<0>Typ:</0>',
        Price: '<0>Èástka:</0>',
        Payment: 'Zpùsob platby',
      },
    },
    noOrders: 'Žádné pøedchozí objednávky neexistují',
  },
  notifications: {
    title: 'Upozornìní',
    subtitle: 'Nejnovìjší upozornìní',
    seoTitle: 'Upozornìní | Sportya',
    seeDetails: 'Zobrazit podrobnosti',
    noNotifications: 'Nebylo nalezeno žádné upozornìní.',
    dropdown: {
      title: 'Upozornìní',
      allNotifications: 'Zobrazit všechna upozornìní',
    },
    topUp: "Zùstatek vaší virtuální penìženky byl aktualizován. <0>Zobrazit podrobnosti</0>.",
    gameLevelChangedByAdmin: "Správce upravil vaši úroveò hry. Vaše nová úroveò je {{ gameLevelSingle }} pro dvouhry, {{ gameLevelDouble }} pro ètyøhry. <0>Zobrazit podrobnosti</0>.",
    eventRegistrationBan: "Kvùli sankcím, které byly uplatnìny pro váš úèet, se již nemùžete registrovat na události. <0>Zobrazit podrobnosti</0>.",
    penaltyAdministrative: "Správce odeèetl z vašeho hodnocení body. <0>Zobrazit podrobnosti</0>",
    penaltyLateWithdrawal: "Vaše opoždìné odstoupení od události <0>{{ eventName }}</0> bylo zpracováno. Váš registraèní poplatek byl zachován.",
    penaltyAnnouncedWithdrawal: "Vaše odstoupení od události <0>{{ eventName }}</0> bylo zpracováno a váš registraèní poplatek byl pøidán do vaší <0>virtuální penìženky</0>.",
    teamWithdrawalByAdmin: "Váš tým byl odvolán od události <0>{{ eventName }}</0>. <0>Zobrazit podrobnosti</0>.",
    playerWithdrawalByAdmin: "Byli jste odvoláni od události <0>{{ eventName }}</0>. <0>Zobrazit podrobnosti</0>.",
    teamWithdrawalFromDoubles: "Váš týmový partner <0>{{ partnerName }}</0> odvolal tým z <0>{{ eventName }}</0>.",
    registrationByAdminOnSLForDoubles: "Registrace vašeho týmu na <0>{{ eventName }}</0> v rozdìlení do seznamu byla úspìšná. <0>Zobrazit podrobnosti</0>.",
    registrationByAdminOnSLForSingles: "Vaše registrace na <0>{{ eventName }}</0> v rozdìlení do seznamu byla úspìšná. <0>Zobrazit podrobnosti</0>.",
    registrationByAdminOnWLForDoubles: "Registrace vašeho týmu na <0>{{ eventName }}</0> v poøadníku byla úspìšná. <0>Zobrazit podrobnosti</0>.",
    registrationByAdminOnWLForSingles: "Vaše registrace na <0>{{ eventName }}</0> v poøadníku byla úspìšná. <0>Zobrazit podrobnosti</0>.",
    registrationByAdminOnMLForDoubles: "Registrace vašeho týmu na <0>{{ eventName }}</0> v hlavním losování byla úspìšná. <0>Zobrazit podrobnosti</0>.",
    registrationByAdminOnMLForSingles: "Vaše registrace na <0>{{ eventName }}</0> v hlavním losování byla úspìšná. <0>Zobrazit podrobnosti</0>.",
    promotionToMLForDoubles: "Vzhledem k odstoupení ze soutìže ètyøher na <0>{{ eventName }}</0> váš tým postoupil do hlavního losování. <0>Zobrazit podrobnosti</0>.",
    promotionToMLForSingles: "Vzhledem k odstoupení ze soutìže dvouher na <0>{{ eventName }}</0> jste postoupili do hlavního losování. <0>Zobrazit podrobnosti</0>.",
    eventRegistrationSLOpen: "Registrace do rozdìlení do seznamu pro <0>{{ eventName }}</0> jsou otevøené. <0>Pøipojit se</0>.",
    eventRegistrationSLClosed: "Registrace do rozdìlení do seznamu pro <0>{{ eventName }}</0> byly uzavøeny. Probíhá tøídìní pro hlavní seznam a poøadník na základì pozice v žebøíèku. <0>Zobrazit podrobnosti</0>.",
    eventRegistrationMLOpen: "Registrace do hlavního seznamu pro <0>{{ eventName }}</0> jsou otevøené. <0>Pøipojit se</0>.",
    eventRegistrationMLClosed: "Registrace do hlavního seznamu pro <0>{{ eventName }}</0> byly uzavøeny. Registrace do poøadníku jsou až do dne pøed zahájením soutìže stále otevøené. <0>Zobrazit podrobnosti</0>.",
    eventMainDrawPublished: "Registrace do hlavního seznamu pro <0>{{ eventName }}</0> byly uzavøeny. Registrace do poøadníku jsou až do dne pøed zahájením soutìže stále otevøené. <0>Zobrazit podrobnosti</0>.",
    eventStarted: "Dnes zaèínají zápasy na <0>{{ eventName }}</0>. <0>Zobrazit podrobnosti</0>.",
    eventEnded: "Zápasy na <0>{{ eventName }}</0> skonèily . Probíhá aktualizace výsledkù a výhercù. <0>Zobrazit podrobnosti</0>.",
    eventClosed: "Výsledky pro <0>{{ eventName }}</0> byly aktualizovány. <0>Zobrazit podrobnosti</0>.",
    eventAnnouncementUpdates: "<0>{{ eventName }}</0> aktualizace turnaje: \n\n {{ announcement }}",
    friendRequestReceived: "Máte žádost o přátelství od {{ partnerName }}. <0>Zobrazit podrobnosti</0>.",
    friendRequestAccepted: "Na Sportya máte nového přítele - {{ partnerName }}. <0>Prohlédnout profil</0>.",
    friendJoined: "{{ partnerName }} se připojil ke Sportya na vaše pozvání. <0>Prohlédnout profil</0>.",
    friendRaisedHand: "{{ partnerName }} hledá přátelský zápas na Sportya. <0>Pozvat na zápas</0>.",
    invitationReceived: "{{ partnerName }} vás pozvalo na zápas {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Viz pozvánka</0>.",
    pastMatchInvite: "Potvrďte svou účast a skóre pro {{ matchGameType }} zápas s {{ partnerName }} - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    matchAccepted: "{{ partnerName }} přijal/a tvou pozvání na {{ matchGameType }} zápas - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    matchDeclined: "{{ partnerName }} odmítl(a) vaše pozvání na hru {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    matchDenied: "{{ partnerName }} odmítl účast ve vašem zápase {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    playerWithdrew: "{{ partnerName }} odstoupil z vašeho zápasu {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    addScoreReminder: "Přidejte svůj skóre z vašeho zápasu {{ matchGameType }} - <strong>{{ matchFormatedId }}</strong>. <0>Přidejte skóre</0>.",
    upcomingMatchReminder: "Tvůj zápas {{ matchGameType }} začíná za 30 minut - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    pastMatchConfirmed: "{{ partnerName }} potvrdil(a) zápas {{ matchGameType }} a výsledek - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    scoreAdded: "{{ partnerName }} přidal skóre pro tvoji {{ matchGameType }} zápas - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    scoreChanged: "{{ partnerName }} změnil skóre tvého {{ matchGameType }} zápasu - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    scoreValidated: "Konečné skóre vašeho zápasu {{ matchGameType }} bylo ověřeno - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    canceledMatch: "Tvůj {{ matchGameType }} zápas byl zrušen - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    updatedMatch: "Váš zápas typu {{ matchGameType }} byl aktualizován - <strong>{{ matchFormatedId }}</strong>. <0>Jdi na zápasy</0>.",
    playerRatingReceived: "Právě jste obdrželi nové hodnocení z přátelského zápasu. <0>Viz průměr hodnocení</0>.",
    newRewardReceived: "Gratulujeme! Obdrželi jste novou odměnu. <0>Zkontrolujte Virtuální Penìženka</0>",
    bookingCancellation: "Vaše rezervace v <strong>{{ clubName }}</strong> byla zrušena klubem - <strong>{{ bookingId }}</strong>. <0>Zobrazit Detaily Rezervace</0>.",
    bookingRescheduled: "Vaše rezervace v <strong>{{ clubName }}</strong> byla klubem přeplánována - <strong>{{ bookingId }}</strong>. <0>Zobrazit Detaily Rezervace</0>.",
  },
  creditCard: 'Kreditní karta',
  virtualWallet: 'Virtuální penìženka',
  summerMembership: 'Letní sezónní úèet',
  premiumMembership: 'Prémiový úèet',
  premium: 'Prémiový {{period}} úèet',
  undefinedMembership: 'Prémiový',
  transactionTypes: {
    topUp: 'Virtuální penìženka: Nabít',
    topUpByAdmin: 'Virtuální penìženka: Nabít (provedeno správcem)',
    subtractByAdmin: 'Virtuální penìženka: Odeèíst (provedeno správcem)',
    eventRegistration: 'Registrace události: <0>{{ eventName }}</0>',
    eventWithdrawal: 'Odstoupení z události: <0>{{ eventName }}</0>',
    upgradeMembership: 'Upgrade èlenství: <1>{{ membershipName }}</1>',
    upgradeMembershipByAdmin: 'Upgrade èlenství (provedeno správcem): <1>{{ membershipName }}</1>',
    balanceAdjustmentByAdmin: 'Úprava zùstatku (provedeno správcem)',
    waitingListRefund: 'Automatická náhrada v poøadníku',
    currencyNotSet: 'Abyste mohli zaèít používat funkci Virtuální penìženka a dobíjení, musíte zadat svou polohu.',
    date: '<0>Datum:</0> {{ createdAt }}',
    firstTaskCompletion: "Odměna: Vlastní aktivita",
    referralReward: "Odměna: Akce hostujícího hráče",
  },
  notificationButton: 'Tlaèítko Upozornìní',
  latestTransactions: 'Poslední transakce',
  partnerEventRegistration: 'Partner - Registrace události: <1>{{ eventName }}</1>',
  planUpdate: 'Aktualizovat èlenství: <1>{{ membershipName }}</1>',
  referral: {
    title: "Pozvěte své přátele a získejte odměny",
    title2: "Vytvořte si účet a získejte odměny!",
    subtitle: "Pozvěte své přátele do Sportya a získejte odměny pro první {{ maxRewards }} hráče*, kteří se připojí ke Sportya pomocí vašeho zvacího kódu! Poté, co <strong>pozvaný hráč poprvé úspěšně dokončí jednu z následujících akcí</strong>, oba obdržíte <strong>{{ amount }} {{ currency }}</strong> do svých virtuálních peněženek**:",
    subtitle2: "Získejte odměnu, jakmile poprvé úspěšně dokončíte JEDNU z následujících akcí:",
    item1: "Získá svůj první ověřený výsledek na turnaji Sportya",
    item11: "Získáte svůj první ověřený výsledek na turnaji Sportya",
    item2: "Načítání jejich virtuální peněženky",
    item22: "Načítáte svou virtuální peněženku",
    item3: "Nákup prémiového účtu pomocí virtuální peněženky nebo bankovní karty",
    item4: "První účast v přátelském utkání s ověřeným skóre",
    amountEarned: "{{ amount }} {{ currency }} celkem",
    rewardsCompleted: "Odměny dokončeny {{ receivedRewards }}/{{ maxRewards }}",
    yourFriend: "Pozvat",
    referVia: "Pozvat pomocí",
    shareTitle: "Sdílet na",
    smallText1: "*Zvoucí hráč bude odměněn pouze za prvních {{ maxRewards }} hráčů, kteří se připojí ke Sportya s referenčním kódem a napoprvé úspěšně dokončí jednu z požadovaných akcí.",
    smallText2: "**Do virtuální peněženky odměněného hráče bude automaticky připsáno 5 eur nebo ekvivalent v jejich měně virtuální peněženky, jakmile bude úspěšně dokončena jedna ze způsobilých akcí. Každý uživatel obdrží odměnu pouze jednou, a to pouze za jednu z způsobilých akcí.",
    smallText3: "*Odpovídající částka hodnoty odměny bude připsána k vašemu zůstatku v měně virtuální peněženky Sportya.",
    smallText4: "**Při prvním dokončení bude odměněna pouze jedna z požadovaných akcí.",
    smallText5: "Přečtěte si prosím <1>Smluvní vztahy a podmínky</1> se více o Sportya Rewards",
    inviteFriends: "Pozvěte své přátele a získejte odměny",
    inviteFriendsDescription: "Získejte <strong>{{ amount }} {{ currency }}</strong> pro první {{ maxRewards }} hráče, kteří se připojí ke Sportya pomocí vašeho zvacího kódu!",
    inviteYourFriends: "Pozvi své přátele",
    invite: {
      playTogether: 'Pozvěte své přátele a hrajte spolu',
      friends: 'Pozvi své přátele',
    },
    shareToMedia: {
      email: {
        title: 'Pojďme si zahrát tenis ve Sportya',
        body: `Dobrý den! %0D%0A
Používám Sportya, soutěžní sportovní platformu pro amatérské tenisty, a zvu vás, abyste se připojili! %0D%0A
Ve Sportya se můžete setkat s ostatními hráči, hrát spolu přátelská utkání nebo se zúčastnit turnajů a bojovat o první místa v národním i mezinárodním žebříčku! %0D%0A
Je to skvělý způsob, jak zůstat aktivní a společně se bavit. %0D%0A
%0D%0A
Připojte se nyní ke Sportya pomocí mého jedinečného zvacího odkazu a získejte odměny! %0D%0A
{{inviteLink}}`,
      },
      facebook: `Join me on Sportya, the competitive sports platform for amateur tennis players!\r\n
We can connect with other players, play friendly matches together, or join tournaments and compete for the top spots in national and international rankings!\r\n
Use my unique invitation link: {{inviteLink}}`,
      twitter: 'Přidejte se ke mně do Sportya, soutěžní sportovní platformy pro amatérské tenisty!%0aVytvořte si účet a seznamte se s ostatními hráči v přátelských zápasech, národních a mezinárodních turnajích!%0a',
    },
    invitedBy: 'Pozval vás <1>{{referee}}</1>.',
    joinSportya: 'Vytvořte si účet a získejte odměny! <1>Zjistit více</1>.',
    share: 'Sdílejte svou lásku k tenisu! Pozvěte své přátele do Sportya a zahrajte si spolu přátelské zápasy nebo bojujte o přední místa v národních a mezinárodních žebříčcích!',
    copyAndShare: 'Zkopírujte a sdílejte odkaz na pozvánku',
    willBeFriend: 'Přátelé, které pozvete a zaregistrujete se do Sportya, budou automaticky přidáni do vašeho Seznamu přátel.',
    copy: 'Kopírování',
    dashboardBannerTitle: "Jste o krok blíž k odměně",
    dashboardBannerSubtitle: "Získejte <1>{{ amount }} {{ currency }}</1> když poprvé úspěšně dokončíte JEDNU z následujících akcí:",
    congrats: 'Gratulujeme! Právě jste obdrželi <1>{{ amount }} {{ currency }}</1> do vaší <3>Virtuální Peněženky</3> po úspěšném dokončení první výzvy na Sportya!',
    findOutMore: 'Zjistěte více o <1>odměnách Sportya</1> nebo si prostudujte <3>Smluvní vztahy a podmínky</3>',
  },
  ratePlayer: 'Ohodnoťte hráče',
  changeRating: 'Změňte udělenou známku',
  receivedRating: '{{ total }} obdržená hodnocení',
  receivedRatings: '{{ total }} obdržená hodnocení',
  ratePlayerInfo: 'Ostatní hráče můžete hodnotit až poté, co jste se s nimi zúčastnili alespoň jednoho přátelského zápasu.<br/>Hráčův průměr hodnocení se vypočítává na základě všech hodnocení obdržených od ostatních<br/>hráčů a bude veřejně zobrazen na profilu daného hráče až po obdržení minimálně 3 hodnocení.',
  noRatings: 'Žádné hodnocení',
  rate: 'Uložit hodnocení',
  removeRating: 'Zrušit hodnocení',
  ownRatingInfo: 'Vaše průměrné hodnocení bude viditelné pro všechny hráče až poté, co obdržíte alespoň 3 hodnocení od hráčů, se kterými jste komunikovali.',
  skipRating: 'Hodnotím později',
  howWouldRatePlayer: 'Ohodnoťte hráče',
  howWouldRateTitlePartner: 'Ohodnoťte Partnera',
  howWouldRateTitleOpponent: 'Ohodnoťte Soupeře 1',
  howWouldRateTitleOpponentPartner: 'Ohodnoťte Soupeře 2',
  thankYouForRating: 'Děkujeme za vaše hodnocení!',
};
