export default {
  seoTitle: 'My Settings | Sportya',
  chat: 'Chat',
  addFriend: 'Add Friend',
  cancelRequest: 'Cancel Request',
  confirmRequest: 'Confirm Request',
  friends: 'Friends',
  unfriend: 'Unfriend',
  profileSettings: {
    title: 'My Settings',

    playerDetails: {
      title: 'Account Details',
      passionatePlayer: 'Passionate Tennis Player',
      accountType: 'Account Type',
      upgradeToPremium: 'Upgrade to Premium',
      subscriptionValidity: 'Subscription Validity',
      subscriptionState: 'Never Expires',
      since: 'Since {{createdAt}}',
      free: 'Free',
      summer: 'Summer Account',
      premium: 'Premium {{period}} Account',
      accountMembership: {
        free: 'Free Account',
        summer: 'Summer Account',
        premium: 'Premium Account',
      },
    },

    playerProfile: {
      accountType: {
        free: 'Free Account',
        summer: 'Summer Account',
        premium: 'Premium Account',
      },
    },

    changePassword: {
      title: 'Change Password',
      oldPassword: 'Old Password',
      newPassword: 'New Password',
      confirmNewPassword: 'Confirm New Password',
      passwordRequirement: 'Your new password should be between 8 - 16 alphanumeric characters and include at least one special character.',
      pleaseEnterNewPassword: 'Please enter your new password.',
      passwordsDoNotMatch: 'Passwords do not match.',
      savePassword: 'Save Password',
    },

    personalInformation: {
      title: 'Personal Information',
      firstName: 'First Name',
      lastName: 'Last Name',
      year: 'Year',
      month: 'Month',
      day: 'Day',
      location: 'Location',
      phoneNumber: 'Phone Number',
      invalidPhoneNumber: 'Invalid Phone Number',
      clubTitle: 'Title',
    },

    profileDetails: {
      title: 'Profile Details',
      singleGameLevel: 'Single Game Level',
      doublesGameLevel: 'Doubles Game Level',
      noGameLevel: 'You haven\'t set your game level yet',
      buttonSetLevel: 'Set Your Game Level',
      needOfChange: 'If you need to change the level please get in touch at <1>contact@sportya.net</1>.',
      dominantHand: 'Dominant Hand',
      left: 'Left',
      right: 'Right',
      shirtSize: 'T-shirt Size',
      shirtNone: 'None',
    },

    languageCommunication: {
      title: 'Language & Communication',
      language: 'Language',
      ro: 'Romanian',
      en: 'English',
      hu: 'Hungarian',
      bg: 'Bulgarian',
      email: 'Email',
      newsletter: 'Newsletter',
      emailChatConversations: 'Email notifications for Chat Conversations',
      emailFriendRequests: 'Email notifications for Friend Requests',
      emailFriendlyMatches: 'Email notifications for Friendly Matches',
      subscribe: 'Subscribed to Sportya\'s newsletter (playing opportunities in my region, new features and more)',
    },

    availabilitySchedule: {
      title: 'Availability Schedule',
      Mon: 'Mon',
      Tue: 'Tue',
      Wed: 'Wed',
      Thu: 'Thu',
      Fri: 'Fri',
      Sat: 'Sat',
      Sun: 'Sun',
    },

    blockedPlayers: {
      title: 'Blocked Players',
      blockImplications: 'Once you block someone, that player can no longer invite you to matches, start a conversation with you, or add you as a friend.',
      howToBlock: 'You can block players by clicking Block Player from their profile options.',
      unblock: 'Unblock',
      block: 'Block',
      unblockUser: 'Unblock {{unblockName}}',
      blockUser: 'Block {{unblockName}}',
      areYouSureUnblock: 'Are you sure you want to unblock <1>{{unblockName}}</1> ?',
      areYouSureBlock: 'Are you sure you want to block <1>{{unblockName}}</1> ?',
      unblockInfo: 'The player will be able to invite you to matches, start a conversation with you, or add you as a friend.',
      blockInfo: 'The player will no longer be able to invite you to matches, start a conversation with you, or add you as a friend.',
      blockNote: 'Note: Does not include matches in competitions you both participate in.',
      blockPlayer: 'Block Player',
      unblockPlayer: 'Unblock Player',
      reportPlayer: 'Report Player',
      reportUser: 'Report {{reportName}}',
      reportInfo: 'Please choose the reason for your report and provide any additional information that will help our team better understand the situation. The reported player will not be notified.',
      reportButton: 'Report',
      reportAndBlockButton: 'Report & Block',
      successReportAndBlockTitle: 'You reported and blocked this player',
      successReportText: 'We will review your report and get back to you if any information is needed.',
      gotIt: 'Got it',
      successReportTitle: 'You reported this player',
      youBlockedThisPlayer: 'You’ve blocked this player',
      manageBlockedPlayers: 'Manage Blocked Players',
    },

    billingData: {
      title: 'Billing Data',
      companyName: 'Company Name',
      cui: 'Tax registration code',
      regNumber: 'Registration Number',
      fullName: 'Full Name',
      address: 'Street name, number, floor, etc',
      city: 'City',
      country: 'Country',
    },

    reportReasons: {
      reason: 'Reason',
      selectTheReason: 'Select the Reason',
      harassment: 'Harassment or Bullying',
      spam: 'Spam',
      fakeProfile: 'Fake profile',
      playerUnderFifteen: 'Player under 15 years old',
      other: 'Other',
    },

    reportTextAreaInfo: 'Please provide additional information (optional)',
  },
};
