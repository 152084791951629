import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import { Link, P } from '../../../Collection';
import mixpanel from '../../../../mixpanel';
import { Item, Nav, List } from './styles';

const NavComponent = ({
  isLoggedIn, accountRole, mobile, whiteNavText, className,
}) => {
  const router = useLocation();
  const { pathname } = router;
  const { t } = useTranslation('header');

  const menu = {
    // isLoggedIn: true -> accountRole: player / club
    true: {
      player: [
        { url: '/dashboard', name: t('home') },
        { url: '/events', name: t('findEvent') },
        { url: '/friendly-matches', name: t('friendlyMatches') },
        { url: '/clubs', name: t('bookCourt') },
        { url: '/find-player', name: t('findAPlayer') },
        { url: '/leaderboards', name: t('leaderboards') },
        // { url: '/for-players', name: t('forPlayers') },
        { url: '/plans', name: t('membership') },
        // { url: 'coming-soon', name: t('comingSoon') },
      ].filter(v => !!v),
      club: [
        { url: '/my-account', name: t('home') },
        // { url: 'coming-soon', name: t('comingSoon') },
      ],
    },
    // isLoggedIn: false -> accountRole: undefined
    false: {
      undefined: [
        { url: '/', name: t('home') },
        { url: '/events', name: t('findEvent') },
        { url: '/friendly-matches', name: t('friendlyMatches') },
        { url: '/clubs', name: t('bookCourt') },
        { url: '/for-players', name: t('forPlayers') },
        { url: '/for-clubs', name: t('forClubs') },
        { url: '/plans', name: t('membership') },
        // { url: 'coming-soon', name: t('comingSoon') },
      ].filter(v => !!v),
    },
  };

  const trackClick = (item) => {
    if (item.url === 'friendly-matches') {
      mixpanel.track('Click on Friendly Matches from header');
    }
  };

  const baseUrl = i18next.language === 'en' ? '' : `/${i18next.language}`;

  const isActive = (itemUrl) => {
    if (itemUrl === '/friendly-matches') {
      return (pathname.split('/')).includes('friendly-matches');
    }

    if (baseUrl) {
      return `${baseUrl}${itemUrl}` === pathname;
    }
    return itemUrl === pathname;
  };

  return (
    <Nav {...className && { className }}>
      <List mobile={mobile}>
        {menu?.[isLoggedIn]?.[accountRole]?.map((item, key) => (
          <Item
            key={key}
            {...{ whiteNavText, mobile }}
            active={isActive(item.url)}
          >
            <Link to={item.url} onClick={() => trackClick(item)}>
              <P bold>
                {item.name}
              </P>
            </Link>
          </Item>
        ))}
      </List>
    </Nav>
  );
};

export default NavComponent;
