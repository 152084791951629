import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { ShowListStyle, ListModal } from './styles';

import Button from '../../../../../components/Form/Button';
import Paragraph from '../../../../../components/Paragraph';
import Modal from '../../../../../components/Modal/Modal';
import PlayerModal from '../PlayerModal';

const ShowList = ({
  type, data, players, availableSlots, reservedSlots, gameType, pdf, eventId,
}) => {
  const [modal, setModal] = useState(false);
  const occupiedSlots = players.length;
  const { t } = useTranslation('events');

  const modalHandler = () => {
    setModal(!modal);
  };

  const slots = {
    title: t(occupiedSlots !== 1 ? 'slotsOccupied' : 'slotOccupied'),
    occupiedSlots,
    ...(type !== 'sortList' && { subtitle: t(availableSlots !== 1 ? 'slotsAvailable_space' : 'slotAvailable_space') }),
    ...(type !== 'sortList' && { availableSlots }),
  };

  const wildcards = players.filter(player => player.slotType === 'WILD_CARD');
  const freeReservedSlots = reservedSlots - wildcards.length;

  return (
    <ShowListStyle bg={type === 'pdf' ? 'mediumTurquoise' : data.bg} color={data.color}>
      {modal && (
        <Modal
          type2
          noborder
          nobackground
          hide={() => modalHandler()}
          isShowing
          headerList
          title={t(type === 'mainList' ? 'acceptanceList' : type)}
          slots={slots}
          nooverflow
        >
          <ListModal>
            {players?.map((player, idx) => (
              <PlayerModal player={player} key={idx} id={idx} gameType={gameType} />
            ))}
            {type === 'mainList' ? (
              <>
                {[...Array(availableSlots)].map((v, i) => <PlayerModal key={i} available id={(occupiedSlots) + i} />)}

                {[...Array(freeReservedSlots)].map((v, i) => (
                  <PlayerModal key={i} reserved id={(occupiedSlots + availableSlots) + i} />
                ))}
              </>
            ) : ''}
          </ListModal>
        </Modal>
      )}

      <div className="showList">
        <Paragraph>
          {type === 'waitingList'
            ? (
              <Trans ns="events" i18nKey={data.translationKey} count={data.occupiedSlots}>
                <strong>List:</strong>
                {{ occupied: data.occupiedSlots > 0 ? data.occupiedSlots : 0 }}
              </Trans>
            ) : (
              type === 'pdf'
                ? <Trans ns="common" i18nKey="viewPDF">View the Main Draw in PDF</Trans>
                : (
                  <Trans ns="events" i18nKey={data.translationKey} count={data.availableSlots}>
                    <strong>List:</strong>
                    {{ available: data.availableSlots }}
                  </Trans>
                )
            )}
        </Paragraph>
        {type === 'pdf' ? (
          <Button
            onClick={() => window.open(`${process.env.REACT_APP_API_HOST}assets/events/${eventId}/${pdf}`, '_blank')}
            outline
            wide
            smaller
          >
            <Trans ns="common" i18nKey="download">Download</Trans>
          </Button>
        ) : (
          <Button
            onClick={(e) => { modalHandler(e); }}
            outline
            wide
            smaller
          >
            <Trans ns="common" i18nKey="show">Show</Trans>
          </Button>
        )}
      </div>
    </ShowListStyle>
  );
};

export default ShowList;
